import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import ControlSignalHandler from './ControlSignalHandler';
import ChargePlanHandler from './ChargePlanHandler';
import { getSignalIcon } from './util/ComponentUtils';

/**
 * A control signal button with overlay.
 */
class ControlSignalButton extends Component {

    constructor(props) {
        super(props);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onPanelHide = this.onPanelHide.bind(this);
        this.state = {
            showPanel: false
        };
    }

    onButtonClick(event) {
        // toggle
        const newState = !this.state.showPanel;
        if (newState) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Control panel shown");
            }
            this.controlPanel.show(event);
        } else {
            this.controlPanel.hide(event);
        }
        this.setState({ showPanel: newState });
    }

    onPanelHide() {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Control panel hidden");
        }
        this.setState({ showPanel: false });
    }

    render() {
        if (this.props.signal !== undefined) {
            return (
                <div>
                    <OverlayPanel ref={(el) => { this.controlPanel = el; }} dismissable={true} showCloseIcon={true} onHide={this.onPanelHide} style={{ minWidth: '20em'}}>
                        <p><b>{this.props.signal.name}</b></p>
                        {(this.props.signal.type === 'chargeplan') ?
                            (<ChargePlanHandler installationId={this.props.installationId} componentId={this.props.componentId} chargePlan={this.props.signal} active={this.state.showPanel} />) :
                            (<ControlSignalHandler installationId={this.props.installationId} componentId={this.props.componentId} signal={this.props.signal} active={this.state.showPanel} />)
                        }
                    </OverlayPanel>
                    <Button label={this.props.signal.name} icon={getSignalIcon(this.props.signal.type)} onClick={this.onButtonClick} style={{ width: '90%' }} />
                </div>
            );
        } else {
            return (<div></div>);
        }
    }
}

export default ControlSignalButton;