import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import Auth0Config from '../config/Auth0Config';
import { create } from 'apisauce';
import Cookies from 'js-cookie';

/**
 * Creates the Auth API.
 */
const createAuthApi = () => {

    // creates the internal API
    const api = create({
        baseURL: "https://" + Auth0Config.domain
    });

    /**
     * Authenticates using user name and password.
     * @param {String} username the user name
     * @param {String} password the password
     * @returns an authentication result with access and id token
     */
    const authenticate = (username, password) =>
        api.post("/oauth/token", { grant_type: 'password', client_id: Auth0Config.clientID, audience: Auth0Config.audience,
            scope: Auth0Config.scope, response_type: Auth0Config.responseType, username: username, password: password });

    /**
     * Resets the user password.
     * @param {String} username the user name
     * @returns a result
     */
    const resetPassword = (username) =>
        api.post("/dbconnections/change_password", { connection: 'Username-Password-Authentication', client_id: Auth0Config.clientID, email: username });

    /**
     * Loads a token from local storage.
     * @returns an authentication result with access and id token
     */
    const loadFromStorage = () => localStorage.getItem('authToken');

    /**
     * Saves a token to local storage.
     * @param {Object} token the authentication token
     */
    const saveToStorage = (token) =>
        localStorage.setItem('authToken', JSON.stringify(token));

    /**
     * Loads a token from cookie.
     * @returns an authentication result with access and id token
     */
    const loadFromCookie = () => Cookies.getJSON('userAuth');

    /**
     * Saves a token to cookie.
     * @param {Object} tokens the authentication tokens as object
     * @param {Object} options the cookie options
     */
    const saveToCookie = (tokens, options) => Cookies.set('userAuth', tokens, options);

    /**
     * Clears the saved cookie.
     */
    const clearCookie = () => Cookies.remove('userAuth');

    // add Reactotron monitor
    if (BuildConfig.isReactotronEnabled) {
        api.addMonitor(Reactotron.apisauce);
    }

    // returns the available API methods as collection of function references (interface)
    return {
        authenticate,
        resetPassword,
        loadFromStorage,
        saveToStorage,
        loadFromCookie,
        saveToCookie,
        clearCookie
    };
};

// create singleton instance
const authApi = createAuthApi();

// export instance
export default authApi;