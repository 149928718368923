import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import evmConfig from '../config/EvmApiConfig';
import { create } from 'apisauce';

/**
 * Creates the EVM API V2.
 * See http://172.28.116.10/apidocs/evm-v2/ for API documentation.
 */
const createEvmApi = () => {

    // creates the internal API
    const api = create({
        baseURL: evmConfig.baseUrl
    });

    /** ----- Authentication ----- */

    /**
     * Sets the API's authentication token.
     * Sets the authorization header.
     * @param {String} tokenType the authentication token type
     * @param {String} token the encoded token
     */
    const setAuthentication = (tokenType, token) => {
        api.setHeader('Authorization', tokenType + ' ' + token);
    };

    /**
     * Clears the API's authentication token.
     * Clears the authorization header.
     */
    const clearAuthentication = () => {
        api.setHeader('Authorization', null);
    };

    /** ----- Management ----- */

    const registerUserAccount = (email, password) =>
        api.post("/management/users", { email: email, password: password });

    /**
     * Deletes the own user account (login), including
     * all installation permissions.
     */
    const deleteUserAccount = () =>
        api.delete("/management/users");

    /**
     * Connects a user to an installation.
     * @param {String} regCode the self-registration code
     */
    const connectUser = (regCode) =>
        api.post("/management/connect", { code: regCode });

    /**
     * Disconnects a user from an installation.
     * @param {Number} id the installation ID
     */
    const disconnectInstallation = (id) =>
        api.delete("/management/installations/" + id);

    /** ----- EVM Data ----- */

    /**
     * Gets all installations.
     * @param {Boolean} withStatus also get status of installation
     * @param {Boolean} withPowerflow also get power flow of installation
     * @param {Boolean} withErrors also get errors of installation
     * @param {Boolean} withPrice also get price of installation
     * @returns a list of installations
     */
    const getAllInstallations = (withStatus, withPowerflow, withErrors, withPrice) =>
        api.get("/installations", { status: withStatus, powerflow: withPowerflow, errors: withErrors, price: withPrice });

    /**
     * Gets an installation.
     * @param {Number} instId the installation ID
     * @param {Boolean} withStatus also get status of installation
     * @param {Boolean} withPowerflow also get power flow of installation
     * @param {Boolean} withErrors also get errors of installation
     * @param {Boolean} withPrice also get price of installation
     * @returns an installation
     */
    const getInstallation = (instId, withStatus, withPowerflow, withErrors, withPrice) =>
        api.get("/installations/" + instId, { status: withStatus, powerflow: withPowerflow, errors: withErrors, price: withPrice });

    /**
     * Gets all building units of an installation.
     * @param {Number} instId the installation ID
     * @returns a list of building units
     */
    const getAllBuildingUnits = (instId) =>
        api.get("/installations/" + instId + "/buildingunits");

    /**
     * Gets a building unit.
     * @param {Number} instId the installation ID
     * @param {Number} unitId the building unit ID
     * @returns a list of installations
     */
    const getBuildingUnit = (instId, unitId) =>
        api.get("/installations/" + instId + "/buildingunits/" + unitId);

    /**
     * Gets all available components of an installation.
     * @param {Number} instId the installation ID
     * @returns a list of components
     */
    const getAllComponents = (instId) =>
        api.get("/installations/" + instId + "/components");

    /**
     * Gets all components assigned to a building unit.
     * @param {Number} instId the installation ID
     * @param {Number} unitId the building unit ID
     * @returns a list of components
     */
    const getComponentsOfUnit = (instId, unitId) =>
        api.get("/installations/" + instId + "/components", { unit: unitId });

    /**
     * Gets a component.
     * @param {*} instId the installation ID
     * @param {*} compId the component ID
     * @returns a component
     */
    const getComponent = (instId, compId) =>
        api.get("/installations/" + instId + "/components/" + compId);

    /**
     * Gets all control signals of all components.
     * @param {Number} instId the installation ID
     * @returns a list of component control signals
     */
    const getAllComponentsControlSignals = (instId) =>
        api.get("/installations/" + instId + "/controls");

    /**
     * Sets control signals of multiple components.
     * @param {Number} instId the installation ID
     * @param {Object} compSignals a list of component control signals to set
     */
    const setAllComponentsControlSignals = (instId, compSignals) =>
        api.put("/installations/" + instId + "/controls", compSignals);

    /**
     * Gets all control signals of a component.
     * @param {Number} instId the installation ID
     * @param {Number} compId the component ID
     * @returns a list of control signals
     */
    const getComponentControlSignals = (instId, compId) =>
        api.get("/installations/" + instId + "/controls/" + compId);

    /**
     * Sets control signals of a component.
     * @param {Number} instId the installation ID
     * @param {Number} compId the component ID
     * @param {Object} compSignals a component control signals object
     */
    const setComponentControlSignals = (instId, compId, compSignals) =>
        api.put("/installations/" + instId + "/controls/" + compId, compSignals);

    /**
     * Gets the condition of an installation.
     * @param {Number} instId the installation ID
     * @returns a condition
     */
    const getMonitoringCondition = (instId) =>
        api.get("/installations/" + instId + "/monitoring/condition");
    
    /**
     * Gets the active errors of an installation.
     * @param {Number} instId the installation ID
     * @returns a list of errors
     */
    const getActiveErrors = (instId) =>
        api.get("/installations/" + instId + "/monitoring/errors");
    
    /**
     * Acknowledges all errors of an installation.
     * @param {Number} instId the installation ID
     * @returns no content
     */
    const ackAllErrors = (instId) =>
        api.post("/installations/" + instId + "/monitoring/ackerrors");

    /**
     * Gets the current power flow within an installation.
     * @param {Number} instId the installation ID
     * @returns a power flow object
     */
    const getCurrentPowerFlow = (instId) =>
        api.get("/installations/" + instId + "/data/current/powerflow");

    /**
     * Gets the current continuous figures of an installation.
     * @param {Number} instId the installation ID
     * @returns a continuous figure object
     */
    const getCurrentFigures = (instId) =>
        api.get("/installations/" + instId + "/data/current/figures");

    /**
     * Gets the current component data, including statistics of the day.
     * @param {Number} instId the installation ID
     * @param {Number} compId the component ID
     * @returns a list of component data objects
     */
    const getCurrentComponentData = (instId, compId) =>
        api.get("/installations/" + instId + "/data/current/components/" + compId);

    /**
     * Gets latest continuous data of all components.
     * @param {Number} instId the installation ID
     * @param {String} series the data series to retrieve (value, amount, costs, count1, count2)
     * @returns an array of component continuous values objects
     */
    const getAllLatestComponentData = (instId, series = 'value') =>
        api.get("/installations/" + instId + "/data/continuous/components", { series: series });

    /**
     * Gets latest continuous data of a component.
     * @param {Number} instId the installation ID
     * @param {Number} compId the component ID
     * @param {String} series the data series to retrieve (value, amount, costs, count1, count2)
     * @returns an array of component continuous values objects
     */
    const getLatestComponentData = (instId, compId, series = 'value') =>
        api.get("/installations/" + instId + "/data/continuous/components/" + compId, { series: series });

    /**
     * Gets continuous data of a component within a time period.
     * @param {Number} instId the installation ID
     * @param {Number} compId the component ID
     * @param {String} from the beginning of the time period as ISO time stamp
     * @param {String} to the end of the time period as ISO time stamp
     * @param {String} series the data series to retrieve (value, amount, costs, count1, count2)
     * @returns a component continuous values object
     */
    const getContinuousComponentData = (instId, compId, from, to, series = 'value') =>
        api.get("/installations/" + instId + "/data/continuous/components/" + compId, { from: from, to: to, series: series });

    /**
     * Gets continuous figures of an installation within a time period.
     * @param {Number} instId the installation ID
     * @param {String} from the beginning of the time period as ISO time stamp
     * @param {String} to the end of the time period as ISO time stamp
     * @returns a list of continuous figures
     */
    const getContinuousFigures = (instId, from, to) =>
        api.get("/installations/" + instId + "/data/continuous/figures", { from: from, to: to });

    /**
     * Gets the general statistics of an installation within a time period.
     * @param {Number} instId the installation ID
     * @param {String} from the beginning of the time period as ISO date
     * @param {String} to the end of the time period as ISO date
     * @param {String} aggregate the time aggregation (day, week, month, year)
     * @returns a list of statistic figures
     */
    const getGeneralStatistics = (instId, from, to, aggregate = 'day') =>
        api.get("/installations/" + instId + "/data/statistic/general", { from: from, to: to, aggregate: aggregate });

    /**
     * Gets component statistics within a time period.
     * @param {Number} instId the installation ID
     * @param {Number} compId the component ID
     * @param {String} from the beginning of the time period as ISO date
     * @param {String} to the end of the time period as ISO date
     * @param {String} aggregate the time aggregation (day, week, month, year)
     * @returns a component statistic values object
     */
    const getComponentStatistics = (instId, compId, from, to, aggregate = 'day') =>
        api.get("/installations/" + instId + "/data/statistic/components/" + compId, { from: from, to: to, aggregate: aggregate });

    /**
     * Gets the service notifications.
     * @param {String} lang the language code
     * @returns a list of notifications
     */
    const getServiceNotifications = (lang) =>
        api.get("/servicenotifications", { lang: lang });

    // add Reactotron monitor
    if (BuildConfig.isReactotronEnabled) {
        api.addMonitor(Reactotron.apisauce);
    }

    // returns the available API methods as collection of function references (interface)
    return {
        setAuthentication,
        clearAuthentication,
        registerUserAccount,
        deleteUserAccount,
        connectUser,
        disconnectInstallation,
        getAllInstallations,
        getInstallation,
        getAllBuildingUnits,
        getBuildingUnit,
        getAllComponents,
        getComponentsOfUnit,
        getComponent,
        getAllComponentsControlSignals,
        setAllComponentsControlSignals,
        getComponentControlSignals,
        setComponentControlSignals,
        getMonitoringCondition,
        getCurrentPowerFlow,
        getCurrentFigures,
        getCurrentComponentData,
        getContinuousComponentData,
        getContinuousFigures,
        getGeneralStatistics,
        getComponentStatistics,
        getAllLatestComponentData,
        getLatestComponentData,
        getServiceNotifications,
        getActiveErrors,
        ackAllErrors
    };
};

// create singleton instance
const evmApi = createEvmApi();

// export instance
export default evmApi;