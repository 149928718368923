import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import InstallationListItem from '../components/InstallationListItem';
import { DataView } from 'primereact/dataview';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { clearInstallation } from '../actions/CommonActions';
import { fetchAllInstallations, installationsClear } from '../actions/InstallationActions';
import { listViewStateUpdate } from '../actions/InstallationListViewActions';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { AuthSelectors } from '../redux/AuthRedux';
import { headerStrings, errorStrings, installationStrings } from '../i18n/translations';

/**
 * The installation list page.
 * Displays all available installations.
 */
class InstallationListView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // methods requiring access to 'this'
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.updateInstallationList = this.updateInstallationList.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);
        this.onSortClick = this.onSortClick.bind(this);
        this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
        // init state
        const sortAscending = props.listViewState.sortAscending;
        const statusFilter = props.listViewState.statusFilter;
        const nameFilter = "";
        const page = props.listViewState.page;
        const items = InstallationListView.filterList(props.installations, nameFilter, statusFilter);
        InstallationListView.sortListByName(items, sortAscending);
        this.state = {
            installationItems: items,
            sortAscending: sortAscending,
            nameFilter: nameFilter,
            statusFilter: statusFilter,
            page: page,
            isRefreshing: false,
            error: null
        };
    }

    componentDidMount() {
        this.mounted = true;
        // initial load
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Installation list did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props were updated
        if (!this.mounted) return;

        let updateList = false;

        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
            // load items after authentication changed.
            if (this.props.isAuthenticated === true) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Authenticated, loading installations");
                }
                this.props.fetchAllInstallations();
            } else {
                // clear if no longer authenticated
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("No longer authenticated, clearing installation list items");
                }
                this.props.doClearInstallations();
            }
        }
        if (this.props.installations !== prevProps.installations) {
            // new installations were loaded
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Installation list updated");
            }
            // check if installations were removed, and if so, clear data
            if (this.props.installations.length < prevProps.installations.length) {
                prevProps.installations.forEach((oldInst) => {
                    if (!this.props.installations.find((inst) => (inst.id === oldInst.id))) {
                        if (BuildConfig.isReactotronEnabled) {
                            Reactotron.log("Removed installation " + oldInst.id);
                        }
                        this.props.doClearInstallationData(oldInst.id);
                    }
                });
            }
            updateList = true;
        }
        // must not check page changes, would result in update loop
        if ((this.state.sortAscending !== prevState.sortAscending) || (this.state.nameFilter !== prevState.nameFilter) || (this.state.statusFilter !== prevState.statusFilter)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Changed filter: name ='" + this.state.nameFilter + "', status = " + this.state.statusFilter + ", asc = " + this.state.sortAscending);
            }
            updateList = true;
        }
        // finally update list
        if (updateList) {
            this.updateInstallationList();
        }
    }

    /**
     * Refreshes all visible data. Fetches data from server where necessary.
     * @param {Boolean} force fetch even if data is present
     */
    refreshAllData(force = false) {
        this.setState({ isRefreshing: true, error: null });

        let fetchTasks = [];

        // load installations (always)
        fetchTasks.push(this.props.doFetchInstallations());

        if (fetchTasks.length > 0) {
            // fetch all data in parallel
            Promise.all(fetchTasks).then((results) => {
                if (!this.mounted) return;
                // check results
                let errors = [];
                results.forEach((result) => {
                    if (result.success !== true) {
                        errors.push(result.message);
                    }
                });
                this.setState({
                    isRefreshing: false,
                    error: (errors.length === 0) ? null : errors.join(' | ')
                });
            });
        } else {
            this.setState({ isRefreshing: false });
            this.updateInstallationList();
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    updateInstallationList() {
        // get new items by filtering all installations
        const items = InstallationListView.filterList(this.props.installations, this.state.nameFilter, this.state.statusFilter);
        // sort items by name
        InstallationListView.sortListByName(items, this.state.sortAscending);
        // set correct page
        let page = this.state.page;
        let nPages = Math.floor(items.length / AppConfig.installationsPerPage);
        if (items.length % AppConfig.installationsPerPage !== 0) {
            nPages++;
        }
        if (page >= nPages) {
            page = nPages - 1;
            if (page < 0) page = 0;
        }
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("DataView: items=" + items.length + ", pages=" + nPages + ", page=" + page);
        }
        // update state
        this.setState({ installationItems: items, page: page });
        this.props.doListViewStateUpdate({ sortAscending: this.state.sortAscending, statusFilter: this.state.statusFilter, page: page });
    }

    static filterList(list, nameFilter, statusFilter) {
        // filter by status
        let newList = (statusFilter != null) ? list.filter((item) => (item.status.status === statusFilter)) : list;
        // filter by name
        newList = (nameFilter !== '') ? newList.filter((item) => item.name.toUpperCase().includes(nameFilter.toUpperCase())) : newList;
        // compare instances
        // eslint-disable-next-line
        if (newList != list) {
            return newList;
        } else {
            // not filtered -> copy!
            return [ ...list ];
        }
    }

    static sortListByName(list, ascending = true) {
        // chrome requires that 1/-1 bullshit
        if (ascending) {
            list.sort((a, b) => (a.name > b.name) ? 1 : -1);
        } else {
            list.sort((a, b) => (a.name < b.name) ? 1 : -1);
        }
    }

    static getStatusCount(installations) {
        let nAll = 0;
        let nCrit = 0;
        let nWarn = 0;
        let nOk = 0;
        installations.forEach((i) => {
            nAll++;
            if (i.status !== undefined) {
                switch (i.status.status) {
                    case 1: {
                        nCrit++;
                        break;
                    }
                    case 2: {
                        nWarn++;
                        break;
                    }
                    case 3: {
                        nOk++;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        });
        return [nAll, nCrit, nWarn, nOk];   // index is same as status code
    }

    onViewLoaded() {
        this.setState({
            sortAscending: this.props.listViewState.sortAscending,
            statusFilter: this.props.listViewState.statusFilter,
            page: this.props.listViewState.page
        });
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    onSortClick() {
        const sortAscending = !(this.state.sortAscending);
        this.setState({ sortAscending: sortAscending });
        // update reducer with view state
        this.props.doListViewStateUpdate({ sortAscending: sortAscending, statusFilter: this.state.statusFilter, page: this.state.page });
    }

    onStatusFilterChange(value) {
        this.setState({ statusFilter: value, nameFilter: "" });
        // update reducer with view state
        this.props.doListViewStateUpdate({ sortAscending: this.state.sortAscending, statusFilter: value, page: this.state.page });
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, active: true, icon: "fas fa-list-ol", path: "/installations" }
        ];

        const firstIdx = this.state.page * AppConfig.installationsPerPage;

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <DataView value={this.state.installationItems}
                        layout="list"
                        paginator={true}
                        paginatorPosition="bottom"
                        rows={AppConfig.installationsPerPage}
                        first={firstIdx}
                        onPage={(e) => {
                            const page = Math.floor(e.first / AppConfig.installationsPerPage);
                            this.setState({ page: page });
                            this.props.doListViewStateUpdate({ sortAscending: this.state.sortAscending, statusFilter: this.state.statusFilter, page: page });
                        }}
                        header={this.renderListHeader()}
                        emptyMessage={installationStrings.noInstallations}
                        itemTemplate={this.renderListItem} />
                </div>
                <Footer />
            </div>
        );
    }

    renderListHeader() {
        const statusCount = InstallationListView.getStatusCount(this.props.installations);

        return (
            <div className="p-grid">
                <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6" style={{ textAlign: 'left' }}>
                    <div className="p-inputgroup">
                        <InputText size="20" keyfilter={/^[^#<>*!]+$/} placeholder={installationStrings.search} value={this.state.nameFilter} onChange={(e) => this.setState({ nameFilter: e.target.value })} />
                        <Button icon="pi pi-search" tooltip={installationStrings.search} onClick={this.updateInstallationList} />
                    </div>
                </div>
                <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6" style={{ textAlign: 'right' }}>
                    <div style={{ marginRight: '0.5em', display: 'inline' }}>
                        <Button icon={(this.state.sortAscending) ? "fas fa-sort-alpha-up" : "fas fa-sort-alpha-down"} tooltip={installationStrings.sortOrder} onClick={this.onSortClick} />
                    </div>
                    <div className="p-inputgroup" style={{ display: 'inline'}}>
                        <Button className="p-button-primary" label={String(statusCount[0])} tooltip={installationStrings.statusAll} onClick={() => this.setState({ statusFilter: null, nameFilter: "" })} />
                        <Button className="p-button-danger" label={String(statusCount[1])} tooltip={installationStrings.statusError} onClick={() => this.setState({ statusFilter: 1, nameFilter: "" })} />
                        <Button className="p-button-warning" label={String(statusCount[2])} tooltip={installationStrings.statusInfo} onClick={() => this.setState({ statusFilter: 2, nameFilter: "" })} />
                        <Button className="p-button-success" label={String(statusCount[3])} tooltip={installationStrings.statusOk} onClick={() => this.setState({ statusFilter: 3, nameFilter: "" })} />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Renders a template for each list item.
     * Item may be undefined or null!
     */
    renderListItem(item) {
        return (
            <InstallationListItem installation={item} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installations: InstallationSelectors.selectAll(state),
        listViewState: state.installationListView
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchInstallations: () => dispatch(fetchAllInstallations()),
        doClearInstallations: () => dispatch(installationsClear()),
        doClearInstallationData: (instId) => dispatch(clearInstallation(instId)),
        doListViewStateUpdate: (viewState) => dispatch(listViewStateUpdate(viewState))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallationListView);