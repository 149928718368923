import evmApi from '../services/EvmApi';
import { BuildingUnitTypes } from '../redux/BuildingUnitRedux';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to building unit list.
 */

/**
 * Fetches all available building units from server.
 * @param {Number} installationId the installation ID
 * @returns a promise with result object
 */
export function fetchAllBuildingUnits(installationId) {
    return (dispatch) => {
        return evmApi.getAllBuildingUnits(installationId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(buildingUnitsLoadSuccess(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches a specific building unit from server.
 * @param {Number} installationId the installation ID
 * @param {Number} unitId the unit ID
 * @returns a promise with result object
 */
export function fetchBuildingUnit(installationId, unitId) {
    return (dispatch) => {
        return evmApi.getBuildingUnit(installationId, unitId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(buildingUnitUpdate(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches a specific building unit's component IDs from server.
 * @param {Number} installationId the installation ID
 * @param {Number} unitId the unit ID
 * @returns a promise with result object
 */
export function fetchBuildingUnitComponentIds(installationId, unitId) {
    return (dispatch) => {
        return evmApi.getComponentsOfUnit(installationId, unitId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const componentIds = response.data.map((comp) => comp.id);
                    dispatch(buildingUnitComponentsUpdate(installationId, unitId, componentIds));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Notifies the reducer that building units were fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Array} units the fetched building units
 * @returns an action
 */
export function buildingUnitsLoadSuccess(installationId, units) {
    // sort by name
    units.sort((a, b) => (a.name > b.name) ? 1 : -1);
    return { type: BuildingUnitTypes.BUILDING_UNITS_LOADED, payload: { installationId: installationId, units: units } };
}

/**
 * Notifies the reducer that a building unit was updated successfully.
 * @param {Number} installationId the installation ID
 * @param {Object} unit the updated unit
 * @returns an action
 */
export function buildingUnitUpdate(installationId, unit) {
    return { type: BuildingUnitTypes.BUILDING_UNIT_UPDATE, payload: { installationId: installationId, unit: unit } };
}

/**
 * Notifies the reducer that a building unit's component IDs were updated successfully.
 * @param {Number} installationId the installation ID
 * @param {Object} unitId the unit ID
 * @param {Array} componentIds the component IDs
 * @returns an action
 */
export function buildingUnitComponentsUpdate(installationId, unitId, componentIds) {
    return { type: BuildingUnitTypes.BUILDING_UNIT_COMPONENTS_UPDATE, payload: { installationId: installationId, unitId: unitId, componentIds: componentIds } };
}

/**
 * Notifies the reducer that all building units of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function buildingUnitsClearInstallation(installationId) {
    return { type: BuildingUnitTypes.BUILDING_UNITS_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all building units should be cleared.
 * @returns an action
 */
export function buildingUnitsClearAll() {
    return { type: BuildingUnitTypes.BUILDING_UNITS_CLEAR_ALL };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
        case 422:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.unitNotFound;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}