import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { clearEverything } from '../actions/CommonActions';
import { authenticationLogout } from '../actions/AuthActions';
import { AuthSelectors } from '../redux/AuthRedux';
import SeeLogo from '../images/see_logo.svg';
import { headerStrings } from '../i18n/translations';

/**
 * The page header.
 */
class Header extends Component {

    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.navigateToView = this.navigateToView.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.renderLogin = this.renderLogin.bind(this);
        this.renderRefresh = this.renderRefresh.bind(this);
        this.renderNavItems = this.renderNavItems.bind(this);
    }

    navigateToView(path) {
        if (path !== undefined) {
            if (path !== "back") {
                window.location.hash = "#" + path;
            } else {
                window.history.back();
            }
        }
    }

    handleRefresh() {
        if (this.props.onRefreshClick !== undefined) {
            this.props.onRefreshClick();
        }
    }

    handleLogin() {
        // go to login page
        window.location.hash = "#/login";
    }

    handleLogout() {
        this.props.doLogout();
        this.props.doClearEverything();
        // go to home page
        window.location.hash = "#/";
    }

    handleUserManage() {
        // go to user page
        window.location.hash = "#/user";
    }

    handleEnterCode() {
        // go to user page
        window.location.hash = "#/connect";
    }

    // show navigation only when authenticated
    render() {
        return (
            <div className="p-grid nested-grid page-header">
                <div className="p-col-fixed" style={{ width: '112px' }}>
                    <Link to="/" title={headerStrings.homePage}><img src={SeeLogo} alt="SEE" /></Link>
                </div>
                <div className="p-col">
                    <div className="p-grid">
                        <div className="p-col-12 p-sm-6">
                            {(this.props.isAuthenticated) && this.renderNavItems()}
                        </div>
                        <div className="p-col-12 p-sm-6" style={{ textAlign: 'right' }}>
                            {(this.props.showRefresh === true) && this.renderRefresh()}
                            {(this.props.showLogin === true) && this.renderLogin()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderNavItems() {
        return this.props.navItems.map((item, index) => {
            if (item.active) {
                return (
                    <Button key={"nav" + index} className="p-button-warning" icon={item.icon} tooltip={item.name} disabled style={{marginRight: '0.5em'}} />
                );
            } else {
                return (item.enabled) ? (
                    <Button key={"nav" + index} className="p-button" icon={item.icon} tooltip={item.name} onClick={() => this.navigateToView(item.path)} style={{marginRight: '0.5em'}} />
                ) : (
                    <Button key={"nav" + index} className="p-button-secondary" icon={item.icon} tooltip={item.name} disabled style={{marginRight: '0.5em'}} />
                );
            }
        });
    }

    renderRefresh() {
        return (this.props.isRefreshing === true) ?
            (<Button className="p-button-warning" icon="pi pi-spin pi-spinner" tooltip={headerStrings.refresh} disabled style={{marginRight: '0.5em'}} />) :
            (<Button className="p-button" icon="pi pi-refresh" tooltip={headerStrings.refresh} onClick={this.handleRefresh} style={{marginRight: '0.5em'}} />);
    }

    renderLogin() {
        if ((this.props.isAuthenticated === true) && (this.props.isExpired === false)) {
            const model = [
                {
                    label: this.props.userName,
                    items: [
                        { label: headerStrings.enterCode, icon: 'pi pi-key', command: this.handleEnterCode },
                        { label: headerStrings.manageUser, icon: 'pi pi-user-edit', command: this.handleUserManage },
                        { label: headerStrings.logout, icon: 'pi pi-sign-out', command: this.handleLogout }
                    ]
                }
            ];
            return (
                <div style={{ display: 'inline', textAlign: 'left' }}>
                    <Menu model={model} popup={true} ref={el => this.userMenu = el} />
                    <Button className="p-button-success" icon="pi pi-user" tooltip={headerStrings.user} onClick={(e) => this.userMenu.toggle(e)} />
                </div>
            );
        } else {
            return (<Button className="p-button-danger" icon="pi pi-sign-in" tooltip={headerStrings.login} onClick={this.handleLogin} />);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        userName: AuthSelectors.selectUserName(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doLogout: () => dispatch(authenticationLogout()),
        doClearEverything: () => dispatch(clearEverything())
    }
};

// default props
Header.defaultProps = {
    navItems: [],
    showRefresh: false,
    showLogin: true,
    isRefreshing: false
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);