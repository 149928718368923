/**
 * Component statistic data reducer.
 * Contains daily values for each month, for each component of each installation.
 * Months are represented by ISO dates (first day of month, YYYY-MM-01).
 */

/* ------------- Types ------------- */

// types
export const ComponentMonthDayTypes = {
  COMPONENT_MONTH_DAY_CLEAR_INSTALLATION: 'COMPONENT_MONTH_DAY_CLEAR_INSTALLATION',
  COMPONENT_MONTH_DAY_CLEAR_ALL: 'COMPONENT_MONTH_DAY_CLEAR_ALL',
  COMPONENT_MONTH_DAY_UPDATE: 'COMPONENT_MONTH_DAY_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const ComponentMonthDaySelectors = {
  selectAllOfInstallation: (state, installationId) => state.componentMonthDayData[installationId],
  selectAllOfComponent: (state, installationId, componentId) => {
    const instData = state.componentMonthDayData[installationId];
    return (instData !== undefined) ? instData[componentId] : undefined;
  }
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onComponentMonthDayUpdate(state, payload) {
  // create or update
  const { installationId, componentId, date, values } = payload;
  const instData = state[installationId];
  if (instData !== undefined) {
    // update component data -> set month with values
    const compData = instData[componentId];
    if (compData !== undefined) {
      const newCompData = { ...compData, [date]: values };
      const newInstData = { ...instData, [componentId]: newCompData };
      return {
        ...state,
        [installationId]: newInstData
      };
    } else {
      // no component -> create component with ...
      const newInstData = { ...instData, [componentId]: { [date]: values } };
      return {
        ...state,
        [installationId]: newInstData
      };
    }
  } else {
    // no installation data -> create component with month and values
    return {
      ...state,
      [installationId]: {
        [componentId]: {
          [date]: values
        }
      }
    };
  }
}

function onComponentMonthDayClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ComponentMonthDayTypes.COMPONENT_MONTH_DAY_UPDATE: {
      return onComponentMonthDayUpdate(state, action.payload);
    }
    case ComponentMonthDayTypes.COMPONENT_MONTH_DAY_CLEAR_INSTALLATION: {
      return onComponentMonthDayClearInstallation(state, action.payload);
    }
    case ComponentMonthDayTypes.COMPONENT_MONTH_DAY_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;