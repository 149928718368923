import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import moment from 'moment';
import evmApi from '../services/EvmApi';
import { componentStrings, errorStrings, primeCalendar } from '../i18n/translations';

/**
 * Special handler for charge plan.
 * Modifies signals of a charge plan (1 per week day).
 */
class ChargePlanHandler extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.refreshSignalValues = this.refreshSignalValues.bind(this);
        this.pushSignalValues = this.pushSignalValues.bind(this);
        this.onBeginTimeChanged = this.onBeginTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        this.onKilometersChanged = this.onKilometersChanged.bind(this);
        if (this.props.chargePlan !== undefined) {
            const signals = this.props.chargePlan.daySignals;
            this.state = {
                refreshing: false,
                error: null,
                daySignals: { ...signals }
            };
        } else {
            this.state = {
                refreshing: false,
                error: null,
                daySignals: {}
            };
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.mounted) return;
        if (this.props.active !== prevProps.active) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Charge plan handler active = " + this.props.active);
            }
            // TODO toggle auto refresh
            if (this.props.active) {
                this.refreshSignalValues();
            }
        }
    }

    refreshSignalValues() {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Refreshing signal values");
        }
        this.setState({ refreshing: true });
        evmApi.getComponentControlSignals(this.props.installationId, this.props.componentId)
            .then((response) => {
                if (!this.mounted) return;
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { signals } = response.data;
                    // filter charge plan signals
                    const plans = signals.filter(s => (s.type === 'plan'));
                    if (plans.length > 0) {
                        const signals = {};
                        plans.forEach((s) => {
                            signals[s.signal] = s;
                        });
                        this.setState({
                            refreshing: false,
                            error: null,
                            daySignals: signals
                        });
                    } else {
                        this.setState({
                            refreshing: false,
                            error: errorStrings.noControl,
                            daySignals: {}
                        });
                    }
                } else {
                    // failed
                    this.setState({
                        refreshing: false,
                        error: response.problem + "(" + response.status + ")"
                    });
                }
            });
    }

    pushSignalValues(daySignal) {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Pushing values of charge plan (" + daySignal.signal + ")");
        }
        const compSignals = {
            componentId: this.props.componentId,
            signals: [
                {
                    signal: daySignal.signal,
                    lowerBound: daySignal.lowerBound,
                    upperBound: daySignal.upperBound,
                    lowerValue: daySignal.lowerValue,
                    upperValue: daySignal.upperValue
                }
            ]
        };
        this.setState({ refreshing: true });
        evmApi.setComponentControlSignals(this.props.installationId, this.props.componentId, compSignals)
            .then((response) => {
                if (!this.mounted) return;
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    this.setState({
                        refreshing: false,
                        error: null
                    });
                } else {
                    // failed
                    this.setState({
                        refreshing: false,
                        error: response.problem + "(" + response.status + ")"
                    });
                }
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Push signal values response = " + response.status);
                }
            });
    }


    onBeginTimeChanged(key, date) {
        let secondsOfDay = date.getHours() * 3600 + date.getMinutes() * 60;
        // update state
        const daySignals = { ...this.state.daySignals };
        daySignals[key].lowerValue = secondsOfDay;
        // send changes to EVM
        this.pushSignalValues(daySignals[key]);
        this.setState({ daySignals: daySignals });
    }

    onEndTimeChanged(key, date) {
        let secondsOfDay = date.getHours() * 3600 + date.getMinutes() * 60;
        // update state
        const daySignals = { ...this.state.daySignals };
        daySignals[key].upperValue = secondsOfDay;
        // send changes to EVM
        this.pushSignalValues(daySignals[key]);
        this.setState({ daySignals: daySignals });
    }

    onKilometersChanged(key, km) {
        // update state
        const daySignals = { ...this.state.daySignals };
        daySignals[key].lowerBound = km;
        daySignals[key].upperBound = km;
        // send changes to EVM
        this.pushSignalValues(daySignals[key]);
        this.setState({ daySignals: daySignals });
    }

    render() {
        if (this.props.chargePlan !== undefined) {
            switch (this.props.chargePlan.type) {
                // combined signal, containing multiple signals
                // lowerValue is begin, upperValue is end, lowerBound/upperBound is km (same value)
                case 'chargeplan': {
                    return (
                        <div className="p-grid p-justify-between">
                            <div className="p-col-12" style={{ textAlign: 'right' }}>
                                <Button className="p-button-secondary" icon={ChargePlanHandler.getRefreshIcon(this.state.refreshing, this.state.error)} tooltip={(this.state.error != null) ? this.state.error : ""} onClick={this.refreshSignalValues} />
                            </div>
                            <div className="p-col-12">
                                <TabView activeIndex={moment().isoWeekday() - 1}>
                                    <TabPanel header={primeCalendar.dayNamesMin[1]} disabled={this.state.daySignals['Mo'] === undefined}>
                                        {(this.state.daySignals['Mo'] !== undefined) ?
                                            (<table>
                                                <tbody>
                                                <tr><td>{componentStrings.begin}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Mo'].lowerValue)} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged('Mo', e.value)} /></td></tr>
                                                <tr><td>{componentStrings.end}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Mo'].upperValue)} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged('Mo', e.value)} /></td></tr>
                                                <tr><td>km</td><td><InputNumber value={this.state.daySignals['Mo'].lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged('Mo', e.value)}
                                                                        showButtons buttonLayout="stacked"
                                                                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                                                        incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" /></td></tr>
                                                </tbody>
                                            </table>) :
                                            (<div>{errorStrings.noControl}</div>)
                                        }
                                    </TabPanel>
                                    <TabPanel header={primeCalendar.dayNamesMin[2]} disabled={this.state.daySignals['Di'] === undefined}>
                                        {(this.state.daySignals['Di'] !== undefined) ?
                                            (<table>
                                                <tbody>
                                                <tr><td>{componentStrings.begin}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Di'].lowerValue)} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged('Di', e.value)} /></td></tr>
                                                <tr><td>{componentStrings.end}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Di'].upperValue)} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged('Di', e.value)} /></td></tr>
                                                <tr><td>km</td><td><InputNumber value={this.state.daySignals['Di'].lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged('Di', e.value)}
                                                                        showButtons buttonLayout="stacked"
                                                                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                                                        incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" /></td></tr>
                                                </tbody>
                                            </table>) :
                                            (<div>{errorStrings.noControl}</div>)
                                        }
                                    </TabPanel>
                                    <TabPanel header={primeCalendar.dayNamesMin[3]} disabled={this.state.daySignals['Mi'] === undefined}>
                                        {(this.state.daySignals['Mi'] !== undefined) ?
                                            (<table>
                                                <tbody>
                                                <tr><td>{componentStrings.begin}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Mi'].lowerValue)} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged('Mi', e.value)} /></td></tr>
                                                <tr><td>{componentStrings.end}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Mi'].upperValue)} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged('Mi', e.value)} /></td></tr>
                                                <tr><td>km</td><td><InputNumber value={this.state.daySignals['Mi'].lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged('Mi', e.value)}
                                                                        showButtons buttonLayout="stacked"
                                                                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                                                        incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" /></td></tr>
                                                </tbody>
                                            </table>) :
                                            (<div>{errorStrings.noControl}</div>)
                                        }
                                    </TabPanel>
                                    <TabPanel header={primeCalendar.dayNamesMin[4]} disabled={this.state.daySignals['Do'] === undefined}>
                                        {(this.state.daySignals['Do'] !== undefined) ?
                                            (<table>
                                                <tbody>
                                                <tr><td>{componentStrings.begin}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Do'].lowerValue)} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged('Do', e.value)} /></td></tr>
                                                <tr><td>{componentStrings.end}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Do'].upperValue)} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged('Do', e.value)} /></td></tr>
                                                <tr><td>km</td><td><InputNumber value={this.state.daySignals['Do'].lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged('Do', e.value)}
                                                                        showButtons buttonLayout="stacked"
                                                                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                                                        incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" /></td></tr>
                                                </tbody>
                                            </table>) :
                                            (<div>{errorStrings.noControl}</div>)
                                        }
                                    </TabPanel>
                                    <TabPanel header={primeCalendar.dayNamesMin[5]} disabled={this.state.daySignals['Fr'] === undefined}>
                                        {(this.state.daySignals['Fr'] !== undefined) ?
                                            (<table>
                                                <tbody>
                                                <tr><td>{componentStrings.begin}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Fr'].lowerValue)} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged('Fr', e.value)} /></td></tr>
                                                <tr><td>{componentStrings.end}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Fr'].upperValue)} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged('Fr', e.value)} /></td></tr>
                                                <tr><td>km</td><td><InputNumber value={this.state.daySignals['Fr'].lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged('Fr', e.value)}
                                                                        showButtons buttonLayout="stacked"
                                                                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                                                        incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" /></td></tr>
                                                </tbody>
                                            </table>) :
                                            (<div>{errorStrings.noControl}</div>)
                                        }
                                    </TabPanel>
                                    <TabPanel header={primeCalendar.dayNamesMin[6]} disabled={this.state.daySignals['Sa'] === undefined}>
                                        {(this.state.daySignals['Sa'] !== undefined) ?
                                            (<table>
                                                <tbody>
                                                <tr><td>{componentStrings.begin}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Sa'].lowerValue)} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged('Sa', e.value)} /></td></tr>
                                                <tr><td>{componentStrings.end}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['Sa'].upperValue)} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged('Sa', e.value)} /></td></tr>
                                                <tr><td>km</td><td><InputNumber value={this.state.daySignals['Sa'].lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged('Sa', e.value)}
                                                                        showButtons buttonLayout="stacked"
                                                                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                                                        incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" /></td></tr>
                                                </tbody>
                                            </table>) :
                                            (<div>{errorStrings.noControl}</div>)
                                        }
                                    </TabPanel>
                                    <TabPanel header={primeCalendar.dayNamesMin[0]} disabled={this.state.daySignals['So'] === undefined}>
                                        {(this.state.daySignals['So'] !== undefined) ?
                                            (<table>
                                                <tbody>
                                                <tr><td>{componentStrings.begin}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['So'].lowerValue)} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged('So', e.value)} /></td></tr>
                                                <tr><td>{componentStrings.end}</td><td><Calendar locale={primeCalendar} value={ChargePlanHandler.getDateFromOffset(this.state.daySignals['So'].upperValue)} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged('So', e.value)} /></td></tr>
                                                <tr><td>km</td><td><InputNumber value={this.state.daySignals['So'].lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged('So', e.value)}
                                                                        showButtons buttonLayout="stacked"
                                                                        decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                                                        incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" /></td></tr>
                                                </tbody>
                                            </table>) :
                                            (<div>{errorStrings.noControl}</div>)
                                        }
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    );
                }
                default: {
                    return (<div>Unsupported signal type</div>);
                }
            }
        }
        return (<div></div>);
    }

    static getRefreshIcon(refreshing, error) {
        if (refreshing) {
            return "pi pi-spin pi-spinner";
        } else if (error != null) {
            return "pi pi-exclamation-triangle";
        } else {
            return "pi pi-refresh";
        }
    }

    static getDateFromOffset(seconds) {
        let date = new Date(0);
        let hours = Math.trunc(seconds / 3600);
        let minutes = Math.trunc((seconds - hours * 3600) / 60);
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    }
}

ChargePlanHandler.defaultProps = {
    active: false
};

export default ChargePlanHandler;