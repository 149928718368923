/**
 * Service notifications reducer. Contains a list of notifications.
 */

/* ------------- Types ------------- */

// types
export const ServiceNotificationTypes = {
  NOTIFICATIONS_LOADED: 'NOTIFICATIONS_LOADED',
  NOTIFICATIONS_CLEAR: 'NOTIFICATIONS_CLEAR',
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const ServiceNotificationSelectors = {
  selectAll: (state) => state.serviceNotifications
};

/* ------------- Initial state ------------- */

const initialState = [];

/* ------------- Reducer Function ------------- */

function onServiceNotificationsLoaded(state, payload) {
  const newServiceNotifications = payload;
  return newServiceNotifications;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ServiceNotificationTypes.NOTIFICATIONS_LOADED: {
      return onServiceNotificationsLoaded(state, action.payload);
    }
    case ServiceNotificationTypes.NOTIFICATIONS_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;