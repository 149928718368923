import BuildConfig from './BuildConfig';
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import tronsauce from 'reactotron-apisauce';

/**
 * Reactotron configuration.
 */

// only enable during development
const reactotron = (BuildConfig.isReactotronEnabled) ?
    // initialize reactotron and use redux plugin
    Reactotron
        .configure({ name: 'Smart Energy Engineering Webportal' })
        .use(reactotronRedux())
        .use(tronsauce())
        .connect() : undefined;

if (reactotron !== undefined) {
    // clear Reactotron on startup
    reactotron.clear();
}

// exports Reactotron, may be undefined! Only use Reactotron within "BuildConfig.isReactotronEnabled" scope!
export default reactotron;