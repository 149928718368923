/**
 * Build environment configuration.
 */

const isDebug = (process.env.NODE_ENV !== 'production');    // debug mode?
const isReactotronEnabled = isDebug;                        // connect to Reactotron
const isAuthenticationEnabled = !isDebug;                   // local development without authentication

export default {
    isDebug, isReactotronEnabled, isAuthenticationEnabled
};