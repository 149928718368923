import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { Slider } from 'primereact/slider';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import evmApi from '../services/EvmApi';
import { componentStrings, errorStrings, primeCalendar, headerStrings } from '../i18n/translations';

/**
 * Generic control signal handler.
 * Modifies a single control signal. Depends on signal type.
 */
class ControlSignalHandler extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.refreshSignalValues = this.refreshSignalValues.bind(this);
        this.pushSignalValues = this.pushSignalValues.bind(this);
        this.onSliderChanged = this.onSliderChanged.bind(this);
        this.onSliderReleased = this.onSliderReleased.bind(this);
        this.onSwitchChanged = this.onSwitchChanged.bind(this);
        this.onBeginTimeChanged = this.onBeginTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        this.onKilometersChanged = this.onKilometersChanged.bind(this);
        if (this.props.signal !== undefined) {
            this.state = {
                refreshing: false,
                error: null,
                lowerValue: this.props.signal.lowerValue,
                upperValue: this.props.signal.upperValue,
                lowerBound: this.props.signal.lowerBound,
                upperBound: this.props.signal.upperBound
            };
        } else {
            this.state = {
                refreshing: false,
                error: null,
                lowerValue: 0.0,
                upperValue: 0.0,
                lowerBound: 0.0,
                upperBound: 0.0
            };
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.mounted) return;
        if (this.props.active !== prevProps.active) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Control signal handler active = " + this.props.active);
            }
            // TODO toggle auto refresh
            if (this.props.active) {
                this.refreshSignalValues();
            }
        }
    }

    refreshSignalValues() {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Refreshing signal values");
        }
        this.setState({ refreshing: true });
        evmApi.getComponentControlSignals(this.props.installationId, this.props.componentId)
            .then((response) => {
                if (!this.mounted) return;
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { signals } = response.data;
                    // match signal identifier
                    const sig = signals.find(s => (s.signal === this.props.signal.signal));
                    if (sig !== undefined) {
                        this.setState({
                            refreshing: false,
                            error: null,
                            lowerBound: sig.lowerBound,
                            upperBound: sig.upperBound,
                            lowerValue: sig.lowerValue,
                            upperValue: sig.upperValue
                        });
                    } else {
                        this.setState({
                            refreshing: false,
                            error: errorStrings.noControl
                        });
                    }
                } else {
                    // failed
                    this.setState({
                        refreshing: false,
                        error: response.problem + "(" + response.status + ")"
                    });
                }
            });
    }

    pushSignalValues(lowerBound, upperBound, lowerValue, upperValue) {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Pushing values of signal " + this.props.signal.signal);
        }
        const sig = {
            signal: this.props.signal.signal,
            lowerValue: lowerValue,
            upperValue: upperValue
        };
        if (lowerBound !== undefined) {
            sig.lowerBound = lowerBound;
        }
        if (upperBound !== undefined) {
            sig.upperBound = upperBound;
        }
        const compSignals = {
            componentId: this.props.componentId,
            signals: [ sig ]
        };
        this.setState({ refreshing: true });
        evmApi.setComponentControlSignals(this.props.installationId, this.props.componentId, compSignals)
            .then((response) => {
                if (!this.mounted) return;
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    this.setState({
                        refreshing: false,
                        error: null
                    });
                } else {
                    // failed
                    this.setState({
                        refreshing: false,
                        error: response.problem + "(" + response.status + ")"
                    });
                }
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Push signal values response = " + response.status);
                }
            });
    }

    onSliderChanged(value) {
        // update state
        if (Array.isArray(value)) {
            this.setState({ lowerValue: value[0], upperValue: value[1] });
        } else {
            this.setState({ lowerValue: value, upperValue: value });
        }
    }

    onSliderReleased(value) {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Slider changed");
        }
        let lowerValue;
        let upperValue;
        if (Array.isArray(value)) {
            // range
            lowerValue = value[0];
            upperValue = value[1];
        } else {
            // single value
            lowerValue = value;
            upperValue = value;
        }
        // send changes to EVM
        this.pushSignalValues(undefined, undefined, lowerValue, upperValue);
    }

    onSwitchChanged(value) {
        const intVal = (value) ? 1 : 0;
        // update state
        this.setState({ lowerValue: intVal, upperValue: intVal });
        // send changes to EVM
        this.pushSignalValues(undefined, undefined, intVal, intVal);
    }

    onBeginTimeChanged(date) {
        let secondsOfDay = date.getHours() * 3600 + date.getMinutes() * 60;
        // update state
        this.setState({ lowerValue: secondsOfDay });
        // send changes to EVM
        this.pushSignalValues(undefined, undefined, secondsOfDay, this.state.upperValue);
    }

    onEndTimeChanged(date) {
        let secondsOfDay = date.getHours() * 3600 + date.getMinutes() * 60;
        // update state
        this.setState({ upperValue: secondsOfDay });
        // send changes to EVM
        this.pushSignalValues(this.state.lowerBound, this.state.upperBound, this.state.lowerValue, secondsOfDay);
    }

    onKilometersChanged(value) {
        // update state
        this.setState({ lowerBound: value, upperBound: value });
        // send changes to EVM
        this.pushSignalValues(value, value, this.state.lowerValue, this.state.upperValue);
    }

    render() {
        if (this.props.signal !== undefined) {
            switch (this.props.signal.type) {
                case 'slider': {
                    const stepSize = ((this.state.upperBound - this.state.lowerBound) <= 20) ? 0.5 : 1.0;
                    if (this.props.signal.isRange) {
                        // range is lowerValue...upperValue
                        const rangeValues = [ this.state.lowerValue, this.state.upperValue ];
                        return (
                            <div className="p-grid p-justify-between">
                                <div className="p-col-12" style={{ textAlign: 'right' }}>
                                    <Button className="p-button-secondary" icon={ControlSignalHandler.getRefreshIcon(this.state.refreshing, this.state.error)} tooltip={(this.state.error != null) ? this.state.error : headerStrings.refresh} onClick={this.refreshSignalValues} />
                                </div>
                                <div className="p-col-12">
                                    {componentStrings.valueRange}: {this.state.lowerValue} ... {this.state.upperValue}
                                </div>
                                <div className="p-col-12">
                                    <Slider value={rangeValues} min={this.state.lowerBound} max={this.state.upperBound} range={true} step={stepSize}
                                        onChange={(e) => this.onSliderChanged(e.value)}
                                        onSlideEnd={(e) => this.onSliderReleased(e.values)} />
                                </div>
                                <div className="p-col-4" style={{ textAlign: 'left', fontSize: 'small' }}>
                                    {this.state.lowerBound}
                                </div>
                                <div className="p-col-4" style={{ textAlign: 'center', fontSize: 'small' }}>
                                    {(this.state.lowerBound + this.state.upperBound) / 2}
                                </div>
                                <div className="p-col-4" style={{ textAlign: 'right', fontSize: 'small' }}>
                                    {this.state.upperBound}
                                </div>
                            </div>
                        );
                    } else {
                        // lowerValue and upperValue are the same
                        return (
                            <div className="p-grid p-justify-between">
                                <div className="p-col-12" style={{ textAlign: 'right' }}>
                                    <Button className="p-button-secondary" icon={ControlSignalHandler.getRefreshIcon(this.state.refreshing, this.state.error)} tooltip={(this.state.error != null) ? this.state.error : ""} onClick={this.refreshSignalValues} />
                                </div>
                                <div className="p-col-12">
                                    {componentStrings.value}: {this.state.lowerValue}
                                </div>
                                <div className="p-col-12">
                                    <Slider value={this.state.lowerValue} min={this.state.lowerBound} max={this.state.upperBound} range={false} step={stepSize}
                                        onChange={(e) => this.onSliderChanged(e.value)}
                                        onSlideEnd={(e) => this.onSliderReleased(e.value)} />
                                </div>
                                <div className="p-col-4" style={{ textAlign: 'left', fontSize: 'small' }}>
                                    {this.state.lowerBound}
                                </div>
                                <div className="p-col-4" style={{ textAlign: 'center', fontSize: 'small' }}>
                                    {(this.state.lowerBound + this.state.upperBound) / 2}
                                </div>
                                <div className="p-col-4" style={{ textAlign: 'right', fontSize: 'small' }}>
                                    {this.state.upperBound}
                                </div>
                            </div>
                        );
                    }
                }
                case 'switch': {
                    // lowerValue and upperValue are the same (1 = on, 0 off)
                    const on = (this.state.lowerValue !== 0);
                    return (
                        <div className="p-grid p-justify-between">
                            <div className="p-col-12" style={{ textAlign: 'right' }}>
                                <Button className="p-button-secondary" icon={ControlSignalHandler.getRefreshIcon(this.state.refreshing, this.state.error)} tooltip={(this.state.error != null) ? this.state.error : ""} onClick={this.refreshSignalValues} />
                            </div>
                            <div className="p-col-6">
                                <InputSwitch checked={on} onChange={(e) => this.onSwitchChanged(e.value)} />
                            </div>
                            <div className="p-col-6">
                                {on ? componentStrings.on : componentStrings.off}
                            </div>
                        </div>
                    );
                }
                case 'uhrzeit': {
                    // only modify upperValue
                    const date = ControlSignalHandler.getDateFromOffset(this.state.upperValue);
                    return (
                        <div className="p-grid p-justify-between">
                            <div className="p-col-12" style={{ textAlign: 'right' }}>
                                <Button className="p-button-secondary" icon={ControlSignalHandler.getRefreshIcon(this.state.refreshing, this.state.error)} tooltip={(this.state.error != null) ? this.state.error : ""} onClick={this.refreshSignalValues} />
                            </div>
                            <div className="p-col-12">
                                {componentStrings.end}&nbsp;<Calendar locale={primeCalendar} value={date} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged(e.value)} />
                            </div>
                        </div>
                    );
                }
                case 'plan': {
                    // used for generic charge plan (separate signal for each week day)
                    // lowerValue is begin, upperValue is end, lowerBound/upperBound is the same (km)
                    const lowDate = ControlSignalHandler.getDateFromOffset(this.state.lowerValue);
                    const hiDate = ControlSignalHandler.getDateFromOffset(this.state.upperValue);
                    return (
                        <div className="p-grid p-justify-between">
                            <div className="p-col-12" style={{ textAlign: 'right' }}>
                                <Button className="p-button-secondary" icon={ControlSignalHandler.getRefreshIcon(this.state.refreshing, this.state.error)} tooltip={(this.state.error != null) ? this.state.error : ""} onClick={this.refreshSignalValues} />
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4">
                                {componentStrings.begin}&nbsp;<Calendar locale={primeCalendar} value={lowDate} showTime={true} timeOnly={true} onChange={(e) => this.onBeginTimeChanged(e.value)} />
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4">
                                {componentStrings.end}&nbsp;<Calendar locale={primeCalendar} value={hiDate} showTime={true} timeOnly={true} onChange={(e) => this.onEndTimeChanged(e.value)} />
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4">
                                km&nbsp;<InputNumber value={this.state.lowerBound} min={0} max={1000} onChange={(e) => this.onKilometersChanged(e.value)}
                                            showButtons buttonLayout="stacked"
                                            decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                                            incrementButtonIcon="pi pi-angle-up" decrementButtonIcon="pi pi-angle-down" />
                            </div>
                        </div>
                    );
                }
                default: {
                    return (<div>Unsupported signal type</div>);
                }
            }
        }
        return (<div></div>);
    }

    static getRefreshIcon(refreshing, error) {
        if (refreshing) {
            return "pi pi-spin pi-spinner";
        } else if (error != null) {
            return "pi pi-exclamation-triangle";
        } else {
            return "pi pi-refresh";
        }
    }

    static getDateFromOffset(seconds) {
        let date = new Date(0);
        let hours = Math.trunc(seconds / 3600);
        let minutes = Math.trunc((seconds - hours * 3600) / 60);
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    }
}

ControlSignalHandler.defaultProps = {
    active: false
};

export default ControlSignalHandler;