import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import authReducer from './AuthRedux';
import installationReducer from './InstallationRedux';
import componentReducer from './ComponentRedux';
import buildingUnitReducer from './BuildingUnitRedux';
import generalFigureReducer from './GeneralFigureRedux';
import generalFigureDayReducer from './GeneralFigureDayRedux';
import generalMonthDayReducer from './GeneralMonthDayDataRedux';
import generalYearMonthReducer from './GeneralYearMonthDataRedux';
import generalYearsReducer from './GeneralYearsDataRedux';
import componentActualDataReducer from './ComponentActualDataRedux';
import componentDayTimeReducer from './ComponentDayTimeDataRedux';
import componentMonthDayReducer from './ComponentMonthDayDataRedux';
import componentYearMonthReducer from './ComponentYearMonthDataRedux';
import componentYearsReducer from './ComponentYearsDataRedux';
import componentControlReducer from './ComponentControlRedux';
import installationListViewReducer from './InstallationListViewRedux';
import serviceNotificationsReducer from './ServiceNotificationRedux';

/**
 * Creates the Redux store, combining multiple reducers.
 * @returns a redux store
 */
function createReduxStore() {

    // create root reducer, assembling the individual reducers
    const rootReducer = combineReducers({
        auth: authReducer,
        installations: installationReducer,
        components: componentReducer,
        buildingUnits: buildingUnitReducer,
        generalFigures: generalFigureReducer,
        generalDayFigures: generalFigureDayReducer,
        generalMonthDayData: generalMonthDayReducer,
        generalYearMonthData: generalYearMonthReducer,
        generalYearsData: generalYearsReducer,
        componentActualData: componentActualDataReducer,
        componentDayTimeData: componentDayTimeReducer,
        componentMonthDayData: componentMonthDayReducer,
        componentYearMonthData: componentYearMonthReducer,
        componentYearsData: componentYearsReducer,
        componentControls: componentControlReducer,
        installationListView: installationListViewReducer,
        serviceNotifications: serviceNotificationsReducer
    });

    // apply middleware(s)
    const middleware = applyMiddleware(thunk);

    // create store
    // add reactotron if enabled
    if (BuildConfig.isReactotronEnabled) {
        return createStore(rootReducer, compose(middleware, Reactotron.createEnhancer()));
    }
    return createStore(rootReducer, compose(middleware));
  }

  export default createReduxStore;