import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { AuthSelectors } from '../redux/AuthRedux';
import { registerUser } from '../actions/ManagementActions';
import { headerStrings, errorStrings, mgmtStrings } from '../i18n/translations';

/**
 * The registration page.
 */
class RegisterView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            isLoading: false,
            message: null
        };
        // This binding is necessary to make `this` work in the callback
        this.handleRegister = this.handleRegister.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Register view did mount.");
        }
        this.setState({ message: null });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleRegister(values, resetForm) {
        // check input
        if ((values.password1 === "") || (values.password2 === "")) {
            this.setState({ message: { severity: 'error', text: errorStrings.passwordEmpty }});
            return;
        }
        if (values.password1 !== values.password2) {
            this.setState({ message: { severity: 'error', text: errorStrings.passwordMismatch }});
            return;
        }

        this.setState({ isLoading: true, message: null });
        this.props.doRegister(values.email, values.password1)
            .then((result) => {
                if (!this.mounted) return;
                this.setState({
                    isLoading: false,
                    message: {
                        severity: (result.success === true) ? 'success' : 'error',
                        text: result.message
                    }
                });
                if (result.success === true) {
                    // clear form
                    Object.keys(values).forEach(key => (values[key] = ''));
                    resetForm(values);
                }
            });
    }

    validateForm(values) {
        let errors = {};
        if (!values.email) {
            errors.email = errorStrings.emailEmpty;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            // test for invalid characters
            errors.email = errorStrings.invalidCharacters;
        }
        if (!values.password1 || !values.password2) {
            errors.password = errorStrings.passwordEmpty;
        } else if (values.password1 !== values.password2) {
            errors.password = errorStrings.passwordMismatch;
        }
        return errors;
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: this.props.isAuthenticated, active: false, icon: "fas fa-list-ol", path: "/installations" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isLoading} />
                <div className="page-content">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-sm-8 p-md-6">
                            <Panel header={headerStrings.register}>
                                <Formik
                                    initialValues={{ email: "", password1: "", password2: "" }}
                                    validate={this.validateForm}
                                    onSubmit={(values, {resetForm}) => this.handleRegister(values, resetForm)}>
                                    <Form autoComplete="off">
                                        <div className="p-grid">
                                            <div className="p-col-12" style={{ padding: '1em' }}>
                                                <span className="p-float-label">
                                                    <Field id="emailIn" type="email" name="email" size="28" className="p-inputtext" />
                                                    <label htmlFor="emailIn">{mgmtStrings.eMail}</label>
                                                </span>
                                                <ErrorMessage name="email" render={msg => <Message severity='warn' text={msg} />} />
                                            </div>
                                            <div className="p-col-12" style={{ padding: '1em' }}>
                                                <span className="p-float-label">
                                                    <Field id="passwordIn1" type="password" name="password1" size="28" className="p-inputtext" />
                                                    <label htmlFor="passwordIn1">{mgmtStrings.password}</label>
                                                </span>
                                            </div>
                                            <div className="p-col-12" style={{ padding: '1em' }}>
                                                <span className="p-float-label">
                                                    <Field id="passwordIn2" type="password" name="password2" size="28" className="p-inputtext" />
                                                    <label htmlFor="passwordIn2">{mgmtStrings.password} ({mgmtStrings.repeat})</label>
                                                </span>
                                                <ErrorMessage name="password" render={msg => <Message severity='warn' text={msg} />} />
                                            </div>
                                            <div className="p-col-12" style={{ padding: '1em' }}>
                                                {mgmtStrings.passwordComplexity}
                                            </div>
                                            <div className="p-col-12" style={{ padding: '1em' }}>
                                                {mgmtStrings.registerNote}
                                            </div>
                                            <div className="p-col-6" style={{ padding: '1em' }}>
                                                <button type="submit" className="p-button" style={{ padding: 8 }}>{headerStrings.register}</button>
                                                &nbsp;<Link to="/"><Button className="p-button-secondary" label={headerStrings.goBack} /></Link>
                                            </div>
                                            {(this.state.message != null) && (
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <Message severity={this.state.message.severity} text={this.state.message.text} />
                                                </div>
                                            )}
                                        </div>
                                    </Form>
                                </Formik>
                            </Panel>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: AuthSelectors.isAuthenticated(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doRegister: (email, password) => dispatch(registerUser(email, password))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterView);