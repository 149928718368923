import evmApi from '../services/EvmApi';
import { InstallationTypes } from '../redux/InstallationRedux';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to installation list.
 */

/**
 * Fetches all available installations from server.
 * @returns a promise with result object
 */
export function fetchAllInstallations() {
    return (dispatch) => {
        return evmApi.getAllInstallations(true, true, true, true)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(installationsLoadSuccess(response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches a specific installation from server.
 * @param {Number} installationId  the installation ID
 * @returns a promise with result object
 */
export function fetchInstallation(installationId) {
    return (dispatch) => {
        return evmApi.getInstallation(installationId, true, true, true, true)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(installationUpdate(response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Notifies the reducer that installations were fetched successfully.
 * @param {Array} installations the fetched installations
 * @returns an action
 */
export function installationsLoadSuccess(installations) {
    return { type: InstallationTypes.INSTALLATIONS_LOADED, payload: installations };
}

/**
 * Notifies the reducer that an installation was updated successfully.
 * @param {Object} installation the updated installation
 * @returns an action
 */
export function installationUpdate(installation) {
    return { type: InstallationTypes.INSTALLATION_UPDATE, payload: installation };
}

/**
 * Notifies the reducer that installations should be cleared.
 * @returns an action
 */
export function installationsClear() {
    return { type: InstallationTypes.INSTALLATIONS_CLEAR };
}

/**
 * Notifies the reducer that an installation's status should be updated.
 * @param {Number} installationId the installation ID
 * @param {Object} status the status object
 */
export function installationStatusUpdate(installationId, status) {
    return { type: InstallationTypes.INSTALLATION_STATUS_UPDATE, payload: { installationId: installationId, status: status } };
}

/**
 * Notifies the reducer that an installation's power flow should be updated.
 * @param {Number} installationId the installation ID
 * @param {Object} powerFlow the power flow object
 */
export function installationPowerFlowUpdate(installationId, powerFlow) {
    return { type: InstallationTypes.INSTALLATION_POWERFLOW_UPDATE, payload: { installationId: installationId, powerFlow: powerFlow } };
}

/**
 * Notifies the reducer that an installation's price should be updated.
 * @param {Number} installationId the installation ID
 * @param {Object} price the price object
 */
export function installationPriceUpdate(installationId, price) {
    return { type: InstallationTypes.INSTALLATION_PRICE_UPDATE, payload: { installationId: installationId, price: price } };
}

/**
 * Removes a specific installation, if present.
 * @param {Number} installationId the installation ID
 */
export function installationRemove(installationId) {
    return { type: InstallationTypes.INSTALLATION_REMOVE, payload: { installationId: installationId } };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.installationNotFound;

        case 422:
            return errorStrings.invalidData;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}