import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DateGraph from '../components/DateGraph';
import ActualValue from '../components/ActualValue';
import { fetchAllInstallations } from '../actions/InstallationActions';
import { fetchAllComponents } from '../actions/ComponentActions';
import { fetchAllBuildingUnits, fetchBuildingUnit, fetchBuildingUnitComponentIds } from '../actions/BuildingUnitActions';
import { fetchComponentDayTimeData, fetchComponentMonthDayData, fetchComponentYearMonthData, fetchComponentYearsData,
            fetchAllComponentsActualData } from '../actions/ComponentDataActions';
import { fetchGeneralFiguresByDay } from '../actions/GeneralFigureActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { ComponentSelectors } from '../redux/ComponentRedux';
import { BuildingUnitSelectors } from '../redux/BuildingUnitRedux';
import { ComponentActualSelectors } from '../redux/ComponentActualDataRedux';
import { ComponentDayTimeSelectors } from '../redux/ComponentDayTimeDataRedux';
import { ComponentMonthDaySelectors } from '../redux/ComponentMonthDayDataRedux';
import { ComponentYearMonthSelectors } from '../redux/ComponentYearMonthDataRedux';
import { ComponentYearsSelectors } from '../redux/ComponentYearsDataRedux';
import { GeneralFigureDaySelectors } from '../redux/GeneralFigureDayRedux';
import { createBarChartOptions, createLineChartOptions, getXAxisLabels, getXAxisScaleLabel, getYAxisScaleLabels,
            convertComponentStatisticData, convertComponentCostData, convertComponentContinuousData, convertContinuousPriceData,
            createDataSetsForLineChart, createDataSetsForBarChart, createCostDayDataSets } from '../components/util/GraphUtils';
import { getMeasurementUnits, filterMeasuredComponentsByType, getTypeIcon } from '../components/util/ComponentUtils';
import { headerStrings, installationStrings, errorStrings, graphStrings, componentStrings } from '../i18n/translations';

/**
 * The building unit electricity page.
 * Displays electric component data.
 */
class BuildingUnitElectricityView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // methods requiring access to 'this'
        this.resetState = this.resetState.bind(this);
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.filterUnitComponents = this.filterUnitComponents.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);

        this.onComponentDayChanged = this.onComponentDayChanged.bind(this);
        this.onComponentMonthChanged = this.onComponentMonthChanged.bind(this);
        this.onComponentYearChanged = this.onComponentYearChanged.bind(this);
        this.updateComponentDayChart = this.updateComponentDayChart.bind(this);
        this.updateComponentMonthChart = this.updateComponentMonthChart.bind(this);
        this.updateComponentYearChart = this.updateComponentYearChart.bind(this);
        this.updateComponentYearComparisonChart = this.updateComponentYearComparisonChart.bind(this);
        this.updateVisibleComponentChart = this.updateVisibleComponentChart.bind(this);
        this.onComponentLegendClick = this.onComponentLegendClick.bind(this);

        this.onComponentCostDayChanged = this.onComponentCostDayChanged.bind(this);
        this.onComponentCostMonthChanged = this.onComponentCostMonthChanged.bind(this);
        this.onComponentCostYearChanged = this.onComponentCostYearChanged.bind(this);
        this.updateComponentCostDayChart = this.updateComponentCostDayChart.bind(this);
        this.updateComponentCostMonthChart = this.updateComponentCostMonthChart.bind(this);
        this.updateComponentCostYearChart = this.updateComponentCostYearChart.bind(this);
        this.updateComponentCostYearComparisonChart = this.updateComponentCostYearComparisonChart.bind(this);
        this.updateVisibleComponentCostChart = this.updateVisibleComponentCostChart.bind(this);
        this.onComponentCostLegendClick = this.onComponentCostLegendClick.bind(this);

        this.renderActualValues = this.renderActualValues.bind(this);

        // init state
        const day = moment().startOf('day').format('YYYY-MM-DD');
        const month = moment().startOf('month').format('YYYY-MM-DD');
        const year = moment().startOf('year').format('YYYY-MM-DD');
        const yLabels = getYAxisScaleLabels('Verbrauch');
        const costYLabels = getYAxisScaleLabels('Kosten');
        const unitComponents = this.filterUnitComponents();
        const elecComponents = filterMeasuredComponentsByType(unitComponents, 'Verbrauch');
        this.state = {
            isRefreshing: false,
            error: null,
            unitComponents: unitComponents,
            elecComponents: elecComponents,

            componentTabIndex: 0,
            componentSelectedDay: day,
            componentSelectedMonth: month,
            componentSelectedYear: year,
            componentSelectedYears: year,
            componentDayOptions: createLineChartOptions(day, true, yLabels[0], this.onComponentLegendClick),
            componentDayData: { datasets: createDataSetsForLineChart(elecComponents, true, true, true) },
            componentMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentMonthData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearComparisonData: { datasets: createDataSetsForBarChart(elecComponents) },

            componentCostTabIndex: 0,
            componentCostSelectedDay: day,
            componentCostSelectedMonth: month,
            componentCostSelectedYear: year,
            componentCostSelectedYears: year,
            componentCostDayOptions: createLineChartOptions(day, false, costYLabels[0], this.onComponentCostLegendClick),
            componentCostDayData: { datasets: createCostDayDataSets() },
            componentCostMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, true, costYLabels[1], this.onComponentCostLegendClick),
            componentCostMonthData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentCostYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, true, costYLabels[1], this.onComponentCostLegendClick),
            componentCostYearData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentCostYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, true, costYLabels[1], this.onComponentCostLegendClick),
            componentCostYearComparisonData: { datasets: createDataSetsForBarChart(elecComponents) },
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Building unit electricity view did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props were updated
        if (!this.mounted) return;

        // probably not allowed to access installation, or not found
        if ((this.props.installationList.length > 0) && (this.props.selectedInstallation === undefined)) {
            // probably not allowed, or no valid installation
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Installation " + this.props.installationId + " not found");
                }
                this.setState({ error: errorStrings.installationNotFound, unitComponents: [], elecComponents: [] });
            }
            return;
        }
        // probably not allowed to access building unit, or not found
        if ((this.props.buildingUnits !== undefined) && (this.props.selectedUnit === undefined)) {
            // probably not allowed, or no valid building unit
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Building unit " + this.props.unitId + " not found");
                }
                this.setState({ error: errorStrings.unitNotFound, unitComponents: [], elecComponents: [] });
            }
            return;
        }
        // changed URL parameters while view is still active
        if (((this.props.installationId !== prevProps.installationId) && (prevProps.installationId !== undefined)) ||
                ((this.props.unitId !== prevProps.unitId) && (prevProps.unitId !== undefined))) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("URL parameters changed, resetting state");
            }
            this.resetState();
            this.onViewLoaded();
            return;
        }
        // authentication changed to true
        if ((this.props.isAuthenticated !== prevProps.isAuthenticated) && (this.props.isAuthenticated === true)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
            this.refreshAllData(true);
            return;
        }
        // components loaded
        if ((this.props.components !== undefined) && (prevProps.components === undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Components loaded");
            }
            // should update unit components
            const unitComponents = this.filterUnitComponents();
            this.setState({
                unitComponents: unitComponents,
                elecComponents: filterMeasuredComponentsByType(unitComponents, 'Verbrauch')
            });
        }
        // building units changed
        if ((this.props.buildingUnits !== prevProps.buildingUnits) && (this.props.buildingUnits !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Building units updated");
            }
            if (this.props.selectedUnit !== undefined) {
                if (this.props.selectedUnit.componentIds == null) {
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require building unit component IDs refresh");
                    }
                    this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId)
                            .then((result) => {
                                if (!this.mounted) return;
                                let newState = {
                                    isRefreshing: false,
                                    error: (result.success === true) ? null : result.message,
                                };
                                if (result.success === true) {
                                    // should update unit components
                                    const unitComponents = this.filterUnitComponents();
                                    newState.unitComponents = unitComponents;
                                    newState.elecComponents = filterMeasuredComponentsByType(unitComponents, 'Verbrauch');
                                }
                                this.setState(newState);
                            });
                } else {
                    // should update unit components
                    const unitComponents = this.filterUnitComponents();
                    this.setState({
                        unitComponents: unitComponents,
                        elecComponents: filterMeasuredComponentsByType(unitComponents, 'Verbrauch')
                    });
                }
            }
        }

        // flags for chart updates
        let updateComponentChart = false;
        let updateComponentCostChart = false;
        let fetchData = false;
        let createSets = false;
        let componentAxes = false;
        let componentCostAxes = false;

        // general figures were updated
        if ((this.props.generalFigureData !== prevProps.generalFigureData) && (this.props.generalFigureData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General figure data (day-time) updated");
            }
            if (this.state.componentCostTabIndex === 0) {
                updateComponentCostChart = true;
            }
        }
        // component day data was updated
        if ((this.props.componentDayTimeData !== prevProps.componentDayTimeData) && (this.props.componentDayTimeData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (day-time) updated");
            }
            if (this.state.componentTabIndex === 0) {
                updateComponentChart = true;
            }
        }
        // component month data was updated
        if ((this.props.componentMonthDayData !== prevProps.componentMonthDayData) && (this.props.componentMonthDayData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (month-day) updated");
            }
            if (this.state.componentTabIndex === 1) {
                updateComponentChart = true;
            }
            if (this.state.componentCostTabIndex === 1) {
                updateComponentCostChart = true;
            }
        }
        // component year data was updated
        if ((this.props.componentYearMonthData !== prevProps.componentYearMonthData) && (this.props.componentYearMonthData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (year-month) updated");
            }
            if (this.state.componentTabIndex === 2) {
                updateComponentChart = true;
            }
            if (this.state.componentCostTabIndex === 2) {
                updateComponentCostChart = true;
            }
        }
        // component years data was updated
        if ((this.props.componentYearsData !== prevProps.componentYearsData) && (this.props.componentYearsData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (years) updated");
            }
            if (this.state.componentTabIndex === 3) {
                updateComponentChart = true;
            }
            if (this.state.componentCostTabIndex === 3) {
                updateComponentCostChart = true;
            }
        }
        // local components changed
        if (this.state.elecComponents !== prevState.elecComponents) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Electric components changed");
            }
            // update ALL charts
            fetchData = true;
            createSets = true;
            updateComponentChart = true;
            updateComponentCostChart = true;
        }
        // component tab changed
        if (this.state.componentTabIndex !== prevState.componentTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data tab changed to " + this.state.componentTabIndex);
            }
            fetchData = true;
            updateComponentChart = true;
        }
        // component cost tab changed
        if (this.state.componentCostTabIndex !== prevState.componentCostTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component cost data tab changed to " + this.state.componentCostTabIndex);
            }
            fetchData = true;
            updateComponentCostChart = true;
        }
        // selected date of component day graph has changed
        if ((this.state.componentSelectedDay !== prevState.componentSelectedDay) && (this.state.componentSelectedDay !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component day changed to " + moment(this.state.componentSelectedDay).format('YYYY-MM-DD'));
            }
            updateComponentChart = true;
            componentAxes = true;
            fetchData = true;
        }
        // selected date of component month graph has changed
        if ((this.state.componentSelectedMonth !== prevState.componentSelectedMonth) && (this.state.componentSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component month changed to " + moment(this.state.componentSelectedMonth).format('YYYY-MM'));
            }
            updateComponentChart = true;
            componentAxes = true;
            fetchData = true;
        }
        // selected date of component year graph has changed
        if ((this.state.componentSelectedYear !== prevState.componentSelectedYear) && (this.state.componentSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component year changed to " + moment(this.state.componentSelectedYear).format('YYYY'));
            }
            updateComponentChart = true;
            componentAxes = true;
            fetchData = true;
        }
        // selected date of component day graph has changed
        if ((this.state.componentCostSelectedDay !== prevState.componentCostSelectedDay) && (this.state.componentCostSelectedDay !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component cost day changed to " + moment(this.state.componentCostSelectedDay).format('YYYY-MM-DD'));
            }
            updateComponentCostChart = true;
            componentCostAxes = true;
            fetchData = true;
        }
        // selected date of component cost month graph has changed
        if ((this.state.componentCostSelectedMonth !== prevState.componentCostSelectedMonth) && (this.state.componentCostSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component cost month changed to " + moment(this.state.componentCostSelectedMonth).format('YYYY-MM'));
            }
            updateComponentCostChart = true;
            componentCostAxes = true;
            fetchData = true;
        }
        // selected date of component cost year graph has changed
        if ((this.state.componentCostSelectedYear !== prevState.componentCostSelectedYear) && (this.state.componentCostSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component cost year changed to " + moment(this.state.componentSelectedYear).format('YYYY'));
            }
            updateComponentCostChart = true;
            componentCostAxes = true;
            fetchData = true;
        }

        // update all charts
        if (updateComponentChart) {
            this.updateComponentDayChart(componentAxes, fetchData, createSets);
            this.updateComponentMonthChart(componentAxes, fetchData, createSets);
            this.updateComponentYearChart(componentAxes, fetchData, createSets);
            this.updateComponentYearComparisonChart(componentAxes, fetchData, createSets);
        }
        if (updateComponentCostChart) {
            this.updateComponentCostDayChart(componentCostAxes, fetchData, createSets);
            this.updateComponentCostMonthChart(componentCostAxes, fetchData, createSets);
            this.updateComponentCostYearChart(componentCostAxes, fetchData, createSets);
            this.updateComponentCostYearComparisonChart(componentCostAxes, fetchData, createSets);
        }
    }

    resetState() {
        // keep selected dates, tab indexes etc.
        const compDay = this.state.componentSelectedDay;
        const compMonth = this.state.componentSelectedMonth;
        const compYear = this.state.componentSelectedYear;
        const compYears = this.state.componentSelectedYears;

        const compCostDay = this.state.componentCostSelectedDay;
        const compCostMonth = this.state.componentCostSelectedMonth;
        const compCostYear = this.state.componentCostSelectedYear;
        const compCostYears = this.state.componentCostSelectedYears;

        const yLabels = getYAxisScaleLabels('Verbrauch');
        const costYLabels = getYAxisScaleLabels('Kosten');
        const unitComponents = this.filterUnitComponents();
        const elecComponents = filterMeasuredComponentsByType(unitComponents, 'Verbrauch');
        this.setState({
            error: null,
            unitComponents: unitComponents,
            elecComponents: elecComponents,

            componentDayOptions: createLineChartOptions(compDay, true, yLabels[0], this.onComponentLegendClick),
            componentDayData: { datasets: createDataSetsForLineChart(elecComponents, true, true, true) },
            componentMonthOptions: createBarChartOptions('MonthDays', compMonth, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentMonthData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentYearOptions: createBarChartOptions('YearMonths', compYear, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentYearComparisonOptions: createBarChartOptions('Years', compYears, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearComparisonData: { datasets: createDataSetsForBarChart(elecComponents) },

            componentCostDayOptions: createLineChartOptions(compCostDay, false, costYLabels[0], this.onComponentCostLegendClick),
            componentCostDayData: { datasets: createCostDayDataSets() },
            componentCostMonthOptions: createBarChartOptions('MonthDays', compCostMonth, AppConfig.yearsToCompare, true, costYLabels[1], this.onComponentCostLegendClick),
            componentCostMonthData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentCostYearOptions: createBarChartOptions('YearMonths', compCostYear, AppConfig.yearsToCompare, true, costYLabels[1], this.onComponentCostLegendClick),
            componentCostYearData: { datasets: createDataSetsForBarChart(elecComponents) },
            componentCostYearComparisonOptions: createBarChartOptions('Years', compCostYears, AppConfig.yearsToCompare, true, costYLabels[1], this.onComponentCostLegendClick),
            componentCostYearComparisonData: { datasets: createDataSetsForBarChart(elecComponents) },
        });
    }

    refreshAllData(force = false) {
        // TODO incremental data update, if the latest time period contains partial data

        let tasks = [];
        let updateUnitComponents = false;
        this.setState({ isRefreshing: true, error: null });
        if (this.props.installationList.length === 0) {
            // load all installations
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation list refresh");
            }
            tasks.push(this.props.doFetchAllInstallations());
        }
        if ((this.props.components === undefined) || (this.props.components.length === 0)) {
            // load all components
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component list refresh");
            }
            tasks.push(this.props.doFetchAllComponents(this.props.installationId));
        }
        if ((this.props.buildingUnits === undefined) || (this.props.buildingUnits.length === 0)) {
            // load all units
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit list refresh");
            }
            tasks.push(this.props.doFetchAllBuildingUnits(this.props.installationId));
        } else if (force) {
            // update building unit
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit update refresh");
            }
            tasks.push(this.props.doFetchBuildingUnit(this.props.installationId, this.props.unitId));
        }
        if ((this.props.selectedUnit !== undefined) && (this.props.selectedUnit.componentIds == null)) {
            // update component IDs
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit component IDs refresh");
            }
            updateUnitComponents = true;
            tasks.push(this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId));
        }
        // always update actual values
        tasks.push(this.props.doFetchAllComponentsActualData(this.props.installationId));

        // component data - fetch only for visible tab, because tab change will initiate fetch anyway
        if (this.state.componentTabIndex === 0) {
            this.state.elecComponents.forEach((comp) => {
                if ((this.props.componentDayTimeData === undefined || this.props.componentDayTimeData[comp.id] === undefined || this.props.componentDayTimeData[comp.id][this.state.componentSelectedDay] === undefined) || (moment().format('YYYY-MM-DD') === this.state.componentSelectedDay) || force) {
                    tasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, comp.id, this.state.componentSelectedDay));
                }
            });
        }
        if (this.state.componentTabIndex === 1) {
            this.state.elecComponents.forEach((comp) => {
                if ((this.props.componentMonthDayData === undefined || this.props.componentMonthDayData[comp.id] === undefined || this.props.componentMonthDayData[comp.id][this.state.componentSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.componentSelectedMonth) || force) {
                    tasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, comp.id, this.state.componentSelectedMonth));
                }
            });
        }
        if (this.state.componentTabIndex === 2) {
            this.state.elecComponents.forEach((comp) => {
                if ((this.props.componentYearMonthData === undefined || this.props.componentYearMonthData[comp.id] === undefined || this.props.componentYearMonthData[comp.id][this.state.componentSelectedYear] === undefined)|| (moment().startOf('year').format('YYYY-MM-DD') === this.state.componentSelectedYear) || force) {
                    tasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, comp.id, this.state.componentSelectedYear));
                }
            });
        }
        if (this.state.componentTabIndex === 3) {
            this.state.elecComponents.forEach((comp) => {
                tasks.push(this.props.doFetchComponentYearsData(this.props.installationId, comp.id, this.state.componentSelectedYears, AppConfig.yearsToCompare));
            });
        }

        // component cost data - fetch only for visible tab, because tab change will initiate fetch anyway
        if (this.state.componentCostTabIndex === 0) {
            if ((this.props.generalFigures === undefined) || (this.props.generalFigures[this.state.componentCostSelectedDay] === undefined) || (moment().startOf('day').format('YYYY-MM-DD') === this.state.componentCostSelectedDay) || force) {
                tasks.push(this.props.doFetchGeneralDayFigures(this.props.installationId, this.state.componentCostSelectedDay));
            }
        }
        if (this.state.componentCostTabIndex === 1) {
            this.state.elecComponents.forEach((comp) => {
                if ((this.props.componentMonthDayData === undefined || this.props.componentMonthDayData[comp.id] === undefined || this.props.componentMonthDayData[comp.id][this.state.componentCostSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.componentCostSelectedMonth) || force) {
                    tasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, comp.id, this.state.componentCostSelectedMonth));
                }
            });
        }
        if (this.state.componentCostTabIndex === 2) {
            this.state.elecComponents.forEach((comp) => {
                if ((this.props.componentYearMonthData === undefined || this.props.componentYearMonthData[comp.id] === undefined || this.props.componentYearMonthData[comp.id][this.state.componentCostSelectedYear] === undefined)|| (moment().startOf('year').format('YYYY-MM-DD') === this.state.componentCostSelectedYear) || force) {
                    tasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, comp.id, this.state.componentCostSelectedYear));
                }
            });
        }
        if (this.state.componentCostTabIndex === 3) {
            this.state.elecComponents.forEach((comp) => {
                tasks.push(this.props.doFetchComponentYearsData(this.props.installationId, comp.id, this.state.componentCostSelectedYears, AppConfig.yearsToCompare));
            });
        }

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks)
                .then((results) => {
                    if (!this.mounted) return;
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });

                    // just refresh state using existing components
                    const unitComponents = updateUnitComponents ? this.filterUnitComponents() : this.state.unitComponents;
                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | '),
                        unitComponents: unitComponents,
                        elecComponents: filterMeasuredComponentsByType(unitComponents, 'Verbrauch')
                    });
                });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // reload
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    filterUnitComponents() {
        if ((this.props.selectedUnit !== undefined) && (this.props.components !== undefined)) {
            const unitComponents = [];
            const cIds = this.props.selectedUnit.componentIds;
            if (cIds != null) {
                cIds.forEach((id) => {
                    let comp = this.props.components.find((c) => (c.id === id));
                    if (comp !== undefined) {
                        unitComponents.push(comp);
                    }
                });
                // sort by name
                unitComponents.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }
            return unitComponents;
        } else if (BuildConfig.isReactotronEnabled) {
            Reactotron.warn("Failed to filter unit components");
        }
        return [];
    }

    updateComponentDayChart(axes, fetch, createsets) {
        const day = this.state.componentSelectedDay;
        const components = this.state.elecComponents;
        const oldDataSets = this.state.componentDayData.datasets;
        const newDataSets = (createsets) ? createDataSetsForLineChart(components, true, true, true) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentDayTimeData !== undefined) && (this.props.componentDayTimeData[components[i].id] !== undefined) && (this.props.componentDayTimeData[components[i].id][day] !== undefined)) {
                    newDataSets[i].data = convertComponentContinuousData(this.props.componentDayTimeData[components[i].id][day], day);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + components[i].id + ": day data of " + day + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, components[i].id, day));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentDayOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].ticks.min = moment(day).startOf('day');
                newOptions.scales.xAxes[0].ticks.max = moment(day).add(1, 'days').startOf('day');
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('DayTime', day);
                newState.componentDayOptions = newOptions;
            }
            newState.componentDayData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentMonthChart(axes, fetch, createsets) {
        const month = this.state.componentSelectedMonth;
        const components = this.state.elecComponents;
        const oldDataSets = this.state.componentMonthData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentMonthDayData !== undefined) && (this.props.componentMonthDayData[components[i].id] !== undefined) && (this.props.componentMonthDayData[components[i].id][month] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentMonthDayData[components[i].id][month], 'MonthDays', month);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + components[i].id + ":month data of " + moment(month).format('YYYY-MM') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, components[i].id, month));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newState.componentMonthOptions = newOptions;
            }
            newState.componentMonthData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentYearChart(axes, fetch, createsets) {
        const year = this.state.componentSelectedYear;
        const components = this.state.elecComponents;
        const oldDataSets = this.state.componentYearData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearMonthData !== undefined) && (this.props.componentYearMonthData[components[i].id] !== undefined) && (this.props.componentYearMonthData[components[i].id][year] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentYearMonthData[components[i].id][year], 'YearMonths', year);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + components[i].id + ": year data of " + moment(year).format('YYYY') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, components[i].id, year));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newState.componentYearOptions = newOptions;
            }
            newState.componentYearData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.componentSelectedYears;
        const components = this.state.elecComponents;
        const oldDataSets = this.state.componentYearComparisonData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearsData !== undefined) && (this.props.componentYearsData[components[i].id] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentYearsData[components[i].id], 'Years', years, AppConfig.yearsToCompare);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + components[i].id + ": years data not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearsData(this.props.installationId, components[i].id, years, AppConfig.yearsToCompare));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newState.componentYearComparisonOptions = newOptions;
            }
            newState.componentYearComparisonData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateVisibleComponentChart(axes, fetch, createsets) {
        switch (this.state.componentTabIndex) {
            case 0: {
                this.updateComponentDayChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateComponentMonthChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateComponentYearChart(axes, fetch, createsets);
                break;
            }
            case 3: {
                this.updateComponentYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    updateComponentCostDayChart(axes, fetch, createsets) {
        const day = this.state.componentCostSelectedDay;

        const oldDataSets = this.state.componentCostDayData.datasets;
        const newDataSets = (createsets) ? createCostDayDataSets() : [...oldDataSets];

        let fetchTasks = [];

        if (fetch && ((this.props.generalFigureData === undefined) || (this.props.generalFigureData[day] === undefined))) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General figures: day data of " + day + " not found, require fetch");
            }
            if (fetchTasks.length === 0) {
                // once
                this.setState({ isRefreshing: true });
            }
            fetchTasks.push(this.props.doFetchGeneralDayFigures(this.props.installationId, day));
        }

        // even if no fetch tasks defined
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // process result of fetch requests
                Promise.all(fetchTasks).then((results) => {
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });
                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | ')
                    });

                    if (errors.length === 0) {
                        if (BuildConfig.isReactotronEnabled) {
                            Reactotron.log("Updating cost day chart ***");
                        }

                        // update chart
                        let newState = {};
                        if (axes) {
                            const oldOptions = this.state.componentCostDayOptions;
                            const newOptions = { ...oldOptions };
                            newOptions.scales.xAxes[0].ticks.min = moment(day).startOf('day');
                            newOptions.scales.xAxes[0].ticks.max = moment(day).add(1, 'days').startOf('day');
                            newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('DayTime', day);
                            newState.componentCostDayOptions = newOptions;
                        }

                        // get price data
                        if ((this.props.generalFigureData !== undefined) && (this.props.generalFigureData[day] !== undefined)) {
                            newDataSets[0].data = convertContinuousPriceData(this.props.generalFigureData[day], day);
                        } else {
                            newDataSets[0].data = [];
                        }

                        // update state
                        newState.componentCostDayData = { datasets: newDataSets };
                        this.setState(newState);
                    }
                });
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    updateComponentCostMonthChart(axes, fetch, createsets) {
        const month = this.state.componentCostSelectedMonth;
        const components = this.state.elecComponents;
        const oldDataSets = this.state.componentCostMonthData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentMonthDayData !== undefined) && (this.props.componentMonthDayData[components[i].id] !== undefined) && (this.props.componentMonthDayData[components[i].id][month] !== undefined)) {
                    newDataSets[i].data = convertComponentCostData(this.props.componentMonthDayData[components[i].id][month], 'MonthDays', month);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + components[i].id + ":month data of " + moment(month).format('YYYY-MM') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, components[i].id, month));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentCostMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newState.componentCostMonthOptions = newOptions;
            }
            newState.componentCostMonthData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentCostYearChart(axes, fetch, createsets) {
        const year = this.state.componentCostSelectedYear;
        const components = this.state.elecComponents;
        const oldDataSets = this.state.componentCostYearData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearMonthData !== undefined) && (this.props.componentYearMonthData[components[i].id] !== undefined) && (this.props.componentYearMonthData[components[i].id][year] !== undefined)) {
                    newDataSets[i].data = convertComponentCostData(this.props.componentYearMonthData[components[i].id][year], 'YearMonths', year);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + components[i].id + ": year data of " + moment(year).format('YYYY') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, components[i].id, year));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentCostYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newState.componentCostYearOptions = newOptions;
            }
            newState.componentCostYearData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentCostYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.componentCostSelectedYears;
        const components = this.state.elecComponents;
        const oldDataSets = this.state.componentCostYearComparisonData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearsData !== undefined) && (this.props.componentYearsData[components[i].id] !== undefined)) {
                    newDataSets[i].data = convertComponentCostData(this.props.componentYearsData[components[i].id], 'Years', years, AppConfig.yearsToCompare);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + components[i].id + ": years data not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearsData(this.props.installationId, components[i].id, years, AppConfig.yearsToCompare));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentCostYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newState.componentCostYearComparisonOptions = newOptions;
            }
            newState.componentCostYearComparisonData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateVisibleComponentCostChart(axes, fetch, createsets) {
        switch (this.state.componentCostTabIndex) {
            case 0: {
                this.updateComponentCostDayChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateComponentCostMonthChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateComponentCostYearChart(axes, fetch, createsets);
                break;
            }
            case 3: {
                this.updateComponentCostYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    onViewLoaded() {
        // update installation and fetch components
        if (this.props.isAuthenticated === true) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Update of building unit and its components");
            }
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);
                // update visible charts
                this.updateVisibleComponentChart(true, false, true);
                this.updateVisibleComponentCostChart(true, false, true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    onComponentDayChanged(date) {
        const day = moment(date).startOf('day').format('YYYY-MM-DD');
        this.setState({ componentSelectedDay: day });
    }

    onComponentMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ componentSelectedMonth: month });
    }

    onComponentYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ componentSelectedYear: year });
    }

    onComponentLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.componentTabIndex) {
            case 0: {
                const sets = this.state.componentDayData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentDayData: { datasets: newSets } });
                }
                break;
            }
            case 1: {
                const sets = this.state.componentMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentMonthData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.componentYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentYearData: { datasets: newSets } });
                }
                break;
            }
            case 3: {
                const sets = this.state.componentYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    onComponentCostDayChanged(date) {
        const day = moment(date).startOf('day').format('YYYY-MM-DD');
        this.setState({ componentCostSelectedDay: day });
    }

    onComponentCostMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ componentCostSelectedMonth: month });
    }

    onComponentCostYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ componentCostSelectedYear: year });
    }

    onComponentCostLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.componentCostTabIndex) {
            case 0: {
                break;
            }
            case 1: {
                const sets = this.state.componentCostMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentCostMonthData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.componentCostYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentCostYearData: { datasets: newSets } });
                }
                break;
            }
            case 3: {
                const sets = this.state.componentCostYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentCostYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.installationOverview, enabled: true, active: false, icon: "fas fa-building", path: "/installations/" + this.props.installationId + "/overview" },
            { name: headerStrings.unitOverview, enabled: true, active: false, icon: "fas fa-door-open", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/overview" },
            { name: headerStrings.electricity, enabled: false, active: true, icon: "fas fa-bolt", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/electricity" }
        ];
        if (this.state.unitComponents.find(c => (c.type === 'Temperatur' || c.type === 'Waermezaehler')) !== undefined) {
            navItems.push({ name: headerStrings.temperatures + " / " + headerStrings.heating, enabled: true, active: false, icon: "fas fa-thermometer-three-quarters", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/thermal" });
        }
        if (this.state.unitComponents.find(c => (c.type === 'Wasserzaehler')) !== undefined) {
            navItems.push({ name: headerStrings.water, enabled: true, active: false, icon: "fas fa-tint", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/water" });
        }
        if (this.state.unitComponents.find(c => c.controllable) !== undefined) {
            navItems.push({ name: headerStrings.controls, enabled: true, active: false, icon: "fas fa-sliders-h", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/control" });
        }

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <Panel header={headerStrings.electricity + " – " + installationStrings.actualValues}>
                        {(this.state.elecComponents.length > 0) ? this.renderActualValues() : (<div>{componentStrings.noElectricComponents}</div>)}
                    </Panel>
                    <div style={{ margin: 20 }}></div>
                    <Panel header={headerStrings.electricity + " – " + installationStrings.consumers}>
                        {(this.state.elecComponents.length > 0) ? (
                            <TabView activeIndex={this.state.componentTabIndex} onTabChange={(e) => this.setState({ componentTabIndex: e.index })} renderActiveOnly={true}>
                                <TabPanel header={graphStrings.day}>
                                    {/* Time of day */}
                                    <DateGraph term="DayTime" date={this.state.componentSelectedDay} onDateChanged={this.onComponentDayChanged} chartType="line" chartData={this.state.componentDayData} chartOptions={this.state.componentDayOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.month}>
                                    {/* All days of a month */}
                                    <DateGraph term="MonthDays" date={this.state.componentSelectedMonth} onDateChanged={this.onComponentMonthChanged} chartType="bar" chartData={this.state.componentMonthData} chartOptions={this.state.componentMonthOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.year}>
                                    {/* All months of a year */}
                                    <DateGraph term="YearMonths" date={this.state.componentSelectedYear} onDateChanged={this.onComponentYearChanged} chartType="bar" chartData={this.state.componentYearData} chartOptions={this.state.componentYearOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years}>
                                    {/* Compare last X years */}
                                    <DateGraph term="Years" date={this.state.componentSelectedYears} chartType="bar" chartData={this.state.componentYearComparisonData} chartOptions={this.state.componentYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                            </TabView>
                        ) : (
                            <div>{componentStrings.noElectricComponents}</div>
                        )}
                    </Panel>
                    <div style={{ margin: 20 }}></div>
                    <Panel header={headerStrings.electricity + " – " + componentStrings.cost}>
                        {(this.state.elecComponents.length > 0) ? (
                            <TabView activeIndex={this.state.componentCostTabIndex} onTabChange={(e) => this.setState({ componentCostTabIndex: e.index })} renderActiveOnly={true}>
                                <TabPanel header={graphStrings.day}>
                                    <DateGraph term="DayTime" date={this.state.componentCostSelectedDay} onDateChanged={this.onComponentCostDayChanged} chartType="line" chartData={this.state.componentCostDayData} chartOptions={this.state.componentCostDayOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.month}>
                                    {/* All days of a month */}
                                    <DateGraph term="MonthDays" date={this.state.componentCostSelectedMonth} onDateChanged={this.onComponentCostMonthChanged} chartType="bar" chartData={this.state.componentCostMonthData} chartOptions={this.state.componentCostMonthOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.year}>
                                    {/* All months of a year */}
                                    <DateGraph term="YearMonths" date={this.state.componentCostSelectedYear} onDateChanged={this.onComponentCostYearChanged} chartType="bar" chartData={this.state.componentCostYearData} chartOptions={this.state.componentCostYearOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years}>
                                    {/* Compare last X years */}
                                    <DateGraph term="Years" date={this.state.componentCostSelectedYears} chartType="bar" chartData={this.state.componentCostYearComparisonData} chartOptions={this.state.componentCostYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                            </TabView>
                        ) : (
                            <div>{componentStrings.noElectricComponents}</div>
                        )}
                    </Panel>
                </div>
                <Footer />
            </div>
        );
    }

    renderActualValues() {
        const components = this.state.elecComponents;
        return (
            <div className="p-grid p-justify-start">
                {components.map((comp) => {
                    const value = ((this.props.componentActualData !== undefined) && (this.props.componentActualData[comp.id] !== undefined) && moment(this.props.componentActualData[comp.id].dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.componentActualData[comp.id].value : undefined;
                    return (
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2" key={comp.id}>
                            <ActualValue name={comp.name} color={comp.color} value={value} unit={getMeasurementUnits(comp.type)[0]} decimals={3} icon={getTypeIcon(comp.type)} />
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const installationId = ownProps.match.params.instId;    // might not be integer!
    const unitId = ownProps.match.params.unitId;            // might not be integer!
    return {
        installationId,
        unitId,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installationList: InstallationSelectors.selectAll(state),
        selectedInstallation: InstallationSelectors.selectOne(state, installationId),
        buildingUnits: BuildingUnitSelectors.selectAll(state, installationId),
        selectedUnit: BuildingUnitSelectors.selectOne(state, installationId, unitId),
        components: ComponentSelectors.selectAll(state, installationId),
        componentActualData: ComponentActualSelectors.selectAllOfInstallation(state, installationId),
        componentDayTimeData: ComponentDayTimeSelectors.selectAllOfInstallation(state, installationId),
        componentMonthDayData: ComponentMonthDaySelectors.selectAllOfInstallation(state, installationId),
        componentYearMonthData: ComponentYearMonthSelectors.selectAllOfInstallation(state, installationId),
        componentYearsData: ComponentYearsSelectors.selectAllOfInstallation(state, installationId),
        generalFigureData: GeneralFigureDaySelectors.selectAllOfInstallation(state, installationId)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchAllInstallations: () => dispatch(fetchAllInstallations()),
        doFetchAllComponents: (instId) => dispatch(fetchAllComponents(instId)),
        doFetchAllBuildingUnits: (instId) => dispatch(fetchAllBuildingUnits(instId)),
        doFetchBuildingUnit: (instId, unitId) => dispatch(fetchBuildingUnit(instId, unitId)),
        doFetchBuildingUnitComponentIds: (instId, unitId) => dispatch(fetchBuildingUnitComponentIds(instId, unitId)),
        doFetchAllComponentsActualData: (instId) => dispatch(fetchAllComponentsActualData(instId)),
        doFetchComponentDayTimeData: (instId, compId, date) => dispatch(fetchComponentDayTimeData(instId, compId, date)),
        doFetchComponentMonthDayData: (instId, compId, date) => dispatch(fetchComponentMonthDayData(instId, compId, date)),
        doFetchComponentYearMonthData: (instId, compId, date) => dispatch(fetchComponentYearMonthData(instId, compId, date)),
        doFetchComponentYearsData: (instId, compId, date, nYears) => dispatch(fetchComponentYearsData(instId, compId, date, nYears)),
        doFetchGeneralDayFigures: (instId, date) => dispatch(fetchGeneralFiguresByDay(instId, date))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingUnitElectricityView);