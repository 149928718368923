/**
 * Building unit reducer.
 * Contains a list of all available building units, for each installation.
 */

/* ------------- Types ------------- */

// types
export const BuildingUnitTypes = {
  BUILDING_UNITS_LOADED: 'BUILDING_UNITS_LOADED',
  BUILDING_UNITS_CLEAR_INSTALLATION: 'BUILDING_UNITS_CLEAR_INSTALLATION',
  BUILDING_UNITS_CLEAR_ALL: 'BUILDING_UNITS_CLEAR_ALL',
  BUILDING_UNIT_UPDATE: 'BUILDING_UNIT_UPDATE',
  BUILDING_UNIT_COMPONENTS_UPDATE: 'BUILDING_UNIT_COMPONENTS_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const BuildingUnitSelectors = {
  selectAll: (state, installationId) => state.buildingUnits[installationId],
  selectOne: (state, installationId, unitId) => {
    const unitList = state.buildingUnits[installationId];
    if (unitList !== undefined) {
      // eslint-disable-next-line
      return unitList.find((unit) => (unit.id == unitId));
    }
    return undefined;
  }
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onBuildingUnitsLoaded(state, payload) {
  const { installationId, units } = payload;
  // NOTE: workaround to allow for single EVM user, whose name is usually 'installationname001'
  if (units.length === 1) {
    units[0].name = 'Haus Detail';
  }
  // init component ID list, fill in separately
  units.forEach((u) => {
    u.componentIds = null;
    // replace underscores from unit name
    u.name = u.name.replace(/_/g, ' ');
  });
  // take existing state and add or overwrite key/value
  return { ...state, [installationId]: units };
}

function onBuildingUnitUpdate(state, payload) {
  const { installationId, unit } = payload;
  unit.componentIds = null;
  // replace underscores from unit name
  unit.name = unit.name.replace(/_/g, ' ');
  if (state[installationId] !== undefined) {
    // eslint-disable-next-line
    const idx = state[installationId].findIndex((u) => (u.id == unit.id));
    if (idx >= 0) {
      // replace unit, keep components
      const newUnits = state[installationId].slice();
      unit.componentIds = newUnits[idx].componentIds;
      newUnits[idx] = unit;
      return { ...state, [installationId]: newUnits };
    } else {
      // push unit
      const newUnits = [ ...state[installationId], unit ];
      return { ...state, [installationId]: newUnits };
    }
  }
  // push installation with unit
  return { ...state, [installationId]: [ unit ] };
}

function onBuildingUnitComponentsUpdate(state, payload) {
  const { installationId, unitId, componentIds } = payload;
  if (state[installationId] !== undefined) {
    // eslint-disable-next-line
    const idx = state[installationId].findIndex((u) => (u.id == unitId));
    if (idx >= 0) {
      // set components of unit
      const newUnits = state[installationId].slice();
      newUnits[idx].componentIds = componentIds;
      return { ...state, [installationId]: newUnits };
    }
  }
  return state;
}

function onBuildingUnitsClearInstallation(state, payload) {
  // remove given key and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BuildingUnitTypes.BUILDING_UNITS_LOADED: {
      return onBuildingUnitsLoaded(state, action.payload);
    }
    case BuildingUnitTypes.BUILDING_UNIT_UPDATE: {
      return onBuildingUnitUpdate(state, action.payload);
    }
    case BuildingUnitTypes.BUILDING_UNIT_COMPONENTS_UPDATE: {
      return onBuildingUnitComponentsUpdate(state, action.payload);
    }
    case BuildingUnitTypes.BUILDING_UNITS_CLEAR_INSTALLATION: {
      return onBuildingUnitsClearInstallation(state, action.payload);
    }
    case BuildingUnitTypes.BUILDING_UNITS_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;