import evmConfig from '../config/EvmApiConfig';
import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ActualValue from './ActualValue';
import { calculateImageHeightFromWidth } from './util/ImageUtils';
import { countryCodeMap, installationStrings, componentStrings } from '../i18n/translations';
import defaultImage from '../images/installation_default.png';
import ErrorViewer from './ErrorViewer';

/**
 * An installation information panel.
 */
class InstallationInfo extends Component {

    constructor(props) {
        super(props);
        this.renderStatus = this.renderStatus.bind(this);
        this.showErrorViewer = this.showErrorViewer.bind(this);
        this.hideErrorViewer = this.hideErrorViewer.bind(this);

        this.state = {
            showErrorViewer: false
        };
    }

    showErrorViewer(event) {
        // toggle
        const newState = !this.state.showErrorViewer;
        if (newState) {
            this.errorOverlay.show(event);
        } else {
            this.errorOverlay.hide(event);
        }
        this.setState({ showErrorViewer: newState });
    }

    hideErrorViewer() {
        this.setState({ showErrorViewer: false });
    }

    render() {
        if (this.props.installation !== undefined) {
            const imageUrl = (this.props.installation.photoFile !== null) ? evmConfig.baseUrl + this.props.installation.photoFile : defaultImage;
            const pBattery = this.props.installation.powerFlow.productionNet - this.props.installation.powerFlow.productionTotal;
            const txtBattery = (pBattery >= 0) ? componentStrings.discharge : componentStrings.charge;

            let actualPrice;
            let actualPriceColor;
            let actualCoverRatio;
            let actualSelfConsumption;
            if ((this.props.figures !== undefined) && moment(this.props.figures.dateTime).isAfter(moment().subtract(1, 'hours'))) {
                actualPrice = this.props.figures.price;
                actualPriceColor = this.props.figures.priceColor;
                actualCoverRatio = this.props.figures.coverRatio * 100;
                actualSelfConsumption = this.props.figures.selfConsumption * 100;
            }

            return (
                <Panel header={installationStrings.info + " – " + this.props.installation.name}>
                    <div className="p-grid p-align-stretch">
                        <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4">
                            <div style={{ padding: '0.5em', border: '1px solid #d0d0d0', borderRadius: 4, overflow: 'auto' }}>
                                <Link to="#" onClick={(e) => { this.imageOverlay.toggle(e); return false; }}>
                                    <img src={imageUrl} width="150" height={calculateImageHeightFromWidth(150)} alt={this.props.installation.name} style={{ float: 'left', marginRight: '1.5em' }} />
                                </Link>
                                <OverlayPanel ref={(el) => { this.imageOverlay = el; }} showCloseIcon={true} dismissable={true}>
                                    <img src={imageUrl} alt={this.props.installation.name} />
                                </OverlayPanel>
                                <table>
                                    <tbody>
                                        <tr><td>{this.props.installation.address}</td></tr>
                                        <tr><td>{this.props.installation.location}</td></tr>
                                        <tr><td>{countryCodeMap[this.props.installation.country]}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-2">
                            <div style={{ padding: '0.5em', border: '1px solid #d0d0d0', borderRadius: 4, textAlign: 'center', height: '100%' }}>
                                <b>{installationStrings.status}</b>
                                {this.renderStatus()}
                            </div>
                        </div>
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                            <div className="p-grid p-justify-start">
                                {(this.props.figures !== undefined) && (
                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-4">
                                        <ActualValue name={installationStrings.electricTariff} color={actualPriceColor} value={actualPrice} unit="CHF/kWh" decimals={2} icon="fas fa-piggy-bank" />
                                    </div>
                                )}
                                {(this.props.figures !== undefined) && (
                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-4">
                                        <ActualValue name={installationStrings.solarCoverage} color="#a07030" value={actualCoverRatio} unit="%" decimals={1} icon="fas fa-cloud-sun" />
                                    </div>
                                )}
                                {(this.props.figures !== undefined) && (
                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-4">
                                        <ActualValue name={installationStrings.selfConsumption} color="#2860c0" value={actualSelfConsumption} unit="%" decimals={1} icon="fas fa-chart-pie" />
                                    </div>
                                )}
                                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-4">
                                    <ActualValue name={componentStrings.totalConsumption} color="#ff0000" value={this.props.installation.powerFlow.consumptionTotal} unit="kW" decimals={3} icon="fas fa-plug" />
                                </div>
                                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-4">
                                    <ActualValue name={componentStrings.totalProduction} color="#008000" value={this.props.installation.powerFlow.productionTotal} unit="kW" decimals={3} icon="fas fa-sun" />
                                </div>
                                {   // net production only relevant if battery installed
                                    (this.props.installation.powerFlow.battery) && (
                                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-4">
                                            <ActualValue name={componentStrings.battery + " " + txtBattery} color="#004000" value={Math.abs(pBattery)} unit="kW" decimals={3} icon={(pBattery >= 0) ? "fas fa-battery-half" : "fas fa-bolt"} />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>

                </Panel>
            );
        } else {
            return (<Panel header={installationStrings.info}>{installationStrings.noInstallations}</Panel> );
        }
    }

    // render installation status
    renderStatus() {
        const status = this.props.installation.status.status;
        let iconstyle;
        switch(status) {
            case 1:
                iconstyle = 'pi-exclamation-triangle condition-error';
                break;

            case 2:
                iconstyle = 'pi pi-info-circle condition-warning';
                break;

            case 3:
                iconstyle = 'pi pi-check-circle condition-ok';
                break;

            default:
                iconstyle = 'pi-question-circle condition-default';
                break;
        }

        return (
            <div className="p-grid p-justify-center">
                <div className="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-12" style={{ textAlign: 'center' }}>
                    <Link to="#" onClick={(e) => { if (status < 3) this.showErrorViewer(e); return false; }}>
                        <span className={`pi ${iconstyle}`} style={{ fontSize: '2em' }} />
                    </Link>
                    <OverlayPanel ref={(el) => { this.errorOverlay = el; }} showCloseIcon={true} dismissable={true} onHide={this.hideErrorViewer} style={{ minWidth: '20%', maxWidth: '95%' }}>
                        <ErrorViewer installationId={this.props.installation.id} active={this.state.showErrorViewer} />
                    </OverlayPanel>
                </div>
                <div className="p-col-10 p-sm-10 p-md-10 p-lg-10 p-xl-12" style={{ textAlign: 'left' }}>{this.props.installation.status.text}</div>
            </div>
        );
    }


    static renderStatusLink(status) {
        switch(status) {
            case 1:
                return (<span className="pi pi-exclamation-triangle condition-error" style={{ fontSize: '2em' }} />);

            case 2:
                return (
                    <div>
                        <span className="pi pi-info-circle condition-warning" style={{ fontSize: '2em' }} />
                    </div>
                );

            case 3:
                return (<span className="pi pi-check-circle condition-ok" style={{ fontSize: '2em' }} />);

            default:
                return (<span className="pi pi-question-circle condition-default" style={{ fontSize: '2em' }} />);
        }
    }
}

export default InstallationInfo;