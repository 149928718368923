import { InstallationListViewTypes } from '../redux/InstallationListViewRedux';

/**
 * Actions related to installation list view.
 */

/**
 * Notifies the reducer that view state is updated.
 * @param {Object} viewState the view state
 * @returns an action
 */
export function listViewStateUpdate(viewState) {
    return { type: InstallationListViewTypes.INSTALLATION_LIST_VIEW_UPDATE, payload: viewState };
}

/**
 * Notifies the reducer that the view state is cleared.
 * @returns an action
 */
export function listViewStateClear() {
    return { type: InstallationListViewTypes.INSTALLATION_LIST_VIEW_CLEAR };
}