// apply css
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './app/styles/fontawesome-all.min.css';
import './app/styles/custom.css';
// polyfills etc.
import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { Provider } from 'react-redux';
import createReduxStore from './app/redux';
import * as serviceWorker from './serviceWorker';

/**
 * Application entry point.
 */

// create store
const reduxStore = createReduxStore();

/**
 * Renders the React application, with redux store provider.
 */
ReactDOM.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
