import evmApi from '../services/EvmApi';
import moment from 'moment';
import { GeneralFigureTypes } from '../redux/GeneralFigureRedux';
import { GeneralFigureDayTypes } from '../redux/GeneralFigureDayRedux';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to general figures.
 */

/**
 * Fetches the current general figures.
 * @param {Number} installationId the installation ID
 * @returns a promise with result object
 */
export function fetchGeneralFigures(installationId) {
    return (dispatch) => {
        return evmApi.getCurrentFigures(installationId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(generalFiguresUpdate(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches the general figures of a given day.
 * @param {Number} installationId the installation ID
 * @param {String} date the date, must be within given day
 * @returns a promise with result object
 */
export function fetchGeneralFiguresByDay(installationId, date) {
    return (dispatch) => {
        const from = moment(date).startOf('day').toISOString();
        const to = moment(date).endOf('day').toISOString();
        return evmApi.getContinuousFigures(installationId, from, to)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(generalFiguresDayUpdate(installationId, date, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Notifies the reducer that general figures were fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Object} figures the fetched figures
 * @returns an action
 */
export function generalFiguresUpdate(installationId, figures) {
    return { type: GeneralFigureTypes.GENERAL_FIGURES_UPDATE, payload: { installationId: installationId, figures: figures } };
}

/**
 * Notifies the reducer that general figures were fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {String} date the date
 * @param {Array} figures the fetched figures
 * @returns an action
 */
export function generalFiguresDayUpdate(installationId, date, figures) {
    return { type: GeneralFigureDayTypes.GENERAL_FIGURES_DAY_UPDATE, payload: { installationId: installationId, date: date, figures: figures } };
}

/**
 * Notifies the reducer that figures of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function generalFiguresClearInstallation(installationId) {
    return { type: GeneralFigureTypes.GENERAL_FIGURES_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that day figures of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function generalFiguresDayClearInstallation(installationId) {
    return { type: GeneralFigureDayTypes.GENERAL_FIGURES_DAY_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all figures should be cleared.
 * @returns an action
 */
export function generalFiguresClearAll() {
    return { type: GeneralFigureTypes.GENERAL_FIGURES_CLEAR_ALL };
}

/**
 * Notifies the reducer that all figures should be cleared.
 * @returns an action
 */
export function generalFiguresDayClearAll() {
    return { type: GeneralFigureDayTypes.GENERAL_FIGURES_DAY_CLEAR_ALL };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.objNotFound;

        case 422:
            return errorStrings.invalidData;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}