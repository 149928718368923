import { componentStrings } from '../../i18n/translations';

/**
 * Filters a component list by the given IDs.
 * @param {Array} components the component list
 * @param {Array} ids the IDs to include
 * @returns a list of the filtered components
 */
export function filterComponentsByIds(components, ids) {
    const found = [];
    if ((components !== undefined) && (ids !== undefined)) {
        ids.forEach((id) => {
            const comp = components.find(c => (c.id === id));
            if (comp !== undefined) {
                found.push(comp);
            }
        });
    }
    return found;
}

/**
 * Filters a component list by the given type.
 * @param {Array} components the component list
 * @param {String} cType the type to look for
 * @returns a list of the filtered components
 */
export function filterComponentsByType(components, cType) {
    if (components !== undefined) {
        return components.filter(c => (c.type === cType));
    }
    return [];
}

/**
 * Filters a component list by measurable and the given type.
 * @param {Array} components the component list
 * @param {String} cType the type to look for
 * @returns a list of the filtered components
 */
export function filterMeasuredComponentsByType(components, cType) {
    if (components !== undefined) {
        return components.filter(c => (c.measurable && (c.type === cType)));
    }
    return [];
}

/**
 * Filters a component list by measurable and the given type.
 * @param {Array} components the component list
 * @param {String} cTypes the types to look for
 * @returns a list of the filtered components
 */
export function filterMeasuredComponentsByTypes(components, cTypes) {
    if (components !== undefined) {
        return components.filter(c => (c.measurable && cTypes.includes(c.type)));
    }
    return [];
}

/**
 * Gets the measurement units, according to the given component type.
 * @param {String} cType the component type
 * @returns an array with two strings (actual value and accumulated value)
 */
export function getMeasurementUnits(cType) {
    switch (cType) {
        case 'Verbrauch':
        case 'Produktion':
        case 'VerbrauchTot':
        case 'ProduktionTot':
        case 'ProduktionNetto':
        case 'Batterie':
        case 'Waermezaehler':
            return ["kW", "kWh"];

        case 'Wasserzaehler':
            return ["l/h", "l"];

        case 'Temperatur':
            return ["°C", "°C"];

        default:
            return ["", ""];
    }
}

/**
 * Gets an icon definition, according to the given component type.
 * @param {String} cType the component type
 * @returns a string with css icon class names
 */
export function getTypeIcon(cType) {
    switch (cType) {
        case 'Verbrauch':
        case 'VerbrauchTot':
            return "fas fa-plug";

        case 'Produktion':
        case 'ProduktionTot':
        case 'ProduktionNetto':
            return "fas fa-solar-panel";

        case 'Batterie':
            return "fas fa-battery-half";

        case 'Waermezaehler':
            return "fas fa-fire-alt";

        case 'Wasserzaehler':
            return "fas fa-tint";

        case 'Temperatur':
            return "fas fa-thermometer-half";

        default:
            return "fas fa-question-circle";
    }
}

/**
 * Converts an HTML hex color (#RRGGBB) to an RGBA string.
 * Opacity is optional.
 * @param {String} hexColor the color string
 * @param {Number} opacity an opacity between 0 and 1
 * @returns an RGB color string
 */
export function convertHexToRGBA(hexColor, opacity = 1.0) {
    let r = 0;
    let g = 0;
    let b = 0;
    if ((hexColor.length > 6) && hexColor.startsWith('#')) {
        r = parseInt(hexColor.substring(1,3), 16);
        g = parseInt(hexColor.substring(3,5), 16);
        b = parseInt(hexColor.substring(5,7), 16);
    }
    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
}

/**
 * Gets a high-contrast color (black or white) from an HTML hex color (#RRGGBB).
 * @param {String} hexColor the color string
 * @returns an HTML color string
 */
export function getContrastColor(hexColor) {
    let r = 0;
    let g = 0;
    let b = 0;
    if ((hexColor.length > 6) && hexColor.startsWith('#')) {
        r = parseInt(hexColor.substring(1,3), 16);
        g = parseInt(hexColor.substring(3,5), 16);
        b = parseInt(hexColor.substring(5,7), 16);
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
        if (brightness < 144) {
            return "#ffffff";
        }
    }
    // defaults to black
    return "#000000";
}

/**
 * Filters component control signals.
 * Merges specific signals such as charge plan into one signal.
 * @param {Array} rawSignals the raw control signals from the EVM
 * @returns a list of control signals
 */
export function filterControlSignals(rawSignals) {
    const hlSignals = [];
    const chargePlanDays = {};
    let chargePlan = false;
    if (rawSignals !== undefined) {
        rawSignals.forEach(s => {
            switch (s.type) {
                // charge plan handled as one special signal
                case "plan":
                    chargePlanDays[s.name] = s;
                    chargePlan = true;
                    break;

                default:
                    hlSignals.push(s);
                    break;
            }
        });
    }
    if (chargePlan) {
        // create special signal containing the actual signals for each day
        hlSignals.push({
            signal: 'chargeplan',
            name: componentStrings.chargePlan,
            type: 'chargeplan',
            daySignals: chargePlanDays
        });
    }

    return hlSignals;
}

/**
 * Gets an icon definition, according to the given signal type.
 * @param {String} signalType the signal type
 * @returns a string with css icon class names
 */
export function getSignalIcon(signalType) {
    switch (signalType) {
        case 'slider':
            return 'fas fa-sliders-h';

        case 'switch':
            return 'fas fa-toggle-on';

        case 'uhrzeit':
            return 'fas fa-clock';

        case 'plan':
        case 'chargeplan':
            return 'fas fa-car';

        default:
            return 'fas fa-question-circle';
    }
}