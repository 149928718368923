import evmApi from '../services/EvmApi';
import { ComponentControlTypes } from '../redux/ComponentControlRedux';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to component controls.
 */

/**
 * Fetches the control signals of all components.
 * @param {Number} installationId the installation ID
 * @returns a promise with result object
 */
export function fetchAllComponentsControls(installationId) {
    return (dispatch) => {
        return evmApi.getAllComponentsControlSignals(installationId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(componentControlsUpdateAll(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches the control signals of a component.
 * @param {Number} installationId the installation ID
 * @returns a promise with result object
 */
export function fetchComponentControls(installationId, componentId) {
    return (dispatch) => {
        return evmApi.getComponentControlSignals(installationId, componentId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { signals } = response.data;
                    dispatch(componentControlsUpdate(installationId, componentId, signals));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Notifies the reducer that control signals were fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Array} componentSignals the fetched component control signals
 * @returns an action
 */
export function componentControlsUpdateAll(installationId, componentSignals) {
    return {
        type: ComponentControlTypes.COMPONENT_CONTROL_UPDATE_ALL,
        payload: { installationId: installationId, componentSignals: componentSignals }
    };
}

/**
 * Notifies the reducer that control signals were fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {Object} signals the fetched component control signals
 * @returns an action
 */
export function componentControlsUpdate(installationId, componentId, signals) {
    return {
        type: ComponentControlTypes.COMPONENT_CONTROL_UPDATE,
        payload: { installationId: installationId, componentId: componentId, signals: signals }
    };
}

/**
 * Notifies the reducer that all component control signals of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function componentControlsClearInstallation(installationId) {
    return { type: ComponentControlTypes.COMPONENT_CONTROL_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all component control signals should be cleared.
 * @returns an action
 */
export function componentControlsClearAll() {
    return { type: ComponentControlTypes.COMPONENT_CONTROL_CLEAR_ALL };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.compNotFound;

        case 422:
            return errorStrings.invalidData;
        
        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;
        
        default:
            return errorStrings.unknownError;
    }
}