//import evmApi from '../services/EvmApi';
import { ServiceNotificationTypes } from '../redux/ServiceNotificationRedux';
import { errorStrings } from '../i18n/translations';
import moment from 'moment';

/**
 * Actions related to service notifications.
 */

/**
 * Converts notifications from a static JSON file.
 * @param {String} json the JSON data
 * @param {String} language the language code to display text
 */
function convertStaticNotifications(json, language) {
    let result = [];
    if (Array.isArray(json)) {
        const today = moment().startOf('day');
        json.forEach((item) => {
            const txt = item.text[language];

            if (item.enabled &&
                    ((item.validBegin == null) || today.isSameOrAfter(item.validBegin, 'day')) &&
                    ((item.validEnd == null) || today.isSameOrBefore(item.validEnd, 'day')) &&
                    txt) {
                result.push({
                    validBegin: item.validBegin,
                    validEnd: item.validEnd,
                    notificationType: item.notificationType,
                    text: txt
                });
            }
          });
    }
    return result;
}

/**
 * Fetches all available service notifications from server.
 * @returns a promise with result object
 */
export function fetchServiceNotifications() {
    return (dispatch) => {
        // get notifications from backend
        /*
        return evmApi.getServiceNotifications(errorStrings.getLanguage())
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(serviceNotificationsLoadSuccess(response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
            */
        // get notifications from static json file in web portal
        return fetch('/content/service_notifications.json')
            .then(response => response.json())
            .then(data => {
                    const notifications = convertStaticNotifications(data, errorStrings.getLanguage());

                    // ok
                    dispatch(serviceNotificationsLoadSuccess(notifications));
                    return { success: true, message: "" };
            })
            .catch((error) => {
                // failed
                return { success: false, message: error.message };
            });
    };
}

/**
 * Notifies the reducer that notifications were fetched successfully.
 * @param {Array} notifications the fetched notifications
 * @returns an action
 */
export function serviceNotificationsLoadSuccess(notifications) {
    return { type: ServiceNotificationTypes.NOTIFICATIONS_LOADED, payload: notifications };
}


/**
 * Notifies the reducer that notifications should be cleared.
 * @returns an action
 */
export function serviceNotificationsClear() {
    return { type: ServiceNotificationTypes.NOTIFICATIONS_CLEAR };
}


// eslint-disable-next-line no-unused-vars
function getErrorMessage(status) {
    switch (status) {
        case 400:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.objNotFound;

        case 422:
            return errorStrings.invalidData;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}