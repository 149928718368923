import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import { AuthTypes } from '../redux/AuthRedux';
import authApi from '../services/AuthApi';
import evmApi from '../services/EvmApi';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to authentication.
 */

/**
 * Notifies the reducer that new authentication was requested.
 * @returns an action
 */
export function authenticationRequest() {
    return { type: AuthTypes.AUTH_REQUEST };
}

/**
 * Notifies the reducer that authentication was successful.
 * @param {String} accessToken the encoded access token
 * @param {String} idToken the encoded ID token
 * @param {String} tokenType the token type
 * @param {Number} expiresAt the token expiration time stamp
 * @param {String} userName the authenticated user name
 * @returns an action
 */
export function authenticationSuccess(accessToken, idToken, tokenType, expiresAt, userName ) {
    return { type: AuthTypes.AUTH_SUCCESS, payload: { accessToken: accessToken, idToken: idToken, tokenType: tokenType, expiresAt: expiresAt, userName: userName } };
}

/**
 * Notifies the reducer that authentication has failed.
 * @param {String} error the error message
 * @returns an action
 */
export function authenticationFailure(error) {
    return { type: AuthTypes.AUTH_FAILURE, payload: { error: error } };
}

/**
 * Notifies the reducer that the user logged out.
 * @returns an action
 */
export function authenticationClear() {
    // clear cookie and then auth state
    evmApi.clearAuthentication();
    authApi.clearCookie();
    return { type: AuthTypes.AUTH_CLEAR };
}

/**
 * Authenticates a user by getting a token from Auth0.
 * @param {String} username the user name
 * @param {String} password the user password
 * @returns a promise with result object
 */
export function authenticationLogin(username, password) {
    return (dispatch) => {
        dispatch(authenticationRequest());
        // authenticate via API
        return authApi.authenticate(username, password)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // got access token from Auth0
                    return processTokens(response.data.access_token, response.data.id_token, response.data.token_type, dispatch, true);
                } else {
                    // failed
                    const msg = getErrorMessage(response.status);
                    dispatch(authenticationFailure(msg));
                    return { success: false, message: msg };
                }
            });
    };
}

/**
 * Performs a logout. Basically just clears the tokens.
 * @returns a promise with result object
 */
export function authenticationLogout() {
    return (dispatch) => {
        dispatch(authenticationClear());
        return { success: true, message: errorStrings.logoutSuccess };
    };
}

/**
 * Resets the password.
 * @param {String} username the user name
 * @returns a promise with result object
 */
export function authenticationResetPassword(username) {
    return (dispatch) => {
        return authApi.resetPassword(username)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    return { success: true, message: errorStrings.passwordSuccess };
                } else {
                    // failed
                    const msg = getErrorMessage(response.status);
                    return { success: false, message: msg };
                }
            });
    };
}

/**
 * Authenticates a user by loading saved tokens.
 * @returns a promise with result object
 */
export function authenticationLoad() {
    return (dispatch) => {
        // load auth0 credentials from cookie
        const loadedTokens = authApi.loadFromCookie();
        if (loadedTokens !== undefined) {
            // validate
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("auth tokens loaded from cookie");
            }
            return processTokens(loadedTokens.accessToken, loadedTokens.idToken, loadedTokens.tokenType, dispatch, false);
        }
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("no cookie with auth token found");
        }
        return { success: false, message: errorStrings.tokenNotFound };
    };
}

function processTokens(accessToken, idToken, tokenType, dispatch, save = false) {
    try {
        // decode access token
        const decodedAccessToken = jwt_decode(accessToken);
        // extract expiration date (unix ts --> milliseconds)
        const expiresAt = decodedAccessToken.exp * 1000;
        const now = moment();
        if (moment(expiresAt).isBefore(now)) {
            // expired
            const msg = errorStrings.tokenExpired;
            dispatch(authenticationFailure(msg));
            return { success: false, message: msg };
        }

        // decode ID token
        const decodedIdToken = jwt_decode(idToken);
        // extract user name
        const userName = decodedIdToken.email;
        if (decodedIdToken.email_verified !== true) {
            // cannot log in unless verified
            const msg = errorStrings.notVerified;
            dispatch(authenticationFailure(msg));
            return { success: false, message: msg };
        }

        // put encoded access token into EVM API
        evmApi.setAuthentication(tokenType, accessToken);
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("API token set.");
        }

        // save auth0 credentials to cookie
        if (save === true) {
            // cookie valid for token validity minus 1 h
            const validForDays = (expiresAt - now.valueOf() - 3600000) / 86400000.0;
            authApi.saveToCookie({ accessToken: accessToken, idToken: idToken, tokenType: tokenType }, { expires: validForDays, sameSite: 'strict' });
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("auth tokens saved as cookie, expires in " + validForDays + " days");
            }
        }
        dispatch(authenticationSuccess(accessToken, idToken, tokenType, expiresAt, userName));
        return { success: true, message: errorStrings.authSuccess };
    } catch(error) {
        dispatch(authenticationFailure(error.message));
        return { success: false, message: error.message };
    }
}

function getErrorMessage(status) {
    switch (status) {
        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.authFailed;

        case 404:
            return errorStrings.objNotFound;

        case 409:
            return errorStrings.objExists;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}