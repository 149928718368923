import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DateGraph from '../components/DateGraph';
import ActualValue from '../components/ActualValue';
import { fetchAllInstallations } from '../actions/InstallationActions';
import { fetchAllComponents } from '../actions/ComponentActions';
import { fetchAllBuildingUnits, fetchBuildingUnit, fetchBuildingUnitComponentIds } from '../actions/BuildingUnitActions';
import { fetchComponentDayTimeData, fetchComponentMonthDayData, fetchComponentYearMonthData, fetchComponentYearsData, fetchAllComponentsActualData } from '../actions/ComponentDataActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { ComponentSelectors } from '../redux/ComponentRedux';
import { BuildingUnitSelectors } from '../redux/BuildingUnitRedux';
import { ComponentActualSelectors } from '../redux/ComponentActualDataRedux';
import { ComponentDayTimeSelectors } from '../redux/ComponentDayTimeDataRedux';
import { ComponentMonthDaySelectors } from '../redux/ComponentMonthDayDataRedux';
import { ComponentYearMonthSelectors } from '../redux/ComponentYearMonthDataRedux';
import { ComponentYearsSelectors } from '../redux/ComponentYearsDataRedux';
import { createBarChartOptions, createLineChartOptions, getXAxisLabels, getXAxisScaleLabel, getYAxisScaleLabels, convertComponentStatisticData, convertComponentContinuousData, createDataSetsForLineChart, createDataSetsForBarChart } from '../components/util/GraphUtils';
import { getMeasurementUnits, filterMeasuredComponentsByType, getTypeIcon } from '../components/util/ComponentUtils';
import { headerStrings, installationStrings, graphStrings, errorStrings, componentStrings } from '../i18n/translations';

/**
 * The building unit thermal page.
 * Displays temperature and thermal energy meter data.
 */
class BuildingUnitThermalView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // methods requiring access to 'this'
        this.resetState = this.resetState.bind(this);
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.filterUnitComponents = this.filterUnitComponents.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);
        this.onTemperatureDayChanged = this.onTemperatureDayChanged.bind(this);
        this.onTemperatureMonthChanged = this.onTemperatureMonthChanged.bind(this);
        this.onTemperatureYearChanged = this.onTemperatureYearChanged.bind(this);
        this.onHeatingMonthChanged = this.onHeatingMonthChanged.bind(this);
        this.onHeatingYearChanged = this.onHeatingYearChanged.bind(this);
        this.updateTemperatureDayChart = this.updateTemperatureDayChart.bind(this);
        this.updateTemperatureMonthChart = this.updateTemperatureMonthChart.bind(this);
        this.updateTemperatureYearChart = this.updateTemperatureYearChart.bind(this);
        this.updateTemperatureYearComparisonChart = this.updateTemperatureYearComparisonChart.bind(this);
        this.updateHeatingMonthChart = this.updateHeatingMonthChart.bind(this);
        this.updateHeatingYearChart = this.updateHeatingYearChart.bind(this);
        this.updateHeatingYearComparisonChart = this.updateHeatingYearComparisonChart.bind(this);
        this.updateVisibleTemperatureChart = this.updateVisibleTemperatureChart.bind(this);
        this.updateVisibleHeatingChart = this.updateVisibleHeatingChart.bind(this);
        this.renderActualValues = this.renderActualValues.bind(this);
        this.onTemperatureLegendClick = this.onTemperatureLegendClick.bind(this);
        this.onHeatingLegendClick = this.onHeatingLegendClick.bind(this);
        // init state
        const day = moment().startOf('day').format('YYYY-MM-DD');
        const month = moment().startOf('month').format('YYYY-MM-DD');
        const year = moment().startOf('year').format('YYYY-MM-DD');
        const yTempLabels = getYAxisScaleLabels('Temperatur');
        const yHeatLabels = getYAxisScaleLabels('Waermezaehler');
        const unitComponents = this.filterUnitComponents();
        const temperatureComponents = filterMeasuredComponentsByType(unitComponents, 'Temperatur');
        const heatingComponents = filterMeasuredComponentsByType(unitComponents, 'Waermezaehler');
        this.state = {
            isRefreshing: false,
            error: null,
            unitComponents: unitComponents,
            temperatureComponents: temperatureComponents,
            heatingComponents: heatingComponents,
            temperatureTabIndex: 0,
            temperatureSelectedDay: day,
            temperatureSelectedMonth: month,
            temperatureSelectedYear: year,
            temperatureSelectedYears: year,
            temperatureDayOptions: createLineChartOptions(day, false, yTempLabels[0], this.onTemperatureLegendClick),
            temperatureDayData: { datasets: createDataSetsForLineChart(temperatureComponents, false, false, false) },
            temperatureMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, false, yTempLabels[1], this.onTemperatureLegendClick),
            temperatureMonthData: { datasets: createDataSetsForBarChart(temperatureComponents, false) },
            temperatureYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, false, yTempLabels[1], this.onTemperatureLegendClick),
            temperatureYearData: { datasets: createDataSetsForBarChart(temperatureComponents, false) },
            temperatureYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, false, yTempLabels[1], this.onTemperatureLegendClick),
            temperatureYearComparisonData: { datasets: createDataSetsForBarChart(temperatureComponents, false) },
            heatingTabIndex: 0,
            heatingSelectedMonth: month,
            heatingSelectedYear: year,
            heatingSelectedYears: year,
            heatingMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, false, yHeatLabels[1], this.onHeatingLegendClick),
            heatingMonthData: { datasets: createDataSetsForBarChart(heatingComponents) },
            heatingYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, false, yHeatLabels[1], this.onHeatingLegendClick),
            heatingYearData: { datasets: createDataSetsForBarChart(heatingComponents) },
            heatingYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, false, yHeatLabels[1], this.onHeatingLegendClick),
            heatingYearComparisonData: { datasets: createDataSetsForBarChart(heatingComponents) }
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Building unit heating view did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props were updated
        if (!this.mounted) return;

        // TODO remember possible update loop! maybe collect state and put setState at end.

        // probably not allowed to access installation, or not found
        if ((this.props.installationList.length > 0) && (this.props.selectedInstallation === undefined)) {
            // probably not allowed, or no valid installation
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Installation " + this.props.installationId + " not found");
                }
                this.setState({ error: errorStrings.installationNotFound, unitComponents: [] });
            }
            return;
        }
        // probably not allowed to access building unit, or not found
        if ((this.props.buildingUnits !== undefined) && (this.props.selectedUnit === undefined)) {
            // probably not allowed, or no valid building unit
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Building unit " + this.props.unitId + " not found");
                }
                this.setState({ error: errorStrings.unitNotFound, unitComponents: [] });
            }
            return;
        }
        // changed URL parameters while view is still active
        if (((this.props.installationId !== prevProps.installationId) && (prevProps.installationId !== undefined)) ||
                ((this.props.unitId !== prevProps.unitId) && (prevProps.unitId !== undefined))) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("URL parameters changed, resetting state");
            }
            this.resetState();
            this.onViewLoaded();
            return;
        }
        // authentication changed to true
        if ((this.props.isAuthenticated !== prevProps.isAuthenticated) && (this.props.isAuthenticated === true)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
            this.refreshAllData(false);
        }
        // components loaded
        if ((this.props.components !== undefined) && (prevProps.components === undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Components loaded");
            }
            // should update unit components
            const unitComponents = this.filterUnitComponents();
            this.setState({
                unitComponents: unitComponents,
                temperatureComponents: filterMeasuredComponentsByType(unitComponents, 'Temperatur'),
                heatingComponents: filterMeasuredComponentsByType(unitComponents, 'Waermezaehler')
            });
        }
        // building units changed
        if ((this.props.buildingUnits !== prevProps.buildingUnits) && (this.props.buildingUnits !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Building units updated");
            }
            if (this.props.selectedUnit !== undefined) {
                if (this.props.selectedUnit.componentIds == null) {
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require building unit component IDs refresh");
                    }
                    this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId)
                            .then((result) => {
                                if (!this.mounted) return;
                                let newState = {
                                    isRefreshing: false,
                                    error: (result.success === true) ? null : result.message,
                                };
                                if (result.success === true) {
                                    const unitComponents = this.filterUnitComponents();
                                    newState.unitComponents = unitComponents;
                                    newState.temperatureComponents = filterMeasuredComponentsByType(unitComponents, 'Temperatur');
                                    newState.heatingComponents = filterMeasuredComponentsByType(unitComponents, 'Waermezaehler');
                                }
                                this.setState(newState);
                            });
                } else {
                    // should update unit components
                    const unitComponents = this.filterUnitComponents();
                    this.setState({
                        unitComponents: unitComponents,
                        temperatureComponents: filterMeasuredComponentsByType(unitComponents, 'Temperatur'),
                        heatingComponents: filterMeasuredComponentsByType(unitComponents, 'Waermezaehler')
                    });
                }
            }
        }
        // component day data was updated
        if ((this.props.componentDayTimeData !== prevProps.componentDayTimeData) && (this.props.componentDayTimeData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (day-time) updated");
            }
            if (this.state.temperatureTabIndex === 0) {
                this.updateTemperatureDayChart(false, false, false);
            }
        }
        // component month data was updated
        if ((this.props.componentMonthDayData !== prevProps.componentMonthDayData) && (this.props.componentMonthDayData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (month-day) updated");
            }
            if (this.state.temperatureTabIndex === 1) {
                this.updateTemperatureMonthChart(false, false, false);
            }
            if (this.state.heatingTabIndex === 0) {
                this.updateHeatingMonthChart(false, false, false);
            }
        }
        // component year data was updated
        if ((this.props.componentYearMonthData !== prevProps.componentYearMonthData) && (this.props.componentYearMonthData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (year-month) updated");
            }
            if (this.state.temperatureTabIndex === 2) {
                this.updateTemperatureYearChart(false, false, false);
            }
            if (this.state.heatingTabIndex === 1) {
                this.updateHeatingYearChart(false, false, false);
            }
        }
        // component years data was updated
        if ((this.props.componentYearsData !== prevProps.componentYearsData) && (this.props.componentYearsData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (years) updated");
            }
            if (this.state.temperatureTabIndex === 3) {
                this.updateTemperatureYearComparisonChart(false, false, false);
            }
            if (this.state.heatingTabIndex === 2) {
                this.updateHeatingYearComparisonChart(false, false, false);
            }
        }
        // temp. components changed
        if (this.state.temperatureComponents !== prevState.temperatureComponents) {
            // update all charts
            this.updateTemperatureDayChart(false, true, true);
            this.updateTemperatureMonthChart(false, true, true);
            this.updateTemperatureYearChart(false, true, true);
            this.updateTemperatureYearComparisonChart(false, true, true);
        }
        // heating components changed
        if (this.state.heatingComponents !== prevState.heatingComponents) {
            // update all charts
            this.updateHeatingMonthChart(false, true, true);
            this.updateHeatingYearChart(false, true, true);
            this.updateHeatingYearComparisonChart(false, true, true);
        }
        // tab changed
        if (this.state.temperatureTabIndex !== prevState.temperatureTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Temperature tab changed to " + this.state.temperatureTabIndex + " or components changed");
            }
            // update the visible chart
            this.updateVisibleTemperatureChart(false, true, false);
        }
        if (this.state.heatingTabIndex !== prevState.heatingTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Heating tab changed to " + this.state.heatingTabIndex + " or components changed");
            }
            // update the visible chart
            this.updateVisibleHeatingChart(false, true, false);
        }
        // selected date of temperature day graph has changed
        if ((this.state.temperatureSelectedDay !== prevState.temperatureSelectedDay) && (this.state.temperatureSelectedDay !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Temperature day changed to " + moment(this.state.temperatureSelectedDay).format('YYYY-MM-DD'));
            }
            this.updateTemperatureDayChart(true, true, false);
        }
        // selected date of temperature month graph has changed
        if ((this.state.temperatureSelectedMonth !== prevState.temperatureSelectedMonth) && (this.state.temperatureSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Temperature month changed to " + moment(this.state.temperatureSelectedMonth).format('YYYY-MM'));
            }
            this.updateTemperatureMonthChart(true, true, false);
        }
        // selected date of temperature year graph has changed
        if ((this.state.temperatureSelectedYear !== prevState.temperatureSelectedYear) && (this.state.temperatureSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Temperature day changed to " + moment(this.state.temperatureSelectedYear).format('YYYY'));
            }
            this.updateTemperatureYearChart(true, true, false);
        }
        // selected date of heating month graph has changed
        if ((this.state.heatingSelectedMonth !== prevState.heatingSelectedMonth) && (this.state.heatingSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Heating month changed to " + moment(this.state.heatingSelectedMonth).format('YYYY-MM'));
            }
            this.updateHeatingMonthChart(true, true, false);
        }
        // selected date of heating year graph has changed
        if ((this.state.heatingSelectedYear !== prevState.heatingSelectedYear) && (this.state.heatingSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Heating day changed to " + moment(this.state.heatingSelectedYear).format('YYYY'));
            }
            this.updateHeatingYearChart(true, true, false);
        }
    }

    resetState() {
        // keep selected dates, tab indexes etc.
        const tempDay = this.state.temperatureSelectedDay;
        const tempMonth = this.state.temperatureSelectedMonth;
        const tempYear = this.state.temperatureSelectedYear;
        const tempYears = this.state.temperatureSelectedYears;
        const heatMonth = this.state.heatingSelectedMonth;
        const heatYear = this.state.heatingSelectedYear;
        const heatYears = this.state.heatingSelectedYears;
        const yTempLabels = getYAxisScaleLabels('Temperatur');
        const yHeatLabels = getYAxisScaleLabels('Waermezaehler');
        const unitComponents = this.filterUnitComponents();
        const temperatureComponents = filterMeasuredComponentsByType(unitComponents, 'Temperatur');
        const heatingComponents = filterMeasuredComponentsByType(unitComponents, 'Waermezaehler');
        this.setState({
            error: null,
            unitComponents: unitComponents,
            temperatureComponents: temperatureComponents,
            heatingComponents: heatingComponents,
            temperatureDayOptions: createLineChartOptions(tempDay, false, yTempLabels[0], this.onTemperatureLegendClick),
            temperatureDayData: { datasets: createDataSetsForLineChart(temperatureComponents, false, false, false) },
            temperatureMonthOptions: createBarChartOptions('MonthDays', tempMonth, AppConfig.yearsToCompare, false, yTempLabels[1], this.onTemperatureLegendClick),
            temperatureMonthData: { datasets: createDataSetsForBarChart(temperatureComponents, false) },
            temperatureYearOptions: createBarChartOptions('YearMonths', tempYear, AppConfig.yearsToCompare, false, yTempLabels[1], this.onTemperatureLegendClick),
            temperatureYearData: { datasets: createDataSetsForBarChart(temperatureComponents, false) },
            temperatureYearComparisonOptions: createBarChartOptions('Years', tempYears, AppConfig.yearsToCompare, false, yTempLabels[1], this.onTemperatureLegendClick),
            temperatureYearComparisonData: { datasets: createDataSetsForBarChart(temperatureComponents, false) },
            heatingMonthOptions: createBarChartOptions('MonthDays', heatMonth, AppConfig.yearsToCompare, false, yHeatLabels[1], this.onHeatingLegendClick),
            heatingMonthData: { datasets: createDataSetsForBarChart(heatingComponents) },
            heatingYearOptions: createBarChartOptions('YearMonths', heatYear, AppConfig.yearsToCompare, false, yHeatLabels[1], this.onHeatingLegendClick),
            heatingYearData: { datasets: createDataSetsForBarChart(heatingComponents) },
            heatingYearComparisonOptions: createBarChartOptions('Years', heatYears, AppConfig.yearsToCompare, false, yHeatLabels[1], this.onHeatingLegendClick),
            heatingYearComparisonData: { datasets: createDataSetsForBarChart(heatingComponents) }
        });
    }

    refreshAllData(force = false) {
        // TODO incremental data update, if the latest time period contains partial data

        let tasks = [];
        let updateUnitComponents = false;
        this.setState({ isRefreshing: true, error: null });
        if (this.props.installationList.length === 0) {
            // load all installations
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation list refresh");
            }
            tasks.push(this.props.doFetchAllInstallations());
        }
        if ((this.props.components === undefined) || (this.props.components.length === 0)) {
            // load all components
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component list refresh");
            }
            tasks.push(this.props.doFetchAllComponents(this.props.installationId));
        }
        if ((this.props.buildingUnits === undefined) || (this.props.buildingUnits.length === 0)) {
            // load all units
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit list refresh");
            }
            tasks.push(this.props.doFetchAllBuildingUnits(this.props.installationId));
        } else if (force) {
            // update building unit
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit update refresh");
            }
            tasks.push(this.props.doFetchBuildingUnit(this.props.installationId, this.props.unitId));
        }
        if ((this.props.selectedUnit !== undefined) && (this.props.selectedUnit.componentIds == null)) {
            // update component IDs
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit component IDs refresh");
            }
            updateUnitComponents = true;
            tasks.push(this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId));
        }
        // always update actual values
        tasks.push(this.props.doFetchAllComponentsActualData(this.props.installationId));

        // component data - fetch only for visible tab, because tab change will initiate fetch anyway
        switch (this.state.temperatureTabIndex) {
            case 0: {
                this.state.temperatureComponents.forEach((comp) => {
                    if ((this.props.componentDayTimeData === undefined || this.props.componentDayTimeData[comp.id] === undefined || this.props.componentDayTimeData[comp.id][this.state.temperatureSelectedDay] === undefined) || (moment().format('YYYY-MM-DD') === this.state.temperatureSelectedDay) || force) {
                        tasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, comp.id, this.state.temperatureSelectedDay));
                    }
                });
                break;
            }
            case 1: {
                this.state.temperatureComponents.forEach((comp) => {
                    if ((this.props.componentMonthDayData === undefined || this.props.componentMonthDayData[comp.id] === undefined || this.props.componentMonthDayData[comp.id][this.state.temperatureSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.temperatureSelectedMonth) || force) {
                        tasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, comp.id, this.state.temperatureSelectedMonth));
                    }
                });
                break;
            }
            case 2: {
                this.state.temperatureComponents.forEach((comp) => {
                    if ((this.props.componentYearMonthData === undefined || this.props.componentYearMonthData[comp.id] === undefined || this.props.componentYearMonthData[comp.id][this.state.temperatureSelectedYear] === undefined)|| (moment().startOf('year').format('YYYY-MM-DD') === this.state.temperatureSelectedYear) || force) {
                        tasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, comp.id, this.state.temperatureSelectedYear));
                    }
                });
                break;
            }
            case 3: {
                this.state.temperatureComponents.forEach((comp) => {
                    // always update
                    tasks.push(this.props.doFetchComponentYearsData(this.props.installationId, comp.id, this.state.temperatureSelectedYears, AppConfig.yearsToCompare));
                });
                break;
            }
            default: {
                break;
            }
        }
        switch (this.state.heatingTabIndex) {
            case 0: {
                this.state.heatingComponents.forEach((comp) => {
                    if ((this.props.componentDayTimeData === undefined || this.props.componentDayTimeData[comp.id] === undefined || this.props.componentDayTimeData[comp.id][this.state.heatingSelectedDay] === undefined) || (moment().format('YYYY-MM-DD') === this.state.heatingSelectedDay) || force) {
                        tasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, comp.id, this.state.heatingSelectedDay));
                    }
                });
                break;
            }
            case 1: {
                this.state.heatingComponents.forEach((comp) => {
                    if ((this.props.componentMonthDayData === undefined || this.props.componentMonthDayData[comp.id] === undefined || this.props.componentMonthDayData[comp.id][this.state.heatingSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.heatingSelectedMonth) || force) {
                        tasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, comp.id, this.state.heatingSelectedMonth));
                    }
                });
                break;
            }
            case 2: {
                this.state.heatingComponents.forEach((comp) => {
                    if ((this.props.componentYearMonthData === undefined || this.props.componentYearMonthData[comp.id] === undefined || this.props.componentYearMonthData[comp.id][this.state.heatingSelectedYear] === undefined)|| (moment().startOf('year').format('YYYY-MM-DD') === this.state.heatingSelectedYear) || force) {
                        tasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, comp.id, this.state.heatingSelectedYear));
                    }
                });
                break;
            }
            case 3: {
                this.state.heatingComponents.forEach((comp) => {
                    // always update
                    tasks.push(this.props.doFetchComponentYearsData(this.props.installationId, comp.id, this.state.heatingSelectedYears, AppConfig.yearsToCompare));
                });
                break;
            }
            default: {
                break;
            }
        }

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks)
                .then((results) => {
                    if (!this.mounted) return;
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });

                    // just refresh state using existing components
                    const unitComponents = updateUnitComponents ? this.filterUnitComponents() : this.state.unitComponents;
                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | '),
                        unitComponents: unitComponents,
                        temperatureComponents: filterMeasuredComponentsByType(unitComponents, 'Temperatur'),
                        heatingComponents: filterMeasuredComponentsByType(unitComponents, 'Waermezaehler')
                    });
                });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // reload
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    filterUnitComponents() {
        if ((this.props.selectedUnit !== undefined) && (this.props.components !== undefined)) {
            const unitComponents = [];
            const cIds = this.props.selectedUnit.componentIds;
            if (cIds != null) {
                cIds.forEach((id) => {
                    let comp = this.props.components.find((c) => (c.id === id));
                    if (comp !== undefined) {
                        unitComponents.push(comp);
                    }
                });
                // sort by name
                unitComponents.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }
            return unitComponents;
        } else if (BuildConfig.isReactotronEnabled) {
            Reactotron.warn("Failed to filter unit components");
        }
        return [];
    }

    updateTemperatureDayChart(axes, fetch, createsets) {
        const day = this.state.temperatureSelectedDay;
        const components = this.state.temperatureComponents;
        const oldDataSets = this.state.temperatureDayData.datasets;
        const newDataSets = (createsets) ? createDataSetsForLineChart(components, false, false, false) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentDayTimeData !== undefined) && (this.props.componentDayTimeData[components[i].id] !== undefined) && (this.props.componentDayTimeData[components[i].id][day] !== undefined)) {
                    newDataSets[i].data = convertComponentContinuousData(this.props.componentDayTimeData[components[i].id][day], day, 1);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Temperature Component " + components[i].id + ": day data of " + day + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, components[i].id, day));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.temperatureDayOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].ticks.min = moment(day).startOf('day');
                newOptions.scales.xAxes[0].ticks.max = moment(day).add(1, 'days').startOf('day');
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('DayTime', day);
                newState.temperatureDayOptions = newOptions;
            }
            newState.temperatureDayData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateTemperatureMonthChart(axes, fetch, createsets) {
        const month = this.state.temperatureSelectedMonth;
        const components = this.state.temperatureComponents;
        const oldDataSets = this.state.temperatureMonthData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components, false) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentMonthDayData !== undefined) && (this.props.componentMonthDayData[components[i].id] !== undefined) && (this.props.componentMonthDayData[components[i].id][month] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentMonthDayData[components[i].id][month], 'MonthDays', month, 1, 1);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Temperature Component " + components[i].id + ": month data of " + moment(month).format('YYYY-MM') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, components[i].id, month));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.temperatureMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newState.temperatureMonthOptions = newOptions;
            }
            newState.temperatureMonthData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateTemperatureYearChart(axes, fetch, createsets) {
        const year = this.state.temperatureSelectedYear;
        const components = this.state.temperatureComponents;
        const oldDataSets = this.state.temperatureYearData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components, false) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearMonthData !== undefined) && (this.props.componentYearMonthData[components[i].id] !== undefined) && (this.props.componentYearMonthData[components[i].id][year] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentYearMonthData[components[i].id][year], 'YearMonths', year, 1, 1);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Temperature Component " + components[i].id + ": year data of " + moment(year).format('YYYY') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, components[i].id, year));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.temperatureYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newState.temperatureYearOptions = newOptions;
            }
            newState.temperatureYearData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateTemperatureYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.temperatureSelectedYears;
        const components = this.state.temperatureComponents;
        const oldDataSets = this.state.temperatureYearComparisonData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components, false) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearsData !== undefined) && (this.props.componentYearsData[components[i].id] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentYearsData[components[i].id], 'Years', years, AppConfig.yearsToCompare, 1);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Temperature Component " + components[i].id + ": years data not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearsData(this.props.installationId, components[i].id, years, AppConfig.yearsToCompare));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.temperatureYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newState.temperatureYearComparisonOptions = newOptions;
            }
            newState.temperatureYearComparisonData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateHeatingMonthChart(axes, fetch, createsets) {
        const month = this.state.heatingSelectedMonth;
        const components = this.state.heatingComponents;
        const oldDataSets = this.state.heatingMonthData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentMonthDayData !== undefined) && (this.props.componentMonthDayData[components[i].id] !== undefined) && (this.props.componentMonthDayData[components[i].id][month] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentMonthDayData[components[i].id][month], 'MonthDays', month);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Heating Component " + components[i].id + ": month data of " + moment(month).format('YYYY-MM') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, components[i].id, month));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.heatingMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newState.heatingMonthOptions = newOptions;
            }
            newState.heatingMonthData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateHeatingYearChart(axes, fetch, createsets) {
        const year = this.state.heatingSelectedYear;
        const components = this.state.heatingComponents;
        const oldDataSets = this.state.heatingYearData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearMonthData !== undefined) && (this.props.componentYearMonthData[components[i].id] !== undefined) && (this.props.componentYearMonthData[components[i].id][year] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentYearMonthData[components[i].id][year], 'YearMonths', year);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Heating Component " + components[i].id + ": year data of " + moment(year).format('YYYY') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, components[i].id, year));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.heatingYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newState.heatingYearOptions = newOptions;
            }
            newState.heatingYearData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateHeatingYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.heatingSelectedYears;
        const components = this.state.heatingComponents;
        const oldDataSets = this.state.heatingYearComparisonData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (components.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < components.length; i++) {
                if ((this.props.componentYearsData !== undefined) && (this.props.componentYearsData[components[i].id] !== undefined)) {
                    newDataSets[i].data = convertComponentStatisticData(this.props.componentYearsData[components[i].id], 'Years', years, AppConfig.yearsToCompare);
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Heating Component " + components[i].id + ": years data not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearsData(this.props.installationId, components[i].id, years, AppConfig.yearsToCompare));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length === components.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.heatingYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newState.heatingYearComparisonOptions = newOptions;
            }
            newState.heatingYearComparisonData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateVisibleTemperatureChart(axes, fetch, createsets) {
        switch (this.state.temperatureTabIndex) {
            case 0: {
                this.updateTemperatureDayChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateTemperatureMonthChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateTemperatureYearChart(axes, fetch, createsets);
                break;
            }
            case 3: {
                this.updateTemperatureYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    updateVisibleHeatingChart(axes, fetch, createsets) {
        switch (this.state.heatingTabIndex) {
            case 0: {
                this.updateHeatingMonthChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateHeatingYearChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateHeatingYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    onViewLoaded() {
        // update installation and fetch components
        if (this.props.isAuthenticated === true) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Update of building unit and its components");
            }
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);
                // update visible charts
                this.updateVisibleTemperatureChart(true, false, true);
                this.updateVisibleHeatingChart(true, false, true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    onTemperatureDayChanged(date) {
        const day = moment(date).startOf('day').format('YYYY-MM-DD');
        this.setState({ temperatureSelectedDay: day });
    }

    onTemperatureMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ temperatureSelectedMonth: month });
    }

    onTemperatureYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ temperatureSelectedYear: year });
    }

    onHeatingMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ heatingSelectedMonth: month });
    }

    onHeatingYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ heatingSelectedYear: year });
    }

    onTemperatureLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.temperatureTabIndex) {
            case 0: {
                const sets = this.state.temperatureDayData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ temperatureDayData: { datasets: newSets } });
                }
                break;
            }
            case 1: {
                const sets = this.state.temperatureMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ temperatureMonthData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.temperatureYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ temperatureYearData: { datasets: newSets } });
                }
                break;
            }
            case 3: {
                const sets = this.state.temperatureYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ temperatureYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    onHeatingLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.heatingTabIndex) {
            case 0: {
                const sets = this.state.heatingMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ heatingMonthData: { datasets: newSets } });
                }
                break;
            }
            case 1: {
                const sets = this.state.heatingYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ heatingYearData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.heatingYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ heatingYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.installationOverview, enabled: true, active: false, icon: "fas fa-building", path: "/installations/" + this.props.installationId + "/overview" },
            { name: headerStrings.unitOverview, enabled: true, active: false, icon: "fas fa-door-open", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/overview" }
        ];
        if (this.state.unitComponents.find(c => (c.type === 'Verbrauch')) !== undefined) {
            navItems.push({ name: headerStrings.electricity, enabled: true, active: false, icon: "fas fa-bolt", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/electricity" });
        }
        navItems.push({ name: headerStrings.temperatures + " / " + headerStrings.heating, enabled: false, active: true, icon: "fas fa-thermometer-three-quarters", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/thermal" });
        if (this.state.unitComponents.find(c => (c.type === 'Wasserzaehler')) !== undefined) {
            navItems.push({ name: headerStrings.water, enabled: true, active: false, icon: "fas fa-tint", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/water" });
        }
        if (this.state.unitComponents.find(c => c.controllable) !== undefined) {
            navItems.push({ name: headerStrings.controls, enabled: true, active: false, icon: "fas fa-sliders-h", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/control" });
        }

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <Panel header={headerStrings.temperatures + " – " + installationStrings.actualValues}>
                        {(this.state.temperatureComponents.length > 0) ? this.renderActualValues() : (<div>{componentStrings.noTempComponents}</div>)}
                    </Panel>
                    <div style={{ margin: 20 }}></div>
                    <Panel header={headerStrings.temperatures + " – " + installationStrings.statistics}>
                        {(this.state.temperatureComponents.length > 0) ? (
                            <TabView activeIndex={this.state.temperatureTabIndex} onTabChange={(e) => this.setState({ temperatureTabIndex: e.index })} renderActiveOnly={true}>
                                <TabPanel header={graphStrings.day}>
                                    <DateGraph term="DayTime" date={this.state.temperatureSelectedDay} onDateChanged={this.onTemperatureDayChanged} chartType="line" chartData={this.state.temperatureDayData} chartOptions={this.state.temperatureDayOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.month}>
                                    <DateGraph term="MonthDays" date={this.state.temperatureSelectedMonth} onDateChanged={this.onTemperatureMonthChanged} chartType="line" chartData={this.state.temperatureMonthData} chartOptions={this.state.temperatureMonthOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.year}>
                                    <DateGraph term="YearMonths" date={this.state.temperatureSelectedYear} onDateChanged={this.onTemperatureYearChanged} chartType="line" chartData={this.state.temperatureYearData} chartOptions={this.state.temperatureYearOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years}>
                                    <DateGraph term="Years" date={this.state.temperatureSelectedYears} chartType="line" chartData={this.state.temperatureYearComparisonData} chartOptions={this.state.temperatureYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                            </TabView>
                        ) : (
                            <div>{componentStrings.noTempComponents}</div>
                        )}
                    </Panel>
                    <div style={{ margin: 20 }}></div>
                    {(this.state.heatingComponents.length > 0) && (
                        <Panel header={headerStrings.heating + " – " + installationStrings.statistics}>
                            <TabView activeIndex={this.state.heatingTabIndex} onTabChange={(e) => this.setState({ heatingTabIndex: e.index })} renderActiveOnly={true}>
                                <TabPanel header={graphStrings.month}>
                                    <DateGraph term="MonthDays" date={this.state.heatingSelectedMonth} onDateChanged={this.onHeatingMonthChanged} chartType="bar" chartData={this.state.heatingMonthData} chartOptions={this.state.heatingMonthOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.year}>
                                    <DateGraph term="YearMonths" date={this.state.heatingSelectedYear} onDateChanged={this.onHeatingYearChanged} chartType="bar" chartData={this.state.heatingYearData} chartOptions={this.state.heatingYearOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years}>
                                    <DateGraph term="Years" date={this.state.heatingSelectedYears} chartType="bar" chartData={this.state.heatingYearComparisonData} chartOptions={this.state.heatingYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                            </TabView>
                        </Panel>
                    )}
                </div>
                <Footer />
            </div>
        );
    }

    renderActualValues() {
        // show only temperatures, because heating has no continuous values
        const components = this.state.temperatureComponents;
        return (
            <div className="p-grid p-justify-start">
                {components.map((comp) => {
                    const value = ((this.props.componentActualData !== undefined) && (this.props.componentActualData[comp.id] !== undefined) && moment(this.props.componentActualData[comp.id].dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.componentActualData[comp.id].value : undefined;
                    return (
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2" key={comp.id}>
                            <ActualValue name={comp.name} color={comp.color} value={value} unit={getMeasurementUnits(comp.type)[0]} decimals={1} icon={getTypeIcon(comp.type)} />
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const installationId = ownProps.match.params.instId;    // might not be integer!
    const unitId = ownProps.match.params.unitId;            // might not be integer!
    return {
        installationId,
        unitId,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installationList: InstallationSelectors.selectAll(state),
        selectedInstallation: InstallationSelectors.selectOne(state, installationId),
        buildingUnits: BuildingUnitSelectors.selectAll(state, installationId),
        selectedUnit: BuildingUnitSelectors.selectOne(state, installationId, unitId),
        components: ComponentSelectors.selectAll(state, installationId),
        componentActualData: ComponentActualSelectors.selectAllOfInstallation(state, installationId),
        componentDayTimeData: ComponentDayTimeSelectors.selectAllOfInstallation(state, installationId),
        componentMonthDayData: ComponentMonthDaySelectors.selectAllOfInstallation(state, installationId),
        componentYearMonthData: ComponentYearMonthSelectors.selectAllOfInstallation(state, installationId),
        componentYearsData: ComponentYearsSelectors.selectAllOfInstallation(state, installationId)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchAllInstallations: () => dispatch(fetchAllInstallations()),
        doFetchAllComponents: (instId) => dispatch(fetchAllComponents(instId)),
        doFetchAllBuildingUnits: (instId) => dispatch(fetchAllBuildingUnits(instId)),
        doFetchBuildingUnit: (instId, unitId) => dispatch(fetchBuildingUnit(instId, unitId)),
        doFetchBuildingUnitComponentIds: (instId, unitId) => dispatch(fetchBuildingUnitComponentIds(instId, unitId)),
        doFetchAllComponentsActualData: (instId) => dispatch(fetchAllComponentsActualData(instId)),
        doFetchComponentDayTimeData: (instId, compId, date) => dispatch(fetchComponentDayTimeData(instId, compId, date)),
        doFetchComponentMonthDayData: (instId, compId, date) => dispatch(fetchComponentMonthDayData(instId, compId, date)),
        doFetchComponentYearMonthData: (instId, compId, date) => dispatch(fetchComponentYearMonthData(instId, compId, date)),
        doFetchComponentYearsData: (instId, compId, date, nYears) => dispatch(fetchComponentYearsData(instId, compId, date, nYears))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingUnitThermalView);