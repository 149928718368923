import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import evmApi from '../services/EvmApi';
import moment from 'moment';

/**
 * An EVM error viewer.
 * Displays active errors and can acknowledge all.
 */
class ErrorViewer extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.refreshErrors = this.refreshErrors.bind(this);
        this.ackErrors = this.ackErrors.bind(this);
        this.dateTimeTemplate = this.dateTimeTemplate.bind(this);

        this.state = {
            refreshing: false,
            errors: []
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.mounted) return;
        if ((this.props.active !== prevProps.active) && (this.props.active)) {
            this.refreshErrors();
        }
    }

    refreshErrors() {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Refreshing errors");
        }
        this.setState({ refreshing: true });
        evmApi.getActiveErrors(this.props.installationId)
            .then((response) => {
                if (!this.mounted) return;
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    let errors = response.data;
                    if (!errors) {
                        errors = [];
                    }
                    this.setState({
                        refreshing: false,
                        errors: errors
                    });
                } else {
                    // failed
                    this.setState({
                        refreshing: false
                    });
                }
            });
    }

    ackErrors() {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Acknowledging errors");
        }
        this.setState({ refreshing: true });
        evmApi.ackAllErrors(this.props.installationId)
            .then((response) => {
                if (!this.mounted) return;
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    this.setState({
                        refreshing: false,
                        errors: []
                    });
                } else {
                    // failed
                    this.setState({
                        refreshing: false
                    });
                }
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Acknowledge errors response = " + response.status);
                }
            });
    }

    dateTimeTemplate(rowData, column) {
        return (
            <span>{moment(rowData['dateTime']).format('YYYY-MM-DD HH:mm:ss')}</span>
        );
    }

    render() {
        const ackDisabled = !this.state.errors || this.state.errors.length === 0;

        return (
            <div>
                <h4>Errors</h4>
                <DataTable value={this.state.errors} sortField="dateTime" sortOrder={1} autoLayout>
                    <Column field="dateTime" header="DateTime" body={this.dateTimeTemplate} sortable={true} />
                    <Column field="code" header="Code" />
                    <Column field="text" header="Text" style={{ textAlign: 'left'}} />
                </DataTable>
                <Button className="p-button-danger" label="Reset" onClick={this.ackErrors} disabled={ackDisabled} />
            </div>
        );
    }
}

export default ErrorViewer;