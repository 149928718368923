/**
 * Installation list view state reducer.
 * Contains the view state of the installation list.
 */

/* ------------- Types ------------- */

// types
export const InstallationListViewTypes = {
  INSTALLATION_LIST_VIEW_UPDATE: 'INSTALLATION_LIST_VIEW_UPDATE',
  INSTALLATION_LIST_VIEW_CLEAR: 'INSTALLATION_LIST_VIEW_CLEAR',
};

/* ------------- Selectors ------------- */

/* ------------- Initial state ------------- */

const initialState = {
  sortAscending: true,
  statusFilter: null,
  page: 0
};

/* ------------- Reducer Function ------------- */

function onInstallationListViewUpdate(state, payload) {
  // take existing state and add or overwrite key/value
  const { sortAscending, statusFilter, page } = payload;
  return { sortAscending, statusFilter, page };
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case InstallationListViewTypes.INSTALLATION_LIST_VIEW_UPDATE: {
      return onInstallationListViewUpdate(state, action.payload);
    }
    case InstallationListViewTypes.INSTALLATION_LIST_VIEW_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;