import evmApi from '../services/EvmApi';
import { ComponentTypes } from '../redux/ComponentRedux';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to component list.
 */

/**
 * Fetches all available components from server.
 * @param {Number} installationId the installation ID
 * @returns a promise with result object
 */
export function fetchAllComponents(installationId) {
    return (dispatch) => {
        return evmApi.getAllComponents(installationId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(componentsLoadSuccess(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches a specific component from server.
 * @param {Number} installationId  the installation ID
 * @param {Number} componentId the component ID
 * @returns a promise with result object
 */
export function fetchComponent(installationId, componentId) {
    return (dispatch) => {
        return evmApi.getComponent(installationId, componentId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(componentUpdate(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Notifies the reducer that components were fetched successfully.
 * @param {Number} installationId  the installation ID
 * @param {Array} components the fetched components
 * @returns an action
 */
export function componentsLoadSuccess(installationId, components) {
    // sort by name
    components.sort((a, b) => (a.name > b.name) ? 1 : -1);
    return { type: ComponentTypes.COMPONENTS_LOADED, payload: { installationId: installationId, components: components } };
}

/**
 * Notifies the reducer that a component was updated successfully.
 * @param {Number} installationId  the installation ID
 * @param {Object} component the updated component
 * @returns an action
 */
export function componentUpdate(installationId, component) {
    return { type: ComponentTypes.COMPONENT_UPDATE, payload: { installationId: installationId, component: component } };
}

/**
 * Notifies the reducer that all components of an installation should be cleared.
 * @param {Number} installationId  the installation ID
 * @returns an action
 */
export function componentsClearInstallation(installationId) {
    return { type: ComponentTypes.COMPONENTS_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all components should be cleared.
 * @returns an action
 */
export function componentsClearAll() {
    return { type: ComponentTypes.COMPONENTS_CLEAR_ALL };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
        case 422:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.compNotFound;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}