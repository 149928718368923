import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DateGraph from '../components/DateGraph';
import InstallationInfo from '../components/InstallationInfo';
import { AuthSelectors } from '../redux/AuthRedux';
import { fetchAllInstallations, fetchInstallation } from '../actions/InstallationActions';
import { fetchAllComponents } from '../actions/ComponentActions';
import { fetchAllBuildingUnits } from '../actions/BuildingUnitActions';
import { fetchComponentDayTimeData, fetchComponentMonthDayData, fetchComponentYearMonthData, fetchComponentYearsData } from '../actions/ComponentDataActions';
import { fetchGeneralMonthDayData, fetchGeneralYearMonthData, fetchGeneralYearsData } from '../actions/GeneralStatisticActions';
import { fetchGeneralFigures, fetchGeneralFiguresByDay } from '../actions/GeneralFigureActions';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { ComponentSelectors } from '../redux/ComponentRedux';
import { BuildingUnitSelectors } from '../redux/BuildingUnitRedux';
import { GeneralFigureSelectors } from '../redux/GeneralFigureRedux';
import { GeneralMonthDaySelectors } from '../redux/GeneralMonthDayDataRedux';
import { GeneralYearMonthSelectors } from '../redux/GeneralYearMonthDataRedux';
import { GeneralYearsSelectors } from '../redux/GeneralYearsDataRedux';
import { ComponentDayTimeSelectors } from '../redux/ComponentDayTimeDataRedux';
import { ComponentMonthDaySelectors } from '../redux/ComponentMonthDayDataRedux';
import { ComponentYearMonthSelectors } from '../redux/ComponentYearMonthDataRedux';
import { GeneralFigureDaySelectors } from '../redux/GeneralFigureDayRedux';
import { ComponentYearsSelectors } from '../redux/ComponentYearsDataRedux';
import { createBarChartOptions, createLineChartOptions, getXAxisLabels, getXAxisScaleLabel, getYAxisScaleLabels,
            createGeneralStatsDataSets, convertGeneralStatsData, convertGeneralCostData, convertComponentContinuousData,
            createGeneralDayDataSets, extractAutarchyData, convertContinuousPriceData, createDatasetsWithAutarchy, createSurplusDataset, createCostDayDataSets } from '../components/util/GraphUtils';
import { componentStrings, errorStrings, headerStrings, installationStrings, graphStrings } from '../i18n/translations';

// components for total consumption or production
// TODO find components dynamically
const PROSUMER_COMPONENTS = [
    {
        id: 2,
        name: componentStrings.totalConsumption,
        type: 'VerbrauchTot',
        measurable: true,
        color: '#ff0000'
    },
    {
        id: 1,
        name: componentStrings.totalProduction,
        type: 'ProduktionTot',
        measurable: true,
        color: '#008000'
    }
];

/**
 * The installation overview page.
 * Displays information about the installation, basic figures and consumption/production.
 */
class InstallationOverviewView extends Component {

    constructor(props) {
        super(props);
        // methods requiring access to 'this'
        this.mounted = false;
        this.resetState = this.resetState.bind(this);
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);

        this.onGeneralStatDayChanged = this.onGeneralStatDayChanged.bind(this);
        this.onGeneralStatMonthChanged = this.onGeneralStatMonthChanged.bind(this);
        this.onGeneralStatYearChanged = this.onGeneralStatYearChanged.bind(this);
        this.updateGeneralStatDayChart = this.updateGeneralStatDayChart.bind(this);
        this.updateGeneralStatMonthChart = this.updateGeneralStatMonthChart.bind(this);
        this.updateGeneralStatYearChart = this.updateGeneralStatYearChart.bind(this);
        this.updateGeneralStatYearComparisonChart = this.updateGeneralStatYearComparisonChart.bind(this);
        this.updateVisibleGeneralStatChart = this.updateVisibleGeneralStatChart.bind(this);

        this.onGeneralCostDayChanged = this.onGeneralCostDayChanged.bind(this);
        this.onGeneralCostMonthChanged = this.onGeneralCostMonthChanged.bind(this);
        this.onGeneralCostYearChanged = this.onGeneralCostYearChanged.bind(this);
        this.updateGeneralCostDayChart = this.updateGeneralCostDayChart.bind(this);
        this.updateGeneralCostMonthChart = this.updateGeneralCostMonthChart.bind(this);
        this.updateGeneralCostYearChart = this.updateGeneralCostYearChart.bind(this);
        this.updateGeneralCostYearComparisonChart = this.updateGeneralCostYearComparisonChart.bind(this);
        this.updateVisibleGeneralCostChart = this.updateVisibleGeneralCostChart.bind(this);

        this.onGeneralStatLegendClick = this.onGeneralStatLegendClick.bind(this);
        this.onGeneralCostLegendClick = this.onGeneralCostLegendClick.bind(this);

        // init state
        const day = moment().startOf('day').format('YYYY-MM-DD');
        const month = moment().startOf('month').format('YYYY-MM-DD');
        const year = moment().startOf('year').format('YYYY-MM-DD');
        const elecYLabels = getYAxisScaleLabels('Verbrauch');    // ok for all electric components
        const costYLabels = getYAxisScaleLabels('Kosten');
        this.state = {
            isRefreshing: false,
            error: null,
            generalStatTabIndex: 0,
            generalStatSelectedDay: day,
            generalStatSelectedMonth: month,
            generalStatSelectedYear: year,
            generalStatSelectedYears: year,
            generalStatDayOptions: createLineChartOptions(day, true, elecYLabels[0], this.onGeneralStatLegendClick),
            generalStatDayData: { datasets: createGeneralDayDataSets(true) },
            generalStatMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, true, elecYLabels[1], this.onGeneralStatLegendClick),
            generalStatMonthData: { datasets: createGeneralStatsDataSets() },
            generalStatYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, true, elecYLabels[1], this.onGeneralStatLegendClick),
            generalStatYearData: { datasets: createGeneralStatsDataSets() },
            generalStatYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, true, elecYLabels[1], this.onGeneralStatLegendClick),
            generalStatYearComparisonData: { datasets: createGeneralStatsDataSets() },

            generalCostTabIndex: 0,
            generalCostSelectedDay: day,
            generalCostSelectedMonth: month,
            generalCostSelectedYear: year,
            generalCostSelectedYears: year,
            generalCostDayOptions: createLineChartOptions(day, false, costYLabels[0], this.onGeneralCostLegendClick),
            generalCostDayData: { datasets: createCostDayDataSets() },
            generalCostMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, true, costYLabels[1], this.onGeneralCostLegendClick),
            generalCostMonthData: { datasets: createGeneralStatsDataSets() },
            generalCostYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, true, costYLabels[1], this.onGeneralCostLegendClick),
            generalCostYearData: { datasets: createGeneralStatsDataSets() },
            generalCostYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, true, costYLabels[1], this.onGeneralCostLegendClick),
            generalCostYearComparisonData: { datasets: createGeneralStatsDataSets() },

            prosumerComponents: PROSUMER_COMPONENTS
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Installation overview did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props or state were updated
        if (!this.mounted) return;

        // probably not allowed to access installation, or not found
        if ((this.props.installationList.length > 0) && (this.props.selectedInstallation === undefined)) {
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Installation " + this.props.installationId + " not found");
                }
                this.setState({ error: errorStrings.installationNotFound });
            }
            return;
        }
        // changed URL parameters while view is still active
        if ((this.props.installationId !== prevProps.installationId) && (prevProps.installationId !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("URL parameters changed, resetting state");
            }
            this.resetState();
            this.onViewLoaded();
            return;
        }
        // authentication changed to true - may happen if view is accessed directly
        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
            if (this.props.isAuthenticated === true) {
                this.refreshAllData(false);
            } else {
                this.resetState();
            }
            return;
        }
        // components loaded
        if ((this.props.components !== prevProps.components) && (this.props.components !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Components loaded");
            }
            // get prosumer components
            // TODO implement when dynamic solution found
        }

        // flags for chart updates
        let updateGeneralChart = false;
        let updateCostChart = false;
        let fetchData = false;
        let createSets = false;
        let generalAxes = false;
        let generalCostAxes = false;

        // general stat. month data was updated
        if ((this.props.generalMonthDayData !== prevProps.generalMonthDayData) && (this.props.generalMonthDayData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General statistic data (month-day) updated");
            }
            if (this.state.generalStatTabIndex === 1) {
                updateGeneralChart = true;
            }
            if (this.state.generalCostTabIndex === 1) {
                updateCostChart = true;
            }
        }
        // general stat. year data was updated
        if ((this.props.generalYearMonthData !== prevProps.generalYearMonthData) && (this.props.generalYearMonthData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General statistic data (year-month) updated");
            }
            if (this.state.generalStatTabIndex === 2) {
                updateGeneralChart = true;
            }
            if (this.state.generalCostTabIndex === 2) {
                updateCostChart = true;
            }
        }
        // general stat. years data was updated
        if ((this.props.generalYearsData !== prevProps.generalYearsData) && (this.props.generalYearsData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General statistic data (years) updated");
            }
            if (this.state.generalStatTabIndex === 3) {
                updateGeneralChart = true;
            }
            if (this.state.generalCostTabIndex === 3) {
                updateCostChart = true;
            }
        }
        // general figures were updated
        if ((this.props.generalFigureData !== prevProps.generalFigureData) && (this.props.generalFigureData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General figure data (day-time) updated");
            }
            if (this.state.generalStatTabIndex === 0) {
                updateGeneralChart = true;
            }
            if (this.state.generalCostTabIndex === 0) {
                updateCostChart = true;
            }
        }
        // prosumer day data was updated
        if ((this.props.componentDayTimeData !== prevProps.componentDayTimeData) && (this.props.componentDayTimeData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (day) updated");
            }
            if (this.state.generalStatTabIndex === 0) {
                updateGeneralChart = true;
            }
        }
        // prosumer month data was updated
        if ((this.props.componentMonthDayData !== prevProps.componentMonthDayData) && (this.props.componentMonthDayData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (month) updated");
            }
            if (this.state.generalStatTabIndex === 1) {
                updateGeneralChart = true;
            }
        }
        // prosumer year data was updated
        if ((this.props.componentYearMonthData !== prevProps.componentYearMonthData) && (this.props.componentYearMonthData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (year) updated");
            }
            if (this.state.generalStatTabIndex === 2) {
                updateGeneralChart = true;
            }
        }
        // prosumer years data was updated
        if ((this.props.componentYearsData !== prevProps.componentYearsData) && (this.props.componentYearsData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (years) updated");
            }
            if (this.state.generalStatTabIndex === 3) {
                updateGeneralChart = true;
            }
        }
        // general stats tab changed
        if (this.state.generalStatTabIndex !== prevState.generalStatTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General statistic tab changed to " + this.state.generalStatTabIndex);
            }
            fetchData = true;
            updateGeneralChart = true;
        }
        // general cost tab changed
        if (this.state.generalCostTabIndex !== prevState.generalCostTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General cost tab changed to " + this.state.generalCostTabIndex);
            }
            fetchData = true;
            updateCostChart = true;
        }
        // prosumer components changed
        if (this.state.prosumerComponents !== prevState.prosumerComponents) {
            fetchData = true;
            updateGeneralChart = true;
            createSets = true;
        }
        // selected date of general stat. day graph has changed
        if ((this.state.generalStatSelectedDay !== prevState.generalStatSelectedDay) && (this.state.generalStatSelectedDay !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats day changed to " + moment(this.state.generalStatSelectedDay).format('YYYY-MM-DD'));
            }
            generalAxes = true;
            updateGeneralChart = true;
            fetchData = true;
        }
        // selected date of general stat. month graph has changed
        if ((this.state.generalStatSelectedMonth !== prevState.generalStatSelectedMonth) && (this.state.generalStatSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats month changed to " + moment(this.state.generalStatSelectedMonth).format('YYYY-MM'));
            }
            generalAxes = true;
            updateGeneralChart = true;
            fetchData = true;
        }
        // selected date of general stat. year graph has changed
        if ((this.state.generalStatSelectedYear !== prevState.generalStatSelectedYear) && (this.state.generalStatSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats year changed to " + moment(this.state.generalStatSelectedYear).format('YYYY'));
            }
            generalAxes = true;
            updateGeneralChart = true;
            fetchData = true;
        }
        // selected date of general cost day graph has changed
        if ((this.state.generalCostSelectedDay !== prevState.generalCostSelectedDay) && (this.state.generalCostSelectedDay !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General cost day changed to " + moment(this.state.generalCostSelectedDay).format('YYYY-MM-DD'));
            }
            generalCostAxes = true;
            updateCostChart = true;
            fetchData = true;
        }
        // selected date of general cost month graph has changed
        if ((this.state.generalCostSelectedMonth !== prevState.generalCostSelectedMonth) && (this.state.generalCostSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General cost month changed to " + moment(this.state.generalCostSelectedMonth).format('YYYY-MM'));
            }
            generalCostAxes = true;
            updateCostChart = true;
            fetchData = true;
        }
        // selected date of general cost year graph has changed
        if ((this.state.generalCostSelectedYear !== prevState.generalCostSelectedYear) && (this.state.generalCostSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General cost year changed to " + moment(this.state.generalCostSelectedYear).format('YYYY'));
            }
            generalCostAxes = true;
            updateCostChart = true;
            fetchData = true;
        }

        // update all charts
        if (updateGeneralChart) {
            this.updateGeneralStatDayChart(generalAxes, fetchData, createSets);
            this.updateGeneralStatMonthChart(generalAxes, fetchData, createSets);
            this.updateGeneralStatYearChart(generalAxes, fetchData, createSets);
            this.updateGeneralStatYearComparisonChart(generalAxes, fetchData, createSets);
        }
        if (updateCostChart) {
            this.updateGeneralCostDayChart(generalCostAxes, fetchData, createSets);
            this.updateGeneralCostMonthChart(generalCostAxes, fetchData, createSets);
            this.updateGeneralCostYearChart(generalCostAxes, fetchData, createSets);
            this.updateGeneralCostYearComparisonChart(generalCostAxes, fetchData, createSets);
        }
    }

    /**
     * Resets the view state after installation/component ID changed.
     */
    resetState() {
        // keep selected dates, tab indexes etc.
        const generalStatDay = this.state.generalStatSelectedDay;
        const generalStatMonth = this.state.generalStatSelectedMonth;
        const generalStatYear = this.state.generalStatSelectedYear;
        const generalStatYears = this.state.generalStatSelectedYears;
        const generalCostDay = this.state.generalCostSelectedDay;
        const generalCostMonth = this.state.generalCostSelectedMonth;
        const generalCostYear = this.state.generalCostSelectedYear;
        const generalCostYears = this.state.generalCostSelectedYears;
        const elecYLabels = getYAxisScaleLabels('Verbrauch');    // ok for all electric components
        const costYLabels = getYAxisScaleLabels('Kosten');
        this.setState({
            error: null,
            generalStatDayOptions: createLineChartOptions(generalStatDay, true, elecYLabels[0], this.onGeneralStatLegendClick),
            generalStatDayData: { datasets: createGeneralDayDataSets(true) },
            generalStatMonthOptions: createBarChartOptions('MonthDays', generalStatMonth, AppConfig.yearsToCompare, true, elecYLabels[1], this.onGeneralStatLegendClick),
            generalStatMonthData: { datasets: createGeneralStatsDataSets() },
            generalStatYearOptions: createBarChartOptions('YearMonths', generalStatYear, AppConfig.yearsToCompare, true, elecYLabels[1], this.onGeneralStatLegendClick),
            generalStatYearData: { datasets: createGeneralStatsDataSets() },
            generalStatYearComparisonOptions: createBarChartOptions('Years', generalStatYears, AppConfig.yearsToCompare, true, elecYLabels[1], this.onGeneralStatLegendClick),
            generalStatYearComparisonData: { datasets: createGeneralStatsDataSets() },
            
            generalCostDayOptions: createLineChartOptions(generalCostDay, true, costYLabels[0], this.onGeneralCostLegendClick),
            generalCostDayData: { datasets: createCostDayDataSets() },
            generalCostMonthOptions: createBarChartOptions('MonthDays', generalCostMonth, AppConfig.yearsToCompare, true, costYLabels[1], this.onGeneralCostLegendClick),
            generalCostMonthData: { datasets: createGeneralStatsDataSets() },
            generalCostYearOptions: createBarChartOptions('YearMonths', generalCostYear, AppConfig.yearsToCompare, true, costYLabels[1], this.onGeneralCostLegendClick),
            generalCostYearData: { datasets: createGeneralStatsDataSets() },
            generalCostYearComparisonOptions: createBarChartOptions('Years', generalCostYears, AppConfig.yearsToCompare, true, costYLabels[1], this.onGeneralCostLegendClick),
            generalCostYearComparisonData: { datasets: createGeneralStatsDataSets() },
            
            prosumerComponents: PROSUMER_COMPONENTS
        });
    }

    /**
     * Refreshes all visible data. Fetches data from server where necessary.
     * @param {Boolean} force fetch even if data is present
     */
    refreshAllData(force = false) {
        // TODO incremental data update, if the latest time period contains partial data

        let tasks = [];
        this.setState({ isRefreshing: true, error: null });
        // installation configuration objects
        if (this.props.installationList.length === 0) {
            // load all installations
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation list refresh");
            }
            tasks.push(this.props.doFetchAllInstallations());
        } else {
            // update installation if all were loaded, also updates status and power flow
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation update refresh");
            }
            tasks.push(this.props.doFetchInstallation(this.props.installationId));
        }
        if ((this.props.components === undefined || this.props.components.length === 0) || force) {
            // load all components
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component list refresh");
            }
            tasks.push(this.props.doFetchAllComponents(this.props.installationId));
        }
        if ((this.props.buildingUnits === undefined || this.props.buildingUnits.length === 0) || force) {
            // load all building units
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit list refresh");
            }
            tasks.push(this.props.doFetchAllBuildingUnits(this.props.installationId));
        }
        // always fetch latest general figures
        tasks.push(
            this.props.doFetchGeneralFigures(this.props.installationId)
        );
        // general statistics - fetch only for visible tab, because tab change will initiate fetch anyway
        switch (this.state.generalStatTabIndex) {
            case 0: {
                const components = PROSUMER_COMPONENTS;
                for (let i = 0; i < components.length; i++) {
                    if ((this.props.componentDayTimeData === undefined) || (this.props.componentDayTimeData[components[i].id] === undefined) || (this.props.componentDayTimeData[components[i].id][this.state.generalStatSelectedDay] === undefined) || (moment().format('YYYY-MM-DD') === this.state.generalStatSelectedDay) || force) {
                        if (BuildConfig.isReactotronEnabled) {
                            Reactotron.log("Component " + components[i].id + ": day data of " + this.state.generalStatSelectedDay + " not found, require fetch");
                        }
                        tasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, components[i].id, this.state.generalStatSelectedDay));
                    }
                }

                if ((this.props.generalFigures === undefined) || (this.props.generalFigures[this.state.generalStatSelectedDay] === undefined) || (moment().startOf('day').format('YYYY-MM-DD') === this.state.generalStatSelectedDay) || force) {
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require general figures day-time refresh");
                    }
                    tasks.push(this.props.doFetchGeneralDayFigures(this.props.installationId, this.state.generalStatSelectedDay));
                }
                break;
            }
            case 1: {
                if ((this.props.generalMonthDayData === undefined || this.props.generalMonthDayData[this.state.generalStatSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.generalStatSelectedMonth) || force) {
                    // load general statistics month-day data
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require general statistic month-day refresh");
                    }
                    tasks.push(this.props.doFetchGeneralMonthDayData(this.props.installationId, this.state.generalStatSelectedMonth));
                }
                break;
            }
            case 2: {
                if ((this.props.generalYearMonthData === undefined || this.props.generalYearMonthData[this.state.generalStatSelectedYear] === undefined) || (moment().startOf('year').format('YYYY-MM-DD') === this.state.generalStatSelectedYear) || force) {
                    // load general statistics year-month data
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require general statistic year-month refresh");
                    }
                    tasks.push(this.props.doFetchGeneralYearMonthData(this.props.installationId, this.state.generalStatSelectedYear));
                }
                break;
            }
            case 3: {
                // load general statistics years data
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Require general statistic years refresh");
                }
                tasks.push(this.props.doFetchGeneralsYearsData(this.props.installationId, this.state.generalStatSelectedYears, AppConfig.yearsToCompare));
                break;
            }
            default: {
                break;
            }
        }

        // general cost - fetch only for visible tab, because tab change will initiate fetch anyway
        switch (this.state.generalCostTabIndex) {
            case 0: {
                if ((this.props.generalFigures === undefined) || (this.props.generalFigures[this.state.generalCostSelectedDay] === undefined) || (moment().startOf('day').format('YYYY-MM-DD') === this.state.generalCostSelectedDay) || force) {
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require general figures day-time refresh");
                    }
                    tasks.push(this.props.doFetchGeneralDayFigures(this.props.installationId, this.state.generalCostSelectedDay));
                }
                break;
            }
            case 1: {
                if ((this.props.generalMonthDayData === undefined || this.props.generalMonthDayData[this.state.generalCostSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.generalCostSelectedMonth) || force) {
                    // load general statistics month-day data
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require general statistic month-day refresh");
                    }
                    tasks.push(this.props.doFetchGeneralMonthDayData(this.props.installationId, this.state.generalCostSelectedMonth));
                }
                break;
            }
            case 2: {
                if ((this.props.generalYearMonthData === undefined || this.props.generalYearMonthData[this.state.generalCostSelectedYear] === undefined) || (moment().startOf('year').format('YYYY-MM-DD') === this.state.generalCostSelectedYear) || force) {
                    // load general statistics year-month data
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require general statistic year-month refresh");
                    }
                    tasks.push(this.props.doFetchGeneralYearMonthData(this.props.installationId, this.state.generalCostSelectedYear));
                }
                break;
            }
            case 3: {
                // load general statistics years data
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Require general statistic years refresh");
                }
                tasks.push(this.props.doFetchGeneralsYearsData(this.props.installationId, this.state.generalCostSelectedYears, AppConfig.yearsToCompare));
                break;
            }
            default: {
                break;
            }
        }

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks).then((results) => {
                if (!this.mounted) return;
                // check results
                let errors = [];
                results.forEach((result) => {
                    if (result.success !== true) {
                        errors.push(result.message);
                    }
                });
                this.setState({
                    isRefreshing: false,
                    error: (errors.length === 0) ? null : errors.join(' | ')
                });
            });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // reload
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    updateGeneralStatDayChart(axes, fetch, createsets) {
        // NOTE
        // trying a new approach to process tasks!
        // this is less reliant on prop/state change handlers
        const day = this.state.generalStatSelectedDay;
        const components = this.state.prosumerComponents;
        const oldDataSets = this.state.generalStatDayData.datasets;
        const newDataSets = (createsets) ? createGeneralDayDataSets(true) : [...oldDataSets];

        let fetchTasks = [];

        if (fetch && ((this.props.generalFigureData === undefined) || (this.props.generalFigureData[day] === undefined))) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General figures: day data of " + day + " not found, require fetch");
            }
            if (fetchTasks.length === 0) {
                // once
                this.setState({ isRefreshing: true });
            }
            fetchTasks.push(this.props.doFetchGeneralDayFigures(this.props.installationId, day));
        }

        for (let i = 0; i < components.length; i++) {
            if (fetch && ((this.props.componentDayTimeData === undefined) || (this.props.componentDayTimeData[components[i].id] === undefined) || (this.props.componentDayTimeData[components[i].id][day] === undefined))) {
                // data must be fetched first
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Component " + components[i].id + ": day data of " + day + " not found, require fetch");
                }
                if (fetchTasks.length === 0) {
                    // once
                    this.setState({ isRefreshing: true });
                }
                fetchTasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, components[i].id, day));
            }
        }

        // even if no fetch tasks defined
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // process result of fetch requests
                Promise.all(fetchTasks).then((results) => {
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });
                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | ')
                    });

                    if (errors.length === 0) {
                        if (BuildConfig.isReactotronEnabled) {
                            Reactotron.log("Updating general day chart ***");
                        }

                        // update chart
                        let newState = {};
                        if (axes) {
                            const oldOptions = this.state.generalStatDayOptions;
                            const newOptions = { ...oldOptions };
                            newOptions.scales.xAxes[0].ticks.min = moment(day).startOf('day');
                            newOptions.scales.xAxes[0].ticks.max = moment(day).add(1, 'days').startOf('day');
                            newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('DayTime', day);
                            newState.generalStatDayOptions = newOptions;
                        }

                        // get autarchy data
                        let autarchyData;
                        if ((this.props.generalFigureData !== undefined) && (this.props.generalFigureData[day] !== undefined)) {
                            autarchyData = extractAutarchyData(this.props.generalFigureData[day], day);
                        } else {
                            autarchyData = [];
                        }

                        // consumption and production
                        // comp. data should be present if all fetch tasks OK
                        let consData;
                        let prodData;
                        if ((this.props.componentDayTimeData !== undefined) && (this.props.componentDayTimeData[components[0].id] !== undefined) && (this.props.componentDayTimeData[components[0].id][day] !== undefined)) {
                            consData = convertComponentContinuousData(this.props.componentDayTimeData[components[0].id][day], day);
                        } else {
                            consData = [];
                        }
                        if ((this.props.componentDayTimeData !== undefined) && (this.props.componentDayTimeData[components[1].id] !== undefined) && (this.props.componentDayTimeData[components[1].id][day] !== undefined)) {
                            prodData = convertComponentContinuousData(this.props.componentDayTimeData[components[1].id][day], day);
                        } else {
                            prodData = [];
                        }

                        // update data sets
                        const newConsValues = createDatasetsWithAutarchy(consData, autarchyData);
                        newDataSets[0].data = newConsValues[0];
                        newDataSets[1].data = newConsValues[1];
                        newDataSets[2].data = createSurplusDataset(prodData, consData);

                        // update state
                        newState.generalStatDayData = { datasets: newDataSets };
                        this.setState(newState);
                    }
                });
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    updateGeneralStatMonthChart(axes, fetch, createsets) {
        const month = this.state.generalStatSelectedMonth;

        // load or fetch data
        let newData = null;
        if ((this.props.generalMonthDayData !== undefined) && (this.props.generalMonthDayData[month] !== undefined)) {
            newData = convertGeneralStatsData(this.props.generalMonthDayData[month], 'MonthDays', month);
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats of month " + moment(month).format('YYYY-MM') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchGeneralMonthDayData(this.props.installationId, month)
                        .then((result) => {
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newData != null)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.generalStatMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newState.generalStatMonthOptions = newOptions;
            }
            if (createsets) {
                newState.generalStatMonthData = { datasets: createGeneralStatsDataSets() };
            } else {
                const oldData = this.state.generalStatMonthData;
                newState.generalStatMonthData = { ...oldData };
            }
            if (newData != null) {
                const dataSets = newState.generalStatMonthData.datasets;
                dataSets[0].data = newData.selfConsumption;
                dataSets[1].data = newData.gridConsumption;
                dataSets[2].data = newData.gridSurplus;
            }
            this.setState(newState);
        }
    }

    updateGeneralStatYearChart(axes, fetch, createsets) {
        const year = this.state.generalStatSelectedYear;

        // load or fetch data sets
        let newData = null;
        if ((this.props.generalYearMonthData !== undefined) && (this.props.generalYearMonthData[year] !== undefined)) {
            newData = convertGeneralStatsData(this.props.generalYearMonthData[year], 'YearMonths', year);
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats of year " + moment(year).format('YYYY') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchGeneralYearMonthData(this.props.installationId, year)
                        .then((result) => {
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newData != null)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.generalStatYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newState.generalStatYearOptions = newOptions;
            }
            if (createsets) {
                newState.generalStatYearData = { datasets: createGeneralStatsDataSets() };
            } else {
                const oldData = this.state.generalStatYearData;
                newState.generalStatYearData = { ...oldData };
            }
            if (newData != null) {
                const dataSets = newState.generalStatYearData.datasets;
                dataSets[0].data = newData.selfConsumption;
                dataSets[1].data = newData.gridConsumption;
                dataSets[2].data = newData.gridSurplus;
            }
            this.setState(newState);
        }
    }

    updateGeneralStatYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.generalStatSelectedYears;

        // load or fetch data sets
        let newData = null;
        if (this.props.generalYearsData !== undefined) {
            newData = convertGeneralStatsData(this.props.generalYearsData, 'Years', years, AppConfig.yearsToCompare);
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats of years " + moment(years).format('YYYY') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchGeneralsYearsData(this.props.installationId, years, AppConfig.yearsToCompare)
                        .then((result) => {
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newData != null)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.generalStatYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newState.generalStatYearComparisonOptions = newOptions;
            }
            if (createsets) {
                newState.generalStatYearComparisonData = { datasets: createGeneralStatsDataSets() };
            } else {
                const oldData = this.state.generalStatYearComparisonData;
                newState.generalStatYearComparisonData = { ...oldData };
            }
            if (newData != null) {
                const dataSets = newState.generalStatYearComparisonData.datasets;
                dataSets[0].data = newData.selfConsumption;
                dataSets[1].data = newData.gridConsumption;
                dataSets[2].data = newData.gridSurplus;
            }
            this.setState(newState);
        }
    }

    updateVisibleGeneralStatChart(axes, fetch, createsets) {
        switch (this.state.generalStatTabIndex) {
            case 0: {
                this.updateGeneralStatDayChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateGeneralStatMonthChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateGeneralStatYearChart(axes, fetch, createsets);
                break;
            }
            case 3: {
                this.updateGeneralStatYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    updateGeneralCostDayChart(axes, fetch, createsets) {
        const day = this.state.generalCostSelectedDay;

        const oldDataSets = this.state.generalCostDayData.datasets;
        const newDataSets = (createsets) ? createCostDayDataSets() : [...oldDataSets];

        let fetchTasks = [];

        if (fetch && ((this.props.generalFigureData === undefined) || (this.props.generalFigureData[day] === undefined))) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General figures: day data of " + day + " not found, require fetch");
            }
            if (fetchTasks.length === 0) {
                // once
                this.setState({ isRefreshing: true });
            }
            fetchTasks.push(this.props.doFetchGeneralDayFigures(this.props.installationId, day));
        }

        // even if no fetch tasks defined
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // process result of fetch requests
                Promise.all(fetchTasks).then((results) => {
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });
                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | ')
                    });

                    if (errors.length === 0) {
                        if (BuildConfig.isReactotronEnabled) {
                            Reactotron.log("Updating cost day chart ***");
                        }

                        // update chart
                        let newState = {};
                        if (axes) {
                            const oldOptions = this.state.generalCostDayOptions;
                            const newOptions = { ...oldOptions };
                            newOptions.scales.xAxes[0].ticks.min = moment(day).startOf('day');
                            newOptions.scales.xAxes[0].ticks.max = moment(day).add(1, 'days').startOf('day');
                            newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('DayTime', day);
                            newState.generalCostDayOptions = newOptions;
                        }

                        // get price data
                        if ((this.props.generalFigureData !== undefined) && (this.props.generalFigureData[day] !== undefined)) {
                            newDataSets[0].data = convertContinuousPriceData(this.props.generalFigureData[day], day);
                        } else {
                            newDataSets[0].data = [];
                        }

                        // update state
                        newState.generalCostDayData = { datasets: newDataSets };
                        this.setState(newState);
                    }
                });
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    updateGeneralCostMonthChart(axes, fetch, createsets) {
        const month = this.state.generalCostSelectedMonth;

        // load or fetch data
        let newData = null;
        if ((this.props.generalMonthDayData !== undefined) && (this.props.generalMonthDayData[month] !== undefined)) {
            newData = convertGeneralCostData(this.props.generalMonthDayData[month], 'MonthDays', month);
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats of month " + moment(month).format('YYYY-MM') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchGeneralMonthDayData(this.props.installationId, month)
                        .then((result) => {
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newData != null)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.generalCostMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newState.generalCostMonthOptions = newOptions;
            }
            if (createsets) {
                newState.generalCostMonthData = { datasets: createGeneralStatsDataSets() };
            } else {
                const oldData = this.state.generalCostMonthData;
                newState.generalCostMonthData = { ...oldData };
            }
            if (newData != null) {
                const dataSets = newState.generalCostMonthData.datasets;
                dataSets[0].data = newData.selfConsumption;
                dataSets[1].data = newData.gridConsumption;
                dataSets[2].data = newData.gridSurplus;
            }
            this.setState(newState);
        }
    }

    updateGeneralCostYearChart(axes, fetch, createsets) {
        const year = this.state.generalCostSelectedYear;

        // load or fetch data sets
        let newData = null;
        if ((this.props.generalYearMonthData !== undefined) && (this.props.generalYearMonthData[year] !== undefined)) {
            newData = convertGeneralCostData(this.props.generalYearMonthData[year], 'YearMonths', year);
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats of year " + moment(year).format('YYYY') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchGeneralYearMonthData(this.props.installationId, year)
                        .then((result) => {
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newData != null)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.generalCostYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newState.generalCostYearOptions = newOptions;
            }
            if (createsets) {
                newState.generalCostYearData = { datasets: createGeneralStatsDataSets() };
            } else {
                const oldData = this.state.generalCostYearData;
                newState.generalCostYearData = { ...oldData };
            }
            if (newData != null) {
                const dataSets = newState.generalCostYearData.datasets;
                dataSets[0].data = newData.selfConsumption;
                dataSets[1].data = newData.gridConsumption;
                dataSets[2].data = newData.gridSurplus;
            }
            this.setState(newState);
        }
    }

    updateGeneralCostYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.generalCostSelectedYears;

        // load or fetch data sets
        let newData = null;
        if (this.props.generalYearsData !== undefined) {
            newData = convertGeneralCostData(this.props.generalYearsData, 'Years', years, AppConfig.yearsToCompare);
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("General stats of years " + moment(years).format('YYYY') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchGeneralsYearsData(this.props.installationId, years, AppConfig.yearsToCompare)
                        .then((result) => {
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newData != null)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.generalCostYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newState.generalCostYearComparisonOptions = newOptions;
            }
            if (createsets) {
                newState.generalCostYearComparisonData = { datasets: createGeneralStatsDataSets() };
            } else {
                const oldData = this.state.generalCostYearComparisonData;
                newState.generalCostYearComparisonData = { ...oldData };
            }
            if (newData != null) {
                const dataSets = newState.generalCostYearComparisonData.datasets;
                dataSets[0].data = newData.selfConsumption;
                dataSets[1].data = newData.gridConsumption;
                dataSets[2].data = newData.gridSurplus;
            }
            this.setState(newState);
        }
    }

    updateVisibleGeneralCostChart(axes, fetch, createsets) {
        switch (this.state.generalCostTabIndex) {
            case 0: {
                this.updateGeneralCostDayChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateGeneralCostMonthChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateGeneralCostYearChart(axes, fetch, createsets);
                break;
            }
            case 3: {
                this.updateGeneralCostYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    onViewLoaded() {
        // update installation and fetch components
        if (this.props.isAuthenticated === true) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Update of installation and initial load of components");
            }
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);

                // update visible charts - at least axes
                this.updateVisibleGeneralStatChart(true, false, true);
                this.updateVisibleGeneralCostChart(true, false, true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    onGeneralStatDayChanged(date) {
        const day = moment(date).startOf('day').format('YYYY-MM-DD');
        this.setState({ generalStatSelectedDay: day });
    }

    onGeneralStatMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ generalStatSelectedMonth: month });
    }

    onGeneralStatYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ generalStatSelectedYear: year });
    }

    onGeneralStatLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.generalStatTabIndex) {
            case 0: {
                const sets = this.state.generalStatDayData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ generalStatDayData: { datasets: newSets } });
                }
                break;
            }
            case 1: {
                const sets = this.state.generalStatMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ generalStatMonthData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.generalStatYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ generalStatYearData: { datasets: newSets } });
                }
                break;
            }
            case 3: {
                const sets = this.state.generalStatYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ generalStatYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    onGeneralCostDayChanged(date) {
        const day = moment(date).startOf('day').format('YYYY-MM-DD');
        this.setState({ generalCostSelectedDay: day });
    }

    onGeneralCostMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ generalCostSelectedMonth: month });
    }

    onGeneralCostYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ generalCostSelectedYear: year });
    }

    onGeneralCostLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.generalCostTabIndex) {
            case 0: {
                // do nothing on day tab
                break;
            }
            case 1: {
                const sets = this.state.generalCostMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ generalCostMonthData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.generalCostYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ generalCostYearData: { datasets: newSets } });
                }
                break;
            }
            case 3: {
                const sets = this.state.generalCostYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ generalCostYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.installationOverview, enabled: true, active: true, icon: "fas fa-building", path: "/installations/" + this.props.installationId + "/overview" },
            { name: headerStrings.photovoltaics, enabled: true, active: false, icon: "fas fa-solar-panel", path: "/installations/" + this.props.installationId + "/pv" }
        ];
        if ((this.props.selectedInstallation != null) && (this.props.selectedInstallation.powerFlow.battery)) {
            navItems.push({ name: headerStrings.battery, enabled: true, active: false, icon: "fas fa-car-battery", path: "/installations/" + this.props.installationId + "/battery" });
        }

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <InstallationInfo installation={this.props.selectedInstallation} figures={this.props.generalFigures} />
                    <div style={{ margin: 20 }}></div>
                    <Panel header={installationStrings.detailViews + " – " + installationStrings.buildingUnits}>
                        <div className="p-grid p-justify-start">
                            {(this.props.buildingUnits !== undefined) &&
                                this.props.buildingUnits.map(unit =>
                                    (<div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2" key={unit.id}>
                                        <Link to={'/installations/' + this.props.installationId + '/units/' + unit.id + '/overview'}>
                                            <Button icon="fas fa-door-open" label={unit.name} style={{ width: '90%' }} />
                                        </Link>
                                    </div>))
                            }
                        </div>
                    </Panel>
                    <div style={{ margin: 20 }}></div>
                    <Panel header={installationStrings.gridSelf + " – " + installationStrings.total}>
                        <TabView activeIndex={this.state.generalStatTabIndex} onTabChange={(e) => this.setState({ generalStatTabIndex: e.index })} renderActiveOnly={true}>
                            <TabPanel header={graphStrings.day}>
                                <DateGraph term="DayTime" date={this.state.generalStatSelectedDay} onDateChanged={this.onGeneralStatDayChanged} chartType="line" chartData={this.state.generalStatDayData} chartOptions={this.state.generalStatDayOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                            <TabPanel header={graphStrings.month}>
                                {/* All days of a month */}
                                <DateGraph term="MonthDays" date={this.state.generalStatSelectedMonth} onDateChanged={this.onGeneralStatMonthChanged} chartType="bar" chartData={this.state.generalStatMonthData} chartOptions={this.state.generalStatMonthOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                            <TabPanel header={graphStrings.year}>
                                {/* All months of a year */}
                                <DateGraph term="YearMonths" date={this.state.generalStatSelectedYear} onDateChanged={this.onGeneralStatYearChanged} chartType="bar" chartData={this.state.generalStatYearData} chartOptions={this.state.generalStatYearOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                            <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years}>
                                {/* Compare last X years */}
                                <DateGraph term="Years" date={this.state.generalStatSelectedYears} chartType="bar" chartData={this.state.generalStatYearComparisonData} chartOptions={this.state.generalStatYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                        </TabView>
                    </Panel>
                    <div style={{ margin: 20 }}></div>
                    <Panel header={installationStrings.costRevenue + " – " + installationStrings.total}>
                        <TabView activeIndex={this.state.generalCostTabIndex} onTabChange={(e) => this.setState({ generalCostTabIndex: e.index })} renderActiveOnly={true}>
                            <TabPanel header={graphStrings.day}>
                                <DateGraph term="DayTime" date={this.state.generalCostSelectedDay} onDateChanged={this.onGeneralCostDayChanged} chartType="line" chartData={this.state.generalCostDayData} chartOptions={this.state.generalCostDayOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                            <TabPanel header={graphStrings.month}>
                                {/* All days of a month */}
                                <DateGraph term="MonthDays" date={this.state.generalCostSelectedMonth} onDateChanged={this.onGeneralCostMonthChanged} chartType="bar" chartData={this.state.generalCostMonthData} chartOptions={this.state.generalCostMonthOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                            <TabPanel header={graphStrings.year}>
                                {/* All months of a year */}
                                <DateGraph term="YearMonths" date={this.state.generalCostSelectedYear} onDateChanged={this.onGeneralCostYearChanged} chartType="bar" chartData={this.state.generalCostYearData} chartOptions={this.state.generalCostYearOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                            <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years}>
                                {/* Compare last X years */}
                                <DateGraph term="Years" date={this.state.generalCostSelectedYears} chartType="bar" chartData={this.state.generalCostYearComparisonData} chartOptions={this.state.generalCostYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                            </TabPanel>
                        </TabView>
                    </Panel>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const installationId = ownProps.match.params.instId;    // might not be integer!
    return {
        installationId,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installationList: InstallationSelectors.selectAll(state),
        selectedInstallation: InstallationSelectors.selectOne(state, installationId),
        components: ComponentSelectors.selectAll(state, installationId),
        buildingUnits: BuildingUnitSelectors.selectAll(state, installationId),
        generalFigures: GeneralFigureSelectors.selectOfInstallation(state, installationId),
        generalMonthDayData: GeneralMonthDaySelectors.selectAllOfInstallation(state, installationId),
        generalYearMonthData: GeneralYearMonthSelectors.selectAllOfInstallation(state, installationId),
        generalYearsData: GeneralYearsSelectors.selectAllOfInstallation(state, installationId),
        componentDayTimeData: ComponentDayTimeSelectors.selectAllOfInstallation(state, installationId),
        componentMonthDayData: ComponentMonthDaySelectors.selectAllOfInstallation(state, installationId),
        componentYearMonthData: ComponentYearMonthSelectors.selectAllOfInstallation(state, installationId),
        componentYearsData: ComponentYearsSelectors.selectAllOfInstallation(state, installationId),
        generalFigureData: GeneralFigureDaySelectors.selectAllOfInstallation(state, installationId)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchAllInstallations: () => dispatch(fetchAllInstallations()),
        doFetchInstallation: (instId) => dispatch(fetchInstallation(instId)),
        doFetchAllComponents: (instId) => dispatch(fetchAllComponents(instId)),
        doFetchAllBuildingUnits: (instId) => dispatch(fetchAllBuildingUnits(instId)),
        doFetchGeneralFigures: (instId) => dispatch(fetchGeneralFigures(instId)),
        doFetchGeneralMonthDayData: (instId, date) => dispatch(fetchGeneralMonthDayData(instId, date)),
        doFetchGeneralYearMonthData: (instId, date) => dispatch(fetchGeneralYearMonthData(instId, date)),
        doFetchGeneralsYearsData: (instId, date, nYears) => dispatch(fetchGeneralYearsData(instId, date, nYears)),
        doFetchComponentDayTimeData: (instId, compId, date) => dispatch(fetchComponentDayTimeData(instId, compId, date)),
        doFetchComponentMonthDayData: (instId, compId, date) => dispatch(fetchComponentMonthDayData(instId, compId, date)),
        doFetchComponentYearMonthData: (instId, compId, date) => dispatch(fetchComponentYearMonthData(instId, compId, date)),
        doFetchComponentYearsData: (instId, compId, date, nYears) => dispatch(fetchComponentYearsData(instId, compId, date, nYears)),
        doFetchGeneralDayFigures: (instId, date) => dispatch(fetchGeneralFiguresByDay(instId, date))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallationOverviewView);