import LocalizedStrings from 'react-localization';

// this module provides react-localization translations

const errorStrings = new LocalizedStrings(require('./error.json'));
const headerStrings = new LocalizedStrings(require('./header.json'));
const mgmtStrings = new LocalizedStrings(require('./mgmt.json'));
const homeStrings = new LocalizedStrings(require('./home.json'));
const installationStrings = new LocalizedStrings(require('./installation.json'));
const componentStrings = new LocalizedStrings(require('./component.json'));
const graphStrings = new LocalizedStrings(require('./graph.json'));

const country = new LocalizedStrings(require('./country.json'));
// maps country codes to names
const countryCodeMap = {
    "": country.undefined,
    "ch": country.switzerland,
    "de": country.germany
};

const cal = new LocalizedStrings(require('./calendar.json'));
// short names of months
const monthShortNames = [ cal.janS, cal.febS, cal.marS, cal.aprS, cal.mayS, cal.junS, cal.julS, cal.augS, cal.sepS, cal.octS, cal.novS, cal.decS ];
const monthLongNames = [ cal.janL, cal.febL, cal.marL, cal.aprL, cal.mayL, cal.junL, cal.julL, cal.augL, cal.sepL, cal.octL, cal.novL, cal.decL ];

const primeCalendar = {
    firstDayOfWeek: 1,
    dayNames: [cal.sunL, cal.monL, cal.tueL, cal.wedL, cal.thuL, cal.friL, cal.satL],
    dayNamesShort: [cal.sunS, cal.monS, cal.tueS, cal.wedS, cal.thuS, cal.friS, cal.satS],
    dayNamesMin: [cal.sunM, cal.monM, cal.tueM, cal.wedM, cal.thuM, cal.friM, cal.satM],
    monthNames: monthLongNames,
    monthNamesShort: monthShortNames,
    today: cal.today,
    clear: cal.clear,
    dateFormat: 'yy/mm/dd',
    weekHeader: cal.week
};

export { errorStrings, headerStrings, mgmtStrings, homeStrings, installationStrings, componentStrings, graphStrings, countryCodeMap, monthShortNames, monthLongNames, primeCalendar };