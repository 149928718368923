/**
 * General statistic data reducer.
 * Contains daily values for each month, for each installation.
 * Months are represented by ISO dates (first day of month).
 */

/* ------------- Types ------------- */

// types
export const GeneralMonthDayTypes = {
  GENERAL_MONTH_DAY_CLEAR_INSTALLATION: 'GENERAL_MONTH_DAY_CLEAR_INSTALLATION',
  GENERAL_MONTH_DAY_CLEAR_ALL: 'GENERAL_MONTH_DAY_CLEAR_ALL',
  GENERAL_MONTH_DAY_UPDATE: 'GENERAL_MONTH_DAY_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const GeneralMonthDaySelectors = {
  selectAllOfInstallation: (state, installationId) => state.generalMonthDayData[installationId]
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onGeneralMonthDayUpdate(state, payload) {
  // create or update
  const { installationId, date, values } = payload;
  const instData = state[installationId];
  if (instData === undefined) {
    // no installation data -> create month with values
    return {
      ...state,
      [installationId]: {
        [date]: values
      }
    };
  } else {
    // update installation data -> set month with values
    const newInstData = { ...instData, [date]: values };
    return {
      ...state,
      [installationId]: newInstData
    };
  }
}

function onGeneralMonthDayClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GeneralMonthDayTypes.GENERAL_MONTH_DAY_UPDATE: {
      return onGeneralMonthDayUpdate(state, action.payload);
    }
    case GeneralMonthDayTypes.GENERAL_MONTH_DAY_CLEAR_INSTALLATION: {
      return onGeneralMonthDayClearInstallation(state, action.payload);
    }
    case GeneralMonthDayTypes.GENERAL_MONTH_DAY_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;