import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { getCurrentDate, getNextDate, getPreviousDate } from './util/GraphUtils';
import { graphStrings, primeCalendar } from '../i18n/translations';

/**
 * A component that displays a chart of either a single day, a month, a year or multiple years.
 * The user can choose which kind to show, using a calendar and buttons.
 * Chart data and options must be fed externally.
 *
 * property 'term':
 * 'DayTime' -> select day using calendar
 * 'MonthDays' -> select month using calendar
 * 'YearMonths' -> select year using dropdown
 * 'Years' -> no selector, use 'maxYears'
 */
class DateGraph extends Component {

    constructor(props) {
        super(props);

        const now = moment();
        const selDate = (props.date) ? moment(props.date) : now.clone();
        const maxYear = now.year();
        const minYear = moment(now).subtract(this.props.maxYears - 1, 'years').year();
        this.state = {
            initialDate: getCurrentDate(this.props.term, now),
            selectedDate: getCurrentDate(this.props.term, selDate),
            maxYear: maxYear,
            minYear: minYear
        };
        this.renderCalendar = this.renderCalendar.bind(this);
        this.onChangeCalendar = this.onChangeCalendar.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickCurrent = this.onClickCurrent.bind(this);
        this.allowCurrent = this.allowCurrent.bind(this);
        this.allowPrevious = this.allowPrevious.bind(this);
        this.allowNext = this.allowNext.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.selectedDate !== prevState.selectedDate) && (prevState.selectedDate !== undefined)) {
            // update if not initial change
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("selected date changed: " + prevState.selectedDate.format('YYYY-MM-DD') + " -> " + this.state.selectedDate.format('YYYY-MM-DD'));
            }
            if (this.props.onDateChanged !== undefined) {
                this.props.onDateChanged(this.state.selectedDate);
            }
        }
    }

    onChangeCalendar(date) {
        // TODO ensure that no 'future' date can be chosen!
        const newDate = (moment(date).isBefore(this.state.initialDate)) ?
            getCurrentDate(this.props.term, date) : getCurrentDate(this.props.term, this.state.initialDate);

        // calender returns a Date object
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("calendar changed -> " + newDate.format('YYYY-MM-DD'));
        }
        this.setState({ selectedDate: newDate });
    }

    onClickNext() {
        // select next month/year
        const newDate = getNextDate(this.props.term, this.state.selectedDate);
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("next date -> " + newDate.format('YYYY-MM-DD'));
        }
        this.setState({ selectedDate: newDate });
    }

    onClickPrevious() {
        // select previous month/year
        const newDate = getPreviousDate(this.props.term, this.state.selectedDate);
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("previous date -> " + newDate.format('YYYY-MM-DD'));
        }
        this.setState({ selectedDate: newDate });
    }

    onClickCurrent() {
        // select current month/year
        const newDate = getCurrentDate(this.props.term, this.state.initialDate);
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("current date -> " + newDate.format('YYYY-MM-DD'));
        }
        this.setState({ selectedDate: newDate });
    }

    allowPrevious() {
        return this.state.selectedDate.isAfter(moment([this.state.minYear]));
    }

    allowNext() {
        return this.state.selectedDate.isBefore(this.state.initialDate);
    }

    allowCurrent() {
        return !this.state.selectedDate.isSame(this.state.initialDate);
    }

    render() {
        return (
            <div>
                <div>
                    <Chart type={this.props.chartType} data={this.props.chartData} options={this.props.chartOptions} style={{ height: '25em'}} />
                </div>
                {((this.props.term === 'DayTime') || (this.props.term === 'MonthDays') || (this.props.term === 'YearMonths')) &&
                    (<div className="p-grid p-justify-start p-align-center">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                            {this.renderCalendar(this.props.term, this.state.selectedDate.toDate())}
                        </div>
                        <div className="p-col">
                        {(this.allowPrevious()) ? (
                            <Button className="p-button-secondary" style={{ margin: 3 }} tooltip={graphStrings.previous} icon="pi pi-caret-left" onClick={this.onClickPrevious} />
                        ) : (
                            <Button disabled="disabled" className="p-button-secondary" style={{ margin: 3 }} tooltip={graphStrings.previous} icon="pi pi-caret-left" onClick={this.onClickPrevious} />
                        )}
                        {(this.allowCurrent()) ? (
                            <Button className="p-button-secondary" style={{ margin: 3 }} tooltip={graphStrings.current} icon="pi pi-clock" onClick={this.onClickCurrent} />
                        ) : ( // disable button
                            <Button disabled="disabled" className="p-button-secondary" style={{ margin: 3 }} tooltip={graphStrings.current} icon="pi pi-clock" onClick={this.onClickCurrent} />
                        )}
                        {(this.allowNext()) ? (
                            <Button className="p-button-secondary" style={{ margin: 3 }} tooltip={graphStrings.next} icon="pi pi-caret-right" onClick={this.onClickNext} />
                        ) : ( // disable button
                            <Button disabled="disabled" className="p-button-secondary" style={{ margin: 3 }} tooltip={graphStrings.next} icon="pi pi-caret-right" onClick={this.onClickNext} />
                        )}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    renderCalendar(term, date) {
        switch (term) {
            case 'DayTime': {
                return (<Calendar locale={primeCalendar} showIcon={true} value={date} readOnlyInput={true} onChange={(e) => this.onChangeCalendar(e.value)} touchUI={true} dateFormat="yy/mm/dd" monthNavigator={true} yearNavigator={true} yearRange={this.state.minYear + ':' + this.state.maxYear} showButtonBar={true} />);
            }
            case 'MonthDays': {
                return (<Calendar locale={primeCalendar} showIcon={true} value={date} readOnlyInput={true} onChange={(e) => this.onChangeCalendar(e.value)} touchUI={true} view="month" dateFormat="yy/mm" yearNavigator={true} yearRange={this.state.minYear + ':' + this.state.maxYear} showButtonBar={true} />);
            }
            case 'YearMonths': {
                const { minYear, maxYear } = this.state;
                let items = [];
                for (let i = minYear; i <= maxYear; i++) {
                    items.push({ label: ''+i, value: i });
                }
                const value = date.getFullYear();
                return (<Dropdown value={value} options={items} onChange={(e) => this.onChangeCalendar(new Date(e.value, 1, 1))} placeholder="Year" />);
            }
            default: {
                return (<div></div>);
            }
        }
    }
}

// default props
// term = DayTime (displays 1 day), MonthDays (displays 1 month), YearMonths (displays 1 year), Years (displays multiple years)
// maxYears = number of years to select from
DateGraph.defaultProps = {
    chartType: 'bar',
    term: 'Years',
    maxYears: 5
};

export default DateGraph;