import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DateGraph from '../components/DateGraph';
import ActualValue from '../components/ActualValue';
import { fetchAllInstallations } from '../actions/InstallationActions';
import { fetchAllComponents } from '../actions/ComponentActions';
import { fetchComponentDayTimeData, fetchComponentMonthDayData, fetchComponentYearMonthData, fetchComponentYearsData, fetchAllComponentsActualData } from '../actions/ComponentDataActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { ComponentSelectors } from '../redux/ComponentRedux';
import { ComponentActualSelectors } from '../redux/ComponentActualDataRedux';
import { ComponentDayTimeSelectors } from '../redux/ComponentDayTimeDataRedux';
import { ComponentMonthDaySelectors } from '../redux/ComponentMonthDayDataRedux';
import { ComponentYearMonthSelectors } from '../redux/ComponentYearMonthDataRedux';
import { ComponentYearsSelectors } from '../redux/ComponentYearsDataRedux';
import { createBarChartOptions, createLineChartOptions, getXAxisLabels, getXAxisScaleLabel, getYAxisScaleLabels, convertComponentStatisticData, convertComponentContinuousData, createDataSetsForLineChart, createDataSetsForBarChart } from '../components/util/GraphUtils';
import { getMeasurementUnits, filterMeasuredComponentsByTypes } from '../components/util/ComponentUtils';
import { headerStrings, installationStrings, errorStrings, graphStrings, componentStrings } from '../i18n/translations';

// required to compute using net production
const PROD_COMPONENTS = [
    {
        id: 1,
        name: componentStrings.totalProduction,
        type: 'ProduktionTot',
        measurable: true,
        color: '#008000'
    }
];

// virtual battery components
const BAT_COMPONENTS = [
    {
        id: 0,
        name: componentStrings.discharge,
        type: 'Batterie',
        measurable: true,
        color: '#FF6000'
    },
    {
        id: 0,
        name: componentStrings.charge,
        type: 'Batterie',
        measurable: true,
        color: '#00CC99'
    }
];

/**
 * The installation battery page.
 * Displays data of all battery devices.
 */
class InstallationBatteryView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // methods requiring access to 'this'
        this.resetState = this.resetState.bind(this);
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);
        this.onComponentDayChanged = this.onComponentDayChanged.bind(this);
        this.onComponentMonthChanged = this.onComponentMonthChanged.bind(this);
        this.onComponentYearChanged = this.onComponentYearChanged.bind(this);
        this.updateComponentDayChart = this.updateComponentDayChart.bind(this);
        this.updateComponentMonthChart = this.updateComponentMonthChart.bind(this);
        this.updateComponentYearChart = this.updateComponentYearChart.bind(this);
        this.updateComponentYearComparisonChart = this.updateComponentYearComparisonChart.bind(this);
        this.updateVisibleComponentChart = this.updateVisibleComponentChart.bind(this);
        this.renderActualValues = this.renderActualValues.bind(this);
        this.onComponentLegendClick = this.onComponentLegendClick.bind(this);
        // init state
        const day = moment().startOf('day').format('YYYY-MM-DD');
        const month = moment().startOf('month').format('YYYY-MM-DD');
        const year = moment().startOf('year').format('YYYY-MM-DD');
        const yLabels = getYAxisScaleLabels('Batterie');
        const fetchComponents = PROD_COMPONENTS.concat(filterMeasuredComponentsByTypes(this.props.components, ['Batterie', 'ProduktionNetto']));
        this.state = {
            isRefreshing: false,
            error: null,
            fetchComponents: fetchComponents,
            isRealBattery: (fetchComponents.length > PROD_COMPONENTS.length + 1),
            componentTabIndex: 0,
            componentSelectedDay: day,
            componentSelectedMonth: month,
            componentSelectedYear: year,
            componentSelectedYears: year,
            componentDayOptions: createLineChartOptions(day, false, yLabels[0], this.onComponentLegendClick),
            componentDayData: { datasets: createDataSetsForLineChart(BAT_COMPONENTS, false, true, true) },
            componentMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentMonthData: { datasets: createDataSetsForBarChart(BAT_COMPONENTS) },
            componentYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearData: { datasets: createDataSetsForBarChart(BAT_COMPONENTS) },
            componentYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearComparisonData: { datasets: createDataSetsForBarChart(BAT_COMPONENTS) }
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Installation battery view did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props were updated
        if (!this.mounted) return;

        // TODO remember possible update loop! maybe collect state and put setState at end.

        // probably not allowed to access installation, or not found
        if ((this.props.installationList.length > 0) && (this.props.selectedInstallation === undefined)) {
            // probably not allowed, or no valid installation
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Installation " + this.props.installationId + " not found");
                }
                this.setState({ error: errorStrings.installationNotFound, fetchComponents: [] });
            }
            return;
        }
        // changed URL parameters while view is still active
        if ((this.props.installationId !== prevProps.installationId) && (prevProps.installationId !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("URL parameters changed, resetting state");
            }
            this.resetState();
            this.onViewLoaded();
            return;
        }
        // authentication changed to true
        if ((this.props.isAuthenticated !== prevProps.isAuthenticated) && (this.props.isAuthenticated === true)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
            this.refreshAllData(false);
        }
        // components loaded
        if ((this.props.components !== prevProps.components) && (this.props.components !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Components loaded");
            }
            // should update components
            const fetchComponents = PROD_COMPONENTS.concat(filterMeasuredComponentsByTypes(this.props.components, ['Batterie','ProduktionNetto']));
            this.setState({
                fetchComponents: fetchComponents,
                isRealBattery: (fetchComponents.length > PROD_COMPONENTS.length + 1)
            });
        }
        // component day data was updated
        if ((this.props.componentDayTimeData !== prevProps.componentDayTimeData) && (this.props.componentDayTimeData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (day-time) updated");
            }
            if (this.state.componentTabIndex === 0) {
                this.updateComponentDayChart(false, false, false);
            }
        }
        // component month data was updated
        if ((this.props.componentMonthDayData !== prevProps.componentMonthDayData) && (this.props.componentMonthDayData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (month-day) updated");
            }
            if (this.state.componentTabIndex === 1) {
                this.updateComponentMonthChart(false, false, false);
            }
        }
        // component year data was updated
        if ((this.props.componentYearMonthData !== prevProps.componentYearMonthData) && (this.props.componentYearMonthData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (year-month) updated");
            }
            if (this.state.componentTabIndex === 2) {
                this.updateComponentYearChart(false, false, false);
            }
        }
        // component years data was updated
        if ((this.props.componentYearsData !== prevProps.componentYearsData) && (this.props.componentYearsData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (years) updated");
            }
            if (this.state.componentTabIndex === 3) {
                this.updateComponentYearComparisonChart(false, false, false);
            }
        }
        // local components changed
        if (this.state.fetchComponents !== prevState.fetchComponents) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Battery components changed");
            }
            this.setState({
                isRealBattery: (this.state.fetchComponents.length > PROD_COMPONENTS.length + 1)
            });
            // update ALL charts
            this.updateComponentDayChart(false, true, true);
            this.updateComponentMonthChart(false, true, true);
            this.updateComponentYearChart(false, true, true);
            this.updateComponentYearComparisonChart(false, true, true);
        }
        // component tab changed
        if (this.state.componentTabIndex !== prevState.componentTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data tab changed to " + this.state.componentTabIndex);
            }
            // update the visible chart
            this.updateVisibleComponentChart(false, true, false);
        }
        // selected date of component day graph has changed
        if ((this.state.componentSelectedDay !== prevState.componentSelectedDay) && (this.state.componentSelectedDay !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component day changed to " + moment(this.state.componentSelectedDay).format('YYYY-MM-DD'));
            }
            this.updateComponentDayChart(true, true, false);
        }
        // selected date of component month graph has changed
        if ((this.state.componentSelectedMonth !== prevState.componentSelectedMonth) && (this.state.componentSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component month changed to " + moment(this.state.componentSelectedMonth).format('YYYY-MM'));
            }
            this.updateComponentMonthChart(true, true, false);
        }
        // selected date of component year graph has changed
        if ((this.state.componentSelectedYear !== prevState.componentSelectedYear) && (this.state.componentSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component day changed to " + moment(this.state.componentSelectedYear).format('YYYY'));
            }
            this.updateComponentYearChart(true, true, false);
        }
    }

    resetState() {
        // keep selected dates, tab indexes etc.
        const compDay = this.state.componentSelectedDay;
        const compMonth = this.state.componentSelectedMonth;
        const compYear = this.state.componentSelectedYear;
        const compYears = this.state.componentSelectedYears;
        const yLabels = getYAxisScaleLabels('Batterie');
        const fetchComponents = PROD_COMPONENTS.concat(filterMeasuredComponentsByTypes(this.props.components, ['Batterie','ProduktionNetto']));
        this.setState({
            error: null,
            fetchComponents: fetchComponents,
            isRealBattery: (fetchComponents.length > PROD_COMPONENTS.length + 1),
            componentDayOptions: createLineChartOptions(compDay, false, yLabels[0], this.onComponentLegendClick),
            componentDayData: { datasets: createDataSetsForLineChart(BAT_COMPONENTS, false, true, true) },
            componentMonthOptions: createBarChartOptions('MonthDays', compMonth, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentMonthData: { datasets: createDataSetsForBarChart(BAT_COMPONENTS) },
            componentYearOptions: createBarChartOptions('YearMonths', compYear, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearData: { datasets: createDataSetsForBarChart(BAT_COMPONENTS) },
            componentYearComparisonOptions: createBarChartOptions('Years', compYears, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearComparisonData: { datasets: createDataSetsForBarChart(BAT_COMPONENTS) }
        });
    }

    refreshAllData(force = false) {
        // TODO incremental data update, if the latest time period contains partial data

        let tasks = [];
        this.setState({ isRefreshing: true, error: null });
        if (this.props.installationList.length === 0) {
            // load all installations
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation list refresh");
            }
            tasks.push(this.props.doFetchAllInstallations());
        }
        if ((this.props.components === undefined) || (this.props.components.length === 0)) {
            // load all components
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component list refresh");
            }
            tasks.push(this.props.doFetchAllComponents(this.props.installationId));
        }

        // always update actual values
        tasks.push(this.props.doFetchAllComponentsActualData(this.props.installationId));

        // component data - fetch only for visible tab, because tab change will initiate fetch anyway
        if (this.state.componentTabIndex === 0) {
            this.state.fetchComponents.forEach((comp) => {
                if ((this.props.componentDayTimeData === undefined || this.props.componentDayTimeData[comp.id] === undefined || this.props.componentDayTimeData[comp.id][this.state.componentSelectedDay] === undefined) || (moment().format('YYYY-MM-DD') === this.state.componentSelectedDay) || force) {
                    tasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, comp.id, this.state.componentSelectedDay));
                }
            });
        }
        if (this.state.componentTabIndex === 1) {
            this.state.fetchComponents.forEach((comp) => {
                if ((this.props.componentMonthDayData === undefined || this.props.componentMonthDayData[comp.id] === undefined || this.props.componentMonthDayData[comp.id][this.state.componentSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.componentSelectedMonth) || force) {
                    tasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, comp.id, this.state.componentSelectedMonth));
                }
            });
        }
        if (this.state.componentTabIndex === 2) {
            this.state.fetchComponents.forEach((comp) => {
                if ((this.props.componentYearMonthData === undefined || this.props.componentYearMonthData[comp.id] === undefined || this.props.componentYearMonthData[comp.id][this.state.componentSelectedYear] === undefined)|| (moment().startOf('year').format('YYYY-MM-DD') === this.state.componentSelectedYear) || force) {
                    tasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, comp.id, this.state.componentSelectedYear));
                }
            });
        }
        if (this.state.componentTabIndex === 3) {
            this.state.fetchComponents.forEach((comp) => {
                tasks.push(this.props.doFetchComponentYearsData(this.props.installationId, comp.id, this.state.componentSelectedYears, AppConfig.yearsToCompare));
            });
        }

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks)
                .then((results) => {
                    if (!this.mounted) return;
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });

                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | ')
                    });
                });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // reload
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    updateComponentDayChart(axes, fetch, createsets) {
        const day = this.state.componentSelectedDay;
        const fetchComponents = this.state.fetchComponents;
        const fetchDataSets = [];
        const oldDataSets = this.state.componentDayData.datasets;
        const newDataSets = (createsets) ? createDataSetsForLineChart(BAT_COMPONENTS, false, true, true) : [...oldDataSets];

        if (fetchComponents.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < fetchComponents.length; i++) {
                if ((this.props.componentDayTimeData !== undefined) && (this.props.componentDayTimeData[fetchComponents[i].id] !== undefined) && (this.props.componentDayTimeData[fetchComponents[i].id][day] !== undefined)) {
                    fetchDataSets.push(convertComponentContinuousData(this.props.componentDayTimeData[fetchComponents[i].id][day], day));
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + fetchComponents[i].id + ": day data of " + day + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, fetchComponents[i].id, day));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (fetchDataSets.length === fetchComponents.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentDayOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].ticks.min = moment(day).startOf('day');
                newOptions.scales.xAxes[0].ticks.max = moment(day).add(1, 'days').startOf('day');
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('DayTime', day);
                newState.componentDayOptions = newOptions;
            }
            newDataSets.forEach(set => {
                set.data = [];
            });
            if (fetchDataSets.length === fetchComponents.length) {
                // compute data sets
                if (fetchComponents.length > PROD_COMPONENTS.length + 1) {
                    // use battery components
                    fetchDataSets[PROD_COMPONENTS.length].forEach(item => {
                        if (!isNaN(item.y)) {
                            item.y = -item.y;
                        }
                    });
                    newDataSets[0].data = fetchDataSets[PROD_COMPONENTS.length];
                    newDataSets[1].data = fetchDataSets[PROD_COMPONENTS.length + 1];
                } else if (fetchComponents.length > PROD_COMPONENTS.length) {
                    // use net production
                    const dischargeData = [];
                    const chargeData = [];
                    const n = (fetchDataSets[0].length >= fetchDataSets[PROD_COMPONENTS.length].length) ? fetchDataSets[PROD_COMPONENTS.length].length : fetchDataSets[0].length;
                    for (let i = 0; i < n; i++) {
                        let yVal = (isNaN(fetchDataSets[0][i].y) || isNaN(fetchDataSets[PROD_COMPONENTS.length][i].y)) ? 0.0 : fetchDataSets[0][i].y - fetchDataSets[PROD_COMPONENTS.length][i].y;
                        dischargeData.push({
                            x: fetchDataSets[0][i].x,
                            y: (yVal < 0.0) ? yVal : 0.0
                        });
                        chargeData.push({
                            x: fetchDataSets[0][i].x,
                            y: (yVal > 0.0) ? yVal : 0.0
                        });
                    }

                    newDataSets[0].data = dischargeData;
                    newDataSets[1].data = chargeData;
                }
            }

            newState.componentDayData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentMonthChart(axes, fetch, createsets) {
        const month = this.state.componentSelectedMonth;
        const fetchComponents = this.state.fetchComponents;
        const fetchDataSets = [];
        const oldDataSets = this.state.componentMonthData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(BAT_COMPONENTS) : [...oldDataSets];

        if (fetchComponents.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < fetchComponents.length; i++) {
                if ((this.props.componentMonthDayData !== undefined) && (this.props.componentMonthDayData[fetchComponents[i].id] !== undefined) && (this.props.componentMonthDayData[fetchComponents[i].id][month] !== undefined)) {
                    fetchDataSets.push(convertComponentStatisticData(this.props.componentMonthDayData[fetchComponents[i].id][month], 'MonthDays', month));
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + fetchComponents[i].id + ":month data of " + moment(month).format('YYYY-MM') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, fetchComponents[i].id, month));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (fetchDataSets.length === fetchComponents.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newState.componentMonthOptions = newOptions;
            }
            newDataSets.forEach(set => {
                set.data = [];
            });
            if (fetchDataSets.length === fetchComponents.length) {
                // compute data sets
                if (fetchComponents.length > PROD_COMPONENTS.length + 1) {
                    // use battery components
                    fetchDataSets[PROD_COMPONENTS.length].forEach((value, index) => {
                        if (!isNaN(value)) {
                            fetchDataSets[PROD_COMPONENTS.length][index] = -value;
                        }
                    });
                    newDataSets[0].data = fetchDataSets[PROD_COMPONENTS.length];
                    newDataSets[1].data = fetchDataSets[PROD_COMPONENTS.length + 1];
                } else if (fetchComponents.length > PROD_COMPONENTS.length) {
                    // use net production
                    const dischargeData = [];
                    const chargeData = [];
                    const n = (fetchDataSets[0].length >= fetchDataSets[PROD_COMPONENTS.length].length) ? fetchDataSets[PROD_COMPONENTS.length].length : fetchDataSets[0].length;
                    for (let i = 0; i < n; i++) {
                        let yVal = (isNaN(fetchDataSets[0][i]) || isNaN(fetchDataSets[PROD_COMPONENTS.length][i])) ? 0.0 : fetchDataSets[0][i] - fetchDataSets[PROD_COMPONENTS.length][i];
                        dischargeData.push((yVal < 0.0) ? yVal : 0.0);
                        chargeData.push((yVal > 0.0) ? yVal : 0.0);
                    }

                    newDataSets[0].data = dischargeData;
                    newDataSets[1].data = chargeData;
                }
            }
            newState.componentMonthData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentYearChart(axes, fetch, createsets) {
        const year = this.state.componentSelectedYear;
        const fetchComponents = this.state.fetchComponents;
        const fetchDataSets = [];
        const oldDataSets = this.state.componentYearData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(BAT_COMPONENTS) : [...oldDataSets];

        if (fetchComponents.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < fetchComponents.length; i++) {
                if ((this.props.componentYearMonthData !== undefined) && (this.props.componentYearMonthData[fetchComponents[i].id] !== undefined) && (this.props.componentYearMonthData[fetchComponents[i].id][year] !== undefined)) {
                    fetchDataSets.push(convertComponentStatisticData(this.props.componentYearMonthData[fetchComponents[i].id][year], 'YearMonths', year));
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + fetchComponents[i].id + ": year data of " + moment(year).format('YYYY') + " not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, fetchComponents[i].id, year));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (fetchDataSets.length === fetchComponents.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newState.componentYearOptions = newOptions;
            }
            newDataSets.forEach(set => {
                set.data = [];
            });
            if (fetchDataSets.length === fetchComponents.length) {
                // compute data sets
                if (fetchComponents.length > PROD_COMPONENTS.length + 1) {
                    // use battery components
                    fetchDataSets[PROD_COMPONENTS.length].forEach((value, index) => {
                        if (!isNaN(value)) {
                            fetchDataSets[PROD_COMPONENTS.length][index] = -value;
                        }
                    });
                    newDataSets[0].data = fetchDataSets[PROD_COMPONENTS.length];
                    newDataSets[1].data = fetchDataSets[PROD_COMPONENTS.length + 1];
                } else if (fetchComponents.length > PROD_COMPONENTS.length) {
                    // use net production
                    const dischargeData = [];
                    const chargeData = [];
                    const n = (fetchDataSets[0].length >= fetchDataSets[PROD_COMPONENTS.length].length) ? fetchDataSets[PROD_COMPONENTS.length].length : fetchDataSets[0].length;
                    for (let i = 0; i < n; i++) {
                        let yVal = (isNaN(fetchDataSets[0][i]) || isNaN(fetchDataSets[PROD_COMPONENTS.length][i])) ? 0.0 : fetchDataSets[0][i] - fetchDataSets[PROD_COMPONENTS.length][i];
                        dischargeData.push((yVal < 0.0) ? yVal : 0.0);
                        chargeData.push((yVal > 0.0) ? yVal : 0.0);
                    }

                    newDataSets[0].data = dischargeData;
                    newDataSets[1].data = chargeData;
                }
            }
            newState.componentYearData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.componentSelectedYears;
        const fetchComponents = this.state.fetchComponents;
        const fetchDataSets = [];
        const oldDataSets = this.state.componentYearComparisonData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(BAT_COMPONENTS) : [...oldDataSets];

        if (fetchComponents.length > 0) {
            let fetchTasks = [];
            let firstFetch = true;
            for (let i = 0; i < fetchComponents.length; i++) {
                if ((this.props.componentYearsData !== undefined) && (this.props.componentYearsData[fetchComponents[i].id] !== undefined)) {
                    fetchDataSets.push(convertComponentStatisticData(this.props.componentYearsData[fetchComponents[i].id], 'Years', years, AppConfig.yearsToCompare));
                } else if (fetch) {
                    // data must be fetched first
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Component " + fetchComponents[i].id + ": years data not found, require fetch");
                    }
                    if (firstFetch) {
                        // once
                        this.setState({ isRefreshing: true });
                        firstFetch = false;
                    }
                    fetchTasks.push(this.props.doFetchComponentYearsData(this.props.installationId, fetchComponents[i].id, years, AppConfig.yearsToCompare));
                }
            }

            if (fetchTasks.length > 0) {
                if (this.props.isAuthenticated === true) {
                    if (this.props.isExpired === false) {
                        // execute fetch requests
                        Promise.all(fetchTasks).then((results) => {
                            if (!this.mounted) return;
                            // check results
                            let errors = [];
                            results.forEach((result) => {
                                if (result.success !== true) {
                                    errors.push(result.message);
                                }
                            });
                            this.setState({
                                isRefreshing: false,
                                error: (errors.length === 0) ? null : errors.join(' | ')
                            });
                        });
                    } else {
                        this.setState({ error: errorStrings.tokenExpired });
                    }
                } else {
                    // error
                    this.setState({ error: errorStrings.notAuthenticated });
                }
            }
        }

        // finally update state - if all data sets present
        if (axes || (fetchDataSets.length === fetchComponents.length)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newState.componentYearComparisonOptions = newOptions;
            }
            newDataSets.forEach(set => {
                set.data = [];
            });
            if (fetchDataSets.length === fetchComponents.length) {
                // compute data sets
                if (fetchComponents.length > PROD_COMPONENTS.length + 1) {
                    // use battery components
                    fetchDataSets[PROD_COMPONENTS.length].forEach((value, index) => {
                        if (!isNaN(value)) {
                            fetchDataSets[PROD_COMPONENTS.length][index] = -value;
                        }
                    });
                    newDataSets[0].data = fetchDataSets[PROD_COMPONENTS.length];
                    newDataSets[1].data = fetchDataSets[PROD_COMPONENTS.length + 1];
                } else if (fetchComponents.length > PROD_COMPONENTS.length) {
                    // use net production
                    const dischargeData = [];
                    const chargeData = [];
                    const n = (fetchDataSets[0].length >= fetchDataSets[PROD_COMPONENTS.length].length) ? fetchDataSets[PROD_COMPONENTS.length].length : fetchDataSets[0].length;
                    for (let i = 0; i < n; i++) {
                        let yVal = (isNaN(fetchDataSets[0][i]) || isNaN(fetchDataSets[PROD_COMPONENTS.length][i])) ? 0.0 : fetchDataSets[0][i] - fetchDataSets[PROD_COMPONENTS.length][i];
                        dischargeData.push((yVal < 0.0) ? yVal : 0.0);
                        chargeData.push((yVal > 0.0) ? yVal : 0.0);
                    }

                    newDataSets[0].data = dischargeData;
                    newDataSets[1].data = chargeData;
                }
            }
            newState.componentYearComparisonData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateVisibleComponentChart(axes, fetch, createsets) {
        switch (this.state.componentTabIndex) {
            case 0: {
                this.updateComponentDayChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateComponentMonthChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateComponentYearChart(axes, fetch, createsets);
                break;
            }
            case 3: {
                this.updateComponentYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    onViewLoaded() {
        // update installation and fetch components
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);
                // update visible charts
                this.updateVisibleComponentChart(true, false, true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    onComponentDayChanged(date) {
        const day = moment(date).startOf('day').format('YYYY-MM-DD');
        this.setState({ componentSelectedDay: day });
    }

    onComponentMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ componentSelectedMonth: month });
    }

    onComponentYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ componentSelectedYear: year });
    }

    onComponentLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.componentTabIndex) {
            case 0: {
                const sets = this.state.componentDayData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentDayData: { datasets: newSets } });
                }
                break;
            }
            case 1: {
                const sets = this.state.componentMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentMonthData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.componentYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentYearData: { datasets: newSets } });
                }
                break;
            }
            case 3: {
                const sets = this.state.componentYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.installationOverview, enabled: true, active: false, icon: "fas fa-building", path: "/installations/" + this.props.installationId + "/overview" },
            { name: headerStrings.photovoltaics, enabled: true, active: false, icon: "fas fa-solar-panel", path: "/installations/" + this.props.installationId + "/pv" },
            { name: headerStrings.battery, enabled: false, active: true, icon: "fas fa-car-battery", path: "/installations/" + this.props.installationId + "/battery" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <Panel header={headerStrings.battery + " – " + installationStrings.actualValues}>
                        {(this.state.fetchComponents.length > PROD_COMPONENTS.length) ? this.renderActualValues() : (<div>{componentStrings.noElectricComponents}</div>)}
                    </Panel>
                    <div style={{ margin: 20 }}></div>
                    <Panel header={headerStrings.battery + " – " + installationStrings.statistics}>
                        {(this.state.fetchComponents.length > PROD_COMPONENTS.length) ? (
                            <TabView activeIndex={this.state.componentTabIndex} onTabChange={(e) => this.setState({ componentTabIndex: e.index })} renderActiveOnly={true}>
                                <TabPanel header={graphStrings.day}>
                                    {/* Time of day */}
                                    <DateGraph term="DayTime" date={this.state.componentSelectedDay} onDateChanged={this.onComponentDayChanged} chartType="line" chartData={this.state.componentDayData} chartOptions={this.state.componentDayOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.month} disabled={this.state.isRealBattery === false}>
                                    {/* All days of a month */}
                                    <DateGraph term="MonthDays" date={this.state.componentSelectedMonth} onDateChanged={this.onComponentMonthChanged} chartType="bar" chartData={this.state.componentMonthData} chartOptions={this.state.componentMonthOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.year} disabled={this.state.isRealBattery === false}>
                                    {/* All months of a year */}
                                    <DateGraph term="YearMonths" date={this.state.componentSelectedYear} onDateChanged={this.onComponentYearChanged} chartType="bar" chartData={this.state.componentYearData} chartOptions={this.state.componentYearOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years} disabled={this.state.isRealBattery === false}>
                                    {/* Compare last X years */}
                                    <DateGraph term="Years" date={this.state.componentSelectedYears} chartType="bar" chartData={this.state.componentYearComparisonData} chartOptions={this.state.componentYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                            </TabView>
                        ) : (
                            <div>{componentStrings.noElectricComponents}</div>
                        )}
                    </Panel>
                </div>
                <Footer />
            </div>
        );
    }

    renderActualValues() {
        const discharge = BAT_COMPONENTS[0];
        const charge = BAT_COMPONENTS[1];

        let chargeValue = 0.0;
        let dischargeValue = 0.0;
        if (this.state.fetchComponents.length > PROD_COMPONENTS.length + 1) {
            // use battery components
            const dischargeId = this.state.fetchComponents[PROD_COMPONENTS.length].id;
            const chargeId = this.state.fetchComponents[PROD_COMPONENTS.length + 1].id;
            dischargeValue = ((this.props.componentActualData !== undefined) && (this.props.componentActualData[dischargeId] !== undefined) && moment(this.props.componentActualData[dischargeId].dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.componentActualData[dischargeId].value : undefined;
            chargeValue = ((this.props.componentActualData !== undefined) && (this.props.componentActualData[chargeId] !== undefined) && moment(this.props.componentActualData[chargeId].dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.componentActualData[chargeId].value : undefined;
        } else if (this.state.fetchComponents.length > PROD_COMPONENTS.length) {
            // use net production
            const netId = this.state.fetchComponents[PROD_COMPONENTS.length].id;
            const prodTotId = PROD_COMPONENTS[0].id;
            const netValue = ((this.props.componentActualData !== undefined) && (this.props.componentActualData[netId] !== undefined) && moment(this.props.componentActualData[netId].dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.componentActualData[netId].value : 0.0;
            const prodTotValue = ((this.props.componentActualData !== undefined) && (this.props.componentActualData[prodTotId] !== undefined) && moment(this.props.componentActualData[prodTotId].dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.componentActualData[prodTotId].value : 0.0;
            if (netValue > 0) {
                if (netValue > prodTotValue) {
                    dischargeValue = netValue - prodTotValue;
                } else {
                    chargeValue = prodTotValue - netValue;
                }
            }
        }

        return (
            <div className="p-grid p-justify-start">
                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2" key={discharge.name}>
                    <ActualValue name={discharge.name} color={discharge.color} value={dischargeValue} unit={getMeasurementUnits(discharge.type)[0]} decimals={3} icon="fas fa-battery-half" />
                </div>
                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2" key={charge.name}>
                    <ActualValue name={charge.name} color={charge.color} value={chargeValue} unit={getMeasurementUnits(charge.type)[0]} decimals={3} icon="fas fa-bolt" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const installationId = ownProps.match.params.instId;    // might not be integer!
    const unitId = ownProps.match.params.unitId;            // might not be integer!
    return {
        installationId,
        unitId,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installationList: InstallationSelectors.selectAll(state),
        selectedInstallation: InstallationSelectors.selectOne(state, installationId),
        components: ComponentSelectors.selectAll(state, installationId),
        componentActualData: ComponentActualSelectors.selectAllOfInstallation(state, installationId),
        componentDayTimeData: ComponentDayTimeSelectors.selectAllOfInstallation(state, installationId),
        componentMonthDayData: ComponentMonthDaySelectors.selectAllOfInstallation(state, installationId),
        componentYearMonthData: ComponentYearMonthSelectors.selectAllOfInstallation(state, installationId),
        componentYearsData: ComponentYearsSelectors.selectAllOfInstallation(state, installationId)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchAllInstallations: () => dispatch(fetchAllInstallations()),
        doFetchAllComponents: (instId) => dispatch(fetchAllComponents(instId)),
        doFetchAllComponentsActualData: (instId) => dispatch(fetchAllComponentsActualData(instId)),
        doFetchComponentDayTimeData: (instId, compId, date) => dispatch(fetchComponentDayTimeData(instId, compId, date)),
        doFetchComponentMonthDayData: (instId, compId, date) => dispatch(fetchComponentMonthDayData(instId, compId, date)),
        doFetchComponentYearMonthData: (instId, compId, date) => dispatch(fetchComponentYearMonthData(instId, compId, date)),
        doFetchComponentYearsData: (instId, compId, date, nYears) => dispatch(fetchComponentYearsData(instId, compId, date, nYears))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallationBatteryView);