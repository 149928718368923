import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import moment from 'moment';
import ActualValue from './ActualValue';
import { installationStrings } from '../i18n/translations';

/**
 * An installation information panel.
 */
class BuildingUnitInfo extends Component {

    render() {
        if (this.props.buildingUnit !== undefined) {

            let actualPrice;
            let actualPriceColor;
            let actualCoverRatio;
            let actualSelfConsumption;
            if ((this.props.figures !== undefined) && moment(this.props.figures.dateTime).isAfter(moment().subtract(1, 'hours'))) {
                actualPrice = this.props.figures.price;
                actualPriceColor = this.props.figures.priceColor;
                actualCoverRatio = this.props.figures.coverRatio * 100;
                actualSelfConsumption = this.props.figures.selfConsumption * 100;
            }

            return (
                <Panel header={installationStrings.unitInfo + " – " + this.props.buildingUnit.name}>
                    {(this.props.figures !== undefined) ? (
                        <div className="p-grid p-justify-start">
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                                <ActualValue name={installationStrings.electricTariff} color={actualPriceColor} value={actualPrice} unit="CHF/kWh" decimals={2} icon="fas fa-piggy-bank" />
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                                <ActualValue name={installationStrings.solarCoverage} color="#a07030" value={actualCoverRatio} unit="%" decimals={1} icon="fas fa-cloud-sun" />
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                                <ActualValue name={installationStrings.selfConsumption} color="#2860c0" value={actualSelfConsumption} unit="%" decimals={1} icon="fas fa-chart-pie" />
                            </div>
                        </div>
                    ) : (installationStrings.noFigures)}
                </Panel>
            );
        } else {
            return (<Panel header={installationStrings.unitInfo}>{installationStrings.noUnit}</Panel> );
        }
    }
}

export default BuildingUnitInfo;