import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AuthSelectors } from '../redux/AuthRedux';
import { connectUser } from '../actions/ManagementActions';
import { authenticationLogin } from '../actions/AuthActions.js';
import { errorStrings, headerStrings, mgmtStrings } from '../i18n/translations';

/**
 * The user connect page.
 */
class ConnectView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            isLoading: false,
            message: null,
            codeToSend: (props.code !== undefined) ? props.code : "",
            codeConfirmed: false
        };
        // This binding is necessary to make `this` work in the callback
        this.onViewLoaded = this.onViewLoaded.bind(this);
        this.handleCodeSubmit = this.handleCodeSubmit.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onAbortClick = this.onAbortClick.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Connect view did mount.");
        }
        this.onViewLoaded();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.mounted) return;
        let nextState = {};
        let updateState = false;
        // changed URL parameters while view is still active
        if (this.props.code !== prevProps.code) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Code in URL changed");
            }
            nextState.codeToSend = (this.props.code !== undefined) ? this.props.code : "";
            nextState.codeConfirmed = false;
            updateState = true;
        }
        // authentication changed to true
        if ((this.props.isAuthenticated !== prevProps.isAuthenticated) && (this.props.isAuthenticated === true)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
        }
        // code to send changed
        if ((this.state.codeToSend !== prevState.codeToSend) || (this.state.codeConfirmed !== prevState.codeConfirmed)) {
            this.onViewLoaded();
        }
        if (updateState) {
            this.setState(nextState);
        }
    }

    onViewLoaded() {
        this.setState({ message: null });
        if ((this.state.codeToSend !== "") && this.state.codeConfirmed) {
            // send
            this.sendCode();
        }
    }

    handleCodeSubmit(values, resetForm) {
        // check input
        if (!values.code || (values.code === "")) {
            this.setState({ message: { severity: 'error', text: errorStrings.codeEmpty }});
            return;
        }
        // skip confirmation when code entered manually
        this.setState({ codeToSend: values.code, codeConfirmed: true });
    }

    handleLoginSubmit(values, resetForm) {
        this.setState({ isLoading: true, message: null });
        this.props.doLogin(values.email, values.password)
            .then((result) => {
                if (!this.mounted) return;
                this.setState({
                    isLoading: false,
                    message: {
                        severity: (result.success === true) ? 'success' : 'error',
                        text: result.message
                    }
                });
                if (result.success === true) {
                    // clear form
                    Object.keys(values).forEach(key => (values[key] = ''));
                    resetForm(values);
                }
            });
    }

    validateCodeForm(values) {
        let errors = {};
        if (!values.code) {
            errors.code = errorStrings.codeEmpty;
        } else if (!/^[a-fA-F0-9-]+$/i.test(values.code)) {
            // test for invalid characters
            errors.code = errorStrings.invalidCharacters;
        }
        return errors;
    }

    validateLoginForm(values) {
        let errors = {};
        if (!values.email) {
            errors.email = errorStrings.emailEmpty;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            // test for invalid characters
            errors.email = errorStrings.invalidCharacters;
        }
        if (!values.password) {
            errors.password = errorStrings.passwordEmpty;
        }
        return errors;
    }

    onConfirmClick() {
        this.setState({ codeConfirmed: true });
    }

    onAbortClick() {
        this.setState({ codeConfirmed: false, codeToSend: "" });
    }

    sendCode() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                this.setState({ isLoading: true, message: null });
                this.props.doConnect(this.state.codeToSend)
                    .then((result) => {
                        if (!this.mounted) return;
                        if (result.success === true) {
                            // clear input fields
                            this.setState({ isLoading: false, message: { severity: 'success', text: result.message }, codeToSend: "", codeConfirmed: false });
                            // redirect
                            setTimeout(() => {
                                // go to installation list
                                window.location.hash = "#/installations";
                            }, 1000);
                        } else {
                            this.setState({ isLoading: false, message: { severity: 'error', text: result.message }, codeToSend: "", codeConfirmed: false });
                        }
                    });
            } else {
                this.setState({ message: { severity: 'error', text: errorStrings.tokenExpired }, codeToSend: "", codeConfirmed: false });
            }
        } else {
            this.setState({ message: { severity: 'error', text: errorStrings.notAuthenticated }, codeToSend: "", codeConfirmed: false });
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: this.props.isAuthenticated, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.enterCode, enabled: this.props.isAuthenticated, active: true, icon: "fas fa-key", path: "/connect" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isLoading} />
                <div className="page-content">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-sm-8 p-md-6">
                            {(this.props.isAuthenticated) ? (
                                (this.state.codeToSend !== "") ? (
                                    (this.state.codeConfirmed) ? (
                                        <Panel header={mgmtStrings.linking}>
                                            {(this.state.message != null) && (
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <Message severity={this.state.message.severity} text={this.state.message.text} />
                                                </div>
                                            )}
                                        </Panel>
                                    ) : (
                                        <Panel header={mgmtStrings.confirm}>
                                            <div>{mgmtStrings.linkAccountQ}</div>
                                            <div><Button className="p-button-success" label={mgmtStrings.ok} onClick={this.onConfirmClick} />&nbsp;&nbsp;<Button className="p-button-danger" label={mgmtStrings.abort} onClick={this.onAbortClick} /></div>
                                        </Panel>
                                    )
                                ) : (
                                    <Panel header={headerStrings.enterCode}>
                                        <Formik
                                            initialValues={{ code: "" }}
                                            validate={this.validateCodeForm}
                                            onSubmit={(values, {resetForm}) => this.handleCodeSubmit(values, resetForm)}>
                                            <Form name="codeForm" autoComplete="off">
                                                <div className="p-grid">
                                                    <div className="p-col-12" style={{ padding: '1em' }}>
                                                        <span className="p-float-label">
                                                            <Field id="codeIn" type="text" name="code" size="28" className="p-inputtext" />
                                                            <label htmlFor="codeIn">{mgmtStrings.code}</label>
                                                        </span>
                                                        <ErrorMessage name="code" render={msg => <Message severity='warn' text={msg} />} />
                                                    </div>
                                                    <div className="p-col-12" style={{ padding: '1em' }}>
                                                        {mgmtStrings.connectCode}
                                                    </div>
                                                    <div className="p-col-6" style={{ padding: '1em' }}>
                                                        <button type="submit" className="p-button" style={{ padding: 8 }}>{mgmtStrings.connect}</button>
                                                        &nbsp;<Link to="/"><Button className="p-button-secondary" label={headerStrings.goBack} /></Link>
                                                    </div>
                                                    {(this.state.message != null) && (
                                                        <div className="p-col-12" style={{ padding: '1em' }}>
                                                            <Message severity={this.state.message.severity} text={this.state.message.text} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Form>
                                        </Formik>
                                    </Panel>
                                )
                            ) : (
                                <Panel header={headerStrings.login}>
                                    <Formik
                                        initialValues={{ email: "", password: "" }}
                                        validate={this.validateLoginForm}
                                        onSubmit={(values, {resetForm}) => this.handleLoginSubmit(values, resetForm)}>
                                        <Form autoComplete="on">
                                            <div className="p-grid">
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <span className="p-float-label">
                                                        <Field id="emailIn" type="email" name="email" size="28" className="p-inputtext" />
                                                        <label htmlFor="emailIn">{mgmtStrings.eMail}</label>
                                                    </span>
                                                    <ErrorMessage name="email" render={msg => <Message severity='warn' text={msg} />} />
                                                </div>
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <span className="p-float-label">
                                                        <Field id="passwordIn" type="password" name="password" size="28" className="p-inputtext" />
                                                        <label htmlFor="passwordIn">{mgmtStrings.password}</label>
                                                    </span>
                                                    <ErrorMessage name="password" render={msg => <Message severity='warn' text={msg} />} />
                                                </div>
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <button type="submit" className="p-button" style={{ padding: 8 }}>{headerStrings.login}</button>
                                                    &nbsp;<Link to="/"><Button className="p-button-secondary" label={headerStrings.goBack} /></Link>
                                                </div>
                                                {(this.state.message != null) && (
                                                    <div className="p-col-12" style={{ padding: '1em' }}>
                                                        <Message severity={this.state.message.severity} text={this.state.message.text} />
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                    </Formik>
                                </Panel>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const code = ownProps.match.params.code;    // might be undefined!
    return {
        code,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doConnect: (code) => dispatch(connectUser(code)),
        doLogin: (email, password) => dispatch(authenticationLogin(email, password))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectView);