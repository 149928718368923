import React, { Component } from 'react';
import { version } from '../../../package.json';   // display application version

/**
 * The page footer.
 */
class Footer extends Component {

    render() {
        return (
            <div className="p-grid p-justify-center page-footer">
                <div className="p-col-12 p-xl-8 p-lg-12 p-md-12">
                    <div style={{ fontSize: 12, textAlign: "center" }}>Smart Energy Engineering Web Portal v{version}</div>
                </div>
            </div>
        )
    }
}

export default Footer;