import moment from 'moment';
import { initAuthReduxWithToken } from '../util/AuthUtils';
import BuildConfig from '../config/BuildConfig';

/**
 * Authentication reducer. Contains credentials and user info.
 */

/* ------------- Types ------------- */

// types
export const AuthTypes = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAILURE: 'AUTH_FAILURE',
  AUTH_CLEAR: 'AUTH_CLEAR'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const AuthSelectors = {
  selectAll: (state) => state.auth,
  selectAccessToken: (state) => state.auth.accessToken,
  selectIdToken: (state) => state.auth.idToken,
  selectTokenType: (state) => state.auth.tokenType,
  selectExpiresAt: (state) => state.auth.expiresAt,
  selectUserName: (state) => state.auth.userName,
  selectError: (state) => state.auth.authError,
  isAuthenticating: (state) => state.auth.authenticating,
  isAuthenticated: (state) => ((BuildConfig.isAuthenticationEnabled === false) || (state.auth.accessToken != null)),
  isExpired: (state) => ((BuildConfig.isAuthenticationEnabled === true) && ((state.auth.expiresAt == null) || moment(state.auth.expiresAt).isBefore(moment())))
};

/* ------------- Initial state ------------- */

const initialState = {
  accessToken: null,
  idToken: null,
  tokenType: null,
  expiresAt: null,
  userName: (BuildConfig.isAuthenticationEnabled === false) ? 'Debug User' : null,
  authError: null,
  authenticating: false
};

/* ------------- Reducer Function ------------- */

function onAuthRequest(state) {
  return { ...state, authError: null, authenticating: true };
}

function onAuthSuccess(state, payload) {
  const { accessToken, idToken, tokenType, expiresAt, userName } = payload;
  return {
    ...state,
    accessToken: accessToken,
    idToken: idToken,
    tokenType: tokenType,
    expiresAt: expiresAt,
    userName: userName,
    authError: null,
    authenticating: false
  };
}

function onAuthFailure(state, payload) {
  const { error } = payload;
  return { ...state, authError: error, authenticating: false };
}

/**
 * Gets the next reducer state based on a given state and an action.
 * Directly loads OAuth token if cookie is present, so that authentication is available upon startup
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initAuthReduxWithToken(initialState), action) => {
  switch (action.type) {
    case AuthTypes.AUTH_REQUEST: {
      return onAuthRequest(state);
    }
    case AuthTypes.AUTH_SUCCESS: {
      return onAuthSuccess(state, action.payload);
    }
    case AuthTypes.AUTH_FAILURE: {
      return onAuthFailure(state, action.payload);
    }
    case AuthTypes.AUTH_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;