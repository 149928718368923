import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DateGraph from '../components/DateGraph';
import ComponentInfo from '../components/ComponentInfo';
import ControlSignalButton from '../components/ControlSignalButton';
import { fetchAllInstallations } from '../actions/InstallationActions';
import { fetchAllComponents, fetchComponent } from '../actions/ComponentActions';
import { fetchComponentDayTimeData, fetchComponentMonthDayData, fetchComponentYearMonthData, fetchComponentYearsData, fetchComponentActualData } from '../actions/ComponentDataActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { ComponentSelectors } from '../redux/ComponentRedux';
import { ComponentDayTimeSelectors } from '../redux/ComponentDayTimeDataRedux';
import { ComponentMonthDaySelectors } from '../redux/ComponentMonthDayDataRedux';
import { ComponentYearMonthSelectors } from '../redux/ComponentYearMonthDataRedux';
import { ComponentYearsSelectors } from '../redux/ComponentYearsDataRedux';
import { ComponentActualSelectors } from '../redux/ComponentActualDataRedux';
import { ComponentControlSelectors } from '../redux/ComponentControlRedux';
import { createBarChartOptions, createLineChartOptions, getXAxisLabels, getXAxisScaleLabel, getYAxisScaleLabels, convertComponentStatisticData, convertComponentContinuousData, createDataSetsForLineChart, createDataSetsForBarChart } from '../components/util/GraphUtils';
import { filterControlSignals } from '../components/util/ComponentUtils';
import { fetchComponentControls } from '../actions/ComponentControlActions';
import { headerStrings, graphStrings, installationStrings, errorStrings } from '../i18n/translations';

/**
 * The component overview page.
 * Displays information about the given component.
 */
class ComponentOverviewView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // methods requiring access to 'this'
        this.resetState = this.resetState.bind(this);
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);
        this.onComponentDayChanged = this.onComponentDayChanged.bind(this);
        this.onComponentMonthChanged = this.onComponentMonthChanged.bind(this);
        this.onComponentYearChanged = this.onComponentYearChanged.bind(this);
        this.updateComponentDayChart = this.updateComponentDayChart.bind(this);
        this.updateComponentMonthChart = this.updateComponentMonthChart.bind(this);
        this.updateComponentYearChart = this.updateComponentYearChart.bind(this);
        this.updateComponentYearComparisonChart = this.updateComponentYearComparisonChart.bind(this);
        this.updateVisibleCharts = this.updateVisibleCharts.bind(this);
        this.updateControlSignals = this.updateControlSignals.bind(this);
        this.onComponentLegendClick = this.onComponentLegendClick.bind(this);
        // init state
        const day = moment().startOf('day').format('YYYY-MM-DD');
        const month = moment().startOf('month').format('YYYY-MM-DD');
        const year = moment().startOf('year').format('YYYY-MM-DD');
        const yLabels = getYAxisScaleLabels((props.selectedComponent !== undefined) ? props.selectedComponent.type : '');
        const components = (props.selectedComponent !== undefined) ? [props.selectedComponent] : [];
        this.state = {
            isRefreshing: false,
            error: null,
            componentTabIndex: 0,
            componentSelectedDay: day,
            componentSelectedMonth: month,
            componentSelectedYear: year,
            componentSelectedYears: year,
            componentDayOptions: createLineChartOptions(day, false, yLabels[0], this.onComponentLegendClick),
            componentDayData: { datasets: createDataSetsForLineChart(components, false, true, true) },
            componentMonthOptions: createBarChartOptions('MonthDays', month, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentMonthData: { datasets: createDataSetsForBarChart(components) },
            componentYearOptions: createBarChartOptions('YearMonths', year, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearData: { datasets: createDataSetsForBarChart(components) },
            componentYearComparisonOptions: createBarChartOptions('Years', year, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearComparisonData: { datasets: createDataSetsForBarChart(components) },
            controlSignals: filterControlSignals(this.props.controlSignals)
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Component overview did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props or state were updated
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Component overview did update.");
        }
        if (!this.mounted) return;

        // TODO remember possible update loop! maybe collect state and put setState at end.

        // probably not allowed to access installation, or not found
        if ((this.props.installationList.length > 0) && (this.props.selectedInstallation === undefined)) {
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Installation " + this.props.installationId + " not found");
                }
                this.setState({ error: errorStrings.installationNotFound });
            }
            return;
        }
        // probably not allowed to access component, or not found
        if (((this.props.components !== undefined) && (this.props.components.length > 0)) && (this.props.selectedComponent === undefined)) {
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Component " + this.props.componentId + " not found");
                }
                this.setState({ error: errorStrings.compNotFound });
            }
            return;
        }
        // changed URL parameters while view is still active
        if (((this.props.installationId !== prevProps.installationId) && (prevProps.installationId !== undefined)) ||
                ((this.props.componentId !== prevProps.componentId) && (prevProps.componentId !== undefined))) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("URL parameters changed, resetting state");
            }
            this.resetState();
            this.onViewLoaded();
            return;
        }
        // authentication changed to true
        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
            if (this.props.isAuthenticated === true) {
                this.refreshAllData(false);
            } else {
                this.resetState();
            }
        }
        // selected component becomes available
        if ((this.props.selectedComponent !== prevProps.selectedComponent) && (this.props.selectedComponent !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component becomes available");
            }
            // update all charts to display component
            this.updateComponentDayChart(false, true, true);
            this.updateComponentMonthChart(false, true, true);
            this.updateComponentYearChart(false, true, true);
            this.updateComponentYearComparisonChart(false, true, true);
            if (this.props.selectedComponent.controllable) {
                this.updateControlSignals(true);
            }
         }
        // component day data was updated
        if ((this.props.componentDayTimeData !== prevProps.componentDayTimeData) && (this.props.componentDayTimeData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (day-time) updated");
            }
            if (this.state.componentTabIndex === 0) {
                this.updateComponentDayChart(false, false, false);
            }
        }
        // component month data was updated
        if ((this.props.componentMonthDayData !== prevProps.componentMonthDayData) && (this.props.componentMonthDayData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (month-day) updated");
            }
            if (this.state.componentTabIndex === 1) {
                this.updateComponentMonthChart(false, false, false);
            }
        }
        // component year data was updated
        if ((this.props.componentYearMonthData !== prevProps.componentYearMonthData) && (this.props.componentYearMonthData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (year-month) updated");
            }
            if (this.state.componentTabIndex === 2) {
                this.updateComponentYearChart(false, false, false);
            }
        }
        // component years data was updated
        if ((this.props.componentYearsData !== prevProps.componentYearsData) && (this.props.componentYearsData !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data (years) updated");
            }
            if (this.state.componentTabIndex === 3) {
                this.updateComponentYearComparisonChart(false, false, false);
            }
        }
        // control signals updated
        if (this.props.controlSignals !== prevProps.controlSignals) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component control signals updated");
            }
            this.updateControlSignals(false);
        }
        // tab changed
        if (this.state.componentTabIndex !== prevState.componentTabIndex) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component data tab changed to " + this.state.componentTabIndex);
            }
            // update the visible chart
            this.updateVisibleCharts(false, true, false);
        }
        // selected date of component day graph has changed
        if ((this.state.componentSelectedDay !== prevState.componentSelectedDay) && (this.state.componentSelectedDay !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component day changed to " + moment(this.state.componentSelectedDay).format('YYYY-MM-DD'));
            }
            this.updateComponentDayChart(true, true, false);
        }
        // selected date of component month graph has changed
        if ((this.state.componentSelectedMonth !== prevState.componentSelectedMonth) && (this.state.componentSelectedMonth !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component month changed to " + moment(this.state.componentSelectedMonth).format('YYYY-MM'));
            }
            this.updateComponentMonthChart(true, true, false);
        }
        // selected date of component year graph has changed
        if ((this.state.componentSelectedYear !== prevState.componentSelectedYear) && (this.state.componentSelectedYear !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component day changed to " + moment(this.state.componentSelectedYear).format('YYYY'));
            }
            this.updateComponentYearChart(true, true, false);
        }
    }

    resetState() {
        // keep selected dates, tab indexes etc.
        const compDay = this.state.componentSelectedDay;
        const compMonth = this.state.componentSelectedMonth;
        const compYear = this.state.componentSelectedYear;
        const compYears = this.state.componentSelectedYears;
        const yLabels = getYAxisScaleLabels((this.props.selectedComponent !== undefined) ? this.props.selectedComponent.type : '');
        const components = (this.props.selectedComponent !== undefined) ? [this.props.selectedComponent] : [];
        this.setState({
            error: null,
            componentDayOptions: createLineChartOptions(compDay, false, yLabels[0], this.onComponentLegendClick),
            componentDayData: { datasets: createDataSetsForLineChart(components, false, true, true) },
            componentMonthOptions: createBarChartOptions('MonthDays', compMonth, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentMonthData: { datasets: createDataSetsForBarChart(components) },
            componentYearOptions: createBarChartOptions('YearMonths', compYear, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearData: { datasets: createDataSetsForBarChart(components) },
            componentYearComparisonOptions: createBarChartOptions('Years', compYears, AppConfig.yearsToCompare, true, yLabels[1], this.onComponentLegendClick),
            componentYearComparisonData: { datasets: createDataSetsForBarChart(components) },
            controlSignals: filterControlSignals(this.props.controlSignals)
        });
    }

    refreshAllData(force = false) {
        // TODO incremental data update, if the latest time period contains partial data

        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Refreshing data (force =" + force + ")");
        }
        let tasks = [];
        this.setState({ isRefreshing: true, error: null });
        if (this.props.installationList.length === 0) {
            // load all installations
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation list refresh");
            }
            tasks.push(this.props.doFetchAllInstallations());
        }
        if ((this.props.components === undefined || this.props.components.length === 0) || force) {
            // load all components
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component list refresh");
            }
            tasks.push(this.props.doFetchAllComponents(this.props.installationId));
        } else if (force) {
            // update component
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component update refresh");
            }
            tasks.push(this.props.doFetchComponent(this.props.installationId, this.props.componentId));
        }
        // refresh only for active tab
        switch (this.state.componentTabIndex) {
            case 0: {
                if ((this.props.componentDayTimeData === undefined || this.props.componentDayTimeData[this.state.componentSelectedDay] === undefined) || (moment().format('YYYY-MM-DD') === this.state.componentSelectedDay) || force) {
                    // load component day-time data
                    // always update if selected day is the current day
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require component day-time data refresh");
                    }
                    tasks.push(this.props.doFetchComponentDayTimeData(this.props.installationId, this.props.componentId, this.state.componentSelectedDay));
                }
                break;
            }
            case 1: {
                if ((this.props.componentMonthDayData === undefined || this.props.componentMonthDayData[this.state.componentSelectedMonth] === undefined) || (moment().startOf('month').format('YYYY-MM-DD') === this.state.componentSelectedMonth) || force) {
                    // load component month-day data
                    // always update if selected month is the current month
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require component statistic month-day refresh");
                    }
                    tasks.push(this.props.doFetchComponentMonthDayData(this.props.installationId, this.props.componentId, this.state.componentSelectedMonth));
                }
                break;
            }
            case 2: {
                if ((this.props.componentYearMonthData === undefined || this.props.componentYearMonthData[this.state.componentSelectedYear] === undefined) || (moment().startOf('year').format('YYYY-MM-DD') === this.state.componentSelectedYear) || force) {
                    // load component month-day data
                    // always update if selected year is the current year
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require component statistic year-month refresh");
                    }
                    tasks.push(this.props.doFetchComponentYearMonthData(this.props.installationId, this.props.componentId, this.state.componentSelectedYear));
                }
                break;
            }
            case 3: {
                // load component month-day data
                // always update
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Require component statistic years refresh");
                }
                tasks.push(this.props.doFetchComponentYearsData(this.props.installationId, this.props.componentId, this.state.componentSelectedYears, AppConfig.yearsToCompare));
                break;
            }
            default: {
                break;
            }
        }
        // update latest value
        if ((this.props.selectedComponent === undefined) || this.props.selectedComponent.measurable) {
            tasks.push(this.props.doFetchComponentActualData(this.props.installationId, this.props.componentId));
        }
        // update control signals
        if ((this.props.selectedComponent === undefined) || this.props.selectedComponent.controllable) {
            tasks.push(this.props.doFetchComponentControls(this.props.installationId, this.props.componentId));
        }

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks).then((results) => {
                if (!this.mounted) return;
                // check results
                let errors = [];
                results.forEach((result) => {
                    if (result.success !== true) {
                        errors.push(result.message);
                    }
                });
                this.setState({
                    isRefreshing: false,
                    error: (errors.length === 0) ? null : errors.join(' | ')
                });
            });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // reload
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    updateComponentDayChart(axes, fetch, createsets) {
        const day = this.state.componentSelectedDay;
        const components = (this.props.selectedComponent !== undefined) ? [this.props.selectedComponent] : [];
        const oldDataSets = this.state.componentDayData.datasets;
        const newDataSets = (createsets) ? createDataSetsForLineChart(components, false, true, true) : [...oldDataSets];

        if ((this.props.componentDayTimeData !== undefined) && (this.props.componentDayTimeData[day] !== undefined)) {
            if (newDataSets.length > 0) {
                newDataSets[0].data = convertComponentContinuousData(this.props.componentDayTimeData[day], day);
            }
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component day data of " + moment(day).format('YYYY-MM-DD') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchComponentDayTimeData(this.props.installationId, this.props.componentId, day)
                        .then((result) => {
                            if (!this.mounted) return;
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length > 0)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentDayOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].ticks.min = moment(day).startOf('day');
                newOptions.scales.xAxes[0].ticks.max = moment(day).add(1, 'days').startOf('day');
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('DayTime', day);
                newOptions.scales.yAxes[0].scaleLabel.labelString = getYAxisScaleLabels((this.props.selectedComponent !== undefined) ? this.props.selectedComponent.type : '')[0];
                newState.componentDayOptions = newOptions;
            }
            newState.componentDayData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentMonthChart(axes, fetch, createsets) {
        const month = this.state.componentSelectedMonth;
        const components = (this.props.selectedComponent !== undefined) ? [this.props.selectedComponent] : [];
        const oldDataSets = this.state.componentMonthData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if ((this.props.componentMonthDayData !== undefined) && (this.props.componentMonthDayData[month] !== undefined)) {
            if (newDataSets.length > 0) {
                newDataSets[0].data = convertComponentStatisticData(this.props.componentMonthDayData[month], 'MonthDays', month);
            }
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component month data of " + moment(month).format('YYYY-MM') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchComponentMonthDayData(this.props.installationId, this.props.componentId, month)
                        .then((result) => {
                            if (!this.mounted) return;
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length > 0)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentMonthOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('MonthDays', month);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('MonthDays', month);
                newOptions.scales.yAxes[0].scaleLabel.labelString = getYAxisScaleLabels((this.props.selectedComponent !== undefined) ? this.props.selectedComponent.type : '')[1];
                newState.componentMonthOptions = newOptions;
            }
            newState.componentMonthData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentYearChart(axes, fetch, createsets) {
        const year = this.state.componentSelectedYear;
        const components = (this.props.selectedComponent !== undefined) ? [this.props.selectedComponent] : [];
        const oldDataSets = this.state.componentYearData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if ((this.props.componentYearMonthData !== undefined) && (this.props.componentYearMonthData[year] !== undefined)) {
            if (newDataSets.length > 0) {
                newDataSets[0].data = convertComponentStatisticData(this.props.componentYearMonthData[year], 'YearMonths', year);
            }
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component year data of " + moment(year).format('YYYY') + " not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchComponentYearMonthData(this.props.installationId, this.props.componentId, year)
                        .then((result) => {
                            if (!this.mounted) return;
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length > 0)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentYearOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('YearMonths', year);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('YearMonths', year);
                newOptions.scales.yAxes[0].scaleLabel.labelString = getYAxisScaleLabels((this.props.selectedComponent !== undefined) ? this.props.selectedComponent.type : '')[1];
                newState.componentYearOptions = newOptions;
            }
            newState.componentYearData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateComponentYearComparisonChart(axes, fetch, createsets) {
        const years = this.state.componentSelectedYears;
        const components = (this.props.selectedComponent !== undefined) ? [this.props.selectedComponent] : [];
        const oldDataSets = this.state.componentYearComparisonData.datasets;
        const newDataSets = (createsets) ? createDataSetsForBarChart(components) : [...oldDataSets];

        if (this.props.componentYearsData !== undefined) {
            if (newDataSets.length > 0) {
                newDataSets[0].data = convertComponentStatisticData(this.props.componentYearsData, 'Years', years, AppConfig.yearsToCompare);
            }
        } else if (fetch) {
            // data must be fetched first
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component years data not found, require fetch");
            }
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchComponentYearsData(this.props.installationId, this.props.componentId, years, AppConfig.yearsToCompare)
                        .then((result) => {
                            if (!this.mounted) return;
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }

        // finally update state - if all data sets present
        if (axes || (newDataSets.length > 0)) {
            let newState = {};
            if (axes) {
                const oldOptions = this.state.componentYearComparisonOptions;
                const newOptions = { ...oldOptions };
                newOptions.scales.xAxes[0].labels = getXAxisLabels('Years', years, AppConfig.yearsToCompare);
                newOptions.scales.xAxes[0].scaleLabel.labelString = getXAxisScaleLabel('Years', years);
                newOptions.scales.yAxes[0].scaleLabel.labelString = getYAxisScaleLabels((this.props.selectedComponent !== undefined) ? this.props.selectedComponent.type : '')[1];
                newState.componentYearComparisonOptions = newOptions;
            }
            newState.componentYearComparisonData = { datasets: newDataSets };
            this.setState(newState);
        }
    }

    updateVisibleCharts(axes, fetch, createsets) {
        switch (this.state.componentTabIndex) {
            case 0: {
                this.updateComponentDayChart(axes, fetch, createsets);
                break;
            }
            case 1: {
                this.updateComponentMonthChart(axes, fetch, createsets);
                break;
            }
            case 2: {
                this.updateComponentYearChart(axes, fetch, createsets);
                break;
            }
            case 3: {
                this.updateComponentYearComparisonChart(axes, fetch, createsets);
                break;
            }
            default: {
                break;
            }
        }
    }

    onComponentLegendClick(e, item) {
        const index = item.datasetIndex;
        switch (this.state.componentTabIndex) {
            case 0: {
                const sets = this.state.componentDayData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentDayData: { datasets: newSets } });
                }
                break;
            }
            case 1: {
                const sets = this.state.componentMonthData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentMonthData: { datasets: newSets } });
                }
                break;
            }
            case 2: {
                const sets = this.state.componentYearData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentYearData: { datasets: newSets } });
                }
                break;
            }
            case 3: {
                const sets = this.state.componentYearComparisonData.datasets;
                if (sets.length > index) {
                    const newSets = [ ...sets ];
                    newSets[index].hidden = !newSets[index].hidden;
                    this.setState({ componentYearComparisonData: { datasets: newSets } });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    updateControlSignals(fetch) {
        if (this.props.controlSignals !== undefined) {
            // update state
            const signals = filterControlSignals(this.props.controlSignals);
            this.setState({ controlSignals: signals });
        }
        if (fetch) {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.setState({ isRefreshing: true });
                    this.props.doFetchComponentControls(this.props.installationId, this.props.componentId)
                        .then((result) => {
                            if (!this.mounted) return;
                            this.setState({
                                isRefreshing: false,
                                error: (result.success === true) ? null : result.message
                            });
                        });
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                // error
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }
    }

    onViewLoaded() {
        // update installation and fetch components
        if (this.props.isAuthenticated) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Update of installation and initial load of components");
            }

            // fetch new data
            this.refreshAllData(false);

            // update visible charts
            this.updateVisibleCharts(true, false, true);
        }
    }

    onComponentDayChanged(date) {
        const day = moment(date).startOf('day').format('YYYY-MM-DD');
        this.setState({ componentSelectedDay: day });
    }

    onComponentMonthChanged(date) {
        const month = moment(date).startOf('month').format('YYYY-MM-DD');
        this.setState({ componentSelectedMonth: month });
    }

    onComponentYearChanged(date) {
        const year = moment(date).startOf('year').format('YYYY-MM-DD');
        this.setState({ componentSelectedYear: year });
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.installationOverview, enabled: true, active: false, icon: "fas fa-building", path: "/installations/" + this.props.installationId + "/overview" },
            { name: headerStrings.componentDetail, active: true, icon: "fas fa-tachometer-alt", path: "/installations/" + this.props.installationId + "/components/" + this.props.componentId + "/overview" },
            { name: headerStrings.goBack, enabled: true, active: false, icon: "fas fa-chevron-left", path: "back" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <ComponentInfo component={this.props.selectedComponent} actualValue={this.props.componentActualData} />
                    <div style={{ margin: 20 }}></div>
                    {((this.props.selectedComponent !== undefined) && (this.props.selectedComponent.measurable)) && (
                        <Panel header={installationStrings.statistics}>
                            <TabView activeIndex={this.state.componentTabIndex} onTabChange={(e) => this.setState({ componentTabIndex: e.index })} renderActiveOnly={true}>
                                <TabPanel header={graphStrings.day}>
                                    <DateGraph term="DayTime" date={this.state.componentSelectedDay} onDateChanged={this.onComponentDayChanged} chartType="line" chartData={this.state.componentDayData} chartOptions={this.state.componentDayOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.month}>
                                    <DateGraph term="MonthDays" date={this.state.componentSelectedMonth} onDateChanged={this.onComponentMonthChanged} chartType="bar" chartData={this.state.componentMonthData} chartOptions={this.state.componentMonthOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={graphStrings.year}>
                                    <DateGraph term="YearMonths" date={this.state.componentSelectedYear} onDateChanged={this.onComponentYearChanged} chartType="bar" chartData={this.state.componentYearData} chartOptions={this.state.componentYearOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                                <TabPanel header={AppConfig.yearsToCompare + " " + graphStrings.years}>
                                    <DateGraph term="Years" date={this.state.componentSelectedYears} chartType="bar" chartData={this.state.componentYearComparisonData} chartOptions={this.state.componentYearComparisonOptions} maxYears={AppConfig.yearsToCompare} />
                                </TabPanel>
                            </TabView>
                        </Panel>
                    )}
                    <div style={{ margin: 20 }}></div>
                    {(this.state.controlSignals.length > 0) && (
                        <Panel header={headerStrings.controls}>
                            <div className="p-grid p-justify-start">
                                {this.state.controlSignals.map(s =>
                                    (<div className="p-col-12 p-sm-6 p-md-4 p-lg-2" key={s.signal}>
                                        <ControlSignalButton installationId={this.props.installationId} componentId={this.props.componentId} signal={s} />
                                    </div>))
                                }
                            </div>
                        </Panel>
                    )}
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const installationId = ownProps.match.params.instId;    // might not be integer!
    const componentId = ownProps.match.params.compId;       // might not be integer!
    return {
        installationId,
        componentId,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installationList: InstallationSelectors.selectAll(state),
        selectedInstallation: InstallationSelectors.selectOne(state, installationId),
        components: ComponentSelectors.selectAll(state, installationId),
        selectedComponent: ComponentSelectors.selectOne(state, installationId, componentId),
        componentDayTimeData: ComponentDayTimeSelectors.selectAllOfComponent(state, installationId, componentId),
        componentMonthDayData: ComponentMonthDaySelectors.selectAllOfComponent(state, installationId, componentId),
        componentYearMonthData: ComponentYearMonthSelectors.selectAllOfComponent(state, installationId, componentId),
        componentYearsData: ComponentYearsSelectors.selectAllOfComponent(state, installationId, componentId),
        componentActualData: ComponentActualSelectors.selectOfComponent(state, installationId, componentId),
        controlSignals: ComponentControlSelectors.selectAllOfComponent(state, installationId, componentId)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchAllInstallations: () => dispatch(fetchAllInstallations()),
        doFetchAllComponents: (instId) => dispatch(fetchAllComponents(instId)),
        doFetchComponent: (instId, compId) => dispatch(fetchComponent(instId, compId)),
        doFetchComponentDayTimeData: (instId, compId, date) => dispatch(fetchComponentDayTimeData(instId, compId, date)),
        doFetchComponentMonthDayData: (instId, compId, date) => dispatch(fetchComponentMonthDayData(instId, compId, date)),
        doFetchComponentYearMonthData: (instId, compId, date) => dispatch(fetchComponentYearMonthData(instId, compId, date)),
        doFetchComponentYearsData: (instId, compId, date, nYears) => dispatch(fetchComponentYearsData(instId, compId, date, nYears)),
        doFetchComponentActualData: (instId, compId) => dispatch(fetchComponentActualData(instId, compId)),
        doFetchComponentControls: (instId, compId) => dispatch(fetchComponentControls(instId, compId))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentOverviewView);