/**
 * Installation reducer. Contains a list of all available installations.
 * Includes status and power flow for faster loading.
 */

/* ------------- Types ------------- */

// types
export const InstallationTypes = {
  INSTALLATIONS_LOADED: 'INSTALLATIONS_LOADED',
  INSTALLATION_UPDATE: 'INSTALLATION_UPDATE',
  INSTALLATIONS_CLEAR: 'INSTALLATIONS_CLEAR',
  INSTALLATION_STATUS_UPDATE: 'INSTALLATION_STATUS_UPDATE',
  INSTALLATION_POWERFLOW_UPDATE: 'INSTALLATION_POWERFLOW_UPDATE',
  INSTALLATION_PRICE_UPDATE: 'INSTALLATION_PRICE_UPDATE',
  INSTALLATION_REMOVE: 'INSTALLATION_REMOVE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const InstallationSelectors = {
  selectAll: (state) => state.installations,
  // eslint-disable-next-line
  selectOne: (state, installationId) => state.installations.find((inst) => (inst.id == installationId))   // must use ==, because id might not be integer
};

/* ------------- Initial state ------------- */

const initialState = [];

/* ------------- Reducer Function ------------- */

function onInstallationsLoaded(state, payload) {
  const newInstallations = payload;
  return newInstallations;
}

function onInstallationUpdate(state, payload) {
  const newInst = payload;
  // eslint-disable-next-line
  const idx = state.findIndex((inst) => (inst.id == newInst.id));
  if (idx >= 0) {
    // replace installation
    const newState = state.slice();
    newState[idx] = newInst;
    return newState;
  }
  // push installation
  return [ ...state, newInst ];
}

function onInstallationStatusUpdate(state, payload) {
  const newState = state.slice();
  // eslint-disable-next-line
  const installation = state.find((inst) => (inst.id == payload.installationId));
  if (installation !== undefined) {
    installation.status = payload.status;
    return newState;
  }
  return state;
}

function onInstallationPowerFlowUpdate(state, payload) {
  const newState = state.slice();
  // eslint-disable-next-line
  const installation = state.find((inst) => (inst.id == payload.installationId));
  if (installation !== undefined) {
    installation.powerFlow = payload.powerFlow;
    return newState;
  }
  return state;
}

function onInstallationPriceUpdate(state, payload) {
  const newState = state.slice();
  // eslint-disable-next-line
  const installation = state.find((inst) => (inst.id == payload.installationId));
  if (installation !== undefined) {
    installation.price = payload.price;
    return newState;
  }
  return state;
}

function onInstallationRemove(state, payload) {
  // eslint-disable-next-line
  const idx = state.findIndex((inst) => (inst.id == payload.installationId));
  if (idx >= 0) {
    return [ ...state.slice(0, idx), ...state.slice(idx + 1) ];
  }
  return state;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case InstallationTypes.INSTALLATIONS_LOADED: {
      return onInstallationsLoaded(state, action.payload);
    }
    case InstallationTypes.INSTALLATION_UPDATE: {
      return onInstallationUpdate(state, action.payload);
    }
    case InstallationTypes.INSTALLATION_STATUS_UPDATE: {
      return onInstallationStatusUpdate(state, action.payload);
    }
    case InstallationTypes.INSTALLATION_POWERFLOW_UPDATE: {
      return onInstallationPowerFlowUpdate(state, action.payload);
    }
    case InstallationTypes.INSTALLATION_PRICE_UPDATE: {
      return onInstallationPriceUpdate(state, action.payload);
    }
    case InstallationTypes.INSTALLATION_REMOVE: {
      return onInstallationRemove(state, action.payload);
    }
    case InstallationTypes.INSTALLATIONS_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;