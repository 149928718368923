import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ControlSignalButton from '../components/ControlSignalButton';
import { fetchAllInstallations } from '../actions/InstallationActions';
import { fetchAllComponents } from '../actions/ComponentActions';
import { fetchAllBuildingUnits, fetchBuildingUnit, fetchBuildingUnitComponentIds } from '../actions/BuildingUnitActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { ComponentSelectors } from '../redux/ComponentRedux';
import { BuildingUnitSelectors } from '../redux/BuildingUnitRedux';
import { ComponentControlSelectors } from '../redux/ComponentControlRedux';
import { filterControlSignals } from '../components/util/ComponentUtils';
import { fetchComponentControls } from '../actions/ComponentControlActions';
import { headerStrings, installationStrings, errorStrings, componentStrings } from '../i18n/translations';

/**
 * The building unit control page.
 * Displays control signals of components.
 */
class BuildingUnitControlView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // methods requiring access to 'this'
        this.resetState = this.resetState.bind(this);
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.filterUnitComponents = this.filterUnitComponents.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);
        // init state
        const unitComponents = this.filterUnitComponents();
        const controlComponents = unitComponents.filter(c => c.controllable);
        const controlSignals = {};
        controlComponents.forEach(comp => {
            if ((this.props.controlSignals !== undefined) && (this.props.controlSignals[comp.id] !== undefined)) {
                controlSignals[comp.id] = filterControlSignals(this.props.controlSignals[comp.id]);
            } else {
                controlSignals[comp.id] = [];
            }
        });

        this.state = {
            isRefreshing: false,
            error: null,
            unitComponents: unitComponents,
            controlComponents: controlComponents,
            controlSignals: controlSignals
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Building unit control view did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props were updated
        if (!this.mounted) return;

        // TODO remember possible update loop! maybe collect state and put setState at end.

        // probably not allowed to access installation, or not found
        if ((this.props.installationList.length > 0) && (this.props.selectedInstallation === undefined)) {
            // probably not allowed, or no valid installation
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Installation " + this.props.installationId + " not found");
                }
                this.setState({ error: errorStrings.installationNotFound, unitComponents: [], controlComponents: [] });
            }
            return;
        }
        // probably not allowed to access building unit, or not found
        if ((this.props.buildingUnits !== undefined) && (this.props.selectedUnit === undefined)) {
            // probably not allowed, or no valid building unit
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Building unit " + this.props.unitId + " not found");
                }
                this.setState({ error: errorStrings.unitNotFound, unitComponents: [], controlComponents: [] });
            }
            return;
        }
        // changed URL parameters while view is still active
        if (((this.props.installationId !== prevProps.installationId) && (prevProps.installationId !== undefined)) ||
                ((this.props.unitId !== prevProps.unitId) && (prevProps.unitId !== undefined))) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("URL parameters changed, resetting state");
            }
            this.resetState();
            this.onViewLoaded();
            return;
        }
        // authentication changed to true
        if ((this.props.isAuthenticated !== prevProps.isAuthenticated) && (this.props.isAuthenticated === true)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
            this.refreshAllData(false);
        }
        // components loaded
        if ((this.props.components !== undefined) && (prevProps.components === undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Components loaded");
            }
            // should update unit components
            const unitComponents = this.filterUnitComponents();
            const controlComponents = unitComponents.filter(c => c.controllable);
            const controlSignals = {};
            controlComponents.forEach(comp => {
                if ((this.props.controlSignals !== undefined) && (this.props.controlSignals[comp.id] !== undefined)) {
                    controlSignals[comp.id] = filterControlSignals(this.props.controlSignals[comp.id]);
                } else {
                    controlSignals[comp.id] = [];
                }
            });
            this.setState({
                unitComponents: unitComponents,
                controlComponents: controlComponents,
                controlSignals: controlSignals
            });
        }
        // building units changed
        if ((this.props.buildingUnits !== prevProps.buildingUnits) && (this.props.buildingUnits !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Building units updated");
            }
            if (this.props.selectedUnit !== undefined) {
                if (this.props.selectedUnit.componentIds == null) {
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require building unit component IDs refresh");
                    }
                    this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId)
                            .then((result) => {
                                if (!this.mounted) return;
                                let newState = {
                                    isRefreshing: false,
                                    error: (result.success === true) ? null : result.message,
                                };
                                if (result.success === true) {
                                    const unitComponents = this.filterUnitComponents();
                                    const controlComponents = unitComponents.filter(c => c.controllable);
                                    const controlSignals = {};
                                    controlComponents.forEach(comp => {
                                        if ((this.props.controlSignals !== undefined) && (this.props.controlSignals[comp.id] !== undefined)) {
                                            controlSignals[comp.id] = filterControlSignals(this.props.controlSignals[comp.id]);
                                        } else {
                                            controlSignals[comp.id] = [];
                                        }
                                    });
                                    newState.unitComponents = unitComponents;
                                    newState.controlComponents = unitComponents.filter(c => c.controllable);
                                    newState.controlSignals = controlSignals;
                                }
                                this.setState(newState);
                            });
                } else {
                    // should update unit components
                    const unitComponents = this.filterUnitComponents();
                    const controlComponents = unitComponents.filter(c => c.controllable);
                    const controlSignals = {};
                    controlComponents.forEach(comp => {
                        if ((this.props.controlSignals !== undefined) && (this.props.controlSignals[comp.id] !== undefined)) {
                            controlSignals[comp.id] = filterControlSignals(this.props.controlSignals[comp.id]);
                        } else {
                            controlSignals[comp.id] = [];
                        }
                    });
                    this.setState({
                        unitComponents: unitComponents,
                        controlComponents: controlComponents,
                        controlSignals: controlSignals
                    });
                }
            }
        }
        // control signals updated
        if (this.props.controlSignals !== prevProps.controlSignals) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Component control signals updated");
            }
            // rebuild local control signals
            const controlSignals = {};
            this.state.controlComponents.forEach(comp => {
                if ((this.props.controlSignals !== undefined) && (this.props.controlSignals[comp.id] !== undefined)) {
                    controlSignals[comp.id] = filterControlSignals(this.props.controlSignals[comp.id]);
                } else {
                    controlSignals[comp.id] = [];
                }
            });
            this.setState({
                controlSignals: controlSignals
            });
        }
        // controllable components changed
        if (this.state.controlComponents !== prevState.controlComponents) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("control components changed");
            }
            // fetch signal values
            this.state.controlComponents.forEach(comp => {
                this.props.doFetchComponentControls(this.props.installationId, comp.id);
            });
        }
    }

    resetState() {
        // keep selected dates, tab indexes etc.
        const unitComponents = this.filterUnitComponents();
        const controlComponents = unitComponents.filter(c => c.controllable);
        const controlSignals = {};
        controlComponents.forEach(comp => {
            if ((this.props.controlSignals !== undefined) && (this.props.controlSignals[comp.id] !== undefined)) {
                controlSignals[comp.id] = filterControlSignals(this.props.controlSignals[comp.id]);
            } else {
                controlSignals[comp.id] = [];
            }
        });
        this.setState({
            error: null,
            unitComponents: unitComponents,
            controlComponents: controlComponents,
            controlSignals: controlSignals
        });
    }

    refreshAllData(force = false) {
        // TODO incremental data update, if the latest time period contains partial data

        let tasks = [];
        let updateUnitComponents = false;
        this.setState({ isRefreshing: true, error: null });
        if (this.props.installationList.length === 0) {
            // load all installations
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation list refresh");
            }
            tasks.push(this.props.doFetchAllInstallations());
        }
        if ((this.props.components === undefined) || (this.props.components.length === 0)) {
            // load all components
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component list refresh");
            }
            tasks.push(this.props.doFetchAllComponents(this.props.installationId));
        }
        if ((this.props.buildingUnits === undefined) || (this.props.buildingUnits.length === 0)) {
            // load all units
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit list refresh");
            }
            tasks.push(this.props.doFetchAllBuildingUnits(this.props.installationId));
        } else if (force) {
            // update building unit
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit update refresh");
            }
            tasks.push(this.props.doFetchBuildingUnit(this.props.installationId, this.props.unitId));
        }
        if ((this.props.selectedUnit !== undefined) && (this.props.selectedUnit.componentIds == null)) {
            // update component IDs
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit component IDs refresh");
            }
            updateUnitComponents = true;
            tasks.push(this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId));
        }
        // always update control signals
        this.state.controlComponents.forEach(comp => {
            tasks.push(this.props.doFetchComponentControls(this.props.installationId, comp.id));
        });

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks)
                .then((results) => {
                    if (!this.mounted) return;
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });

                    // just refresh state using existing components
                    const unitComponents = updateUnitComponents ? this.filterUnitComponents() : this.state.unitComponents;
                    const controlComponents = unitComponents.filter(c => c.controllable);
                    const controlSignals = {};
                    controlComponents.forEach(comp => {
                        if ((this.props.controlSignals !== undefined) && (this.props.controlSignals[comp.id] !== undefined)) {
                            controlSignals[comp.id] = filterControlSignals(this.props.controlSignals[comp.id]);
                        } else {
                            controlSignals[comp.id] = [];
                        }
                    });
                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | '),
                        unitComponents: unitComponents,
                        controlComponents: controlComponents,
                        controlSignals: controlSignals
                    });
                });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // reload
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    filterUnitComponents() {
        if ((this.props.selectedUnit !== undefined) && (this.props.components !== undefined)) {
            const unitComponents = [];
            const cIds = this.props.selectedUnit.componentIds;
            if (cIds != null) {
                cIds.forEach((id) => {
                    let comp = this.props.components.find((c) => (c.id === id));
                    if (comp !== undefined) {
                        unitComponents.push(comp);
                    }
                });
                // sort by name
                unitComponents.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }
            return unitComponents;
        } else if (BuildConfig.isReactotronEnabled) {
            Reactotron.warn("Failed to filter unit components");
        }
        return [];
    }

    onViewLoaded() {
        // update installation and fetch components
        if (this.props.isAuthenticated === true) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Update of building unit and its components");
            }
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.installationOverview, enabled: true, active: false, icon: "fas fa-building", path: "/installations/" + this.props.installationId + "/overview" },
            { name: headerStrings.unitOverview, enabled: true, active: false, icon: "fas fa-door-open", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/overview" }
        ];
        if (this.state.unitComponents.find(c => (c.type === 'Verbrauch')) !== undefined) {
            navItems.push({ name: headerStrings.electricity, enabled: true, active: false, icon: "fas fa-bolt", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/electricity" });
        }
        if (this.state.unitComponents.find(c => (c.type === 'Temperatur' || c.type === 'Waermezaehler')) !== undefined) {
            navItems.push({ name: headerStrings.temperatures + " / " + headerStrings.heating, enabled: true, active: false, icon: "fas fa-thermometer-three-quarters", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/thermal" });
        }
        if (this.state.unitComponents.find(c => (c.type === 'Wasserzaehler')) !== undefined) {
            navItems.push({ name: headerStrings.water, enabled: true, active: false, icon: "fas fa-tint", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/water" });
        }
        navItems.push({ name: headerStrings.controls, enabled: false, active: true, icon: "fas fa-sliders-h", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/control" });

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <Panel header={headerStrings.controls}>
                        {(this.state.controlComponents.length > 0) ?
                            this.state.controlComponents.map(comp => {
                                const signals = this.state.controlSignals[comp.id];
                                return (
                                    <div className="p-grid" key={"comp" + comp.id} style={{ border: '1px solid #d0d0d0', borderRadius: 4, margin: '0.25em' }}>
                                        <div className="p-col-12" style={{ textAlign: 'center' }}>
                                            <b>{comp.name}</b>
                                        </div>
                                        <div className="p-col-12">
                                            <div className="p-grid p-justify-start">
                                                {(signals.length > 0) ? signals.map(sig => (
                                                    <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2" key={sig.signal}>
                                                        <ControlSignalButton installationId={this.props.installationId} componentId={comp.id} signal={sig} />
                                                    </div>
                                                )) : (
                                                    <div className="p-col-12">{installationStrings.noControls}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) : (
                                <div>{componentStrings.noControlComponents}</div>
                            )}
                    </Panel>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const installationId = ownProps.match.params.instId;    // might not be integer!
    const unitId = ownProps.match.params.unitId;            // might not be integer!
    return {
        installationId,
        unitId,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installationList: InstallationSelectors.selectAll(state),
        selectedInstallation: InstallationSelectors.selectOne(state, installationId),
        buildingUnits: BuildingUnitSelectors.selectAll(state, installationId),
        selectedUnit: BuildingUnitSelectors.selectOne(state, installationId, unitId),
        components: ComponentSelectors.selectAll(state, installationId),
        controlSignals: ComponentControlSelectors.selectAllOfInstallation(state, installationId)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchAllInstallations: () => dispatch(fetchAllInstallations()),
        doFetchAllComponents: (instId) => dispatch(fetchAllComponents(instId)),
        doFetchAllBuildingUnits: (instId) => dispatch(fetchAllBuildingUnits(instId)),
        doFetchBuildingUnit: (instId, unitId) => dispatch(fetchBuildingUnit(instId, unitId)),
        doFetchBuildingUnitComponentIds: (instId, unitId) => dispatch(fetchBuildingUnitComponentIds(instId, unitId)),
        doFetchComponentControls: (instId, compId) => dispatch(fetchComponentControls(instId, compId))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingUnitControlView);