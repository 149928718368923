import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import AppConfig from '../config/AppConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import moment from 'moment';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BuildingUnitInfo from '../components/BuildingUnitInfo';
import ActualValue from '../components/ActualValue';
import { fetchAllInstallations } from '../actions/InstallationActions';
import { fetchAllComponents } from '../actions/ComponentActions';
import { fetchAllBuildingUnits, fetchBuildingUnit, fetchBuildingUnitComponentIds } from '../actions/BuildingUnitActions';
import { fetchGeneralFigures } from '../actions/GeneralFigureActions';
import { fetchAllComponentsActualData } from '../actions/ComponentDataActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { ComponentSelectors } from '../redux/ComponentRedux';
import { BuildingUnitSelectors } from '../redux/BuildingUnitRedux';
import { GeneralFigureSelectors } from '../redux/GeneralFigureRedux';
import { ComponentActualSelectors } from '../redux/ComponentActualDataRedux';
import { getMeasurementUnits, getTypeIcon } from '../components/util/ComponentUtils';
import { installationStrings, componentStrings, headerStrings, errorStrings } from '../i18n/translations';

/**
 * The building unit overview page.
 * Displays basic figures and the components.
 */
class BuildingUnitOverviewView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // methods requiring access to 'this'
        this.resetState = this.resetState.bind(this);
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.filterUnitComponents = this.filterUnitComponents.bind(this);
        this.onViewLoaded = this.onViewLoaded.bind(this);
        this.renderActualValues = this.renderActualValues.bind(this);
        // init state
        this.state = {
            isRefreshing: false,
            error: null,
            unitComponents: this.filterUnitComponents()
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Building unit overview did mount.");
        }
        this.onViewLoaded();
        // init refresh timer
        this.refreshTimer = setInterval(() => {
            if (this.props.isAuthenticated === true) {
                if (this.props.isExpired === false) {
                    this.refreshAllData(false);
                } else {
                    this.setState({ error: errorStrings.tokenExpired });
                }
            } else {
                this.setState({ error: errorStrings.notAuthenticated });
            }
        }, AppConfig.refreshInterval * 1000);
    }

    componentWillUnmount() {
        this.mounted = false;
        // clear refresh timer
        clearInterval(this.refreshTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        // props were updated
        if (!this.mounted) return;

        // TODO remember possible update loop! maybe collect state and put setState at end.

        // probably not allowed to access installation, or not found
        if ((this.props.installationList.length > 0) && (this.props.selectedInstallation === undefined)) {
            // probably not allowed, or no valid installation
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Installation " + this.props.installationId + " not found");
                }
                this.setState({ error: errorStrings.installationNotFound, unitComponents: [] });
            }
            return;
        }
        // probably not allowed to access building unit, or not found
        if ((this.props.buildingUnits !== undefined) && (this.props.selectedUnit === undefined)) {
            // probably not allowed, or no valid building unit
            if (this.state.error == null) {
                if (BuildConfig.isReactotronEnabled) {
                    Reactotron.log("Building unit " + this.props.unitId + " not found");
                }
                this.setState({ error: errorStrings.unitNotFound, unitComponents: [] });
            }
            return;
        }
        // changed URL parameters while view is still active
        if (((this.props.installationId !== prevProps.installationId) && (prevProps.installationId !== undefined)) ||
                ((this.props.unitId !== prevProps.unitId) && (prevProps.unitId !== undefined))) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("URL parameters changed, resetting state");
            }
            this.resetState();
            this.onViewLoaded();
            return;
        }
        // authentication changed to true
        if ((this.props.isAuthenticated !== prevProps.isAuthenticated) && (this.props.isAuthenticated === true)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Authentication changed to " + this.props.isAuthenticated);
            }
            this.refreshAllData(false);
        }
        // components loaded
        if ((this.props.components !== undefined) && (prevProps.components === undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Components loaded");
            }
            // should update unit components
            const unitComponents = this.filterUnitComponents();
            this.setState({
                unitComponents: unitComponents
            });
        }
        // building units changed
        if ((this.props.buildingUnits !== prevProps.buildingUnits) && (this.props.buildingUnits !== undefined)) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Building units updated");
            }
            if (this.props.selectedUnit !== undefined) {
                if (this.props.selectedUnit.componentIds == null) {
                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Require building unit component IDs refresh");
                    }
                    this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId)
                            .then((result) => {
                                if (!this.mounted) return;
                                let newState = {
                                    isRefreshing: false,
                                    error: (result.success === true) ? null : result.message,
                                };
                                if (result.success === true) {
                                    newState.unitComponents = this.filterUnitComponents();
                                }
                                this.setState(newState);
                            });
                } else {
                    // should update unit components
                    this.setState({
                        unitComponents: this.filterUnitComponents()
                    });
                }
            }
        }
    }

    resetState() {
        this.setState({
            error: null,
            unitComponents: this.filterUnitComponents()
        });
    }

    refreshAllData(force = false) {
        // TODO incremental data update, if the latest time period contains partial data

        let tasks = [];
        let updateUnitComponents = false;
        this.setState({ isRefreshing: true, error: null });
        if (this.props.installationList.length === 0) {
            // load all installations
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require installation list refresh");
            }
            tasks.push(this.props.doFetchAllInstallations());
        }
        if ((this.props.components === undefined) || (this.props.components.length === 0)) {
            // load all components
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require component list refresh");
            }
            tasks.push(this.props.doFetchAllComponents(this.props.installationId));
        }
        if ((this.props.buildingUnits === undefined) || (this.props.buildingUnits.length === 0)) {
            // load all units
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit list refresh");
            }
            tasks.push(this.props.doFetchAllBuildingUnits(this.props.installationId));
        } else if (force) {
            // update building unit
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit update refresh");
            }
            tasks.push(this.props.doFetchBuildingUnit(this.props.installationId, this.props.unitId));
        }
        if ((this.props.selectedUnit !== undefined) && (this.props.selectedUnit.componentIds == null)) {
            // update component IDs
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require building unit component IDs refresh");
            }
            updateUnitComponents = true;
            tasks.push(this.props.doFetchBuildingUnitComponentIds(this.props.installationId, this.props.unitId));
        }
        // always update figures
        tasks.push(this.props.doFetchGeneralFigures(this.props.installationId));
        // always update actual values
        tasks.push(this.props.doFetchAllComponentsActualData(this.props.installationId));

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks)
                .then((results) => {
                    if (!this.mounted) return;
                    // check results
                    let errors = [];
                    results.forEach((result) => {
                        if (result.success !== true) {
                            errors.push(result.message);
                        }
                    });

                    // just refresh state using existing components
                    const unitComponents = updateUnitComponents ? this.filterUnitComponents() : this.state.unitComponents;
                    this.setState({
                        isRefreshing: false,
                        error: (errors.length === 0) ? null : errors.join(' | '),
                        unitComponents: unitComponents
                    });
                });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // reload
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    filterUnitComponents() {
        if ((this.props.selectedUnit !== undefined) && (this.props.components !== undefined)) {
            const unitComponents = [];
            const cIds = this.props.selectedUnit.componentIds;
            if (cIds != null) {
                cIds.forEach((id) => {
                    let comp = this.props.components.find((c) => (c.id === id));
                    if (comp !== undefined) {
                        unitComponents.push(comp);
                    }
                });
                // sort by name
                unitComponents.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }
            return unitComponents;
        } else if (BuildConfig.isReactotronEnabled) {
            Reactotron.warn("Failed to filter unit components");
        }
        return [];
    }

    onViewLoaded() {
        // update installation and fetch components
        if (this.props.isAuthenticated === true) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Update of building unit and its components");
            }
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.installationOverview, enabled: true, active: false, icon: "fas fa-building", path: "/installations/" + this.props.installationId + "/overview" },
            { name: headerStrings.unitOverview, active: true, icon: "fas fa-door-open", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/overview" }
        ];
        if (this.state.unitComponents.find(c => (c.type === 'Verbrauch')) !== undefined) {
            navItems.push({ name: headerStrings.electricity, enabled: true, active: false, icon: "fas fa-bolt", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/electricity" });
        }
        if (this.state.unitComponents.find(c => (c.type === 'Temperatur' || c.type === 'Waermezaehler')) !== undefined) {
            navItems.push({ name: headerStrings.temperatures + " / " + headerStrings.heating, enabled: true, active: false, icon: "fas fa-thermometer-three-quarters", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/thermal" });
        }
        if (this.state.unitComponents.find(c => (c.type === 'Wasserzaehler')) !== undefined) {
            navItems.push({ name: headerStrings.water, enabled: true, active: false, icon: "fas fa-tint", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/water" });
        }
        if (this.state.unitComponents.find(c => c.controllable) !== undefined) {
            navItems.push({ name: headerStrings.controls, enabled: true, active: false, icon: "fas fa-sliders-h", path: "/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/control" });
        }

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    {(this.state.error != null) && (
                        <div style={{ padding: '1em' }}>
                            <Message severity="error" text={this.state.error} />
                        </div>
                    )}
                    <BuildingUnitInfo buildingUnit={this.props.selectedUnit} figures={this.props.generalFigures} />
                    <div style={{ margin: 20 }}></div>
                    <Panel header={installationStrings.actualValues + " – " + headerStrings.electricity} style={{ marginBottom: 20 }}>
                        {this.renderActualValues('electricity', 'Verbrauch', 3)}
                    </Panel>
                    <Panel header={installationStrings.actualValues + " – " + componentStrings.temperature} style={{ marginBottom: 20 }}>
                        {this.renderActualValues('thermal', 'Temperatur', 1)}
                    </Panel>
                </div>
                <Footer />
            </div>
        );
    }

    renderActualValues(linkTarget, cType, decimals) {
        const components = this.state.unitComponents.filter((comp) => (comp.measurable && (comp.type === cType)));
        if (components.length > 0) {
            return (
                <div className="p-grid p-justify-start">
                    {components.map((comp) => {
                        const value = ((this.props.componentActualData !== undefined) && (this.props.componentActualData[comp.id] !== undefined) && moment(this.props.componentActualData[comp.id].dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.componentActualData[comp.id].value : undefined;
                        return (
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2" key={cType + comp.id}>
                                <Link to={"/installations/" + this.props.installationId + "/units/" + this.props.unitId + "/" + linkTarget}>
                                    <ActualValue name={comp.name} color={comp.color} value={value} unit={getMeasurementUnits(comp.type)[0]} decimals={decimals} icon={getTypeIcon(comp.type)} />
                                </Link>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (<div>{componentStrings.noComponents}</div>);
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const installationId = ownProps.match.params.instId;    // might not be integer!
    const unitId = ownProps.match.params.unitId;            // might not be integer!
    return {
        installationId,
        unitId,
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        installationList: InstallationSelectors.selectAll(state),
        selectedInstallation: InstallationSelectors.selectOne(state, installationId),
        buildingUnits: BuildingUnitSelectors.selectAll(state, installationId),
        selectedUnit: BuildingUnitSelectors.selectOne(state, installationId, unitId),
        components: ComponentSelectors.selectAll(state, installationId),
        generalFigures: GeneralFigureSelectors.selectOfInstallation(state, installationId),
        componentActualData: ComponentActualSelectors.selectAllOfInstallation(state, installationId)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchAllInstallations: () => dispatch(fetchAllInstallations()),
        doFetchAllComponents: (instId) => dispatch(fetchAllComponents(instId)),
        doFetchAllBuildingUnits: (instId) => dispatch(fetchAllBuildingUnits(instId)),
        doFetchBuildingUnit: (instId, unitId) => dispatch(fetchBuildingUnit(instId, unitId)),
        doFetchBuildingUnitComponentIds: (instId, unitId) => dispatch(fetchBuildingUnitComponentIds(instId, unitId)),
        doFetchGeneralFigures: (instId) => dispatch(fetchGeneralFigures(instId)),
        doFetchAllComponentsActualData: (instId) => dispatch(fetchAllComponentsActualData(instId))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingUnitOverviewView);