/**
 * Component statistic data reducer.
 * Contains yearly values for multiple years, for each installation.
 * Years are represented by ISO dates (first day of year).
 */

/* ------------- Types ------------- */

// types
export const ComponentYearsTypes = {
  COMPONENT_YEARS_CLEAR_INSTALLATION: 'COMPONENT_YEARS_CLEAR_INSTALLATION',
  COMPONENT_YEARS_CLEAR_ALL: 'COMPONENT_YEARS_CLEAR_ALL',
  COMPONENT_YEARS_UPDATE: 'COMPONENT_YEARS_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const ComponentYearsSelectors = {
  selectAllOfInstallation: (state, installationId) => state.componentYearsData[installationId],
  selectAllOfComponent: (state, installationId, componentId) => {
    const instData = state.componentYearsData[installationId];
    return (instData !== undefined) ? instData[componentId] : undefined;
  }
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onComponentYearsUpdate(state, payload) {
  // create or update
  const { installationId, componentId, values } = payload;
  const instData = state[installationId];
  if (instData !== undefined) {
    // update component data -> set values
    const newInstData = { ...instData, [componentId]: values };
    return {
      ...state,
      [installationId]: newInstData
    };
  } else {
    // no installation data -> create component with values
    return {
      ...state,
      [installationId]: {
        [componentId]: values
      }
    };
  }
}

function onComponentYearsClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ComponentYearsTypes.COMPONENT_YEARS_UPDATE: {
      return onComponentYearsUpdate(state, action.payload);
    }
    case ComponentYearsTypes.COMPONENT_YEARS_CLEAR_INSTALLATION: {
      return onComponentYearsClearInstallation(state, action.payload);
    }
    case ComponentYearsTypes.COMPONENT_YEARS_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;