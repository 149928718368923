import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import moment from 'moment';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import evmApi from '../services/EvmApi';

/**
 *
 * @param {Object} defaultState the initial reducer state, which is returned as fallback
 * @returns an object as the new reducer state
 */
export function initAuthReduxWithToken(defaultState) {
    const tokens = Cookies.getJSON('userAuth');
    if (tokens !== undefined) {
        try {
            // decode access token
            const decodedAccessToken = jwt_decode(tokens.accessToken);
            // extract expiration date (unix ts --> milliseconds)
            const expiresAt = decodedAccessToken.exp * 1000;
            const now = moment();
            if (moment(expiresAt).isAfter(now)) {
                // decode ID token
                const decodedIdToken = jwt_decode(tokens.idToken);
                if (decodedIdToken.email_verified === true) {
                    // extract user name
                    const userName = decodedIdToken.email;
                    // put encoded access token into EVM API
                    evmApi.setAuthentication(tokens.tokenType, tokens.accessToken);

                    if (BuildConfig.isReactotronEnabled) {
                        Reactotron.log("Initialized redux with auth cookie");
                    }

                    // return merged state
                    return {
                        ...defaultState,
                        accessToken: tokens.accessToken,
                        idToken: tokens.idToken,
                        tokenType: tokens.tokenType,
                        expiresAt: expiresAt,
                        userName: userName
                    };
                }
            }
        } catch (error) {
            // could not load
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Error loading cookie: " + error);
            }
        }
    } else {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("No auth cookie found");
        }
    }
    return defaultState;
}