import evmApi from '../services/EvmApi';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to management functionality.
 */

/**
 * Registers a new user account.
 * @param {String} email the user's e-mail address
 * @param {String} password the user password
 * @returns a result object
 */
export function registerUser(email, password) {
    return (dispatch) => {
        // register via EVM API
        return evmApi.registerUserAccount(email, password)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // created
                    return { success: true, message: errorStrings.regSuccess };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Connects the authenticated user to an installation.
 * @param {String} code the registration code
 * @returns a result object
 */
export function connectUser(code) {
    return (dispatch) => {
        // connect via EVM API
        return evmApi.connectUser(code)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // connected
                    return { success: true, message: errorStrings.connectSuccess };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Disconnects the authenticated user from an installation.
 * @param {Number} instId the installation ID
 * @returns a result object
 */
export function disconnectInstallation(instId) {
    return (dispatch) => {
        // connect via EVM API
        return evmApi.disconnectInstallation(instId)
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // disconnected
                    return { success: true, message: errorStrings.connectSuccess };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Deletes the own user account.
 * @returns a result object
 */
export function deleteUser() {
    return (dispatch) => {
        // delete via EVM API
        return evmApi.deleteUserAccount()
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // deleted
                    return { success: true, message: errorStrings.userDeleted };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.objNotFound;

        case 409:
            return errorStrings.objExists;

        case 422:
            return errorStrings.invalidData;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}