/**
 * General statistic data reducer.
 * Contains yearly values for a number of years, for each installation.
 * Years are represented by ISO dates (first day of year).
 */

/* ------------- Types ------------- */

// types
export const GeneralYearsTypes = {
  GENERAL_YEARS_CLEAR_INSTALLATION: 'GENERAL_YEARS_CLEAR_INSTALLATION',
  GENERAL_YEARS_CLEAR_ALL: 'GENERAL_YEARS_CLEAR_ALL',
  GENERAL_YEARS_UPDATE: 'GENERAL_YEARS_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const GeneralYearsSelectors = {
  selectAllOfInstallation: (state, installationId) => state.generalYearsData[installationId]
};

/* ------------- Initial state ------------- */

const initialState = [];

/* ------------- Reducer Function ------------- */

function onGeneralYearsUpdate(state, payload) {
  // replace installation data
  const { installationId, values } = payload;
  return {
    ...state,
    [installationId]: values
  };
}

function onGeneralYearsClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GeneralYearsTypes.GENERAL_YEARS_UPDATE: {
      return onGeneralYearsUpdate(state, action.payload);
    }
    case GeneralYearsTypes.GENERAL_YEARS_CLEAR_INSTALLATION: {
      return onGeneralYearsClearInstallation(state, action.payload);
    }
    case GeneralYearsTypes.GENERAL_YEARS_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;