import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import moment from 'moment';
import ActualValue from './ActualValue';
import { getMeasurementUnits, getTypeIcon } from './util/ComponentUtils';
import { installationStrings } from '../i18n/translations';

/**
 * A component information panel.
 */
class ComponentInfo extends Component {

    render() {
        if (this.props.component !== undefined) {
            const value = ((this.props.actualValue !== undefined) && moment(this.props.actualValue.dateTime).isAfter(moment().subtract(1, 'hours'))) ? this.props.actualValue.value : 0.0;

            return (
                <Panel header={installationStrings.compInfo}>
                    <div className="p-grid">
                        <div className="p-col-12 p-sm-6 p-md-6">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{installationStrings.name}</td>
                                        <td>{this.props.component.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{installationStrings.type}</td>
                                        <td>{this.props.component.type}</td>
                                    </tr>
                                    <tr>
                                        <td>{installationStrings.measurements}</td>
                                        <td>{(this.props.component.measurable === true) ? installationStrings.yes : installationStrings.no}</td>
                                    </tr>
                                    <tr>
                                        <td>{installationStrings.controllable}</td>
                                        <td>{(this.props.component.controllable === true) ? installationStrings.yes : installationStrings.no}</td>
                                    </tr>
                                    <tr>
                                        <td>{installationStrings.range}</td>
                                        <td>{this.props.component.minValue} ... {this.props.component.maxValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="p-col-12 p-sm-6 p-md-6">
                            {(this.props.actualValue !== undefined) && (
                                <ActualValue name={this.props.component.name} color={this.props.component.color} value={value} unit={getMeasurementUnits(this.props.component.type)[0]} decimals={3} icon={getTypeIcon(this.props.component.type)} />
                            )}
                        </div>
                    </div>
                </Panel>
            );
        } else {
            return (<Panel header={installationStrings.compInfo}>{installationStrings.noComp}</Panel> );
        }
    }
}

export default ComponentInfo;