import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { AuthSelectors } from '../redux/AuthRedux';
import { authenticationResetPassword } from '../actions/AuthActions';
import { headerStrings, errorStrings, mgmtStrings } from '../i18n/translations';

/**
 * The password reset page.
 */
class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            isLoading: false,
            message: null
        };
        // This binding is necessary to make `this` work in the callback
        this.handleReset = this.handleReset.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Reset password view did mount.");
        }
        this.setState({ message: null });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleReset(values, actions) {
        // check input
        if (!values.email || (values.email === "")) {
            this.setState({ message: { severity: 'error', text: errorStrings.emailEmpty }});
            return;
        }

        this.setState({ isLoading: true, message: null });
        this.props.doResetPassword(values.email)
            .then((result) => {
                if (!this.mounted) return;
                if (result.success === true) {
                    this.setState({ isLoading: false, message: { severity: 'success', text: result.message }});
                } else {
                    this.setState({ isLoading: false, message: { severity: 'error', text: result.message }});
                }
            });
    }

    validateForm(values) {
        let errors = {};
        if (!values.email) {
            errors.email = errorStrings.emailEmpty;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            // test for invalid characters
            errors.email = errorStrings.invalidCharacters;
        }
        return errors;
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: this.props.isAuthenticated, active: false, icon: "fas fa-list-ol", path: "/installations" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isLoading} />
                <div className="page-content">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-sm-8 p-md-6">
                            <Panel header={mgmtStrings.resetPassword}>
                                <Formik
                                    initialValues={{ email: "" }}
                                    validate={this.validateForm}
                                    onSubmit={this.handleReset}>
                                    <Form autoComplete="off">
                                        <div className="p-grid">
                                            <div className="p-col-12" style={{ padding: '1em' }}>
                                                <span className="p-float-label">
                                                    <Field id="emailIn" type="email" name="email" size="28" className="p-inputtext" />
                                                    <label htmlFor="emailIn">{mgmtStrings.eMail}</label>
                                                </span>
                                                <ErrorMessage name="email" render={msg => <Message severity='warn' text={msg} />} />
                                            </div>
                                            <div className="p-col-12" style={{ padding: '1em' }}>
                                                {mgmtStrings.passwordSet}
                                            </div>
                                            <div className="p-col-6" style={{ padding: '1em' }}>
                                                <button type="submit" className="p-button" style={{ padding: 8 }}>{mgmtStrings.resetPassword}</button>
                                                &nbsp;<Link to="/"><Button className="p-button-secondary" label={headerStrings.goBack} /></Link>
                                            </div>
                                            {(this.state.message != null) && (
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <Message severity={this.state.message.severity} text={this.state.message.text} />
                                                </div>
                                            )}
                                        </div>
                                    </Form>
                                </Formik>
                            </Panel>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: AuthSelectors.isAuthenticated(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doResetPassword: (email) => dispatch(authenticationResetPassword(email))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordView);