/**
 * General figures data reducer.
 * Contains the current general figures, for each installation.
 */

/* ------------- Types ------------- */

// types
export const GeneralFigureTypes = {
  GENERAL_FIGURES_CLEAR_INSTALLATION: 'GENERAL_FIGURES_CLEAR_INSTALLATION',
  GENERAL_FIGURES_CLEAR_ALL: 'GENERAL_FIGURES_CLEAR_ALL',
  GENERAL_FIGURES_UPDATE: 'GENERAL_FIGURES_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const GeneralFigureSelectors = {
  selectOfInstallation: (state, installationId) => state.generalFigures[installationId]
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onGeneralFiguresUpdate(state, payload) {
  // create or update
  const { installationId, figures } = payload;
  return {
    ...state,
    [installationId]: figures
  };
}

function onGeneralFiguresClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GeneralFigureTypes.GENERAL_FIGURES_UPDATE: {
      return onGeneralFiguresUpdate(state, action.payload);
    }
    case GeneralFigureTypes.GENERAL_FIGURES_CLEAR_INSTALLATION: {
      return onGeneralFiguresClearInstallation(state, action.payload);
    }
    case GeneralFigureTypes.GENERAL_FIGURES_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;