import moment from 'moment';
import evmApi from '../services/EvmApi';
import { GeneralMonthDayTypes } from '../redux/GeneralMonthDayDataRedux';
import { GeneralYearMonthTypes } from '../redux/GeneralYearMonthDataRedux';
import { GeneralYearsTypes } from '../redux/GeneralYearsDataRedux';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to general statistics.
 */

/**
 * Fetches general statistics of a given month, aggregated by day.
 * @param {Number} installationId the installation ID
 * @param {String} date the date, must be within given month
 * @returns a promise with result object
 */
export function fetchGeneralMonthDayData(installationId, date) {
    return (dispatch) => {
        const from = moment(date).startOf('month').format('YYYY-MM-DD');
        const to = moment(date).endOf('month').format('YYYY-MM-DD');
        return evmApi.getGeneralStatistics(installationId, from, to, 'day')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(generalMonthDayDataUpdate(installationId, date, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches general statistics of a given year, aggregated by month.
 * @param {Number} installationId the installation ID
 * @param {String} date the date, must be within given year
 * @returns a promise with result object
 */
export function fetchGeneralYearMonthData(installationId, date) {
    return (dispatch) => {
        const from = moment(date).startOf('year').format('YYYY-MM-DD');
        const to = moment(date).endOf('year').format('YYYY-MM-DD');
        return evmApi.getGeneralStatistics(installationId, from, to, 'month')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(generalYearMonthDataUpdate(installationId, date, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches general statistics of multiple years, aggregated by year.
 * @param {Number} installationId the installation ID
 * @param {String} date the date, represents the latest year
 * @param {Number} nYears the number of years to fetch
 * @returns a promise with result object
 */
export function fetchGeneralYearsData(installationId, date, nYears = 1) {
    return (dispatch) => {
        const from = moment(date).subtract(nYears - 1, 'years').startOf('year').format('YYYY-MM-DD');
        const to = moment(date).endOf('year').format('YYYY-MM-DD');
        return evmApi.getGeneralStatistics(installationId, from, to, 'year')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(generalYearsDataUpdate(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Notifies the reducer that month data was fetched successfully.
 * @param {Number} installationId  the installation ID
 * @param {String} the month date
 * @param {Array} values the fetched statistic values
 * @returns an action
 */
export function generalMonthDayDataUpdate(installationId, date, values) {
    return { type: GeneralMonthDayTypes.GENERAL_MONTH_DAY_UPDATE, payload: { installationId: installationId, date: date, values: values } };
}

/**
 * Notifies the reducer that year data was fetched successfully.
 * @param {Number} installationId  the installation ID
 * @param {String} the year date
 * @param {Array} values the fetched statistic values
 * @returns an action
 */
export function generalYearMonthDataUpdate(installationId, date, values) {
    return { type: GeneralYearMonthTypes.GENERAL_YEAR_MONTH_UPDATE, payload: { installationId: installationId, date: date, values: values } };
}

/**
 * Notifies the reducer that years data was fetched successfully.
 * @param {Number} installationId  the installation ID
 * @param {Array} values the fetched statistic values
 * @returns an action
 */
export function generalYearsDataUpdate(installationId, values) {
    return { type: GeneralYearsTypes.GENERAL_YEARS_UPDATE, payload: { installationId: installationId, values: values } };
}

/**
 * Notifies the reducer that all month data of an installation should be cleared.
 * @param {Number} installationId  the installation ID
 * @returns an action
 */
export function generalMonthDayDataClearInstallation(installationId) {
    return { type: GeneralMonthDayTypes.GENERAL_MONTH_DAY_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all year data of an installation should be cleared.
 * @param {Number} installationId  the installation ID
 * @returns an action
 */
export function generalYearMonthDataClearInstallation(installationId) {
    return { type: GeneralYearMonthTypes.GENERAL_YEAR_MONTH_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all years data of an installation should be cleared.
 * @param {Number} installationId  the installation ID
 * @returns an action
 */
export function generalYearsDataClearInstallation(installationId) {
    return { type: GeneralYearsTypes.GENERAL_YEARS_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all month data should be cleared.
 * @returns an action
 */
export function generalMonthDayDataClearAll() {
    return { type: GeneralMonthDayTypes.GENERAL_MONTH_DAY_CLEAR_ALL };
}

/**
 * Notifies the reducer that all year data should be cleared.
 * @returns an action
 */
export function generalYearMonthDataClearAll() {
    return { type: GeneralYearMonthTypes.GENERAL_YEAR_MONTH_CLEAR_ALL };
}

/**
 * Notifies the reducer that all years data should be cleared.
 * @returns an action
 */
export function generalYearsDataClearAll() {
    return { type: GeneralYearsTypes.GENERAL_YEARS_CLEAR_ALL };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.objNotFound;

        case 422:
            return errorStrings.invalidData;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}