/**
 * Component actual data reducer.
 * Contains the latest value of each component, for each installation.
 */

/* ------------- Types ------------- */

// types
export const ComponentActualTypes = {
  COMPONENT_ACTUAL_CLEAR_INSTALLATION: 'COMPONENT_ACTUAL_CLEAR_INSTALLATION',
  COMPONENT_ACTUAL_CLEAR_ALL: 'COMPONENT_ACTUAL_CLEAR_ALL',
  COMPONENT_ACTUAL_UPDATE_ALL: 'COMPONENT_ACTUAL_UPDATE_ALL',
  COMPONENT_ACTUAL_UPDATE: 'COMPONENT_ACTUAL_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const ComponentActualSelectors = {
  selectAllOfInstallation: (state, installationId) => state.componentActualData[installationId],
  selectOfComponent: (state, installationId, componentId) => {
    const instData = state.componentActualData[installationId];
    return (instData !== undefined) ? instData[componentId] : undefined;
  }
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onComponentActualDataUpdateAll(state, payload) {
  // create or update
  const { installationId, values } = payload;
  const instData = state[installationId];
  const newInstData = (instData !== undefined) ? { ...instData } : {};
  values.forEach((comp) => {
    if (comp.values.length > 0) {
      newInstData[comp.componentId] = comp.values[0];
    }
  });
  return {
    ...state,
    [installationId]: newInstData
  };
}

function onComponentActualDataUpdate(state, payload) {
  // create or update
  const { installationId, componentId, value } = payload;
  const instData = state[installationId];
  if (instData !== undefined) {
    // update component data -> set value
    const newInstData = { ...instData, [componentId]: value };
    return {
      ...state,
      [installationId]: newInstData
    };
  } else {
    // no installation data -> create component with value
    return {
      ...state,
      [installationId]: {
        [componentId]: value
      }
    };
  }
}

function onComponentActualDataClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ComponentActualTypes.COMPONENT_ACTUAL_UPDATE_ALL: {
      return onComponentActualDataUpdateAll(state, action.payload);
    }
    case ComponentActualTypes.COMPONENT_ACTUAL_UPDATE: {
      return onComponentActualDataUpdate(state, action.payload);
    }
    case ComponentActualTypes.COMPONENT_ACTUAL_CLEAR_INSTALLATION: {
      return onComponentActualDataClearInstallation(state, action.payload);
    }
    case ComponentActualTypes.COMPONENT_YEARS_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;