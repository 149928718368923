/**
 * Component control reducer.
 * Contains the control signals of each component, for each installation.
 */

/* ------------- Types ------------- */

// types
export const ComponentControlTypes = {
  COMPONENT_CONTROL_CLEAR_INSTALLATION: 'COMPONENT_CONTROL_CLEAR_INSTALLATION',
  COMPONENT_CONTROL_CLEAR_ALL: 'COMPONENT_CONTROL_CLEAR_ALL',
  COMPONENT_CONTROL_UPDATE_ALL: 'COMPONENT_CONTROL_UPDATE_ALL',
  COMPONENT_CONTROL_UPDATE: 'COMPONENT_CONTROL_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const ComponentControlSelectors = {
  selectAllOfInstallation: (state, installationId) => state.componentControls[installationId],
  selectAllOfComponent: (state, installationId, componentId) => {
    const instData = state.componentControls[installationId];
    return (instData !== undefined) ? instData[componentId] : undefined;
  }
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onComponentControlsUpdateAll(state, payload) {
  // create or update
  const { installationId, componentSignals } = payload;
  const instData = state[installationId];
  const newInstData = (instData !== undefined) ? { ...instData } : {};
  componentSignals.forEach((comp) => {
    // replace underscores from component name
    comp.signals.forEach((sig) => {
      sig.name = sig.signal.replace(/_/g, ' ');
    });
    newInstData[comp.componentId] = comp.signals;
  });
  return {
    ...state,
    [installationId]: newInstData
  };
}

function onComponentControlsUpdate(state, payload) {
  // create or update
  const { installationId, componentId, signals } = payload;
  // replace underscores from component name
  signals.forEach((sig) => {
    sig.name = sig.signal.replace(/_/g, ' ');
  });
  const instData = state[installationId];
  if (instData !== undefined) {
    // update component data -> set value
    const newInstData = { ...instData, [componentId]: signals };
    return {
      ...state,
      [installationId]: newInstData
    };
  } else {
    // no installation data -> create component with value
    return {
      ...state,
      [installationId]: {
        [componentId]: signals
      }
    };
  }
}

function onComponentControlsClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ComponentControlTypes.COMPONENT_CONTROL_UPDATE_ALL: {
      return onComponentControlsUpdateAll(state, action.payload);
    }
    case ComponentControlTypes.COMPONENT_CONTROL_UPDATE: {
      return onComponentControlsUpdate(state, action.payload);
    }
    case ComponentControlTypes.COMPONENT_CONTROL_CLEAR_INSTALLATION: {
      return onComponentControlsClearInstallation(state, action.payload);
    }
    case ComponentControlTypes.COMPONENT_CONTROL_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;