import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { clearEverything, clearInstallation } from '../actions/CommonActions';
import { authenticationResetPassword } from '../actions/AuthActions';
import { fetchAllInstallations, installationRemove } from '../actions/InstallationActions';
import { disconnectInstallation, deleteUser } from '../actions/ManagementActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { InstallationSelectors } from '../redux/InstallationRedux';
import { headerStrings, mgmtStrings, errorStrings, installationStrings } from '../i18n/translations';

/**
 * The user management page.
 */
class UserView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.forceRefresh = this.forceRefresh.bind(this);
        this.showDisconnectDialog = this.showDisconnectDialog.bind(this);
        this.closeDisconnectDialog = this.closeDisconnectDialog.bind(this);
        this.confirmDisconnect = this.confirmDisconnect.bind(this);
        this.showResetPasswordDialog = this.showResetPasswordDialog.bind(this);
        this.closeResetPasswordDialog = this.closeResetPasswordDialog.bind(this);
        this.confirmResetPassword = this.confirmResetPassword.bind(this);
        this.showDeleteAccountDialog = this.showDeleteAccountDialog.bind(this);
        this.closeDeleteAccountDialog = this.closeDeleteAccountDialog.bind(this);
        this.confirmDeleteAccount = this.confirmDeleteAccount.bind(this);
        this.state = {
            isRefreshing: false,
            error: null,
            disconnectDialogVisible: false,
            disconnectDialogId: null,
            resetPasswordDialogVisible: false,
            deleteAccountDialogVisible: false
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Home view did mount.");
        }
        this.onViewLoaded();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    /**
     * Refreshes all visible data. Fetches data from server where necessary.
     * @param {Boolean} force fetch even if data is present
     */
    refreshAllData(force = false) {
        this.setState({ isRefreshing: true, error: null });

        let fetchTasks = [];

        // load installations
        if ((this.props.installations.length === 0) || force) {
            fetchTasks.push(this.props.doFetchInstallations());
        }

        if (fetchTasks.length > 0) {
            // fetch all data in parallel
            Promise.all(fetchTasks).then((results) => {
                if (!this.mounted) return;
                // check results
                let errors = [];
                results.forEach((result) => {
                    if (result.success !== true) {
                        errors.push(result.message);
                    }
                });
                this.setState({
                    isRefreshing: false,
                    error: (errors.length === 0) ? null : errors.join(' | ')
                });
            });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    forceRefresh() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                this.refreshAllData(true);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        } else {
            // error
            this.setState({ error: errorStrings.notAuthenticated });
        }
    }

    onViewLoaded() {
        if (this.props.isAuthenticated === true) {
            if (this.props.isExpired === false) {
                // fetch new data
                this.refreshAllData(false);
            } else {
                this.setState({ error: errorStrings.tokenExpired });
            }
        }
    }

    showDisconnectDialog(instId) {
        this.setState({ disconnectDialogVisible: true, disconnectDialogId: instId });
    }

    closeDisconnectDialog() {
        this.setState({ disconnectDialogVisible: false, disconnectDialogId: null });
    }

    confirmDisconnect() {
        const instId = this.state.disconnectDialogId;
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Disconnecting from installation " + instId);
        }
        this.setState({ error: null });
        if (instId != null) {
            // disconnect if id not null
            this.props.doDisconnectInstallation(instId)
                .then((result) => {
                    if (result.success === true) {
                        // clear data
                        this.props.doRemoveInstallation(instId);
                        this.props.doClearInstallationData(instId);
                    } else {
                        this.setState({ error: result.message });
                    }
                });
        }
        this.closeDisconnectDialog();
    }

    showDeleteAccountDialog() {
        this.setState({ deleteAccountDialogVisible: true });
    }

    closeDeleteAccountDialog() {
        this.setState({ deleteAccountDialogVisible: false });
    }

    confirmDeleteAccount() {
        // delete and then logout and clear everything
        this.setState({ error: null });
        this.props.doDeleteUser()
            .then((result) => {
                if (result.success === true) {
                    // clear data
                    this.props.doClearEverything();
                } else {
                    this.setState({ error: result.message });
                }
            });
        this.closeDeleteAccountDialog();
    }

    showResetPasswordDialog() {
        this.setState({ resetPasswordDialogVisible: true });
    }

    closeResetPasswordDialog() {
        this.setState({ resetPasswordDialogVisible: false });
    }

    confirmResetPassword() {
        // reset pw
        this.props.doResetPassword(this.props.userName)
            .then((result) => {
                this.setState({ error: result.message });
            });
        this.closeResetPasswordDialog();
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" },
            { name: headerStrings.user, enabled: false, active: true, icon: "fas fa-user-cog", path: "/user" },
            { name: headerStrings.enterCode, enabled: true, active: false, icon: "fas fa-key", path: "/connect" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-sm-8 p-md-6">
                            {(this.state.error != null) && (
                                <div style={{ padding: '1em' }}>
                                    <Message severity="error" text={this.state.error} />
                                </div>
                            )}
                            <Panel header={mgmtStrings.userAccount + " (" + this.props.userName +")"}>
                                <div><Button className="p-button-warning" label={mgmtStrings.resetPassword} onClick={this.showResetPasswordDialog} /></div>
                                <Dialog
                                    header={mgmtStrings.confirm}
                                    footer={<div><Button className="p-button-danger" label={mgmtStrings.ok} onClick={this.confirmResetPassword} /><Button label={mgmtStrings.cancel} onClick={this.closeResetPasswordDialog} /></div>}
                                    visible={this.state.resetPasswordDialogVisible}
                                    modal={true}
                                    onHide={this.closeResetPasswordDialog}>
                                        {mgmtStrings.passwordResetConfirm}
                                </Dialog>
                            </Panel>
                            <div style={{ margin: 20 }}></div>
                            <Panel header={mgmtStrings.linkedInstallations}>
                                {(this.props.installations.length > 0) ?
                                    this.props.installations.map((inst) => {
                                        return (
                                            <div style={{ margin: 4 }}>
                                                <Button className="p-button-danger" icon="pi pi-trash" onClick={() => this.showDisconnectDialog(inst.id)} />&nbsp;&nbsp;
                                                <Link to={"/installations/" + inst.id + "/overview"}>{inst.name}</Link>
                                            </div>
                                        );
                                    }) : (
                                        <div style={{ margin: 4 }}>{installationStrings.noInstallations}</div>
                                    )
                                }
                                <Dialog
                                    header={mgmtStrings.confirm}
                                    footer={<div><Button className="p-button-danger" label={mgmtStrings.ok} onClick={this.confirmDisconnect} /><Button label={mgmtStrings.cancel} onClick={this.closeDisconnectDialog} /></div>}
                                    visible={this.state.disconnectDialogVisible}
                                    modal={true}
                                    onHide={this.closeDisconnectDialog}>
                                        {mgmtStrings.unlinkConfirm}
                                </Dialog>
                            </Panel>
                            <div style={{ margin: 20 }}></div>
                            <Panel header={mgmtStrings.dangerZone}>
                                <div><Button className="p-button-danger" label={mgmtStrings.deleteAccount} onClick={this.showDeleteAccountDialog}/></div>
                                <Dialog
                                    header={mgmtStrings.confirm}
                                    footer={<div><Button className="p-button-danger" label={mgmtStrings.ok} onClick={this.confirmDeleteAccount} /><Button label={mgmtStrings.cancel} onClick={this.closeDeleteAccountDialog} /></div>}
                                    visible={this.state.deleteAccountDialogVisible}
                                    modal={true}
                                    onHide={this.closeDeleteAccountDialog}>
                                        {mgmtStrings.deleteAccountConfirm}
                                </Dialog>
                            </Panel>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        userName: AuthSelectors.selectUserName(state),
        installations: InstallationSelectors.selectAll(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doFetchInstallations: () => dispatch(fetchAllInstallations()),
        doRemoveInstallation: (instId) => dispatch(installationRemove(instId)),
        doDisconnectInstallation: (instId) => dispatch(disconnectInstallation(instId)),
        doDeleteUser: () => dispatch(deleteUser()),
        doClearInstallationData: (instId) => dispatch(clearInstallation(instId)),
        doClearEverything: () => dispatch(clearEverything()),
        doResetPassword: (userName) => dispatch(authenticationResetPassword(userName))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserView);