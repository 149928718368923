/**
 * Component reducer. Contains a list of all available components, for each installation.
 */

/* ------------- Types ------------- */

// types
export const ComponentTypes = {
  COMPONENTS_LOADED: 'COMPONENTS_LOADED',
  COMPONENTS_CLEAR_INSTALLATION: 'COMPONENTS_CLEAR_INSTALLATION',
  COMPONENTS_CLEAR_ALL: 'COMPONENTS_CLEAR_ALL',
  COMPONENT_UPDATE: 'COMPONENT_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const ComponentSelectors = {
  selectAll: (state, installationId) => state.components[installationId],
  selectOne: (state, installationId, componentId) => {
    const compList = state.components[installationId];
    if (compList !== undefined) {
      // eslint-disable-next-line
      return compList.find((comp) => (comp.id == componentId));
    }
    return undefined;
  }
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onComponentsLoaded(state, payload) {
  const installationId = payload.installationId;
  const components = payload.components;
  // replace underscores from component name
  components.forEach((comp) => {
    comp.name = comp.name.replace(/_/g, ' ');
  });
  // take existing state and add or overwrite key/value
  return { ...state, [installationId]: components };
}

function onComponentUpdate(state, payload) {
  const installationId = payload.installationId;
  const component = payload.component;
  // replace underscores from component name
  component.name = component.name.replace(/_/g, ' ');
  if (state[installationId] !== undefined) {
    // eslint-disable-next-line
    const idx = state[installationId].findIndex((comp) => (comp.id == component.id));
    if (idx >= 0) {
      // replace component
      const newComponents = state[installationId].slice();
      newComponents[idx] = component;
      return { ...state, [installationId]: newComponents };
    } else {
      // push component
      const newComponents = [ ...state[installationId], component ];
      return { ...state, [installationId]: newComponents };
    }
  }
  // push installation
  return { ...state, [installationId]: [ component ] };
}

function onComponentsClearInstallation(state, payload) {
  // remove given key and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ComponentTypes.COMPONENTS_LOADED: {
      return onComponentsLoaded(state, action.payload);
    }
    case ComponentTypes.COMPONENT_UPDATE: {
      return onComponentUpdate(state, action.payload);
    }
    case ComponentTypes.COMPONENTS_CLEAR_INSTALLATION: {
      return onComponentsClearInstallation(state, action.payload);
    }
    case ComponentTypes.COMPONENTS_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;