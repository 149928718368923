import React, { Component } from 'react';
import { getContrastColor } from './util/ComponentUtils';

/**
 * A component's actual value.
 *
 */
class ActualValue extends Component {

    render() {
        const fgColor = (this.props.color !== undefined) ? getContrastColor(this.props.color) : "#ffffff";
        return (
            <div style={{ backgroundColor: this.props.color, color: fgColor, textAlign: 'center', padding: '0.5em', borderRadius: 4 }}>
                {(this.props.icon !== undefined) && (<i className={this.props.icon} style={{ fontSize: '1.5em', float: 'left' }}></i>)}
                {(this.props.name !== undefined) && (<span style={{ fontSize: '0.9em' }}>{this.props.name}<br /></span>)}
                <span style={{ fontSize: '1.2em' }}>{(this.props.value !== undefined) ? this.props.value.toFixed(this.props.decimals) : "---"}</span>&nbsp;
                <span style={{ fontSize: '0.9em' }}>{this.props.unit}</span>
            </div>
        )
    }
}

// default props
ActualValue.defaultProps = {
    color: '#404040',
    unit: '',
    decimals: 0
};

export default ActualValue;