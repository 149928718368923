import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { DataView } from 'primereact/dataview';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ServiceNotificationItem from '../components/ServiceNotificationItem';
import { authenticationLogin, authenticationLogout } from '../actions/AuthActions';
import { fetchServiceNotifications } from '../actions/ServiceNotificationActions';
import { AuthSelectors } from '../redux/AuthRedux';
import { ServiceNotificationSelectors } from '../redux/ServiceNotificationRedux';
import { headerStrings, homeStrings } from '../i18n/translations';

/**
 * The home page.
 */
class HomeView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.refreshAllData = this.refreshAllData.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.state = {
            isRefreshing: false,
            error: null
        };
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Home view did mount.");
            Reactotron.log("History length " + this.props.history.length);
        }
        // fetch notifications
        this.refreshAllData(false);
        // redirect to list if authenticated and fresh window/tab opened
        if ((this.props.isAuthenticated === true) && (this.props.isExpired === false) && (1 >= this.props.history.length)) {
            setTimeout(() => {
                // go to installation list
                window.location.hash = "#/installations";
            }, 1500);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    forceRefresh() {
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("clicked refresh");
        }
        this.refreshAllData(true);
    }

    /**
     * Refreshes all visible data. Fetches data from server where necessary.
     * @param {Boolean} force fetch even if data is present
     */
    refreshAllData(force = false) {
        let tasks = [];
        this.setState({ isRefreshing: true, error: null });
        if ((this.props.serviceNotifications.length === 0) || force) {
            if (BuildConfig.isReactotronEnabled) {
                Reactotron.log("Require service notifications refresh");
            }
            tasks.push(this.props.doFetchServiceNotifications());
        }

        // fetch all data in parallel
        if (tasks.length > 0) {
            Promise.all(tasks).then((results) => {
                if (!this.mounted) return;
                // check results
                let errors = [];
                results.forEach((result) => {
                    if (result.success !== true) {
                        errors.push(result.message);
                    }
                });
                this.setState({
                    isRefreshing: false,
                    error: (errors.length === 0) ? null : errors.join(' | ')
                });
            });
        } else {
            this.setState({ isRefreshing: false });
        }
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: true, active: false, icon: "fas fa-list-ol", path: "/installations" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.state.isRefreshing} onRefreshClick={this.forceRefresh} />
                <div className="page-content">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-md-8">
                            <h1>{homeStrings.welcome}.</h1>
                            {((this.props.isAuthenticated === true) && (this.props.isExpired === false)) ?
                                (<div><p>{homeStrings.loggedInAs} {this.props.userName}.</p></div>) :
                                (<div>
                                    <p>{homeStrings.notLoggedIn}.</p>
                                    <span style={{ marginRight: '1em' }}>
                                        <Link to="/login"><Button className="p-button-rounded" label={headerStrings.login} tooltip={headerStrings.login} /></Link>
                                    </span>
                                    <span>
                                        <Link to="/register"><Button className="p-button-rounded" label={headerStrings.register} tooltip={headerStrings.register} /></Link>
                                    </span>
                                </div>)}
                            <div style={{ marginBottom: '5em' }}></div>
                        </div>
                    </div>
                    <div className="p-grid p-justify-center p-align-start">
                        <div className="p-col-12 p-lg-6">
                            <Panel header={homeStrings.info}>
                                <p>
                                    {/* static! TODO support other language */}
                                    <a href="/content/webportal_manual_de.pdf" rel="noopener noreferrer" target="_blank">{homeStrings.manual} ({homeStrings.german})</a>
                                </p>
                            </Panel>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <Panel header={homeStrings.serviceNotifications}>
                                <DataView value={this.props.serviceNotifications}
                                    layout="list"
                                    paginator={false}
                                    emptyMessage={homeStrings.noNotifications}
                                    itemTemplate={this.renderListItem} />
                            </Panel>
                        </div>
                    </div>
                    <div style={{ marginBottom: '5em' }}></div>
                </div>
                <Footer />
            </div>
        );
    }

    /**
     * Renders a template for each list item.
     * Item may be undefined or null!
     */
    renderListItem(item) {
        return (
            <ServiceNotificationItem notification={item} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        userName: AuthSelectors.selectUserName(state),
        serviceNotifications: ServiceNotificationSelectors.selectAll(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doLogin: (email, password) => dispatch(authenticationLogin(email, password)),
        doLogout: () => dispatch(authenticationLogout()),
        doFetchServiceNotifications: () => dispatch(fetchServiceNotifications())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeView);