import { authenticationClear } from './AuthActions';
import { installationsClear } from './InstallationActions';
import { componentsClearAll, componentsClearInstallation } from './ComponentActions';
import { buildingUnitsClearAll, buildingUnitsClearInstallation } from './BuildingUnitActions';
import { generalFiguresClearAll } from './GeneralFigureActions';
import { generalMonthDayDataClearAll, generalYearMonthDataClearAll, generalYearsDataClearAll } from './GeneralStatisticActions';
import { componentActualDataClearAll, componentDayTimeDataClearAll, componentMonthDayDataClearAll, componentYearMonthDataClearAll, componentYearsDataClearAll, componentActualDataClearInstallation, componentDayTimeDataClearInstallation, componentMonthDayDataClearInstallation, componentYearMonthDataClearInstallation, componentYearsDataClearInstallation } from './ComponentDataActions';
import { componentControlsClearAll, componentControlsClearInstallation } from './ComponentControlActions';
import { listViewStateClear } from './InstallationListViewActions';

/**
 * Common actions.
 */

/**
 * Clears the whole redux store, e.g. after logout.
 */
export function clearEverything() {
    return (dispatch) => {
        dispatch(authenticationClear());
        dispatch(installationsClear());
        dispatch(componentsClearAll());
        dispatch(buildingUnitsClearAll());
        dispatch(componentActualDataClearAll());
        dispatch(componentDayTimeDataClearAll());
        dispatch(componentMonthDayDataClearAll());
        dispatch(componentYearMonthDataClearAll());
        dispatch(componentYearsDataClearAll());
        dispatch(componentControlsClearAll());
        dispatch(generalFiguresClearAll());
        dispatch(generalMonthDayDataClearAll());
        dispatch(generalYearMonthDataClearAll());
        dispatch(generalYearsDataClearAll());
        dispatch(listViewStateClear());
        return { success: true, message: "" };
    };
}

/**
 * Clears all data of a specific installation.
 * @param {Number} installationId the installation ID
 */
export function clearInstallation(installationId) {
    return (dispatch) => {
        dispatch(componentsClearInstallation(installationId));
        dispatch(buildingUnitsClearInstallation(installationId));
        dispatch(componentActualDataClearInstallation(installationId));
        dispatch(componentDayTimeDataClearInstallation(installationId));
        dispatch(componentMonthDayDataClearInstallation(installationId));
        dispatch(componentYearMonthDataClearInstallation(installationId));
        dispatch(componentYearsDataClearInstallation(installationId));
        dispatch(componentControlsClearInstallation(installationId));
        return { success: true, message: "" };
    };
}