import moment from 'moment';
import evmApi from '../services/EvmApi';
import { ComponentActualTypes } from '../redux/ComponentActualDataRedux';
import { ComponentDayTimeTypes } from '../redux/ComponentDayTimeDataRedux';
import { ComponentMonthDayTypes } from '../redux/ComponentMonthDayDataRedux';
import { ComponentYearMonthTypes } from '../redux/ComponentYearMonthDataRedux';
import { ComponentYearsTypes } from '../redux/ComponentYearsDataRedux';
import { errorStrings } from '../i18n/translations';

/**
 * Actions related to component data.
 */

/**
 * Fetches the latest values of all components.
 * @param {Number} installationId the installation ID
 * @returns a promise with result object
 */
export function fetchAllComponentsActualData(installationId) {
    return (dispatch) => {
        return evmApi.getAllLatestComponentData(installationId, 'value')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    dispatch(componentActualDataUpdateAll(installationId, response.data));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches the latest value of a component.
 * @param {Number} installationId the installation ID
 * @returns a promise with result object
 */
export function fetchComponentActualData(installationId, componentId) {
    return (dispatch) => {
        return evmApi.getLatestComponentData(installationId, componentId, 'value')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { values } = response.data;
                    if (values.length > 0) {
                        dispatch(componentActualDataUpdate(installationId, componentId, values[0]));
                    }
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches component continuous date of a given day, in 15-minute steps.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {String} date the date, must be within given day
 * @returns a promise with result object
 */
export function fetchComponentDayTimeData(installationId, componentId, date) {
    return (dispatch) => {
        const from = moment(date).startOf('day').toISOString();
        const to = moment(date).endOf('day').toISOString();
        return evmApi.getContinuousComponentData(installationId, componentId, from, to, 'value')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { values } = response.data;
                    dispatch(componentDayTimeDataUpdate(installationId, componentId, date, values));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches component statistics of a given month, aggregated by day.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {String} date the date, must be within given month
 * @returns a promise with result object
 */
export function fetchComponentMonthDayData(installationId, componentId, date) {
    return (dispatch) => {
        const from = moment(date).startOf('month').format('YYYY-MM-DD');
        const to = moment(date).endOf('month').format('YYYY-MM-DD');
        return evmApi.getComponentStatistics(installationId, componentId, from, to, 'day')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { values } = response.data;
                    dispatch(componentMonthDayDataUpdate(installationId, componentId, date, values));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches component statistics of a given year, aggregated by month.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {String} date the date, must be within given year
 * @returns a promise with result object
 */
export function fetchComponentYearMonthData(installationId, componentId, date) {
    return (dispatch) => {
        const from = moment(date).startOf('year').format('YYYY-MM-DD');
        const to = moment(date).endOf('year').format('YYYY-MM-DD');
        return evmApi.getComponentStatistics(installationId, componentId, from, to, 'month')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { values } = response.data;
                    dispatch(componentYearMonthDataUpdate(installationId, componentId, date, values));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Fetches component statistics of a given year, aggregated by month.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {String} date the date, represents the latest year
 * @param {Number} nYears the number of years to fetch
 * @returns a promise with result object
 */
export function fetchComponentYearsData(installationId, componentId, date, nYears = 1) {
    return (dispatch) => {
        const from = moment(date).subtract(nYears - 1, 'years').startOf('year').format('YYYY-MM-DD');
        const to = moment(date).endOf('year').format('YYYY-MM-DD');
        return evmApi.getComponentStatistics(installationId, componentId, from, to, 'year')
            .then((response) => {
                if ((response.status >= 200) && (response.status < 300)) {
                    // ok
                    const { values } = response.data;
                    dispatch(componentYearsDataUpdate(installationId, componentId, values));
                    return { success: true, message: "" };
                } else {
                    // failed
                    return { success: false, message: getErrorMessage(response.status) };
                }
            });
    };
}

/**
 * Notifies the reducer that actual data was fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Array} values the fetched component data values
 * @returns an action
 */
export function componentActualDataUpdateAll(installationId, values) {
    return {
        type: ComponentActualTypes.COMPONENT_ACTUAL_UPDATE_ALL,
        payload: { installationId: installationId, values: values }
    };
}

/**
 * Notifies the reducer that actual data was fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {Object} value the fetched component data value
 * @returns an action
 */
export function componentActualDataUpdate(installationId, componentId, value) {
    return {
        type: ComponentActualTypes.COMPONENT_ACTUAL_UPDATE,
        payload: { installationId: installationId, componentId: componentId, value: value }
    };
}

/**
 * Notifies the reducer that day data was fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {String} the day date
 * @param {Array} values the fetched continuous values
 * @returns an action
 */
export function componentDayTimeDataUpdate(installationId, componentId, date, values) {
    return {
        type: ComponentDayTimeTypes.COMPONENT_DAY_TIME_UPDATE,
        payload: { installationId: installationId, componentId: componentId, date: date, values: values }
    };
}

/**
 * Notifies the reducer that month data was fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {String} the month date
 * @param {Array} values the fetched statistic values
 * @returns an action
 */
export function componentMonthDayDataUpdate(installationId, componentId, date, values) {
    return {
        type: ComponentMonthDayTypes.COMPONENT_MONTH_DAY_UPDATE,
        payload: { installationId: installationId, componentId: componentId, date: date, values: values }
    };
}

/**
 * Notifies the reducer that year data was fetched successfully.
 * @param {Number} installationId the installation ID
 * @param {Number} componentId the component ID
 * @param {String} the year date
 * @param {Array} values the fetched statistic values
 * @returns an action
 */
export function componentYearMonthDataUpdate(installationId, componentId, date, values) {
    return {
        type: ComponentYearMonthTypes.COMPONENT_YEAR_MONTH_UPDATE,
        payload: { installationId: installationId, componentId: componentId, date: date, values: values }
    };
}

/**
 * Notifies the reducer that year data was fetched successfully.
 * @param {Number} installationId  the installation ID
 * @param {Number} componentId the component ID
 * @param {Array} values the fetched statistic values
 * @returns an action
 */
export function componentYearsDataUpdate(installationId, componentId, values) {
    return {
        type: ComponentYearsTypes.COMPONENT_YEARS_UPDATE,
        payload: { installationId: installationId, componentId: componentId, values: values }
    };
}

/**
 * Notifies the reducer that all actual data of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function componentActualDataClearInstallation(installationId) {
    return { type: ComponentActualTypes.COMPONENT_ACTUAL_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all day data of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function componentDayTimeDataClearInstallation(installationId) {
    return { type: ComponentDayTimeTypes.COMPONENT_DAY_TIME_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all month data of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function componentMonthDayDataClearInstallation(installationId) {
    return { type: ComponentMonthDayTypes.COMPONENT_MONTH_DAY_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all year data of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function componentYearMonthDataClearInstallation(installationId) {
    return { type: ComponentYearMonthTypes.COMPONENT_YEAR_MONTH_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all years data of an installation should be cleared.
 * @param {Number} installationId the installation ID
 * @returns an action
 */
export function componentYearsDataClearInstallation(installationId) {
    return { type: ComponentYearsTypes.COMPONENT_YEARS_CLEAR_INSTALLATION, payload: installationId };
}

/**
 * Notifies the reducer that all actual data should be cleared.
 * @returns an action
 */
export function componentActualDataClearAll() {
    return { type: ComponentActualTypes.COMPONENT_ACTUAL_CLEAR_ALL };
}

/**
 * Notifies the reducer that all day data should be cleared.
 * @returns an action
 */
export function componentDayTimeDataClearAll() {
    return { type: ComponentDayTimeTypes.COMPONENT_DAY_TIME_CLEAR_ALL };
}

/**
 * Notifies the reducer that all month data should be cleared.
 * @returns an action
 */
export function componentMonthDayDataClearAll() {
    return { type: ComponentMonthDayTypes.COMPONENT_MONTH_DAY_CLEAR_ALL };
}

/**
 * Notifies the reducer that all year data should be cleared.
 * @returns an action
 */
export function componentYearMonthDataClearAll() {
    return { type: ComponentYearMonthTypes.COMPONENT_YEAR_MONTH_CLEAR_ALL };
}

/**
 * Notifies the reducer that all year data should be cleared.
 * @returns an action
 */
export function componentYearsDataClearAll() {
    return { type: ComponentYearsTypes.COMPONENT_YEARS_CLEAR_ALL };
}

function getErrorMessage(status) {
    switch (status) {
        case 400:
            return errorStrings.invalidParameter;

        case 401:
            return errorStrings.notAuthenticated;

        case 403:
            return errorStrings.accessDenied;

        case 404:
            return errorStrings.objNotFound;

        case 422:
            return errorStrings.invalidData;

        case 408:
        case 0:
            return errorStrings.timeout;

        case 503:
        case 504:
            return errorStrings.serviceNotAvailable;

        case 500:
            return errorStrings.internalError;

        default:
            return errorStrings.unknownError;
    }
}