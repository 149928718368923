import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import HomeView from './views/HomeView';
import LoginView from './views/LoginView';
import RegisterView from './views/RegisterView';
import ConnectView from './views/ConnectView';
import ResetPasswordView from './views/ResetPasswordView';
import InstallationListView from './views/InstallationListView';
import InstallationOverviewView from './views/InstallationOverviewView';
import InstallationPhotovoltaicsView from './views/InstallationPhotovoltaicsView';
import InstallationBatteryView from './views/InstallationBatteryView';
import BuildingUnitOverviewView from './views/BuildingUnitOverviewView';
import BuildingUnitElectricityView from './views/BuildingUnitElectricityView';
import BuildingUnitThermalView from './views/BuildingUnitThermalView';
import BuildingUnitWaterView from './views/BuildingUnitWaterView';
import BuildingUnitControlView from './views/BuildingUnitControlView';
import ComponentOverviewView from './views/ComponentOverviewView';
import UserView from './views/UserView';
import { AuthSelectors } from './redux/AuthRedux';

/**
 * The root component.
 */
class App extends Component {

  render() {
    // can only navigate to view when session is valid. does NOT affect currently open view!
    const authValid = ((this.props.isAuthenticated === true) && (this.props.isExpired === false));

    return (
      <div>
        <HashRouter>
          <Switch>
            <Route exact path="/" component={HomeView} />
            <Route exact path="/login" component={LoginView} />
            <Route exact path="/register" component={RegisterView} />
            <Route exact path="/resetpassword" component={ResetPasswordView} />
            <Route exact path="/connect" component={ConnectView} />
            <Route exact path="/connect/:code" component={ConnectView} />
            {authValid && (<Route exact path="/user" component={UserView} />)}
            {authValid && (<Route exact path="/installations" component={InstallationListView} />)}
            {authValid && (<Route exact path="/installations/:instId/overview" component={InstallationOverviewView} />)}
            {authValid && (<Route exact path="/installations/:instId/pv" component={InstallationPhotovoltaicsView} />)}
            {authValid && (<Route exact path="/installations/:instId/battery" component={InstallationBatteryView} />)}
            {authValid && (<Route exact path="/installations/:instId/units/:unitId/overview" component={BuildingUnitOverviewView} />)}
            {authValid && (<Route exact path="/installations/:instId/units/:unitId/electricity" component={BuildingUnitElectricityView} />)}
            {authValid && (<Route exact path="/installations/:instId/units/:unitId/thermal" component={BuildingUnitThermalView} />)}
            {authValid && (<Route exact path="/installations/:instId/units/:unitId/water" component={BuildingUnitWaterView} />)}
            {authValid && (<Route exact path="/installations/:instId/units/:unitId/control" component={BuildingUnitControlView} />)}
            {authValid && (<Route exact path="/installations/:instId/components/:compId/overview" component={ComponentOverviewView} />)}
            <Route render={() => (<Redirect to="/" />)} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      isAuthenticated: AuthSelectors.isAuthenticated(state),
      isExpired: AuthSelectors.isExpired(state)
  }
};

export default connect(
  mapStateToProps,
  null
)(App);
