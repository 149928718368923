import moment from 'moment';
import { convertHexToRGBA } from './ComponentUtils';
import { componentStrings, graphStrings, installationStrings, monthShortNames, monthLongNames } from '../../i18n/translations';

/**
 * Gets the current date, depending on the graph's display period.
 * @param {String} term the display period
 * @param {Date|Moment|String} date the reference date, can be JS date, moment or string
 * @returns a date
 */
export function getCurrentDate(term, date) {
    switch (term) {
        case 'DayTime': {
            return moment(date).startOf('day');
        }
        case 'MonthDays': {
            return moment(date).startOf('month');
        }
        case 'YearMonths': {
            return moment(date).startOf('year');
        }
        // do not care about 'Years'
        default: {
            return moment(date).startOf('year');
        }
    }
}

/**
 * Gets the next date, depending on the graph's display period and the given date.
 * @param {String} term the display period
 * @param {Date|Moment|String} date the reference date, can be JS date, moment or string
 * @returns a date
 */
export function getNextDate(term, date) {
    switch (term) {
        case 'DayTime': {
            return moment(date).add(1, 'days').startOf('day');
        }
        case 'MonthDays': {
            return moment(date).add(1, 'months').startOf('month');
        }
        case 'YearMonths': {
            return moment(date).add(1, 'years').startOf('year');
        }
        // do not care about 'Years'
        default: {
            return moment(date).add(1, 'years').startOf('year');
        }
    }
}

/**
 * Gets the previous date, depending on the graph's display period and the given date.
 * @param {String} term the display period
 * @param {Date|Moment|String} date the reference date, can be JS date, moment or string
 * @returns a date
 */
export function getPreviousDate(term, date) {
    switch (term) {
        case 'DayTime': {
            return moment(date).subtract(1, 'days').startOf('day');
        }
        case 'MonthDays': {
            return moment(date).subtract(1, 'months').startOf('month');
        }
        case 'YearMonths': {
            return moment(date).subtract(1, 'years').startOf('year');
        }
        // do not care about 'Years'
        default: {
            return moment(date).subtract(1, 'years').startOf('year');
        }
    }
}

/**
 * Builds an array with X-axis labels for charts.
 * @param {String} term
 * @param {Date|Moment|String} date the reference date, can be JS date, moment or string
 * @param {Number} nYears the number of years to display, if the chart should just display multiple years
 * @returns an array
 */
export function getXAxisLabels(term, date, nYears = 1) {
    switch (term) {
        case 'DayTime': {
            // 1 day, display time of day, include next midnight
            const end = moment(date).add(1, 'days').startOf('day');
            let d = moment(date).startOf('day');
            let labels = [];
            while (d.isSameOrBefore(end)) {
                labels.push(d.date());
                d.add(15, 'minutes');
            }
            return labels;
        }
        case 'MonthDays': {
            // 1 month, display day number
            const end = moment(date).endOf('month');
            let d = moment(date).startOf('month');
            let labels = [];
            while (d.isSameOrBefore(end)) {
                labels.push(d.date());
                d.add(1, 'days');
            }
            return labels;
        }
        case 'YearMonths': {
            // 1 year, display short name of month
            const end = moment(date).endOf('year');
            let d = moment(date).startOf('year');
            let labels = [];
            while (d.isSameOrBefore(end)) {
                labels.push(monthShortNames[d.month()]);
                d.add(1, 'months');
            }
            return labels;
        }
        // 'Years'
        default: {
            // n-1 years back, plus current one
            const end = moment(date).year();
            let d = end - (nYears - 1);
            let labels = [];
            while (d <= end) {
                labels.push(d);
                d++;
            }
            return labels;
        }
    }
}

/**
 * Gets an X-axis scale label, according to the given display period
 * @param {String} term the display period
 * @param {Date|Moment|String} date the reference date, within the given period
 * @returns a string
 */
export function getXAxisScaleLabel(term, date) {
    switch (term) {
        case 'DayTime': {
            return graphStrings.timeOfDay;
        }
        case 'MonthDays': {
            // 1 month, display day number
            const d = moment(date);
            return monthLongNames[d.month()] + " " + d.year();
        }
        case 'YearMonths': {
            // 1 year, display short name of month
            return graphStrings.month + " (" + moment(date).year() + ")";
        }
        // 'Years'
        default: {
            // n-1 years back, plus current one
            return graphStrings.years;
        }
    }
}

/**
 * Gets the Y-axis scale labels, according to the given component type.
 * @param {String} cType the component type
 * @returns an array with two labels (actual value and accumulated value)
 */
export function getYAxisScaleLabels(cType) {
    switch (cType) {
        case 'Verbrauch':
        case 'Produktion':
        case 'VerbrauchTot':
        case 'ProduktionTot':
        case 'ProduktionNetto':
        case 'Batterie':
            return [componentStrings.electricPower + " (kW)", componentStrings.electricEnergy + " (kWh)"];

        case 'Waermezaehler':
            return [componentStrings.thermalPower + " (kW)", componentStrings.thermalEnergy + " (kWh)"];

        case 'Wasserzaehler':
            return [componentStrings.waterFlow + " (l/h)", componentStrings.waterVolume + " (l)"];

        case 'Temperatur':
            return [componentStrings.temperature + " (°C)", componentStrings.meanTemperature + " (°C)"];

        case 'Kosten':
            return [componentStrings.price + " (CHF/kWh)", componentStrings.cost + " (CHF)"];

        default:
            return ["", ""];
    }
}

/**
 * Creates initial options for bar charts.
 * @param {String} term the chart display period
 * @param {Date|Moment|String} date the reference date
 * @param {Number} maxYears the maximal number of years, for year comparison graphs
 * @param {Boolean} stacked data sets are stacked if true
 * @param {String} yLabel the Y-axis label
 * @param {Function} clickHandler a function that handles clicks on data series legend
 */
export function createBarChartOptions(term, date, maxYears, stacked, yLabel, clickHandler) {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    type: 'category',
                    labels: getXAxisLabels(term, date, maxYears),
                    stacked: stacked,
                    autoSkip: false,
                    ticks: {
                        stepSize: 1,
                        autoSkip: false
                    },
                    scaleLabel: {
                        display: true,
                        labelString: getXAxisScaleLabel(term, date)
                    }
                }
            ],
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    stacked: stacked,
                    position: 'left',
                    ticks: {
                        beginAtZero: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    }
                }
            ]
        },
        legend: {
            position: 'top',
            labels: {
                boxWidth: 20
            },
            onClick: clickHandler
        }
    };
}

/**
 * Creates initial options for line charts. X-axis is time of day.
 * @param {Date|Moment|String} date the reference date
 * @param {Boolean} stacked data sets are stacked if true
 * @param {String} yLabel the Y-axis label
 * @param {Function} clickHandler a function that handles clicks on data series legend
 */
export function createLineChartOptions(date, stacked, yLabel, clickHandler) {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'hour',
                        displayFormats: {
                            'hour': 'HH:mm'
                        }
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 7,
                        min: moment(date).startOf('day'),
                        max: moment(date).add(1, 'days').startOf('day')
                    },
                    scaleLabel: {
                        display: true,
                        labelString: getXAxisScaleLabel('DayTime', date)
                    },
                    stacked: stacked
                }
            ],
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    stacked: stacked,
                    position: 'left',
                    ticks: {
                        beginAtZero: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    }
                }
            ]
        },
        elements: {
            line: {
                tension: 0, // disables bezier curves
                borderWidth: 2,
                fill: false
            },
            point: {
                radius: 0,
                hoverRadius: 3,
                hitRadius: 2
            }
        },
        legend: {
            position: 'top',
            labels: {
                boxWidth: 20
            },
            onClick: clickHandler
        }
    };
}

/**
 * Creates empty data sets for general stats.
 * 0=self-consumption, 1=grid-consumption, 2=surplus
 * @param {Boolean} surplus with surplus or not, defaults to true
 * @returns an array of 3 empty data sets
 */
export function createGeneralStatsDataSets(surplus = true) {
    const sets = [
        {
            label: installationStrings.selfConsumption,
            backgroundColor: '#2860c0',
            borderColor: '#2860c0',
            fill: true,
            data: [],
            hidden: false
        },
        {
            label: installationStrings.gridConsumption,
            backgroundColor: '#ff0000',
            borderColor: '#ff0000',
            fill: true,
            data: [],
            hidden: false
        }
    ];
    if (surplus) {
        sets.push({
            label: installationStrings.surplus,
            backgroundColor: '#00c060',
            borderColor: '#00c060',
            fill: true,
            data: [],
            hidden: false
        });
    }
    return sets;
}

/**
 * Creates empty data sets for general stats (but for line chart).
 * 0=self-consumption, 1=grid-consumption, 2=surplus
 * @param {Boolean} surplus with surplus or not, defaults to true
 * @returns an array of 3 empty data sets
 */
export function createGeneralDayDataSets(surplus = true) {
    const sets = [
        {
            label: installationStrings.selfConsumption,
            backgroundColor: convertHexToRGBA('#2860c0', 0.33),
            borderColor: '#2860c0',
            fill: 'origin',
            data: [],
            steppedLine: 'before',
            spanGaps: false,
            hidden: false
        },
        {
            label: installationStrings.gridConsumption,
            backgroundColor: convertHexToRGBA('#ff0000', 0.33),
            borderColor: '#ff0000',
            fill: '-1',
            data: [],
            steppedLine: 'before',
            spanGaps: false,
            hidden: false
        }
    ];
    if (surplus) {
        sets.push({
            label: installationStrings.surplus,
            backgroundColor: convertHexToRGBA('#00c060', 0.33),
            borderColor: '#00c060',
            fill: '-1',
            data: [],
            steppedLine: 'before',
            spanGaps: false,
            hidden: false
        });
    }
    return sets;
}

/**
 * Creates empty data sets for general costs (but for line chart).
 * 0=price
 * @returns an array of 1 empty data set
 */
export function createCostDayDataSets() {
    const sets = [
        {
            label: installationStrings.electricTariff,
            backgroundColor: convertHexToRGBA('#2860c0', 0.33),
            borderColor: '#2860c0',
            fill: 'none',
            data: [],
            steppedLine: 'before',
            spanGaps: false,
            hidden: false
        }
    ];
    return sets;
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {String} term the chart display period
 * @param {Date|Moment|String} date the reference date
 * @param {Number} maxYears the maximal number of years, for year comparison graphs
 * @returns an array of data values
 */
export function convertGeneralStatsData(values, term, date, nYears = 1) {
    if (values === undefined) {
        return [];
    }
    let startDate;
    let endDate;
    let stepUnit;
    let dateUnit;
    switch (term) {
        case 'MonthDays': {
            startDate = moment(date).startOf('month');
            endDate = moment(date).endOf('month');
            stepUnit = 'days';
            dateUnit = 'day';
            break;
        }
        case 'YearMonths': {
            startDate = moment(date).startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'months';
            dateUnit = 'month';
            break;
        }
        // years
        default: {
            startDate = moment(date).subtract(nYears - 1, 'years').startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'years';
            dateUnit = 'year';
            break;
        }
    }

    let gridConsumption = [];
    let selfConsumption = [];
    let gridSurplus = [];

    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].date).startOf(dateUnit);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add NaN to fill gap
                gridConsumption.push(NaN);
                selfConsumption.push(NaN);
                gridSurplus.push(NaN);
                nextDate.add(1, stepUnit);
            }
        }
        gridConsumption.push(Number(values[idx].gridAmount.toFixed(3)));
        selfConsumption.push(Number(values[idx].selfAmount.toFixed(3)));
        gridSurplus.push(Number(-values[idx].surplusAmount.toFixed(3)));
        nextDate.add(1, stepUnit);
        idx++;
    }

    // return data values
    return { selfConsumption, gridConsumption, gridSurplus };
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {String} term the chart display period
 * @param {Date|Moment|String} date the reference date
 * @param {Number} maxYears the maximal number of years, for year comparison graphs
 * @returns an array of data values
 */
export function convertGeneralCostData(values, term, date, nYears = 1) {
    if (values === undefined) {
        return [];
    }
    let startDate;
    let endDate;
    let stepUnit;
    let dateUnit;
    switch (term) {
        case 'MonthDays': {
            startDate = moment(date).startOf('month');
            endDate = moment(date).endOf('month');
            stepUnit = 'days';
            dateUnit = 'day';
            break;
        }
        case 'YearMonths': {
            startDate = moment(date).startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'months';
            dateUnit = 'month';
            break;
        }
        // years
        default: {
            startDate = moment(date).subtract(nYears - 1, 'years').startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'years';
            dateUnit = 'year';
            break;
        }
    }

    let gridConsumption = [];
    let selfConsumption = [];
    let gridSurplus = [];

    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].date).startOf(dateUnit);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add NaN to fill gap
                gridConsumption.push(NaN);
                selfConsumption.push(NaN);
                gridSurplus.push(NaN);
                nextDate.add(1, stepUnit);
            }
        }
        gridConsumption.push(Number(values[idx].gridCosts.toFixed(2)));
        selfConsumption.push(Number(values[idx].selfCosts.toFixed(2)));
        gridSurplus.push(Number(-values[idx].surplusCosts.toFixed(2)));
        nextDate.add(1, stepUnit);
        idx++;
    }

    // return data values
    return { selfConsumption, gridConsumption, gridSurplus };
}

/**
 * Creates empty data sets for line charts.
 * @param {Array} components the components
 * @param {Boolean} stacked lines are stacked or not
 * @param {Boolean} filled line area is filled or not
 * @param {Boolean} steps stepped lines
 * @returns an array of empty data sets
 */
export function createDataSetsForLineChart(components, stacked, filled, steps) {
    if ((components === undefined) || (components.length === 0)) {
        return [];
    }
    const fill = (filled) ? ((stacked) ? '-1' : 'origin') : false;
    const stepped = (steps) ? 'before' : false;
    const sets = components.map((c) => {
        return {
            label: c.name,
            backgroundColor: convertHexToRGBA(c.color, 0.33),
            borderColor: c.color,
            fill: fill,
            spanGaps: false,
            steppedLine: stepped,
            data: [],
            hidden: false
        };
    });
    if (stacked && filled) sets[0].fill = 'origin';
    return sets;
}

/**
 * Creates empty data sets for bar charts.
 * @param {Array} components the components
 * @param {Boolean} filled fill or not
 * @returns an array of empty data sets
 */
export function createDataSetsForBarChart(components, filled = true) {
    if ((components === undefined) || (components.length === 0)) {
        return [];
    }
    return components.map((c) => {
        return {
            label: c.name,
            backgroundColor: c.color,
            borderColor: c.color,
            fill: filled,
            data: [],
            hidden: false
        };
    });
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {String} term the chart display period
 * @param {Date|Moment|String} date the reference date
 * @param {Number} maxYears the maximal number of years, for year comparison graphs
 * @param {Number} decimals the number of decimals to round values
 * @returns a data set object containing the data values
 */
export function convertComponentStatisticData(values, term, date, nYears = 1, decimals = 3) {
    if ((values === undefined) || (values.length === 0)) {
        return [];
    }
    let startDate;
    let endDate;
    let stepUnit;
    let dateUnit;
    switch (term) {
        case 'MonthDays': {
            startDate = moment(date).startOf('month');
            endDate = moment(date).endOf('month');
            stepUnit = 'days';
            dateUnit = 'day';
            break;
        }
        case 'YearMonths': {
            startDate = moment(date).startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'months';
            dateUnit = 'month';
            break;
        }
        // years
        default: {
            startDate = moment(date).subtract(nYears - 1, 'years').startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'years';
            dateUnit = 'year';
            break;
        }
    }

    let dataValues = [];
    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].date).startOf(dateUnit);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add NaN to fill gap
                dataValues.push(NaN);
                nextDate.add(1, stepUnit);
            }
            // amount value
            dataValues.push(Number(values[idx].amount.toFixed(decimals)));
            nextDate.add(1, stepUnit);
            idx++;
        } else {
            // abort when value date goes beyond end date
            break;
        }
    }

    // return data set
    return dataValues;
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {String} term the chart display period
 * @param {Date|Moment|String} date the reference date
 * @param {Number} maxYears the maximal number of years, for year comparison graphs
 * @returns a data set object containing the data values
 */
export function convertComponentCostData(values, term, date, nYears = 1) {
    if ((values === undefined) || (values.length === 0)) {
        return [];
    }
    let startDate;
    let endDate;
    let stepUnit;
    let dateUnit;
    switch (term) {
        case 'MonthDays': {
            startDate = moment(date).startOf('month');
            endDate = moment(date).endOf('month');
            stepUnit = 'days';
            dateUnit = 'day';
            break;
        }
        case 'YearMonths': {
            startDate = moment(date).startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'months';
            dateUnit = 'month';
            break;
        }
        // years
        default: {
            startDate = moment(date).subtract(nYears - 1, 'years').startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'years';
            dateUnit = 'year';
            break;
        }
    }

    let dataValues = [];
    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].date).startOf(dateUnit);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add NaN to fill gap
                dataValues.push(NaN);
                nextDate.add(1, stepUnit);
            }
            // amount value
            dataValues.push(Number(values[idx].costs.toFixed(2)));
            nextDate.add(1, stepUnit);
            idx++;
        } else {
            // abort when value date goes beyond end date
            break;
        }
    }

    // return data set
    return dataValues;
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {Date|Moment|String} date the reference date
 * @param {Number} decimals the number of decimals to round values
 * @returns a data set object containing the data values
 */
export function convertComponentContinuousData(values, date, decimals = 3) {
    if ((values === undefined) || (values.length === 0)) {
        return [];
    }

    const startDate = moment(date).startOf('day');
    const endDate = moment(date).add(1, 'days').startOf('day');

    let dataValues = [];
    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].dateTime);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add NaN to fill gap
                dataValues.push({ x: nextDate, y: NaN });
                nextDate.add(15, 'minutes');
            }
            dataValues.push({
                x: vDate,
                y: Number(values[idx].value.toFixed(decimals))
            });
            nextDate.add(15, 'minutes');
            idx++;
        } else {
            // abort when value time stamp goes beyond end
            break;
        }
    }

    // fill in gap at end of day or latest value, when using stepped lines
    if (values.length > 0) {
        dataValues.push({
            x: nextDate,
            y: Number(values[values.length - 1].value.toFixed(decimals))
        });
    }

    // return data set
    return dataValues;
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {Date|Moment|String} date the reference date
 * @param {Number} decimals the number of decimals to round values
 * @returns a data set object containing the data values
 */
export function convertContinuousPriceData(values, date) {
    if ((values === undefined) || (values.length === 0)) {
        return [];
    }

    const startDate = moment(date).startOf('day');
    const endDate = moment(date).add(1, 'days').startOf('day');

    let dataValues = [];
    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].dateTime);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add NaN to fill gap
                dataValues.push({ x: nextDate, y: NaN });
                nextDate.add(15, 'minutes');
            }
            dataValues.push({
                x: vDate,
                y: Number(values[idx].price.toFixed(2))
            });
            nextDate.add(15, 'minutes');
            idx++;
        } else {
            // abort when value time stamp goes beyond end
            break;
        }
    }

    // fill in gap at end of day or latest value, when using stepped lines
    if (values.length > 0) {
        dataValues.push({
            x: nextDate,
            y: Number(values[values.length - 1].price.toFixed(2))
        });
    }

    // return data set
    return dataValues;
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {Date|Moment|String} date the reference date
 * @param {Number} decimals the number of decimals to round values
 * @returns a data set object containing the data values
 */
export function extractAutarchyData(values, date) {
    if ((values === undefined) || (values.length === 0)) {
        return [];
    }

    const startDate = moment(date).startOf('day');
    const endDate = moment(date).add(1, 'days').startOf('day');

    let dataValues = [];
    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].dateTime);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add zero to fill gap
                dataValues.push(0.0);
                nextDate.add(15, 'minutes');
            }
            dataValues.push(values[idx].coverRatio);
            nextDate.add(15, 'minutes');
            idx++;
        } else {
            // abort when value time stamp goes beyond end
            break;
        }
    }

    // fill in gap at end of day or latest value, when using stepped lines
    if (values.length > 0) {
        dataValues.push(values[values.length - 1].coverRatio);
    }

    // return data set
    return dataValues;
}

/**
 * Converts cached EVM data to chart-compatible data sets.
 * @param {Array} values the retrieved data values
 * @param {String} term the chart display period
 * @param {Date|Moment|String} date the reference date
 * @param {Number} maxYears the maximal number of years, for year comparison graphs
 * @param {Number} decimals the number of decimals to round values
 * @returns an array of data sets containing self and grid consumption
 */
export function convertComponentGeneralData(values, term, date, nYears = 1, decimals = 3) {
    if ((values === undefined) || (values.length === 0)) {
        return [ [], [] ];
    }
    let startDate;
    let endDate;
    let stepUnit;
    let dateUnit;
    switch (term) {
        case 'MonthDays': {
            startDate = moment(date).startOf('month');
            endDate = moment(date).endOf('month');
            stepUnit = 'days';
            dateUnit = 'day';
            break;
        }
        case 'YearMonths': {
            startDate = moment(date).startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'months';
            dateUnit = 'month';
            break;
        }
        // years
        default: {
            startDate = moment(date).subtract(nYears - 1, 'years').startOf('year');
            endDate = moment(date).endOf('year');
            stepUnit = 'years';
            dateUnit = 'year';
            break;
        }
    }

    let selfValues = [];
    let gridValues = [];
    const nextDate = startDate.clone();
    let idx = 0;
    while (idx < values.length) {
        let vDate = moment(values[idx].date).startOf(dateUnit);
        if (vDate.isSameOrBefore(endDate)) {
            while (vDate.isAfter(nextDate)) {
                // add 0 to fill gap
                selfValues.push(0.0);
                gridValues.push(0.0);
                nextDate.add(1, stepUnit);
            }
            // self and grid value
            selfValues.push(values[idx].selfT1 + values[idx].selfT2);
            gridValues.push(values[idx].gridT1 + values[idx].gridT2);
            nextDate.add(1, stepUnit);
            idx++;
        } else {
            // abort when value date goes beyond end date
            break;
        }
    }

    // return data set
    return [ selfValues, gridValues ];
}

/**
 * Builds an array with zero chart values. How many depends on the given term/date.
 * @param {String} term
 * @param {Date|Moment|String} date the reference date, can be JS date, moment or string
 * @param {Number} nYears the number of years to display, if the chart should just display multiple years
 * @returns an array
 */
export function getZeroChartData(term, date, nYears = 1) {
    let values = [];
    switch (term) {
        case 'MonthDays': {
            // 1 month, display day number
            const end = moment(date).endOf('month');
            let d = moment(date).startOf('month');
            while (d.isSameOrBefore(end)) {
                values.push(0.0);
                d.add(1, 'days');
            }
            break;
        }
        case 'YearMonths': {
            // 1 year, display short name of month
            const end = moment(date).endOf('year');
            let d = moment(date).startOf('year');
            while (d.isSameOrBefore(end)) {
                values.push(0.0);
                d.add(1, 'months');
            }
            break;
        }
        // 'Years'
        default: {
            // n years
            for (let i = 0; i < nYears; i++) {
                values.push(0.0);
            }
            break;
        }
    }
    return values;
}

/**
 * Builds an array with zero chart values. Only for time charts.
 * @param {Date|Moment|String} date the reference date, can be JS date, moment or string
 * @returns an array with XY values
 */
export function getZeroChartDataForDay(date) {
    const startDate = moment(date).startOf('day');
    const endDate = moment(date).add(1, 'days').startOf('day');
    let values = [];
    const nextDate = startDate.clone();
    while (nextDate.isSameOrBefore(endDate)) {
        values.push({
            x: nextDate.clone(),
            y: 0.0
        });
        nextDate.add(15, 'minutes');
    }
    return values;
}

/**
 * Adds each value of an array to an other array.
 * @param {Array} sumArray the array that will contain the sum, also the first summand
 * @param {Array} arrayToAdd the other summand
 * @param {Boolean} isXY array contains XY-values, defaults to false
 */
export function arraysAddToSum(sumArray, arrayToAdd, isXY = false) {
    const nValues = (sumArray.length <= arrayToAdd.length) ? sumArray.length : arrayToAdd.length;
    if (isXY) {
        for (let i = 0; i < nValues; i++) {
            // assume zero when NaN
            if (!isNaN(arrayToAdd[i].y)) {
                sumArray[i].y += arrayToAdd[i].y;
            }
        }
    } else {
        for (let i = 0; i < nValues; i++) {
            sumArray[i] += arrayToAdd[i];
        }
    }
}

/**
 * Gets self and grid consumption using autarchy.
 * @param {Array} pValues an array with day-time values
 * @param {Array} autarchy an array with day-time autarchy values
 */
export function createDatasetsWithAutarchy(pValues, autarchy) {
    const nValues = (pValues.length <= autarchy.length) ? pValues.length : autarchy.length;
    const selfValues = [];
    const gridValues = [];
    for (let i = 0; i < nValues; i++) {
        const aut = isNaN(autarchy[i]) ? 0.0 : autarchy[i];
        const sval = pValues[i].y * aut;
        const gval = pValues[i].y * (1.0 - aut);
        selfValues.push({
            x: pValues[i].x,
            y: Number(sval.toFixed(3))
        });
        gridValues.push({
            x: pValues[i].x,
            y: Number(gval.toFixed(3))
        });
    }
    return [ selfValues, gridValues ];
}

/**
 * Gets a surplus data set from total consumption and production.
 * @param {Array} prodValues the total production values
 * @param {Array} consValues the total consumption values
 */
export function createSurplusDataset(prodValues, consValues) {
    const nValues = (prodValues.length <= consValues.length) ? prodValues.length : consValues.length;
    const surplusValues = [];
    let surplus;
    for (let i = 0; i < nValues; i++) {
        // surplus = production - consumption, or NaN (= invisible)
        if (isNaN(prodValues[i].x) || isNaN(prodValues[i].y)) {
            surplus = NaN;
        } else if (prodValues[i].y > consValues[i].y) {
            surplus = prodValues[i].y - consValues[i].y;
        } else {
            surplus = 0.0;
        }
        surplusValues.push({
            x: prodValues[i].x,
            y: Number(surplus.toFixed(3))
        });
    }
    return surplusValues;
}