import React, { Component } from 'react';
import { homeStrings } from '../i18n/translations';

/**
 * A service notification item.
 */
class ServiceNotificationItem extends Component {

    render() {
        if ((this.props.notification !== undefined) && (this.props.notification != null)) {
            return (
                <div style={{ margin: '0.7em' }}>
                    <div className="p-grid">
                        <div className="p-col-fixed">
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {this.props.notification.validBegin}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                {ServiceNotificationItem.renderIcon(this.props.notification.notificationType)}
                            </div>
                        </div>
                        <div className="p-col">
                            {this.props.notification.text}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }

    // render installation status
    static renderIcon(notificationType) {
        switch(notificationType) {
            case 1:
                return (<span className="pi pi-check-circle notification-ok" title={homeStrings.notificationGood} style={{ fontSize: '1em' }} />);

            case 2:
                return (<span className="pi pi-exclamation-triangle notification-warning" title={homeStrings.notificationWarning} style={{ fontSize: '1em' }} />);

            case 3:
                return (<span className="pi pi-exclamation-triangle notification-critical" title={homeStrings.notificationCritical} style={{ fontSize: '1em' }} />);

            case 4:
                return (<span className="pi pi-info-circle notification-info" title={homeStrings.notificationInfo} style={{ fontSize: '1em' }} />);

            default:
                return (<span className="pi pi-question-circle notification-default" title={homeStrings.notificationDefault} style={{ fontSize: '1em' }} />);
        }
    }
}

export default ServiceNotificationItem;