import BuildConfig from '../config/BuildConfig';
import Reactotron from '../config/ReactotronConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { authenticationLogin, authenticationLogout } from '../actions/AuthActions.js';
import { AuthSelectors } from '../redux/AuthRedux';
import { headerStrings, homeStrings, mgmtStrings, errorStrings } from '../i18n/translations';

/**
 * The login page.
 */
class LoginView extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        // This binding is necessary to make `this` work in the callback
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        // on enter
        if (BuildConfig.isReactotronEnabled) {
            Reactotron.log("Login view did mount.");
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleLogin(values, resetForm) {
        this.props.doLogin(values.email, values.password)
            .then((result) => {
                if (!this.mounted) return;
                if (result.success === true) {
                    // redirect
                    setTimeout(() => {
                        // go to installations
                        window.location.hash = "#/installations";
                    }, 1000);
                    // clear form
                    Object.keys(values).forEach(key => (values[key] = ''));
                    resetForm(values);
                }
            });
    }

    handleLogout() {
        this.props.doLogout();
    }

    validateLoginForm(values) {
        let errors = {};
        if (!values.email) {
            errors.email = errorStrings.emailEmpty;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            // test for invalid characters
            errors.email = errorStrings.invalidCharacters;
        }
        if (!values.password) {
            errors.password = errorStrings.passwordEmpty;
        }
        return errors;
    }

    render() {
        const navItems = [
            { name: headerStrings.installationList, enabled: this.props.isAuthenticated, active: false, icon: "fas fa-list-ol", path: "/installations" }
        ];

        return (
            <div className="p-component page-root">
                <Header navItems={navItems} showRefresh={true} isRefreshing={this.props.isAuthenticating} />
                <div className="page-content">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-sm-8 p-md-6">
                            <Panel header={headerStrings.login}>
                                {((this.props.isAuthenticated === false) || (this.props.isExpired === true)) ? (
                                    <Formik
                                        initialValues={{ email: "", password: "" }}
                                        validate={this.validateLoginForm}
                                        onSubmit={(values, {resetForm}) => this.handleLogin(values, resetForm)}>
                                        <Form autoComplete="on">
                                            <div className="p-grid">
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <span className="p-float-label">
                                                        <Field id="emailIn" type="email" name="email" size="28" className="p-inputtext" />
                                                        <label htmlFor="emailIn">{mgmtStrings.eMail}</label>
                                                    </span>
                                                    <ErrorMessage name="email" render={msg => <Message severity='warn' text={msg} />} />
                                                </div>
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <span className="p-float-label">
                                                        <Field id="passwordIn" type="password" name="password" size="28" className="p-inputtext" />
                                                        <label htmlFor="passwordIn">{mgmtStrings.password}</label>
                                                    </span>
                                                    <ErrorMessage name="password" render={msg => <Message severity='warn' text={msg} />} />
                                                </div>
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <button type="submit" className="p-button" style={{ padding: 8 }}>{headerStrings.login}</button>
                                                    &nbsp;<Link to="/"><Button className="p-button-secondary" label={headerStrings.goBack} /></Link>
                                                </div>
                                                <div className="p-col-12" style={{ padding: '1em' }}>
                                                    <Link to="/resetpassword">{mgmtStrings.forgotPassword}</Link>
                                                </div>
                                                {(this.props.authError != null) && (
                                                    <div className="p-col-12" style={{ padding: '1em' }}>
                                                        <Message severity="error" text={this.props.authError} />
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                    </Formik>
                                ) : (
                                    <div>
                                        <p>{homeStrings.loggedInAs} {this.props.userName}.</p>
                                        <Button label={headerStrings.logout} onClick={this.handleLogout} />
                                        &nbsp;<Link to="/"><Button className="p-button-secondary" label={headerStrings.goBack} /></Link>
                                    </div>
                                )}
                            </Panel>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticating: AuthSelectors.isAuthenticating(state),
        isAuthenticated: AuthSelectors.isAuthenticated(state),
        isExpired: AuthSelectors.isExpired(state),
        userName: AuthSelectors.selectUserName(state),
        authError: AuthSelectors.selectError(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        doLogin: (email, password) => dispatch(authenticationLogin(email, password)),
        doLogout: () => dispatch(authenticationLogout())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginView);