/**
 * General statistic data reducer.
 * Contains monthly values for each year, for each installation.
 * Years are represented by ISO dates (first day of year).
 */

/* ------------- Types ------------- */

// types
export const GeneralYearMonthTypes = {
  GENERAL_YEAR_MONTH_CLEAR_INSTALLATION: 'GENERAL_YEAR_MONTH_CLEAR_INSTALLATION',
  GENERAL_YEAR_MONTH_CLEAR_ALL: 'GENERAL_YEAR_MONTH_CLEAR_ALL',
  GENERAL_YEAR_MONTH_UPDATE: 'GENERAL_YEAR_MONTH_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const GeneralYearMonthSelectors = {
  selectAllOfInstallation: (state, installationId) => state.generalYearMonthData[installationId]
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onGeneralYearMonthUpdate(state, payload) {
  // create or update
  const { installationId, date, values } = payload;
  const instData = state[installationId];
  if (instData === undefined) {
    // no installation data -> create year with values
    return {
      ...state,
      [installationId]: {
        [date]: values
      }
    };
  } else {
    // update installation data -> set year with values
    const newInstData = { ...instData, [date]: values };
    return {
      ...state,
      [installationId]: newInstData
    };
  }
}

function onGeneralYearMonthClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GeneralYearMonthTypes.GENERAL_YEAR_MONTH_UPDATE: {
      return onGeneralYearMonthUpdate(state, action.payload);
    }
    case GeneralYearMonthTypes.GENERAL_YEAR_MONTH_CLEAR_INSTALLATION: {
      return onGeneralYearMonthClearInstallation(state, action.payload);
    }
    case GeneralYearMonthTypes.GENERAL_YEAR_MONTH_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;