import evmConfig from '../config/EvmApiConfig';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActualValue from './ActualValue';
import { calculateImageHeightFromWidth } from './util/ImageUtils';
import { countryCodeMap } from '../i18n/translations';
import defaultImage from '../images/installation_default.png';

/**
 * An installation list item.
 */
class InstallationListItem extends Component {

    render() {
        if ((this.props.installation !== undefined) && (this.props.installation != null)) {
            const imageUrl = (this.props.installation.photoFile !== null) ? evmConfig.baseUrl + this.props.installation.photoFile : defaultImage;
            const linkTo = "/installations/" + this.props.installation.id + "/overview";

            return (
                <div style={{ margin: '0.7em' }}>
                    <Link to={linkTo} title={this.props.installation.name} style={{ color: 'initial' }}>
                        <div className="p-grid p-align-center" style={{ backgroundColor: '#fcfcfc', border: '1px solid #d0d0d0', borderRadius: 4 }}>
                            <div className="p-col-12" style={{ textAlign: 'center', fontWeight: 'bold', color: '#2860c0' }}>
                                {this.props.installation.name}
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-4">
                                <div style={{ padding: '0.5em', overflow: 'auto' }}>
                                    <img src={imageUrl} width="100" height={calculateImageHeightFromWidth(100)} alt={this.props.installation.name} style={{ float: 'left', marginRight: '1.5em' }} />
                                    <table>
                                        <tbody>
                                            <tr><td>{this.props.installation.address}</td></tr>
                                            <tr><td>{this.props.installation.location}</td></tr>
                                            <tr><td>{countryCodeMap[this.props.installation.country]}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="p-col-12 p-sm-6 p-md-6 p-lg-2 p-xl-2" style={{ textAlign: 'center' }}>
                                {InstallationListItem.renderStatus(this.props.installation.status)}
                            </div>
                            <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-2">
                                <ActualValue value={this.props.installation.powerFlow.consumptionTotal} unit="kW" color="#ff0000" decimals={3} icon="fas fa-plug" />
                            </div>
                            <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-2">
                                <ActualValue value={this.props.installation.powerFlow.productionTotal} unit="kW" color="#008000" decimals={3} icon="fas fa-sun" />
                            </div>
                            <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-2">
                                <ActualValue value={this.props.installation.price.amount} unit="CHF/kWh" color={this.props.installation.price.color} decimals={2} icon="fas fa-piggy-bank" />
                            </div>
                        </div>
                    </Link>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }

    // render installation status
    static renderStatus(instStatus) {
        switch(instStatus.status) {
            case 1:
                return (<span className="pi pi-exclamation-triangle condition-error" title={instStatus.text} style={{ fontSize: '3em' }} />);

            case 2:
                return (<span className="pi pi-info-circle condition-warning" title={instStatus.text} style={{ fontSize: '3em' }} />);

            case 3:
                return (<span className="pi pi-check-circle condition-ok" title={instStatus.text} style={{ fontSize: '3em' }} />);

            default:
                return (<span className="pi pi-question-circle condition-default" title={instStatus.text} style={{ fontSize: '3em' }} />);
        }
    }
}

export default InstallationListItem;