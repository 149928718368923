/**
 * Component continuous data reducer.
 * Contains 15-minute values for each day, for each component of each installation.
 * Days are represented by ISO dates (YYYY-MM-DD).
 */

/* ------------- Types ------------- */

// types
export const ComponentDayTimeTypes = {
  COMPONENT_DAY_TIME_CLEAR_INSTALLATION: 'COMPONENT_DAY_TIME_CLEAR_INSTALLATION',
  COMPONENT_DAY_TIME_CLEAR_ALL: 'COMPONENT_DAY_TIME_CLEAR_ALL',
  COMPONENT_DAY_TIME_UPDATE: 'COMPONENT_DAY_TIME_UPDATE'
};

/* ------------- Selectors ------------- */

// exported selectors to access state (based on root reducer)
export const ComponentDayTimeSelectors = {
  selectAllOfInstallation: (state, installationId) => state.componentDayTimeData[installationId],
  selectAllOfComponent: (state, installationId, componentId) => {
    const instData = state.componentDayTimeData[installationId];
    return (instData !== undefined) ? instData[componentId] : undefined;
  }
};

/* ------------- Initial state ------------- */

// use object as pseudo-array with integer keys
const initialState = {};

/* ------------- Reducer Function ------------- */

function onComponentDayTimeUpdate(state, payload) {
  // create or update
  const { installationId, componentId, date, values } = payload;
  const instData = state[installationId];
  if (instData !== undefined) {
    // update component data -> set month with values
    const compData = instData[componentId];
    if (compData !== undefined) {
      const newCompData = { ...compData, [date]: values };
      const newInstData = { ...instData, [componentId]: newCompData };
      return {
        ...state,
        [installationId]: newInstData
      };
    } else {
      // no component -> create component with ...
      const newInstData = { ...instData, [componentId]: { [date]: values } };
      return {
        ...state,
        [installationId]: newInstData
      };
    }
  } else {
    // no installation data -> create component with month and values
    return {
      ...state,
      [installationId]: {
        [componentId]: {
          [date]: values
        }
      }
    };
  }
}

function onComponentDayTimeClearInstallation(state, payload) {
  // remove given installation and keep the rest
  const installationId = payload;
  const { [installationId]: value, ...rest } = state;
  return rest;
}

/**
 * Gets the next reducer state based on a given state and an action.
 * @param {*} state the current state (immutable!)
 * @param {Object} action the action
 * @returns the new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ComponentDayTimeTypes.COMPONENT_DAY_TIME_UPDATE: {
      return onComponentDayTimeUpdate(state, action.payload);
    }
    case ComponentDayTimeTypes.COMPONENT_DAY_TIME_CLEAR_INSTALLATION: {
      return onComponentDayTimeClearInstallation(state, action.payload);
    }
    case ComponentDayTimeTypes.COMPONENT_DAY_TIME_CLEAR_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

// export creator as default
export default reducer;